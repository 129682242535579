import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SplitIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path d="M7.5.5v15h-1V.5h1zM4 1a1 1 0 0 1 1 1h0v12a1 1 0 0 1-1 1h0-3a1 1 0 0 1-1-1h0V2a1 1 0 0 1 1-1h0zm9 0a1 1 0 0 1 1 1h0v12a1 1 0 0 1-1 1h0-3a1 1 0 0 1-1-1h0V2a1 1 0 0 1 1-1h0zM4 2H1v12h3V2zm9 0h-3v12h3V2z" />
    </Icon>
  );
}
