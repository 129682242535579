import type { ReactNode } from 'react';

import Typography from '../Typography';

import styles from './WizardHeader.module.css';

interface WizardHeaderProps {
  children?: ReactNode;
  label: string;
  title: string;
}

const WizardHeader = ({ children, label, title }: WizardHeaderProps) => {
  return (
    <header className={styles.wizardHeader}>
      <div className={styles.titleContainer}>
        <Typography className={styles.label} hasNoWrap>
          {label}
        </Typography>
        <Typography className={styles.title} hasNoWrap>
          {title}
        </Typography>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </header>
  );
};

export default WizardHeader;
