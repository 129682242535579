import * as zod from 'zod';

import { NoGripperConfiguration } from '../../NoGripper/types/Configuration';
import { OnRobot2FG7GrippersConfiguration } from '../../OnRobot2FG7/types/Configuration';
import { OnRobot3FG15GrippersConfiguration } from '../../OnRobot3FG15/types/Configuration';

export const DualGripperCompatibleGrippers = zod.union([
  OnRobot2FG7GrippersConfiguration,
  OnRobot3FG15GrippersConfiguration,
  NoGripperConfiguration,
]);

export type DualGripperCompatibleGrippers = zod.infer<
  typeof DualGripperCompatibleGrippers
>;

export const DualEquipmentConfiguration = zod.object({
  kind: zod.literal('OnRobotDualQuickChanger'),
  name: zod.string().optional(),
  grippers: zod.object({
    primary: DualGripperCompatibleGrippers,
    secondary: DualGripperCompatibleGrippers,
  }),
});

export type DualGripperConfiguration = zod.infer<
  typeof DualEquipmentConfiguration
>;
