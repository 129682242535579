import type {
  RoutineRunnerState,
  RoutineRunningPauseDetails,
} from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getPauseReasons(
  state: RoutineRunnerState | null,
): RoutineRunningPauseDetails[] {
  if (state?.kind === 'RoutineRunning' && state.isPaused) {
    return state.pauseDetails;
  }

  return [];
}

export function useRobotPauseDetails(
  args: UseRoutineRunnerHandleArguments,
): RoutineRunningPauseDetails[] {
  return useRobotState(args, getPauseReasons);
}
