import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AddAfterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path
        fillRule="evenodd"
        d="M0 0.5C0 0.223858 0.223858 0 0.5 0H5.5C5.77614 0 6 0.223858 6 0.5V15.5C6 15.7761 5.77614 16 5.5 16H0.5C0.223858 16 0 15.7761 0 15.5V0.5ZM1 1V15H5V1H1ZM10.5 7.5V5H11.5V7.5H14V8.5H11.5V11H10.5V8.5H8V7.5H10.5Z"
      />
    </Icon>
  );
}
