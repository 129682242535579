import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SearchIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 11 11">
      <path d="M7.31 8.016A4.48 4.48 0 0 1 4.5 9a4.5 4.5 0 1 1 0-9A4.5 4.5 0 0 1 9 4.5a4.48 4.48 0 0 1-.984 2.809l2.838 2.838a.5.5 0 0 1-.707.707L7.31 8.016zM4.5 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 1 0 0 7z" />
    </Icon>
  );
}
