import styles from './justifyContent.module.css';

export default {
  start: styles.start,
  end: styles.end,
  center: styles.center,
  stretch: styles.stretch,
  spaceBetween: styles.spaceBetween,
  spaceAround: styles.spaceAround,
};
