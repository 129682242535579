import { roundToDecimalPlaces } from '@sb/utilities';

import type { MotionPlanRequest } from './MotionPlanRequest';

/**
 * Function to get a cache key for a motion plan request.
 * Separated into another file for cleanliness and easier testing.
 *
 * @param request
 * @param decimalDigits
 */
export function getMotionPlanRequestCacheKey(
  request: MotionPlanRequest,
  decimalDigits = 2,
): string {
  return JSON.stringify(request, (_: string, val: any) => {
    if (typeof val === 'number') {
      return roundToDecimalPlaces(val, decimalDigits);
    }

    return val;
  });
}
