import type { IOLevel, MoveArmToStepVariables } from '@sb/routine-runner';
import type { RoutineVariable, Step } from '@sb/types';

/** Message displayed to users when a step hasn't been configured yet. */
export const ACTION_REQUIRED_STEP_MESSAGE_DEFAULT =
  'This step needs to be configured.';

export const DECORATOR_STEP_LIST: Step.Kind[] = [
  'AddOffset',
  'RunInBackground',
  'If',
  'Loop',
  'HaasControlRegion',
];

// steps based on integration. Integration steps only show when integration enabled
export const INTEGRATION_STEP_LIST: Step.Kind[] = [
  'ActuateGripper',
  'ActuateScrewdriver',
  'ActuateVacuum',
];

export const LOOP_STEP_FIXED_LOOP_MIN = 1;

export const STEP_NAME_BY_STEP_KIND: Record<Step.Kind, string> = {
  ActuateGripper: 'Actuate Gripper',
  ActuateScrewdriver: 'Actuate Screwdriver',
  ActuateVacuum: 'Actuate Vacuum',
  AddOffset: 'Add Offset',
  AnticipatePayload: 'Set Payload',
  RunInBackground: 'Run in Background',
  HaasRunProgram: 'Run Haas Program',
  HaasControlRegion: 'Monitor Haas Machine',
  If: 'If',
  Loop: 'Loop',
  LoopControl: 'Restart Loop',
  MoveArmTo: 'Move Arm',
  PressButton: 'Press Button',
  SetEnvironmentVariable: 'Set Variable',
  SetIO: 'Set I/O Output',
  Wait: 'Wait',
  WaitForConfirmation: 'Wait for Confirmation',
};

export const MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE: Record<
  MoveArmToStepVariables['currentActivity'],
  string
> = {
  moving: 'Moving Arm',
  none: 'Not Moving to Position',
  paused: 'Paused',
  planning: 'Planning Motion',
  receivingPlan: 'Receiving Motion Plan',
  requestingPlan: 'Requesting Motion Plan',
};

function toEnumValues<V extends string>(
  byValue: Record<V, string>,
): RoutineVariable.EnumValue<V>[] {
  return Object.entries(byValue).map(([value, displayName]) => ({
    value: value as V,
    displayName: displayName as string,
  }));
}

const MOVE_ARM_VARIABLE_LIST: RoutineVariable.Information[] = [
  {
    variableName: 'currentActivity',
    displayName: 'Current Activity',
    variableKind: 'enum',
    values: toEnumValues(MOVE_ARM_TO_VARIABLE_NAME_BY_VALUE),
  },
];

/**
 * All steps that support output variables.
 */
export const VARIABLE_INFO_LIST_BY_STEP_KIND: Record<
  Step.Kind,
  RoutineVariable.Information[]
> = {
  ActuateScrewdriver: [],
  ActuateGripper: [],
  ActuateVacuum: [],
  AnticipatePayload: [],
  AddOffset: [],
  HaasControlRegion: [
    {
      variableName: 'haasProgram',
      displayName: 'Currently Running Program',
      variableKind: 'string',
    },
    {
      variableName: 'haasStatus',
      displayName: 'Current Haas Machine Status',
      variableKind: 'string',
    },
    {
      variableName: 'haasPartCount',
      displayName: 'Haas Part Count',
      variableKind: 'number',
      minimum: 0,
    },
  ],
  HaasRunProgram: [
    {
      variableName: 'isProgramComplete',
      displayName: 'Machine Program Complete',
      variableKind: 'boolean',
      values: [
        { value: true, displayName: 'Complete' },
        { value: false, displayName: 'Not Complete' },
      ],
    },
  ],
  If: [],
  Loop: [
    {
      variableName: 'currentIteration',
      displayName: 'Current Iteration',
      variableKind: 'number',
      minimum: 0,
      maximum: Infinity,
    },
  ],
  LoopControl: [],
  MoveArmTo: [
    ...MOVE_ARM_VARIABLE_LIST,
    {
      variableName: 'completedCount',
      displayName: 'Completed Count',
      variableKind: 'number',
    },
  ],
  PressButton: MOVE_ARM_VARIABLE_LIST,
  RunInBackground: [
    {
      variableName: 'isRunning',
      displayName: 'Current Activity',
      variableKind: 'boolean',
      values: [
        { value: true, displayName: 'Running' },
        { value: false, displayName: 'Not Running' },
      ],
    },
  ],
  SetEnvironmentVariable: [],
  SetIO: [],
  Wait: [],
  WaitForConfirmation: [
    {
      variableName: 'confirmed',
      displayName: 'Confirmed',
      variableKind: 'boolean',
      values: [
        { value: true, displayName: 'Confirmed' },
        { value: false, displayName: 'Not confirmed' },
      ],
    },
  ],
};

/**
 * Each variable kind has a different set of operators.
 * We're list here all supported operators for each
 * variable kind.
 */
export const OPERATOR_INFO_LIST_BY_VARIABLE_KIND: Record<
  RoutineVariable.Kind,
  RoutineVariable.OperatorInformation[]
> = {
  boolean: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  composite: [],
  enum: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
  expression: [{ displayName: 'Is True', operator: '==' }],
  number: [
    { displayName: 'Greater Than', operator: '>' },
    { displayName: 'Equal To', operator: '==' },
    { displayName: 'Less Than', operator: '<' },
  ],
  string: [
    { displayName: 'Is', operator: '==' },
    { displayName: 'Is Not', operator: '!=' },
  ],
};

export const IO_STATE_VARIABLES_DEFAULT: RoutineVariable.EnumValue<IOLevel>[] =
  [
    { displayName: 'High', value: 'high' },
    { displayName: 'Low', value: 'low' },
  ];

export const SET_SPEED_SUPPORTED_STEP_KIND: Step.Kind[] = [
  'MoveArmTo',
  'PressButton',
];
