import * as zod from 'zod';

import {
  OR_3FG15_FORCE_NEWTONS_MIN,
  OR_3FG15_FORCE_NEWTONS_MAX,
  OR3FG15GripKind,
} from './constants';

export const OR3FGForce = zod
  .number()
  .min(OR_3FG15_FORCE_NEWTONS_MIN)
  .max(OR_3FG15_FORCE_NEWTONS_MAX);

export const OnRobot3FG15Command = zod.object({
  kind: zod.literal('OnRobot3FG15Command'),
  /**
   * This field sets whether the grip will be inward or outward.
   * It also sets the if the diameter is measured from the inside of the fingertips
   * (inward grip) or from the outside of the fingertips (outward grip).
   */
  gripKind: OR3FG15GripKind,

  /**
   * This field sets the target force to be reached when gripping and holding a
   * workpiece in Newtons.
   * If not provided, the command is treated as a move command.
   */
  targetForce: OR3FGForce.optional(),

  targetDiameter: zod.number(),

  /**
   * How close to the target do we need to be to consider it a success?
   */
  targetDiameterTolerance: zod.number().optional(),

  /**
   * This field will fail the actuation if the force grip is not detected
   * after actuating a grip command.
   */
  waitForGripToContinue: zod.boolean().optional(),

  /**
   * OnRobot3FG15 specific feature, move until a grip is detected
   */
  isFlexGrip: zod.boolean().optional(),
});

export type OnRobot3FG15Command = zod.infer<typeof OnRobot3FG15Command>;
