import type {
  ArmJointAccelerations,
  ArmJointVelocities,
} from '@sb/motion-planning';
import {
  COLLABORATIVE_MAX_JOINT_ACCELERATIONS,
  COLLABORATIVE_MAX_JOINT_SPEEDS,
  COLLABORATIVE_MAX_TOOLTIP_SPEED,
} from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';

export const calculateSpeedProfileFromPercent = (
  speedPercent: number,
  baseSpeedProfile?: SpeedProfile,
): SpeedProfile => {
  const multiplier = speedPercent / 100;

  const MAX_JOINT_SPEEDS =
    baseSpeedProfile?.maxJointSpeeds ?? COLLABORATIVE_MAX_JOINT_SPEEDS;

  const MAX_JOINT_ACCELERATIONS =
    baseSpeedProfile?.maxJointAccelerations ??
    COLLABORATIVE_MAX_JOINT_ACCELERATIONS;

  const MAX_TOOLTIP_SPEED =
    baseSpeedProfile?.maxTooltipSpeed ?? COLLABORATIVE_MAX_TOOLTIP_SPEED;

  const maxJointSpeeds = MAX_JOINT_SPEEDS.map((jointSpeed) => {
    return multiplier * jointSpeed;
  }) as ArmJointVelocities;

  const maxJointAccelerations = MAX_JOINT_ACCELERATIONS.map(
    (jointAcceleration) => {
      return multiplier * jointAcceleration;
    },
  ) as ArmJointAccelerations;

  const maxTooltipSpeed = multiplier * MAX_TOOLTIP_SPEED;

  return {
    maxJointSpeeds,
    maxJointAccelerations,
    maxTooltipSpeed,
  };
};
