import type { Paginated } from '@feathersjs/feathers';

import { isPaginated } from './isPaginated';

/**
 * Run `mapper` over each item in a `find` response,
 * returning an array of mapped items
 */
export function mapPaginated<T, U>(
  data: T | T[] | Paginated<T>,
  mapper: (data: T) => U,
): U[] {
  if (Array.isArray(data)) {
    return data.map(mapper);
  }

  if (isPaginated(data)) {
    return data.data.map(mapper);
  }

  return [mapper(data)];
}
