import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PauseIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 15 22">
      <path d="M0 0h2v22H0V0zm13 0h2v22h-2V0z" />
    </Icon>
  );
}
