type Value<Data, F> = F extends keyof Data ? Data[F] : any;

class PatchBuilder<Data> {
  private $set?: Record<string, any>;

  private $unset?: Record<string, true>;

  /** Set top level (unnested) data */
  public setData(data: Partial<Data>): this {
    this.$set = { ...this.$set, ...data };

    return this;
  }

  /** Set a single field (can be deeply nested) */
  public set<F extends string>(fieldId: F, value: Value<Data, F>): this {
    if (!this.$set) {
      this.$set = {};
    }

    this.$set[fieldId] = value;

    return this;
  }

  /** Remove a single field (can be deeply nested) */
  public unset(fieldId: string): this {
    if (!this.$unset) {
      this.$unset = {};
    }

    this.$unset[fieldId] = true;

    return this;
  }

  /** Duplicate this patchBuilder */
  public copy(): PatchBuilder<Data> {
    const copy = new PatchBuilder<Data>();
    copy.$set = this.$set && { ...this.$set };
    copy.$unset = this.$unset && { ...this.$unset };

    return copy;
  }

  public buildPatch(): Record<string, any> {
    return {
      $set: this.$set,
      $unset: this.$unset,
    };
  }
}

/**
 * Build a record to send to a `patch` update
 */
export function patchBuilder<Data = unknown>() {
  return new PatchBuilder<Data>();
}
