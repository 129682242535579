import type { CartesianPose } from '@sb/geometry';
import { distanceBetweenPoses } from '@sb/geometry';
import { Logger } from '@sb/logger';
import type { ArmTarget } from '@sb/motion-planning';
import type { EventEmitter } from '@sb/utilities';

// Distance threshold to determine if a waypoint has been reached
const WAYPOINT_REACHED_THRESHOLD_DIST = 0.1;

// Interval to check if a waypoint has been reached
const WAYPOINT_REACHED_CHECK_INTERVAL_MS = 100;

/**
 * This class is a helper class for RoutineContext to track if a waypoint has
 * been reached based on the current pose and the target list.  This is to solve
 * the issue when we pause and resume during a motion with multiple waypoints.
 * This class is separated to keep the logic separate from the RoutineContext class.
 *
 * This class works by checking the current pose against first target in the list.
 * Once the distance is below a threshold, the target is considered visited, and
 * the next target is checked.  This continues until all targets are visited.
 */
export class WaypointReachedTracker {
  private targets: ArmTarget[];

  private events: EventEmitter<any>;

  private readonly getCurrentPose: () => CartesianPose;

  private readonly getTargetPose: (
    armTarget: ArmTarget,
    useMotionPlannerForwardKinematics: boolean,
  ) => Promise<CartesianPose>;

  private nextTargetPose: CartesianPose | null = null;

  private logger: Logger = new Logger();

  private checkWaypointReachedInterval: ReturnType<typeof setInterval> | null =
    null;

  constructor({
    targets,
    events,
    getCurrentPose,
    getTargetPose,
  }: {
    targets: ArmTarget[];
    events: EventEmitter<any>;
    getCurrentPose: () => CartesianPose;
    getTargetPose: (
      armTarget: ArmTarget,
      useMotionPlannerForwardKinematics: boolean,
    ) => Promise<CartesianPose>;
  }) {
    this.targets = targets;
    this.events = events;
    this.getCurrentPose = getCurrentPose;
    this.getTargetPose = getTargetPose;

    this.updateNextTargetPose();
  }

  /**
   * Sets interval to call the checkWaypointReached function
   */
  public setCheckWaypointReachedInterval() {
    if (this.targets.length <= 1) {
      return;
    }

    this.logger.info(
      `Setting check waypoint reached for ${this.targets.length} points with interval ${WAYPOINT_REACHED_CHECK_INTERVAL_MS}`,
    );

    this.clearCheckWaypointReachedInterval();

    // if we are using waypoints, run the check to see if we have reached a waypoint
    this.checkWaypointReachedInterval = setInterval(async () => {
      await this.checkWaypointReached();
    }, WAYPOINT_REACHED_CHECK_INTERVAL_MS);
  }

  /**
   * Clears the checkWaypointReached interval
   */
  public clearCheckWaypointReachedInterval() {
    if (!this.checkWaypointReachedInterval) {
      return;
    }

    this.logger.info('Clearing check waypoint reached interval');
    clearInterval(this.checkWaypointReachedInterval);
    this.checkWaypointReachedInterval = null;
  }

  public isChecking(): boolean {
    return this.checkWaypointReachedInterval !== null;
  }

  /**
   * Check to see if we have reached the next waypoint.
   */
  private async checkWaypointReached() {
    if (this.targets.length <= 1) {
      this.clearCheckWaypointReachedInterval();

      return;
    }

    const dist = distanceBetweenPoses(
      this.getCurrentPose(),
      this.nextTargetPose as CartesianPose,
    );

    this.logger.debug('distance to next waypoint:', dist);

    if (dist <= WAYPOINT_REACHED_THRESHOLD_DIST) {
      // remove the first target.  When the first routine is resumed, it will
      // replay from the next target, since we have removed the first one
      this.logger.info('Reached waypoint');
      this.events.emit('waypointReached');
      this.targets.shift();
      await this.updateNextTargetPose();
    }
  }

  private async updateNextTargetPose() {
    if (this.targets.length > 0) {
      this.nextTargetPose = await this.getTargetPose(this.targets[0], false);
    }
  }
}
