export type CallbackPropsType<T> = {
  lastFrame: T | undefined;
  timeSinceLastFrame: number;
  timeSinceStart: number;
};
type CallbackType<T> = (props: CallbackPropsType<T>) => T;

export abstract class AbstractAnimation<T> {
  protected startTs: number = 0;

  protected lastFrameTs: number = 0;

  protected lastFrame: T | undefined;

  protected callback: CallbackType<T> | undefined;

  public constructor(protected msPerFrame: number = 50) {}

  public setCallback(callback: CallbackType<T>) {
    this.callback = callback;
  }

  public abstract get running(): boolean;

  public abstract start(): void;

  public abstract run(): Promise<void>;

  public abstract stop(): void;
}
