import type {
  RoutineRunnerState,
  TaggedRoutineStepVariables,
} from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

type UseStepVariablesState = Record<string, TaggedRoutineStepVariables> | null;

function getVariablesFromRoutineRunnerState(
  routineRunnerState: RoutineRunnerState | null,
): UseStepVariablesState {
  return routineRunnerState?.kind === 'RoutineRunning'
    ? routineRunnerState.variables
    : null;
}

export default function useRobotVariables(
  args: UseRoutineRunnerHandleArguments,
): UseStepVariablesState {
  return useRobotState(args, getVariablesFromRoutineRunnerState);
}
