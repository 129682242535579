import type { RoutineData } from '@sb/feathers-types';
import type { Routine } from '@sb/types';

export const ROUTINE_DATA_SUMMARY_FIELDS = [
  'id',
  'isTemplate',
  'createdAt',
  'createdByID',
  'createdByName',
  'createdByPhoto',
  'description',
  'name',
  'updatedAt',
] as const;

const ROUTINE_PATTERN_COUNT = 8;

export function generateRoutineCover(): string {
  const patternNumber = Math.floor(Math.random() * ROUTINE_PATTERN_COUNT) + 1;

  return `/images/patterns/${patternNumber}.jpg`;
}

const TWENTYFOUR_HOURS = 24 * 60 * 60 * 1000;

export function convertRoutineSummary(
  data: Pick<RoutineData, typeof ROUTINE_DATA_SUMMARY_FIELDS[number]>,
): Routine.Summary {
  const createdAt = new Date(data.createdAt);
  const updatedAt = new Date(data.updatedAt);

  const isNew = Date.now() - createdAt.valueOf() < TWENTYFOUR_HOURS;

  return {
    cover: generateRoutineCover(),
    createdAt: new Date(data.createdAt),
    createdBy: {
      id: data.createdByID,
      isProfileSetup: true,
      name: data.createdByName,
      photo: data.createdByPhoto,
    },
    description: data.description,
    id: data.id,
    isDisabled: false,
    isNew,
    isTemplate: data.isTemplate ?? false,
    name: data.name,
    updatedAt,
  };
}
