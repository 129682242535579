import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function FeedbackIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M8 9.5a1 1 0 0 1 1 1 1 1 0 0 1-.617.924 1 1 0 0 1-1.09-.217 1 1 0 0 1-.217-1.09A1 1 0 0 1 8 9.5zM7.5 4h1v4h-1V4zm.518 10.5c-1.922.07-3.792-.628-5.202-1.936A7.28 7.28 0 0 1 .5 7.481c.074-1.922.907-3.736 2.316-5.044S6.096.432 7.982.5c1.922-.07 3.792.628 5.202 1.936S15.426 5.56 15.5 7.48c-.06 1.797-.775 3.468-2 4.727V16a.5.5 0 0 1-.757.429l-3.41-2.04c-.44.074-.885.11-1.316.11zm4.486-11.33C11.288 2.04 9.675 1.44 7.982 1.5c-1.657-.06-3.27.54-4.486 1.67A6.25 6.25 0 0 0 1.5 7.481c.064 1.657.782 3.22 1.997 4.35S6.325 13.56 8 13.5a7.05 7.05 0 0 0 1.335-.128.5.5 0 0 1 .352.062l2.813 1.684V12a.5.5 0 0 1 .152-.36A6.07 6.07 0 0 0 14.5 7.501c-.068-1.65-.786-3.207-1.996-4.332z" />
    </Icon>
  );
}
