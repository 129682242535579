import type { Equipment } from '@sb/types';

import { getEquipmentService } from './utils';

export async function createEquipment(
  equipment: Equipment.EditableFields,
): Promise<string> {
  const response = await getEquipmentService().create(equipment);

  return response.id;
}
