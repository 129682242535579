import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SpaceIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H4.5C4.77614 1 5 0.776142 5 0.5C5 0.223858 4.77614 0 4.5 0H2.5C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V4.5C0 4.77614 0.223858 5 0.5 5C0.776142 5 1 4.77614 1 4.5V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934Z" />
      <path d="M11.5 0C11.2239 0 11 0.223858 11 0.5C11 0.776142 11.2239 1 11.5 1H13.5C13.8978 1 14.2794 1.15804 14.5607 1.43934C14.842 1.72064 15 2.10218 15 2.5V4.5C15 4.77614 15.2239 5 15.5 5C15.7761 5 16 4.77614 16 4.5V2.5C16 1.83696 15.7366 1.20107 15.2678 0.732233C14.7989 0.263392 14.163 0 13.5 0H11.5Z" />
      <path d="M0.5 11C0.776142 11 1 11.2239 1 11.5V13.5C1 13.8978 1.15804 14.2794 1.43934 14.5607C1.72064 14.842 2.10218 15 2.5 15H4.5C4.77614 15 5 15.2239 5 15.5C5 15.7761 4.77614 16 4.5 16H2.5C1.83696 16 1.20107 15.7366 0.732233 15.2678C0.263392 14.7989 0 14.163 0 13.5V11.5C0 11.2239 0.223858 11 0.5 11Z" />
      <path d="M16 11.5C16 11.2239 15.7761 11 15.5 11C15.2239 11 15 11.2239 15 11.5V13.5C15 13.8978 14.842 14.2794 14.5607 14.5607C14.2794 14.842 13.8978 15 13.5 15H11.5C11.2239 15 11 15.2239 11 15.5C11 15.7761 11.2239 16 11.5 16H13.5C14.163 16 14.7989 15.7366 15.2678 15.2678C15.7366 14.7989 16 14.163 16 13.5V11.5Z" />
      <path
        fillRule="evenodd"
        d="M7.77639 3.05279C7.91716 2.9824 8.08284 2.9824 8.22361 3.05279L12.2156 5.04876C12.3126 5.09502 12.3955 5.17278 12.4473 5.27638C12.4856 5.35287 12.5024 5.43449 12.5 5.51438V10.5C12.5 10.6894 12.393 10.8625 12.2236 10.9472L8.23262 12.9427C8.16711 12.9772 8.09301 12.9976 8.01436 12.9998C8.00958 12.9999 8.00479 13 8 13C7.99342 13 7.98683 12.9999 7.98025 12.9996C7.90361 12.9966 7.8314 12.9764 7.76739 12.9427L3.77639 10.9472C3.607 10.8625 3.5 10.6894 3.5 10.5V5.5C3.50003 5.46533 3.50368 5.42961 3.51113 5.39501C3.52616 5.325 3.55599 5.25948 3.59803 5.20264C3.64257 5.14226 3.69914 5.09412 3.76264 5.05993L3.77639 5.05279L7.77639 3.05279ZM8.5 11.691L11.5 10.191V6.30906L8.5 7.80906V11.691ZM7.5 7.80895V11.691L4.5 10.191V6.30895L7.5 7.80895ZM8 4.05902L10.882 5.50002L8.00011 6.94097L5.1181 5.49997L8 4.05902Z"
      />
    </Icon>
  );
}
