import type {
  ArmJointAccelerations,
  ArmJointVelocities,
} from '@sb/motion-planning';
import {
  DEFAULT_MAX_JOINT_ACCELERATIONS,
  DEFAULT_MAX_JOINT_SPEEDS,
  DEFAULT_MAX_TOOLTIP_SPEED,
  COLLABORATIVE_MAX_JOINT_ACCELERATIONS,
  COLLABORATIVE_MAX_JOINT_SPEEDS,
  COLLABORATIVE_MAX_TOOLTIP_SPEED,
  JOINT_NUMBERS,
} from '@sb/motion-planning';

import { calculateSpeedProfileLimitValue } from './calculateSpeedProfileLimitValue';
import type { SafetyLimits } from './SafetyLimits';
import type { SpeedProfile } from './SpeedProfile';

interface Options {
  /** Slow mode can be triggered by equipment attached to the Safeguard Stop inputs */
  isSlowMode?: boolean;
  /** Reduce the safety limit speed by a small amount so that motion plans don't trigger the watchdog */
  speedMultiplier?: number;
}

export function calculateSpeedProfileFromSafetyLimits(
  safetyLimits: SafetyLimits | null,
  { isSlowMode = false, speedMultiplier = 0.9 }: Options = {},
): SpeedProfile {
  const maxTooltipSpeed =
    calculateSpeedProfileLimitValue({
      isSlowMode,
      isSpeedLimit: true,
      defaultValue: DEFAULT_MAX_TOOLTIP_SPEED,
      collaborativeValue: COLLABORATIVE_MAX_TOOLTIP_SPEED,
      fullValue: safetyLimits?.maxTooltipSpeed ?? null,
      slowValue: safetyLimits?.slowTooltipSpeed ?? null,
    }) * speedMultiplier;

  const maxJointSpeeds = JOINT_NUMBERS.map(
    (jointNumber) =>
      calculateSpeedProfileLimitValue({
        isSlowMode,
        isSpeedLimit: true,
        defaultValue: DEFAULT_MAX_JOINT_SPEEDS[jointNumber],
        collaborativeValue: COLLABORATIVE_MAX_JOINT_SPEEDS[jointNumber],
        fullValue: safetyLimits?.maxJointSpeeds[jointNumber] ?? null,
        slowValue: safetyLimits?.slowJointSpeeds[jointNumber] ?? null,
      }) * speedMultiplier,
  ) as ArmJointVelocities;

  const maxJointAccelerations = JOINT_NUMBERS.map((jointNumber) =>
    calculateSpeedProfileLimitValue({
      isSlowMode,
      isSpeedLimit: false,
      defaultValue: DEFAULT_MAX_JOINT_ACCELERATIONS[jointNumber],
      collaborativeValue: COLLABORATIVE_MAX_JOINT_ACCELERATIONS[jointNumber],
      fullValue: safetyLimits?.maxJointAccelerations[jointNumber] ?? null,
      slowValue: safetyLimits?.slowJointAccelerations[jointNumber] ?? null,
    }),
  ) as ArmJointAccelerations;

  return {
    maxTooltipSpeed,
    maxJointSpeeds,
    maxJointAccelerations,
  };
}
