import type { OnRobot3FG15Command } from '../Command';
import type { OnRobot3FG15State } from '../State';

export function calculateOR3FG15CommandFromState(
  state: OnRobot3FG15State,
  waitForGripToContinue?: boolean,
  isFlexGrip?: boolean,
): OnRobot3FG15Command {
  const command: OnRobot3FG15Command = {
    kind: 'OnRobot3FG15Command',
    gripKind: state.gripKind,
    targetDiameter: state.diameterMeters,
    targetForce: state.targetForceNewtons,
    waitForGripToContinue,
    isFlexGrip,
  };

  return command;
}
