const STORAGE_KEY = 'api-endpoint';

const DEFAULT_API = '/';

function getApiFromURLQuery(): string | null {
  const apiFromQuery = new URLSearchParams(window.location.search).get('api');

  // if no `?api=xxx` in query, return previously set value
  if (apiFromQuery === null) {
    return localStorage.getItem(STORAGE_KEY);
  }

  // if `?api=` or `?api=default` then clear local storage value
  if (!apiFromQuery.replace(/[\W]/g, '') || apiFromQuery === 'default') {
    localStorage.removeItem(STORAGE_KEY);

    return null;
  }

  // else set local storage value
  localStorage.setItem(STORAGE_KEY, apiFromQuery);

  return apiFromQuery;
}

/**
 * Lets you choose which Feathers backend to connect to at runtime
 * by setting a query parameter in the url, `?api=gladman` etc.
 */
function getApi() {
  if (process.env.NODE_ENV === 'test') {
    // testing - api should be mocked
    return 'mock';
  }

  if (typeof window === 'undefined') {
    // SSR - api not available
    return 'not-available';
  }

  const api =
    getApiFromURLQuery() ||
    process.env.NEXT_PUBLIC_FEATHERS_ENDPOINT ||
    DEFAULT_API;

  // default api is the same server that the remote-control is being served from
  if (api === DEFAULT_API) {
    const { protocol, host } = window.location;
    const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';

    return `${wsProtocol}//${host}/`;
  }

  if (api.includes('/')) {
    return api;
  }

  return `wss://${api}.standardbots.com/`;
}

export const API_ENDPOINT = getApi();
