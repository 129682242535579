import type { Notification, Unsubscribe } from '@sb/types';

import { mapPaginated, retryOnTimeout } from '../utils';

import { convertNotificationResponse, getNotificationsService } from './utils';

export function onGetNotifications(
  userID: string,
  onSnapshot: (notifications: Notification.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getNotificationsService()
    .watch()
    .find({
      query: {
        $sort: { createdAt: -1 },
      },
    })
    .pipe(retryOnTimeout())
    .subscribe((response) =>
      onSnapshot(mapPaginated(response, convertNotificationResponse(userID))),
    );

  return () => subscription.unsubscribe();
}
