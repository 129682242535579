import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function Edit2Icon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        d="M4.55001 2C4.51722 2.16156 4.5 2.32877 4.5 2.5C4.5 2.67123 4.51722 2.83844 4.55001 3C4.78164 4.14112 5.79052 5 7 5C8.20948 5 9.21836 4.14112 9.44999 3C9.48279 2.83844 9.5 2.67123 9.5 2.5C9.5 2.32877 9.48279 2.16156 9.44999 2C9.21836 0.85888 8.20948 0 7 0C5.79052 0 4.78164 0.85888 4.55001 2ZM11.05 7.5C11.0172 7.66155 11 7.82877 11 8C11 8.17123 11.0172 8.33844 11.05 8.5C11.2816 9.64112 12.2905 10.5 13.5 10.5C14.8807 10.5 16 9.38071 16 8C16 6.61929 14.8807 5.5 13.5 5.5C12.2905 5.5 11.2816 6.35888 11.05 7.5ZM4.94999 13C4.71836 11.8589 3.70948 11 2.5 11C1.11929 11 0 12.1193 0 13.5C0 14.8807 1.11929 16 2.5 16C3.70948 16 4.71836 15.1411 4.94999 14C4.98278 13.8384 5 13.6712 5 13.5C5 13.3288 4.98278 13.1616 4.94999 13ZM7 4C7.82843 4 8.5 3.32843 8.5 2.5C8.5 1.67157 7.82843 1 7 1C6.17157 1 5.5 1.67157 5.5 2.5C5.5 3.32843 6.17157 4 7 4ZM13.5 9.5C14.3284 9.5 15 8.82843 15 8C15 7.17157 14.3284 6.5 13.5 6.5C12.6716 6.5 12 7.17157 12 8C12 8.82843 12.6716 9.5 13.5 9.5ZM2.5 15C3.32843 15 4 14.3284 4 13.5C4 12.6716 3.32843 12 2.5 12C1.67157 12 1 12.6716 1 13.5C1 14.3284 1.67157 15 2.5 15Z"
      />
    </Icon>
  );
}
