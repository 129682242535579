import type { ArmJointLimits } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getArmJointLimits(
  state: RoutineRunnerState | null,
): ArmJointLimits | null {
  return state ? state.kinematicState.jointLimits : null;
}

export default function useRobotJointLimits(
  args: UseRoutineRunnerHandleArguments,
): ArmJointLimits | null {
  return useRobotState(args, getArmJointLimits);
}
