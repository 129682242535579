import type { Unsubscribe, User } from '@sb/types';

import { mapPaginated, retryOnTimeout } from '../utils';

import { convertUserResponse, getUsersService } from './utils';

export function onGetUser(
  userID: string,
  onSnapshot: (settings: User.ConvertedResponse | null) => void,
): Unsubscribe {
  const subscription = getUsersService()
    .watch()
    .find({ query: { id: userID, $limit: 1 } })
    .pipe(retryOnTimeout())
    .subscribe((response) => {
      const user = mapPaginated(response, convertUserResponse)[0] ?? null;
      onSnapshot(user);
    });

  return () => subscription.unsubscribe();
}
