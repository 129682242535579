import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ProfileIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M13.404 13.905A5.99 5.99 0 0 0 8 10.5a5.99 5.99 0 0 0-5.404 3.405.5.5 0 0 0 .902.432A4.99 4.99 0 0 1 8 11.5a4.99 4.99 0 0 1 4.502 2.836.5.5 0 1 0 .902-.432zM8 9.5a3 3 0 1 1 0-6 3 3 0 1 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 1 0 0 4zm0 8a8 8 0 1 1 0-16 8 8 0 1 1 0 16zm0-1a7 7 0 1 0 0-14 7 7 0 1 0 0 14z" />
    </Icon>
  );
}
