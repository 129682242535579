import type { NotificationData } from '@sb/feathers-types';
import type { Notification } from '@sb/types';

import { patchBuilder } from '../utils';

import { getNotificationsService } from './utils';

export async function updateNotificationStatus(
  notificationIDs: string[],
  userID: string,
  status: Notification.Status,
): Promise<void> {
  const patch = patchBuilder<NotificationData>()
    .set(`statusByUserID.${userID}`, status)
    .buildPatch();

  await getNotificationsService().patch(null, patch, {
    query: { id: { $in: notificationIDs } },
  });
}
