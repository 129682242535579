/* eslint-disable class-methods-use-this */
// The above lint disable is necesary because most of these methods
// are just implemented to satisfy the interface and have no functionality.

import type {
  CameraState,
  CameraOptions,
  RGBAImage,
  PointCloudResponse,
  CameraInterface,
} from '@sb/routine-runner';

// Mock Camera class used by the SingleRobotSimulator
export default class Camera implements CameraInterface {
  private state: CameraState = {
    fx: 923.59228515625,
    fy: 922.6881103515625,
    cx: 622.410949707031,
    cy: 361.0360107421875,
    width: 1280,
    height: 720,
    fps: 12.00960768614892,
    depth: {
      isAutoWhiteBalance: false,
      isAutoExposure: true,
      gain: { min: 16, max: 248, current: 228 },
      exposure: { min: 1, max: 165000, current: 9500 },
      laserPower: { min: 0, max: 360, current: 150 },
    },
    color: {
      isAutoExposure: true,
      exposure: { min: 1, max: 10000, current: 166 },
      brightness: { min: -64, max: 64, current: 0 },
      gain: { min: 0, max: 128, current: 64 },
      gamma: { min: 100, max: 500, current: 300 },
      hue: { min: -180, max: 180, current: 0 },
      sharpness: { min: 0, max: 100, current: 50 },
      contrast: { min: 0, max: 100, current: 50 },
      saturation: { min: 0, max: 100, current: 64 },
      isAutoWhiteBalance: true,
      whiteBalance: { min: 2800, max: 6500, current: 4600 },
      isBacklightCompensation: false,
    },
    maximumDistance: {
      min: 65.53500366210938,
      max: 65.53500366210938,
      current: 65.53500366210938,
    },
  };

  public getState(): CameraState {
    return this.state;
  }

  public async setOptions({ depth, color }: CameraOptions) {
    if (depth) {
      const { isAutoExposure, exposure, gain, laserPower, isAutoWhiteBalance } =
        depth;

      if (typeof isAutoExposure !== 'undefined') {
        this.state.depth.isAutoExposure = isAutoExposure;
      }

      if (typeof exposure !== 'undefined') {
        this.state.depth.exposure.current = exposure;
      }

      if (typeof gain !== 'undefined') {
        this.state.depth.gain.current = gain;
      }

      if (typeof laserPower !== 'undefined') {
        this.state.depth.laserPower.current = laserPower;
      }

      if (typeof isAutoWhiteBalance !== 'undefined') {
        this.state.depth.isAutoWhiteBalance = isAutoWhiteBalance;
      }
    }

    if (color) {
      const {
        isAutoExposure,
        exposure,
        brightness,
        gain,
        gamma,
        hue,
        sharpness,
        contrast,
        saturation,
        isAutoWhiteBalance,
        whiteBalance,
        isBacklightCompensation,
      } = color;

      if (typeof isAutoExposure !== 'undefined') {
        this.state.color.isAutoExposure = isAutoExposure;
      }

      if (typeof exposure !== 'undefined') {
        this.state.color.exposure.current = exposure;
      }

      if (typeof brightness !== 'undefined') {
        this.state.color.brightness.current = brightness;
      }

      if (typeof gain !== 'undefined') {
        this.state.color.gain.current = gain;
      }

      if (typeof gamma !== 'undefined') {
        this.state.color.gamma.current = gamma;
      }

      if (typeof hue !== 'undefined') {
        this.state.color.hue.current = hue;
      }

      if (typeof sharpness !== 'undefined') {
        this.state.color.sharpness.current = sharpness;
      }

      if (typeof contrast !== 'undefined') {
        this.state.color.contrast.current = contrast;
      }

      if (typeof saturation !== 'undefined') {
        this.state.color.saturation.current = saturation;
      }

      if (typeof isAutoWhiteBalance !== 'undefined') {
        this.state.color.isAutoWhiteBalance = isAutoWhiteBalance;
      }

      if (typeof whiteBalance !== 'undefined') {
        this.state.color.whiteBalance.current = whiteBalance;
      }

      if (typeof isBacklightCompensation !== 'undefined') {
        this.state.color.isBacklightCompensation = isBacklightCompensation;
      }
    }

    return Promise.resolve();
  }

  /**
   * @category Camera
   */
  public getRGBCameraFeed(): any {
    throw new Error('Method not implemented.');
  }

  /**
   * @category Camera
   */
  public getDepthCameraFeed(): any {
    throw new Error('Method not implemented.');
  }

  /**
   * @category Camera
   */
  public async getColorFrame(): Promise<RGBAImage> {
    return Promise.resolve(this.generateFakeImage());
  }

  /**
   * @category Camera
   */
  public async getDepthFrame(): Promise<RGBAImage> {
    return Promise.resolve(this.generateFakeImage());
  }

  /**
   * @category Camera
   */
  public getPointCloud(): Promise<PointCloudResponse> {
    throw new Error('getPointCloud unimplemented');
  }

  /**
   *
   * @category Camera
   */
  public onColorFrame(): () => void {
    throw new Error('onColorFrame unimplemented');
  }

  /**
   * @category Camera
   */
  public onDepthFrame(): () => void {
    throw new Error('onDepthFrame unimplemented');
  }

  /**
   * @category Camera
   */
  public onPointCloud(): () => void {
    throw new Error('onPointCloud unimplemented');
  }

  /**
   * Generates a fake image supposedly from the camera
   */
  public generateFakeImage(): RGBAImage {
    const width = 100;
    const height = width / (16 / 9);

    return { rgba: Buffer.alloc(width * height * 4), width, height };
  }
}
