import type { MotionSpeed } from '@sb/types';

export const validateEmail = (email: string): boolean => {
  return email.length > 1 && email.includes('@');
};

export const validateName = (name: string): boolean => {
  return name.trim().length > 1;
};

export const validateMotionSpeed = (
  stepKind: string,
  motionSpeed?: MotionSpeed,
) => {
  const customLimits: any = motionSpeed?.customLimits;

  if (
    customLimits?.jointAccelerationsPercent ||
    customLimits?.jointSpeedsPercent ||
    customLimits?.tooltipSpeedPercent
  ) {
    throw Error(`${stepKind} step is configured with motion speed custom limits with an outdated configuration. 
    Please reconfigure ${stepKind} steps with custom limit speeds.`);
  }
};
