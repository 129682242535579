import EventEmitter3 from 'eventemitter3';

/**
 * Stolen from strict-event-emitter-types (where it's called ListenerType for some reason)
 */
export type EventData<T> = [T] extends [(...args: infer U) => any]
  ? U
  : [T] extends [void]
  ? []
  : [T];

export type EventKind<Events> = string & keyof Events;

export type ListenerType<Events, Key extends keyof Events> = (
  ...args: EventData<Events[Key]>
) => void;

/**
 * Our custom event emitter class with first-class support for event typing and
 * some additional conveniences that most other event emitters don't offer.
 */
export class EventEmitter<Events extends Record<string, any>> {
  // This event emitter is backed by eventemitter3, which is untyped
  private events = new EventEmitter3();

  /**
   * Listen for new messages coming in.
   *
   * @param eventKind The key of the event that gets emitted
   * @param listener The callback that gets called with the event data
   * @returns a cancel function, after which the listener will no longer get called
   */
  public on<K extends EventKind<Events>>(
    eventKind: K,
    listener: ListenerType<Events, K>,
  ): () => void {
    this.events.on(eventKind, listener as any);

    return () => this.events.off(eventKind, listener as any);
  }

  /**
   * Listen for exactly one new message that comes in. Cancels itself automatically
   * after one message occurs.
   *
   * @param eventKind The key of the event that gets emitted
   * @param listener The callback that gets called with the event data
   * @returns a cancel function, after which the listener will no longer get called
   */
  public once<K extends EventKind<Events>>(
    eventKind: K,
    listener: ListenerType<Events, K>,
  ): () => void {
    const onEvent = (...args: EventData<Events[K]>) => {
      this.off(eventKind, onEvent);
      listener(...args);
    };

    return this.on(eventKind, onEvent);
  }

  /**
   * Listen for exactly one new message that comes in and resolves when that occurs.
   *
   * Specify a filter function if you want to wait for an event that fits a specific condition.
   *
   * Only returns the first argument for ease-of-use.
   *
   * ```
   * const val = await emitter.next('oneVal')
   * ```
   *
   * rather than
   *
   * ```
   * const [val] = await emitter.next('oneVal')`
   * ```
   *
   * @param eventKind The key of the event that gets emitted
   * @param filter? A function that gets called on each event. `nextAll` resolves only when the
   *               filter function returns `true`.
   * @returns A Promise that resolves to the event data as an array of values.
   */
  public next<K extends EventKind<Events>>(
    eventKind: K,
    filter: (...args: EventData<Events[K]>) => boolean = () => true,
  ): Promise<EventData<Events[K]>[0]> {
    return this.nextAll(eventKind, filter).then(([val]) => val);
  }

  /**
   * Listen for exactly one new message that comes in and resolves when that occurs.
   *
   * Specify a filter function if you want to wait for an event that fits a specific condition.
   *
   * @param eventKind The key of the event that gets emitted
   * @param filter? A function that gets called on each event. `nextAll` resolves only when the
   *               filter function returns `true`.
   * @returns A Promise that resolves to the event data as an array of values.
   */
  public nextAll<K extends EventKind<Events>>(
    eventKind: K,
    filter: (...args: EventData<Events[K]>) => boolean = () => true,
  ): Promise<EventData<Events[K]>> {
    return new Promise((resolve, reject) => {
      const onEvent = (...data: EventData<Events[K]>) => {
        try {
          if (filter(...data)) {
            this.off(eventKind, onEvent);
            resolve(data);
          }
        } catch (e) {
          this.off(eventKind, onEvent);
          reject(e);
        }
      };

      this.on(eventKind, onEvent);
    });
  }

  /**
   * Wait for the next of one of the event kinds.
   *
   * @returns A Promise that resolves to the event data as an array of values.
   */
  public async raceAll<K extends EventKind<Events>>(
    ...kinds: Array<K>
  ): Promise<EventData<Events[K]>> {
    // Keep track of functions to cancel the listeners for cleanup
    // after the Promise.
    const cancels: Array<() => void> = [];

    return Promise.race(
      // for each event kind
      kinds.map(
        (eventKind) =>
          new Promise<EventData<Events[K]>>((resolve) => {
            // listen for this eventKind
            const cancelListener = this.once(eventKind, (...values) => {
              resolve(values);
            });

            cancels.push(cancelListener);
          }),
      ),
    ).then((result) => {
      // cancel the listeners
      cancels.forEach((cancel) => cancel());

      return result;
    });
  }

  /**
   * Wait for the next of one of the event kinds.
   *
   * @returns A Promise that resolves to the first value in the event data
   */
  public race<K extends EventKind<Events>>(
    ...kinds: Array<K>
  ): Promise<EventData<Events[K]>[0]> {
    return this.raceAll(...kinds).then(([value]) => value);
  }

  /**
   * Removes a listener from the event.
   *
   * @param eventKind The key of the event that gets emitted
   * @param listener The callback that was previously attached as a listener using `on` or `once`.
   *                 Checked against using referential integrity, so it must be the same function
   *                 that was previously passed in.
   */
  public off<K extends EventKind<Events>>(
    eventKind: K,
    listener: ListenerType<Events, K>,
  ) {
    this.events.off(eventKind, listener as any);
  }

  /**
   * Removes all listeners for a particular event kind. If no event kind is specified, all listeners
   * for all events are removed.
   *
   * @param eventKind The key of the event that has all listeners removed.
   */
  public removeAllListeners(eventKind?: EventKind<Events>) {
    this.events.removeAllListeners(eventKind);
  }

  /**
   * Emits an event to all listeners who are listening for it
   *
   * @param eventKind The event kind we're signaling
   * @param eventData The event data we're signaling
   */
  public emit<K extends EventKind<Events>>(
    eventKind: K,
    ...eventData: EventData<Events[K]>
  ) {
    this.events.emit(eventKind, ...eventData);
  }

  /**
   * Retrieve the number of listeners for a specific event kind
   */
  public listenerCount(eventKind: EventKind<Events>): number {
    return this.events.listenerCount(eventKind);
  }
}
