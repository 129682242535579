import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function FunctionIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" />
      <path d="M7.58545 8.17383C7.82573 8.17383 8.02051 7.97905 8.02051 7.73877C8.02051 7.49849 7.82573 7.30371 7.58545 7.30371H5.31348V5.37451H7.96997C8.21146 5.37451 8.40723 5.17875 8.40723 4.93726C8.40723 4.69577 8.21146 4.5 7.96997 4.5H4.75C4.47386 4.5 4.25 4.72386 4.25 5V10.3984C4.25 10.6746 4.47386 10.8984 4.75 10.8984H4.81348C5.08962 10.8984 5.31348 10.6746 5.31348 10.3984V8.17383H7.58545Z" />
      <path d="M10.2878 7.74316L9.30333 6.34332C9.21526 6.21808 9.07173 6.14355 8.91862 6.14355C8.53653 6.14355 8.3139 6.57504 8.53532 6.88644L9.67261 8.48584L8.48471 10.1495C8.26085 10.463 8.48497 10.8984 8.87021 10.8984C9.02348 10.8984 9.16728 10.8243 9.25614 10.6994L10.2966 9.2373L11.3419 10.7007C11.4306 10.8248 11.5736 10.8984 11.7261 10.8984C12.1101 10.8984 12.3334 10.4645 12.1103 10.152L10.9207 8.48584L12.0565 6.89272C12.2802 6.57895 12.0559 6.14355 11.6705 6.14355C11.5171 6.14355 11.3732 6.21778 11.2843 6.34275L10.2878 7.74316Z" />
    </Icon>
  );
}
