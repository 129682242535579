import type { CartesianPose } from '@sb/geometry';
import type { Logger } from '@sb/logger';

import type { ArmTarget } from './ArmTarget';
import { blendRadiusMove } from './blendRadiusMove';
import type { Waypoint } from './Waypoint';

const SPACING_BETWEEN_POINTS: number = 0.01;

export type GetWaypointArmTargetsArgs = {
  pendingWaypoints: Waypoint[];
  getTargetPose: (armTarget: ArmTarget) => Promise<CartesianPose>;
  getCurrentPose: () => CartesianPose;
  logger: Logger;
};

async function getPreviousPose(
  context: GetWaypointArmTargetsArgs,
  index: number,
): Promise<CartesianPose> {
  if (index === 0) {
    return context.getCurrentPose();
  }

  return context.getTargetPose(context.pendingWaypoints[index - 1].armTarget);
}

export async function blendArmTargets(
  context: GetWaypointArmTargetsArgs,
  index: number,
  spacingBetweenPoints: number = SPACING_BETWEEN_POINTS,
): Promise<ArmTarget[]> {
  const waypoint = context.pendingWaypoints[index];

  const { blend } = waypoint;

  if (!blend || blend.radius <= 0) {
    // just return if there is not blend
    context.logger.info(
      `blend radius not specified ${blend} for waypoint`,
      waypoint,
    );

    return [waypoint.armTarget];
  }

  // check that there is a next point to blend into
  if (index >= context.pendingWaypoints.length - 1) {
    context.logger.info(
      `no next target to blend into for waypoint ${index}`,
      waypoint,
    );

    return [waypoint.armTarget];
  }

  const [previousPose, pose, nextPose] = await Promise.all([
    getPreviousPose(context, index),
    context.getTargetPose(waypoint.armTarget),
    context.getTargetPose(context.pendingWaypoints[index + 1].armTarget),
  ]);

  context.logger.info(
    'calculating blend points for ',
    previousPose,
    pose,
    nextPose,
  );

  const positions = blendRadiusMove(
    previousPose,
    pose,
    nextPose,
    blend.radius,
    spacingBetweenPoints,
  );

  context.logger.info(`  found ${positions.length} points`);
  context.logger.debug('  found positions:', positions);

  return positions.map((position) => ({
    ...waypoint.armTarget,
    pose: {
      ...pose,
      ...position,
    },
  }));
}

export async function getWaypointArmTargets(
  context: GetWaypointArmTargetsArgs,
): Promise<ArmTarget[]> {
  const promises = context.pendingWaypoints.map((waypoint) => {
    switch (waypoint.blend?.kind) {
      case 'blendRadius':
        return {
          ...waypoint.armTarget,
          blendRadius: waypoint.blend.radius,
        };
      default:
        return waypoint.armTarget;
    }
  });

  return (await Promise.all(promises)).flat();
}

export function usesBlendRadius(context: GetWaypointArmTargetsArgs): boolean {
  return context.pendingWaypoints.some(
    (waypoint) =>
      waypoint.blend?.kind === 'blendRadius' && waypoint.blend.radius > 0,
  );
}
