import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function WiFiIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path d="M7.99872 7.50012C6.67335 7.50012 5.4022 8.02635 4.46463 8.96315L3.75781 8.25575C4.88289 7.13159 6.40827 6.50012 7.99872 6.50012C9.58917 6.50012 11.1146 7.13159 12.2396 8.25575L11.5328 8.96315C10.5952 8.02635 9.3241 7.50012 7.99872 7.50012Z" />
      <path d="M9.75 11.7485C9.75 12.715 8.9665 13.4985 8 13.4985C7.0335 13.4985 6.25 12.715 6.25 11.7485C6.25 10.782 7.0335 9.99854 8 9.99854C8.9665 9.99854 9.75 10.782 9.75 11.7485ZM8.75 11.7485C8.75 12.1627 8.41421 12.4985 8 12.4985C7.58579 12.4985 7.25 12.1627 7.25 11.7485C7.25 11.3343 7.58579 10.9985 8 10.9985C8.41421 10.9985 8.75 11.3343 8.75 11.7485Z" />
      <path d="M7.99871 3.5C5.61223 3.5 3.32346 4.44784 1.63571 6.13508L0.928711 5.42786C2.80399 3.55315 5.34707 2.5 7.99871 2.5C10.6504 2.5 13.1934 3.55315 15.0687 5.42786L14.3617 6.13508C12.674 4.44784 10.3852 3.5 7.99871 3.5Z" />
    </Icon>
  );
}
