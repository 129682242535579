import cx from 'classnames';
import { Children, useState } from 'react';

import { isTabItemElement } from './TabItem';

import styles from './Tabs.module.css';

interface TabsProps {
  children: React.ReactNode;
  defaultTab?: string;
  tabListClassName?: string;
  tabPanelContainerClassName?: string;
  onChange?: (id: string) => void;
  selected?: string;
}

let uniqueCounter = 0;

export function Tabs({
  children,
  defaultTab,
  tabListClassName,
  tabPanelContainerClassName,
  onChange,
  selected,
}: TabsProps) {
  const [uniqueID] = useState(() => {
    uniqueCounter += 1;

    return uniqueCounter;
  });

  const items = Children.toArray(children).filter(isTabItemElement);

  const [uncontrolledSelectedID, setUncontrolledSelectedID] = useState<
    string | undefined
  >(() => {
    if (defaultTab && items.some((i) => i.props.id === defaultTab)) {
      return defaultTab;
    }

    return items[0]?.props.id;
  });

  const selectedID = selected ?? uncontrolledSelectedID;

  return (
    <>
      <div className={cx(styles.tabList, tabListClassName)} role="tablist">
        {items.map(({ props }) => {
          return (
            <button
              key={props.id}
              type="button"
              id={`tab-${uniqueID}-${props.id}`}
              className={styles.tab}
              disabled={props.isDisabled}
              role="tab"
              aria-selected={props.id === selectedID}
              aria-controls={`panel-${uniqueID}-${props.id}`}
              onClick={() => {
                onChange?.(props.id);
                setUncontrolledSelectedID(props.id);
              }}
            >
              {props.label}
            </button>
          );
        })}
      </div>
      {items.map(({ props }) => {
        return (
          <div
            key={props.id}
            id={`panel-${uniqueID}-${props.id}`}
            className={tabPanelContainerClassName}
            role="tabpanel"
            aria-labelledby={`tab-${uniqueID}-${props.id}`}
            hidden={props.id !== selectedID}
          >
            {props.panel}
          </div>
        );
      })}
    </>
  );
}
