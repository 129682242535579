import type { RoutineRunnerHandle } from '@sbrc/services';

import { useLiveRoutineRunnerHandle } from './useLiveRoutineRunnerHandle';
import { useVizbotRoutineRunnerHandle } from './useVizbotRoutineRunnerHandle';

export interface UseRoutineRunnerHandleArguments {
  isVizbot?: boolean;
  robotID: string | null;
}

export function useRoutineRunnerHandle({
  isVizbot,
  robotID,
}: UseRoutineRunnerHandleArguments): RoutineRunnerHandle {
  const vizbotRoutineRunnerHandle = useVizbotRoutineRunnerHandle();

  const liveRobotRoutineRunnerHandle = useLiveRoutineRunnerHandle({ robotID });

  return isVizbot ? vizbotRoutineRunnerHandle : liveRobotRoutineRunnerHandle;
}
