/**
 * Relay port: A label corresponding to a relay input port on the IO board
 */
import * as zod from 'zod';

export const RELAY_PORT_NAMES = ['Relay 1', 'Relay 2', 'Relay 3'] as const;

export const RELAY_PORT_COUNT = RELAY_PORT_NAMES.length;

export const RelayPort = zod.enum(RELAY_PORT_NAMES);

export type RelayPort = zod.infer<typeof RelayPort>;
