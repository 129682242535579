import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SupportIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M5.547 5.34C6.225 4.814 7.076 4.5 8 4.5s1.776.314 2.453.84l2.497-2.497a.5.5 0 0 1 .707.707L11.16 6.048c.526.677.84 1.528.84 2.452s-.314 1.775-.84 2.453l2.497 2.497a.5.5 0 1 1-.707.707l-2.497-2.497c-.677.527-1.528.84-2.453.84s-1.775-.313-2.452-.84L3.05 14.157a.5.5 0 0 1-.707-.707l2.497-2.497C4.314 10.276 4 9.425 4 8.5s.314-1.775.84-2.453L2.344 3.55a.5.5 0 0 1 .707-.707L5.547 5.34zM8 11.5a3 3 0 1 0 0-6 3 3 0 1 0 0 6zm0 5a8 8 0 1 1 0-16 8 8 0 1 1 0 16zm0-1a7 7 0 1 0 0-14 7 7 0 1 0 0 14z" />
    </Icon>
  );
}
