import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function OpenIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M6.688 3.563v1H2.313c-.181 0-.332.129-.367.299l-.008.076v8.75c0 .181.129.332.299.367l.076.008h8.75c.181 0 .332-.129.367-.299l.008-.076V9.313h1v4.375c0 .714-.546 1.302-1.243 1.369l-.132.006h-8.75c-.714 0-1.302-.546-1.369-1.243l-.006-.132v-8.75c0-.714.546-1.302 1.243-1.369l.132-.006h4.375zM14.563.938a.5.5 0 0 1 .492.41l.008.09v6.125h-1l-.001-4.918-6.145 6.147-.707-.707 6.147-6.147-4.918.001v-1h6.125z" />
    </Icon>
  );
}
