export const ACTUATION_TIMEOUT_MS = 5000;

export const RETRY_INTERVAL_MS = 10000;

export const INITIALIZATION_TIMEOUT_MS = 1000;

export const MODBUS_TIMEOUT_MS = 5000;

export enum StatusCodes {
  NOT_BUSY = 0x0000,
  BUSY = 0x0001,
  Z_AXIS_BUSY = 0x0002,

  ERROR_Z_AXIS_SAFETY = 0x0004,
  ERROR_NOT_CALIBRATED = 0x0008,
  Z_STALL_CURRENT_NOT_REACHED = 0x0010,
  NO_Z_INDEX_MARK_FOUND = 0x0020,
  UNABLE_TO_HOME_Z_AXIS = 0x0030,
  Z_INDEX_PLACEMENT_NOT_OK = 0x0040,
  NO_INDEX_MARK_TORQUE_ENCODERS = 0x0050,
  TOO_BIG_TORQUE_DIFFERENCE = 0x0060,
  INDEX_MARK_VALUE_CHANGED = 0x0070,
  ERROR_QC_TYPE = 0x0100,
  ERROR_POWER_SUPPLY = 0x0200,
}

export const STATUS_CODES_TO_STRING: Record<number, string> = {
  [StatusCodes.BUSY]: 'BUSY',
  [StatusCodes.Z_AXIS_BUSY]: 'Z_AXIS_BUSY',

  [StatusCodes.ERROR_Z_AXIS_SAFETY]: 'ERROR_Z_AXIS_SAFETY',
  [StatusCodes.ERROR_NOT_CALIBRATED]: 'ERROR_NOT_CALIBRATED',
  [StatusCodes.Z_STALL_CURRENT_NOT_REACHED]: 'Z_STALL_CURRENT_NOT_REACHED',
  [StatusCodes.NO_Z_INDEX_MARK_FOUND]: 'NO_Z_INDEX_MARK_FOUND',
  [StatusCodes.UNABLE_TO_HOME_Z_AXIS]: 'UNABLE_TO_HOME_Z_AXIS',
  [StatusCodes.Z_INDEX_PLACEMENT_NOT_OK]: 'Z_INDEX_PLACEMENT_NOT_OK',
  [StatusCodes.NO_INDEX_MARK_TORQUE_ENCODERS]: 'NO_INDEX_MARK_TORQUE_ENCODERS',
  [StatusCodes.TOO_BIG_TORQUE_DIFFERENCE]: 'TOO_BIG_TORQUE_DIFFERENCE',
  [StatusCodes.INDEX_MARK_VALUE_CHANGED]: 'INDEX_MARK_VALUE_CHANGED',
  [StatusCodes.ERROR_QC_TYPE]: 'ERROR_QC_TYPE',
  [StatusCodes.ERROR_POWER_SUPPLY]: 'ERROR_POWER_SUPPLY',
};

export const ADDITIONAL_RESULTS_TO_STRING: Record<number, string> = {
  0x00: 'No additional results',
  0x01: 'Command unknown',
  0x02: 'Not screwing in',
  0x03: 'Timeout waiting for correct torque (2 sec)',
  0x04: 'Torque exceeded unexpected (premature)',
  0x05: 'Unable to loosen screw',
  0x06: 'Z axis reached the end',
  0x07: 'Z axis obstructed during move',
};

export enum Register {
  // write
  SHANK_POSITION = 0x00, // 0-55mm
  Z_FORCE = 0x01, // 18-30N
  SCREW_LENGTH = 0x02, // micrometers
  TARGET_TORQUE = 0x03, // mili newton meters
  COMMAND = 0x04,
  // read
  STATUS = 0x0100,
  CURRENT_TORQUE = 0x0101,
  SHANK_Z_POSITION = 0x0102,
  TORQUE_ANGLE_GRADIENT = 0x0103,
  ACHIEVED_TORQUE = 0x0104,
  ADDITIONAL_RESULTS = 0x0105,
  QUICK_CHANGER_VERSION = 0x0200,
}

export enum SCREWDRIVER_CONTROL_COMMAND {
  TIGHTEN = 0x00,
  LOOSEN = 0x01,
  PICKUP = 0x02,
  STOP = 0x04,
}

export const UM_TO_MM = 0.001;
export const MM_TO_UM = 1000;
export const mNM_TO_NM = 0.001;
export const NM_TO_mNM = 1000;
