import type { DeviceConfiguration } from '@sb/integrations/device';
import { DynamicBaseState } from '@sb/integrations/device';
import type { GripperState } from '@sb/integrations/gripper-general';
import type { ArmJointPositions } from '@sb/motion-planning';
import { RoutineRunnerPacketReceiver } from '@sb/routine-runner';
import type {
  ExternalToRobotPort,
  IOLevel,
  RelayPort,
  RoutineRunner,
} from '@sb/routine-runner';

import type { SingleRobotSimulator } from './SingleRobotSimulator';

export default class SimulatorPacketReceiver extends RoutineRunnerPacketReceiver {
  public constructor(
    private simulator: SingleRobotSimulator,
    routineRunner: RoutineRunner,
  ) {
    super({ routineRunner });

    this.addExtendedMethod('setJointPositions', this.setJointPositions);

    this.addExtendedMethod('setGripperState', this.setGripperState);

    this.addExtendedMethod('setDynamicBaseState', this.setDynamicBaseState);

    this.addExtendedMethod('setInputIO', this.setInputIO);

    this.addExtendedMethod('resetEquipmentList', this.resetEquipmentList);

    this.addExtendedMethod('stopArm', this.stopArm);
  }

  private stopArm = () => {
    this.simulator.stopArm();
  };

  private setJointPositions = (message: Record<string, unknown>) => {
    const { positions } = message;

    if (!Array.isArray(positions)) {
      throw new TypeError('`positions` must be an array');
    }

    if (positions.length !== 6) {
      throw new TypeError('`positions` must be an array of length 6');
    }

    this.simulator.setJointPositions(positions as ArmJointPositions);
  };

  private setDynamicBaseState = (message: Record<string, unknown>) => {
    const dynamicBaseState = DynamicBaseState.parse(message.dynamicBaseState);
    this.simulator.equipment.setDynamicBaseState(dynamicBaseState);
  };

  private setGripperState = (message: Record<string, unknown>) => {
    const { gripperState } = message;
    this.simulator.equipment.setGripperState(gripperState as GripperState);
  };

  private resetEquipmentList = (message: Record<string, unknown>) => {
    const { deviceConfigs } = message;

    this.simulator.equipment.resetEquipmentList(
      deviceConfigs as DeviceConfiguration[],
    );
  };

  public setInputIO = (message: Record<string, unknown>) => {
    const { label } = message as { label: ExternalToRobotPort | RelayPort };
    const { level } = message as { level: IOLevel };

    if (typeof label !== 'string') {
      throw new TypeError(`'${label}' is not a valid axes mode`);
    }

    if (typeof level !== 'string') {
      throw new TypeError(`'${level}' is not a valid axes mode`);
    }

    return this.simulator.setInputIO(label, level);
  };
}
