/**
 * The reported state of the OnRobot 3FG15 Gripper
 */
import * as zod from 'zod';

import { OR3FGForce } from './Command';
import {
  OR_3FG15_FORCE_APPLIED_FRACTION_MIN,
  OR_3FG15_MOVE_JOINT_MAX,
  OR_3FG15_MOVE_JOINT_MIN,
  OR_3FG15_FORCE_APPLIED_FRACTION_MAX,
  OR3FG15GripKind,
  OR3FG15FingerPosition,
} from './constants';

export const OnRobot3FG15State = zod.object({
  kind: zod.literal('OnRobot3FG15'),
  isBusy: zod.boolean(),
  isConnected: zod.boolean(),
  isGripDetected: zod.boolean(),
  isForceGripDetected: zod.boolean(),
  isCalibrationOk: zod.boolean(),
  diameterMeters: zod.number(),
  gripKind: OR3FG15GripKind,
  fingerAngle: zod
    .number()
    .min(OR_3FG15_MOVE_JOINT_MAX)
    .max(OR_3FG15_MOVE_JOINT_MIN),
  forceAppliedFraction: zod
    .number()
    .min(OR_3FG15_FORCE_APPLIED_FRACTION_MIN)
    .max(OR_3FG15_FORCE_APPLIED_FRACTION_MAX),

  forceAppliedNewtons: OR3FGForce,
  // This isn't actually returned from the hardware, but we
  // provide it as a convenience so the frontend doesn't
  // have to remember the last target force that was sent to
  // this gripper.
  //
  // If this is undefined, the last command was a no force move
  // command or the gripper has not been commanded since
  // the robot started up.
  targetForceNewtons: OR3FGForce.optional(),

  fingerPosition: OR3FG15FingerPosition,
  fingerLength: zod.number().positive(),
  fingertipDiameter: zod.number().positive(),
});

export type OnRobot3FG15State = zod.infer<typeof OnRobot3FG15State>;
