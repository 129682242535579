import cx from 'classnames';

import { DoubleArrowBackIcon, DoubleArrowNextIcon } from '@sb/ui/icons';

import Button from '../Button';

import { useWizardContext } from './shared';

import styles from './WizardSidebar.module.css';

interface WizardSidebarProps {
  children: React.ReactNode;
}

const WizardSidebar = ({ children }: WizardSidebarProps) => {
  const { isCollapsed, setIsCollapsed } = useWizardContext();

  return (
    <>
      <aside
        className={cx(styles.wizardSidebar, {
          [styles.collapsed]: isCollapsed,
        })}
      >
        {children}
      </aside>
      <div className={styles.collapseButton}>
        <Button
          onClick={() => setIsCollapsed((previous) => !previous)}
          startIcon={
            isCollapsed ? <DoubleArrowNextIcon /> : <DoubleArrowBackIcon />
          }
          variant="gray"
        />
      </div>
    </>
  );
};

export default WizardSidebar;
