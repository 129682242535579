import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PositionListBlankIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.02699 1.10866C6.21388 0.938434 5.37117 0.973487 4.57501 1.21065C3.77885 1.44781 3.05433 1.87961 2.46691 2.46703C1.87949 3.05445 1.44769 3.77897 1.21053 4.57513C0.973365 5.37129 0.938312 6.214 1.10854 7.02711C1.27876 7.84022 1.6489 8.5981 2.1855 9.23227C2.72211 9.86644 3.40827 10.3569 4.18199 10.6594C4.43917 10.7599 4.56616 11.0499 4.46562 11.3071C4.36508 11.5643 4.07508 11.6913 3.81789 11.5907C2.88944 11.2278 2.06604 10.6392 1.42212 9.87821C0.77819 9.1172 0.334025 8.20775 0.129755 7.23202C-0.0745144 6.25629 -0.0324509 5.24504 0.252145 4.28965C0.536741 3.33425 1.0549 2.46482 1.7598 1.75992C2.4647 1.05502 3.33413 0.536863 4.28952 0.252267C5.24492 -0.0323288 6.25617 -0.0743923 7.2319 0.129877C8.20762 0.334147 9.11708 0.778312 9.87809 1.42224C10.6391 2.06616 11.2277 2.88956 11.5906 3.81802C11.6912 4.0752 11.5642 4.3652 11.307 4.46574C11.0498 4.56628 10.7598 4.4393 10.6593 4.18211C10.3568 3.40839 9.86632 2.72223 9.23215 2.18563C8.59797 1.64902 7.84009 1.27888 7.02699 1.10866ZM5.14639 5.14651C5.25379 5.0391 5.40419 4.98635 5.55516 5.00312L14.5552 6.00312C14.7462 6.02435 14.9081 6.15319 14.9718 6.33456C15.0354 6.51593 14.9894 6.71771 14.8535 6.85362L12.207 9.50006L15.8535 13.1465C16.0488 13.3418 16.0488 13.6584 15.8535 13.8536L13.8535 15.8536C13.6582 16.0489 13.3416 16.0489 13.1464 15.8536L9.49994 12.2072L6.85349 14.8536C6.71759 14.9895 6.5158 15.0355 6.33444 14.9719C6.15307 14.9083 6.02422 14.7463 6.003 14.5553L5.003 5.55528C4.98622 5.40431 5.03898 5.25391 5.14639 5.14651ZM6.0659 6.06602L6.882 13.4109L9.14639 11.1465C9.34165 10.9512 9.65823 10.9512 9.85349 11.1465L13.4999 14.793L14.7928 13.5001L11.1464 9.85362C10.9511 9.65835 10.9511 9.34177 11.1464 9.14651L13.4108 6.88212L6.0659 6.06602Z"
      />
    </Icon>
  );
}
