import type { RoutineData } from '@sb/feathers-types';
import type { Routine } from '@sb/types';
import {
  ACTION_REQUIRED_STEP_MESSAGE_DEFAULT,
  convertStepConfigurations,
  convertStepSummary,
} from '@sbrc/utils';

import { convertRoutineSummary } from './convertRoutineSummary';
import { migrateStepConfiguration } from './migrateStepConfiguration';

export function convertRoutineResponse(
  data: RoutineData,
): Routine.ConvertedResponse {
  const summary = convertRoutineSummary(data);

  const steps = convertStepSummary(data.steps);

  const stepConfigurations = convertStepConfigurations(
    data.id,
    steps,
    data.stepConfigurations ?? undefined,
  );

  const actionRequiredByStepID = (data.actionRequiredByStepID ?? {}) as Record<
    string,
    Routine.ActionRequiredStep
  >;

  for (const step of Object.values(stepConfigurations)) {
    if (!step.args && !actionRequiredByStepID[step.id]) {
      actionRequiredByStepID[step.id] = {
        kind: 'missingConfiguration',
        message: ACTION_REQUIRED_STEP_MESSAGE_DEFAULT,
      };
    }

    migrateStepConfiguration(step);
  }

  return {
    ...summary,
    actionRequiredByStepID,
    configurationUpdatedAt: new Date(
      data.configurationUpdatedAt ?? data.createdAt,
    ),
    currentEditorID: null,
    environmentVariables: data.environmentVariables ?? [],
    equipment: [], // will be merged in from equipment service
    isActionRequired: data.actionRequiredByStepID
      ? Object.keys(data.actionRequiredByStepID).length > 0
      : false,
    mainLoopStepID: data.mainLoopStepID ?? null,
    robotCount: 0,
    space: (data.space as Routine.SpaceItem[]) ?? [],
    steps,
    stepConfigurations,
  };
}
