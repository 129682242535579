import type { DeviceCommand } from '../device';
import { calculateOR2FG7CommandFromState } from '../OnRobot2FG7';
import { calculateOR3FG15CommandFromState } from '../OnRobot3FG15';
import { calculateORDualQuickChangerCommandFromState } from '../OnRobotDualQuickChanger';
import { calculateORScrewdriverCommandFromState } from '../OnRobotScrewdriver';

import type { GripperState } from './GripperState';

export function gripperStateToCommand(state: GripperState): DeviceCommand {
  if (!state) {
    throw new Error('Cannot command an absent gripper');
  }

  switch (state.kind) {
    case 'OnRobot3FG15': {
      return calculateOR3FG15CommandFromState(state);
    }

    case 'OnRobot2FG7': {
      return calculateOR2FG7CommandFromState(state);
    }

    case 'OnRobotScrewdriver': {
      return calculateORScrewdriverCommandFromState(state);
    }

    case 'OnRobotDualQuickChanger': {
      return calculateORDualQuickChangerCommandFromState(state);
    }

    default: {
      throw new Error(`Unknown gripper state kind: ${state.kind}`);
    }
  }
}
