import type { DBBackupData } from '@sb/feathers-types';

import { getService } from '../utils';

export async function createBackup(
  path: string,
  robotID: string,
): Promise<DBBackupData> {
  const dataService = getService('dataBackup')();
  dataService.timeout = 20000;

  const response = await dataService.create({ path, robotID });

  return response;
}
