import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getIsPaused(state: RoutineRunnerState | null): boolean | null {
  return state ? state.kind === 'RoutineRunning' && state.isPaused : null;
}

export default function useIsRobotPaused(
  args: UseRoutineRunnerHandleArguments,
): boolean | null {
  return useRobotState(args, getIsPaused, Object.is);
}
