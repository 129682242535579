import { clamp } from 'lodash';

export function clampAndRoundValue(
  value: number,
  min: number,
  max: number,
  step: number,
) {
  let roundedValue: number;

  if (value <= min || value >= max) {
    // avoid rounding at & beyond the limits
    roundedValue = value;
  } else if (step > 1) {
    roundedValue = Math.round(value / step) * step;
  } else if (step === 1) {
    roundedValue = Math.round(value);
  } else if (step > 0) {
    // this is more accurate for fractional steps
    // (dividing by 1000 is more accurate than multiplying by 0.001)
    const inverseStep = 1 / step;
    roundedValue = Math.round(value * inverseStep) / inverseStep;
  } else {
    roundedValue = value;
  }

  return clamp(roundedValue, min, max);
}
