// import { startCase } from 'lodash';

import type {
  FailureDetails,
  // MotionResult,
  RoutineRunnerState,
} from '@sb/routine-runner';
import type { RecoveryType } from '@sb/routine-runner/RecoveryType';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

type FailureState = {
  kind: 'Failure' | 'Recovering';
  failedStep?: string;
  failure: FailureDetails;
  failureReason: string;
  recoveryType: RecoveryType;
};

function getFailureState(
  routineRunnerState: RoutineRunnerState | null,
): FailureState | null {
  if (!routineRunnerState) {
    return null;
  }

  if (
    routineRunnerState.kind !== 'Failure' &&
    routineRunnerState.kind !== 'Recovering'
  ) {
    return null;
  }

  const failedState =
    routineRunnerState.kind === 'Failure'
      ? routineRunnerState
      : routineRunnerState.failedState;

  return {
    kind: routineRunnerState.kind,
    failedStep: failedState.failedStep,
    failure: failedState.failure,
    failureReason: failedState.failureReason,
    recoveryType: failedState.recoveryType,
  };
}

export default function useRobotFailureState(
  args: UseRoutineRunnerHandleArguments,
): FailureState | null {
  return useRobotState(args, getFailureState);
}
