import * as zod from 'zod';

export const OR2FG7GripKind = zod.enum(['inward', 'outward']);

export const OR_2FG7_GRIP_KINDS = OR2FG7GripKind.options;

export type OR2FG7GripKind = zod.infer<typeof OR2FG7GripKind>;

export const OR2FG7_MIN_SPEED = 0.1;
export const OR2FG7_MAX_SPEED = 1;

// Percent, from 10% to 100%
export const OR2FG7Speed = zod
  .number()
  .min(OR2FG7_MIN_SPEED)
  .max(OR2FG7_MAX_SPEED);

export type OR2FG7Speed = zod.infer<typeof OR2FG7Speed>;

export const OnRobot2FG7Command = zod.object({
  kind: zod.literal('OnRobot2FG7Command'),
  /**
   * This field sets whether the grip will be inward or outward.
   * It also sets the if the width is measured from the inside of the fingertips
   * (inward grip) or from the outside of the fingertips (outward grip).
   */
  gripKind: OR2FG7GripKind,

  /**
   * This field sets the target force to be reached when gripping and holding a
   * workpiece in Newtons.
   * If not provided, a sensible default is chosen for moving but not gripping.
   */
  targetForce: zod.number().optional(),

  targetDiameter: zod.number(),

  /**
   * How close to the target do we need to be to consider it a success?
   */
  targetDiameterTolerance: zod.number().optional(),

  targetSpeed: OR2FG7Speed,

  /**
   * This field will fail the actuation if the force grip is not detected
   * after actuating a grip command.
   */
  waitForGripToContinue: zod.boolean().optional(),
});

export type OnRobot2FG7Command = zod.infer<typeof OnRobot2FG7Command>;
