import type { Routine, Unsubscribe } from '@sb/types';

import { mapPaginated, retryOnTimeout } from '../utils';

import {
  convertRoutineSummary,
  getRoutinesService,
  ROUTINE_DATA_SUMMARY_FIELDS,
} from './utils';

export function onGetRoutineSummaries(
  onSnapshot: (routines: Routine.Summary[]) => void,
): Unsubscribe {
  const subscription = getRoutinesService()
    .watch()
    .find({
      query: {
        $sort: { updatedAt: -1 },
        $select: ROUTINE_DATA_SUMMARY_FIELDS,
      },
    })
    .pipe(retryOnTimeout())
    .subscribe((response) => {
      onSnapshot(mapPaginated(response, convertRoutineSummary));
    });

  return () => subscription.unsubscribe();
}
