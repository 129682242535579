import { useCallback } from 'react';

import type {
  ExternalToRobotPort,
  IOLevel,
  RobotToExternalPort,
  RoutineRunnerState,
  SafetyPort,
} from '@sb/routine-runner';
import type { Robot } from '@sb/types';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

interface UseIOStateArguments extends UseRoutineRunnerHandleArguments {
  kind: Robot.IOPortKind;
  port: number;
}

export default function useRobotIOState({
  kind,
  port,
  robotID,
  isVizbot,
}: UseIOStateArguments): IOLevel | null {
  const routineRunnerArgs = { robotID, isVizbot };

  const getIOState = useCallback(
    (state: RoutineRunnerState | null) => {
      const ioKey = `${kind} ${port}` as
        | ExternalToRobotPort
        | RobotToExternalPort
        | SafetyPort;

      return state?.kinematicState.ioState[ioKey] ?? null;
    },
    [kind, port],
  );

  return useRobotState(routineRunnerArgs, getIOState);
}
