import { Logger } from '@sb/logger';

import { getWebRTCConfig } from '../feathers-client';

import { WebRTCRoutineRunnerHandle } from './WebRTCRoutineRunnerHandle';

/**
 * We use a static global registry here, but this actually may result in problems.
 * Namely, changes to the registry won't cause re-renders in the components. Therefore,
 * re-renders need to be considered carefully, especially as they relate to re-connection
 * when a robot disconnects while using it.
 *
 * The logic in [[useWebRTCRoutineRunnerHandle]] relies on referential equality, so any instance of
 * [[useWebRTCRoutineRunnerHandle]] referencing the same routine runner connection should use
 * the same object instance. Failure to do this carefully may lead to orphan/leaked WebRTC
 * connections or result closing the connections too early.
 */
const registry = new Map<string | null, WebRTCRoutineRunnerHandle>();

export function getWebRTCRoutineRunnerHandle(robotID: string | null) {
  // if the handle exists in the registry, use that one
  let handle = registry.get(robotID);

  if (!handle) {
    const logger = new Logger();
    logger.label = `Robot ${robotID}`;
    logger.addContext('robotID', String(robotID));
    logger.enableConsole();
    handle = new WebRTCRoutineRunnerHandle({ getWebRTCConfig, logger });
    registry.set(robotID, handle);
  }

  return handle;
}
