import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AddBelowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8.5 11V8.5h-1V11H5v1h2.5v2.5h1V12H11v-1H8.5zm7-11a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-5A.5.5 0 0 1 .5 0h15zM15 1H1v4h14V1z" />
    </Icon>
  );
}
