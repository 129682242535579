import type { Unsubscribe } from '@sb/types';

import { currentUserID } from './utils';

export function onGetCurrentUserID(
  /** `null` means not logged in. */
  onChange: (uid: string | null) => void,
): Unsubscribe {
  const subscription = currentUserID()
    .asObservable()
    .subscribe((value) => {
      if (value !== undefined) {
        onChange(value);
      }
    });

  return () => subscription.unsubscribe();
}
