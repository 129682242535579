import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SettingsIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 14">
      <path d="M4.78 1L1.087 7l3.692 6h6.44l3.692-6-3.692-6H4.78zM4.5 0h7a.5.5 0 0 1 .426.238l4 6.5a.5.5 0 0 1 0 .524l-4 6.5A.5.5 0 0 1 11.5 14h-7a.5.5 0 0 1-.426-.238l-4-6.5a.5.5 0 0 1 0-.524l4-6.5A.5.5 0 0 1 4.5 0zM8 10a3 3 0 1 0 0-6 3 3 0 1 0 0 6zm0-1a2 2 0 1 1 0-4 2 2 0 1 1 0 4z" />
    </Icon>
  );
}
