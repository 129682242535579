import type { Step } from '@sb/types';

import type { HaasControlRegionArguments } from '../steps/HaasControlRegion/Arguments';

/**
 * Types matching the routine runner schema for "HaasControlRegion" Steps
 */
export namespace HaasControlRegionStepDatabase {
  /** Arguments to be passed to the routine runner */
  export interface Arguments
    extends Step.CommonArguments,
      HaasControlRegionArguments {
    argumentKind: 'HaasControlRegion';
  }

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): HaasControlRegionArguments => {
    if (!configuration) {
      throw new Error(
        `HaasControlRegion step is not configured. stepID: ${stepID}`,
      );
    }

    return configuration;
  };
}
