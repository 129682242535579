import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AddAboveIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8.5 3V.5h-1V3H5v1h2.5v2.5h1V4H11V3H8.5zm7 6a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-5A.5.5 0 0 1 .5 9h15zM1 10v4h14v-4H1z" />
    </Icon>
  );
}
