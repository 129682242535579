import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ResetIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path d="M4 .5a.5.5 0 0 1 .5-.5H11a5 5 0 1 1 0 10H.5a.5.5 0 1 1 0-1H11a4 4 0 1 0 0-8H4.5A.5.5 0 0 1 4 .5z" />
      <path d="M4.854 5.146a.5.5 0 0 1 0 .707L1.207 9.5l3.646 3.646a.5.5 0 1 1-.707.707l-4-4a.5.5 0 0 1 0-.707l4-4a.5.5 0 0 1 .707 0z" />
    </Icon>
  );
}
