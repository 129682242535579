import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ControlIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 17 17">
      <path d="M10.688 3.446L8.5 1.696l-2.188 1.75a.5.5 0 1 1-.625-.781l2.5-2a.5.5 0 0 1 .625 0l2.5 2a.5.5 0 1 1-.625.781zm2.922 7.297l1.75-2.188-1.75-2.188a.5.5 0 1 1 .781-.625l2 2.5a.5.5 0 0 1 0 .625l-2 2.5a.5.5 0 1 1-.781-.625zm-7.297 2.922l2.188 1.75 2.188-1.75a.5.5 0 1 1 .625.781l-2.5 2a.5.5 0 0 1-.625 0l-2.5-2a.5.5 0 1 1 .625-.781zm-4.672-5.1l1.75 2.188a.5.5 0 0 1-.781.625l-2-2.5a.5.5 0 0 1 0-.625l2-2.5a.5.5 0 1 1 .781.625L1.64 8.556zm6.86 3a3 3 0 1 1 0-6 3 3 0 1 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 1 0 0 4z" />
    </Icon>
  );
}
