import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ArrowForwardIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 8 8" {...props}>
      <path d="M0 3.5H6.1L3.3 0.7L4 0L8 4L4 8L3.3 7.3L6.1 4.5H0V3.5Z" />
    </Icon>
  );
}
