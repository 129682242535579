import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ArrowDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 15" {...props}>
      <path d="M1.556 0L0 1.415l12 13.2 12-13.2L22.444 0 12 11.488z" />
    </Icon>
  );
}
