import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ThreeFingerGripperIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M4.56051 5.53675L2.41568 6.52667C1.25906 7.0605 0.688787 8.37864 1.09162 9.58714L2.34091 13.335C2.60524 14.128 3.2484 14.7364 4.05483 14.9564L4.99653 15.2132C5.16781 15.2599 5.35101 15.2123 5.47787 15.0881C5.60473 14.9639 5.65622 14.7818 5.61316 14.6095L4.51911 10.2333C4.36536 9.61831 4.61482 8.97306 5.14227 8.62142L6.22316 7.90083C5.92025 7.722 5.64662 7.49885 5.4112 7.24029L4.58757 7.78937C3.70848 8.37543 3.29272 9.45085 3.54896 10.4758L4.43595 14.0238L4.31794 13.9916C3.83409 13.8596 3.44819 13.4946 3.2896 13.0188L2.0403 9.27091C1.7986 8.54581 2.14077 7.75493 2.83474 7.43463L4.88748 6.48721C4.73534 6.19232 4.62388 5.87303 4.56051 5.53675Z" />
      <path d="M11.1123 6.48721C11.2644 6.19232 11.3759 5.87302 11.4392 5.53674L13.5701 6.52021C14.7341 7.05745 15.3031 8.38815 14.8873 9.60089L13.5978 13.3622C13.3315 14.1389 12.7007 14.7354 11.9103 14.9579L11.0072 15.2121C10.8356 15.2604 10.6513 15.2137 10.5234 15.0895C10.3955 14.9653 10.3434 14.7825 10.3866 14.6095L11.4806 10.2334C11.6344 9.6184 11.3849 8.97304 10.8575 8.62142L9.7766 7.90082C10.0795 7.722 10.3531 7.49884 10.5886 7.24028L11.4122 7.78937C12.2913 8.37545 12.707 9.45102 12.4508 10.476L11.5658 14.016L11.6393 13.9953C12.1136 13.8618 12.492 13.5039 12.6518 13.0379L13.9414 9.27657C14.1908 8.54893 13.8494 7.75051 13.151 7.42817L11.1123 6.48721Z" />
      <path d="M9.49988 8.04786C9.18903 8.19553 8.85295 8.29873 8.49988 8.34923V12.7929L7.99988 13.2929L7.49988 12.7929V8.34923C7.14681 8.29873 6.81073 8.19553 6.49988 8.04786V12.7929C6.49988 13.0581 6.60524 13.3125 6.79277 13.5L7.50491 14.2122C7.77827 14.4855 8.22149 14.4855 8.49486 14.2122L9.20699 13.5C9.39452 13.3125 9.49988 13.0581 9.49988 12.7929V8.04786Z" />
      <path d="M10.755 2.7257L11.6566 0.622069L10.7374 0.228149L9.97912 1.99753C10.2733 2.19958 10.5351 2.44544 10.755 2.7257Z" />
      <path d="M5.24505 2.72566L4.34351 0.622068L5.26265 0.228149L6.02094 1.9975C5.72674 2.19956 5.46498 2.44541 5.24505 2.72566Z" />
      <path
        fillRule="evenodd"
        d="M9.5 4.88464C9.5 4.05622 8.82843 3.38464 8 3.38464C7.17157 3.38464 6.5 4.05622 6.5 4.88464C6.5 5.71307 7.17157 6.38464 8 6.38464C8.82843 6.38464 9.5 5.71307 9.5 4.88464ZM8 2.38464C9.38071 2.38464 10.5 3.50393 10.5 4.88464C10.5 6.26536 9.38071 7.38464 8 7.38464C6.61929 7.38464 5.5 6.26536 5.5 4.88464C5.5 3.50393 6.61929 2.38464 8 2.38464Z"
      />
    </Icon>
  );
}
