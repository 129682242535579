import cx from 'classnames';
import { useRef } from 'react';

import Card from '../Card';
import Portal from '../Portal';

import { ModalContext } from './shared';

import styles from './Modal.module.css';

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
  isScrollable?: boolean;
  onClose?: React.MouseEventHandler;
}

const Modal = ({
  children,
  className,
  isOpen,
  isScrollable,
  onClose,
}: ModalProps) => {
  const ref = useRef<HTMLDivElement>(null);
  if (!isOpen) return null;

  const onClickBackground = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target === event.currentTarget) {
      onClose?.(event);
    }
  };

  return (
    <Portal selector="#modal">
      <ModalContext.Provider value={{ isOpen, onClose }}>
        {/* The modal contents should have a close button that allows keyboard interaction */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.modal}
          onClick={onClickBackground}
          data-testid="modal-shim"
        >
          <Card
            ref={ref}
            className={cx(
              styles.modalCard,
              { [styles.scrollable]: isScrollable },
              className,
            )}
            data-testid="modal-contents"
            borderSize="medium"
          >
            {children}
          </Card>
        </div>
      </ModalContext.Provider>
    </Portal>
  );
};

export default Modal;
