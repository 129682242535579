import type {
  DeviceCommand,
  DeviceConfiguration,
  DeviceState,
} from '@sb/integrations/device';
import type {
  ModbusRegisterRequest,
  ModbusResponse,
} from '@sb/integrations/modbus/types';
import type {
  DeviceSimulator,
  SimulatorConstructorArgs,
} from '@sb/integrations/simulator';

import { OnRobotDualQuickChangerCommand } from '..';
import { OnRobot2FG7Simulator } from '../../OnRobot2FG7/simulation/OnRobot2FG7Simulator';
import { OnRobot3FG15Simulator } from '../../OnRobot3FG15/simulation/OnRobot3FG15Simulator';
import type { OnRobotDualQuickChangerState } from '../State';

const primaryAddress = 0x42;
const secondaryAddress = 0x43;

const getGripperClass = (item: DeviceConfiguration) => {
  switch (item.kind) {
    case 'OnRobot2FG7':
      return OnRobot2FG7Simulator;
    case 'OnRobot3FG15':
      return OnRobot3FG15Simulator;
    case 'NoGripper':
      return null;
    default: {
      throw new Error(`Unsupported gripper implementation: ${item.kind}`);
    }
  }
};

const getImplementation = (
  item: DeviceConfiguration,
  existingGripper: OnRobot2FG7Simulator | OnRobot3FG15Simulator | void | null,
) => {
  const Cls = getGripperClass(item);
  // try to reuse existing if same class, otherwise switch out
  if (!Cls) return null;

  if (existingGripper instanceof Cls) return existingGripper;

  return new Cls({
    configuration: item,
  });
};

type DualQuickChangerCompatibleSimulatedGripper =
  | OnRobot2FG7Simulator
  | OnRobot3FG15Simulator
  | null;

export class OnRobotDualQuickChangerSimulator
  implements DeviceSimulator<OnRobotDualQuickChangerState>
{
  public kind = 'OnRobotDualQuickChanger' as const;

  public state: OnRobotDualQuickChangerState;

  private primary: DualQuickChangerCompatibleSimulatedGripper;

  private secondary: DualQuickChangerCompatibleSimulatedGripper;

  public constructor({ configuration }: SimulatorConstructorArgs) {
    if (configuration.kind !== 'OnRobotDualQuickChanger') {
      throw new Error(
        `Invalid gripper configuration for simulated dual quick changer: ${configuration.kind}`,
      );
    }

    this.primary = getImplementation(configuration.grippers.primary);

    this.secondary = getImplementation(configuration.grippers.secondary);

    this.state = {
      kind: 'OnRobotDualQuickChanger',
      primary: this.primary?.getState(),
      secondary: this.secondary?.getState(),
    };
  }

  public updateConfig(configuration: DeviceConfiguration) {
    if (configuration.kind !== 'OnRobotDualQuickChanger') {
      throw new Error(
        `Invalid gripper configuration for simulated dual quick changer: ${configuration.kind}`,
      );
    }

    this.primary = getImplementation(
      configuration.grippers.primary,
      this.primary,
    );

    this.secondary = getImplementation(
      configuration.grippers.secondary,
      this.secondary,
    );

    this.state.primary = this.primary?.getState();
    this.state.secondary = this.secondary?.getState();
  }

  public getState(): OnRobotDualQuickChangerState {
    return {
      ...this.state,
      primary: this.primary?.getState(),
      secondary: this.secondary?.getState(),
    };
  }

  public getModbusAddressSubscriptions(): Set<number> {
    return new Set([primaryAddress, secondaryAddress]);
  }

  public setState(state: Partial<DeviceState>) {
    if (state.kind !== 'OnRobotDualQuickChanger') return;

    if (state.primary) this.primary?.setState(state.primary);
    if (state.secondary) this.secondary?.setState(state.secondary);
  }

  public async actuate(command: DeviceCommand) {
    const parsedCommand = OnRobotDualQuickChangerCommand.parse(command);

    if (parsedCommand.active === 'primary') {
      await this.primary?.actuate(parsedCommand.command);

      return;
    }

    await this.secondary?.actuate(parsedCommand.command);
  }

  public stop() {
    this.primary?.stop();
    this.secondary?.stop();
  }

  public handleModbusRegisterRequest(
    request: ModbusRegisterRequest,
  ): ModbusResponse {
    const { targetAddress } = request;

    switch (targetAddress) {
      case primaryAddress:
        if (this.primary) {
          return this.primary?.handleModbusRegisterRequest(request);
        }

        break;

      case secondaryAddress:
        if (this.secondary) {
          return this.secondary?.handleModbusRegisterRequest(request);
        }

        break;

      default:
        break;
    }

    throw new Error(`Unsupported target address: ${targetAddress}`);
  }
}
