import cx from 'classnames';

import styles from './Loader.module.css';

type LoaderSize = 'default' | 'extraSmall' | 'small' | 'large';
type LoaderVariant = 'primary' | 'white';

interface LoaderProps {
  className?: string;
  size?: LoaderSize;
  variant?: LoaderVariant;
}

const Loader = ({
  size = 'default',
  variant = 'primary',
  className = '',
}: LoaderProps) => {
  return (
    <div
      className={cx(styles.loader, styles[size], styles[variant], className)}
    >
      <span className={cx(styles.loaderSpan)} />
    </div>
  );
};

export default Loader;
