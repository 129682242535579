import LRUCache from 'lru-cache';

/**
 * Local cache that will return the cached value if available, otherwise it will
 * call the `getValue` function to get the value and cache it.
 *
 * @param Key The type of the key used to get the value.
 * @param Value The type of the value to be cached.
 */
export class LocalCache<Key, Value> {
  public getCount: number = 0;

  public missCount: number = 0;

  private cache: LRUCache<any, Value>;

  private getValue: (key: Key) => Value;

  private getCacheKey: (key: Key) => any;

  /**
   *
   * @param getValue Function to fetch the value if the key is not in the cache
   * @param getCacheKey Function to transform the key to a cache key
   * @param cacheOptions LRU cache options
   * @param cacheName Set the context for logging for logs and metrics
   */
  public constructor({
    getValue,
    getCacheKey = (key: Key) => key as any,
    cacheOptions,
  }: {
    getValue: (key: Key) => Value;
    getCacheKey?: (key: Key) => any;
    cacheOptions?: LRUCache.Options<any, Value>;
  }) {
    this.cache = new LRUCache<any, Value>(cacheOptions);
    this.getValue = getValue;
    this.getCacheKey = getCacheKey;
  }

  public get(key: Key): any {
    const cacheKey = this.getCacheKey(key);
    this.getCount += 1;

    if (!this.cache.has(cacheKey)) {
      this.missCount += 1;
      this.cache.set(cacheKey, this.getValue(key));
    }

    return this.cache.get(cacheKey);
  }

  public set(key: Key, value: Value): void {
    this.cache.set(this.getCacheKey(key), value);
  }

  public has(key: Key): boolean {
    return this.cache.has(this.getCacheKey(key));
  }

  public size(): number {
    return this.cache.itemCount;
  }

  public reset(): void {
    this.cache.reset();
    this.getCount = 0;
    this.missCount = 0;
  }

  public get hitCount(): number {
    return this.getCount - this.missCount;
  }
}
