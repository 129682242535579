import { calculatePercent } from './calculatePercent';
import { MeterLabelIndicator } from './MeterLabelIndicator';

import styles from './SecondaryValueMeter.module.css';

export interface SecondaryValueMeterProps {
  primaryPercent: number;
  secondaryValue: number;
  min: number;
  max: number;
  valueToString: (value: number) => string;
}

export function SecondaryValueMeter({
  primaryPercent,
  secondaryValue,
  min,
  max,
  valueToString,
}: SecondaryValueMeterProps) {
  const secondaryPercent = calculatePercent(secondaryValue, min, max);
  const fillLeftPercent = Math.min(secondaryPercent, primaryPercent);
  const fillRightPercent = 100 - Math.max(secondaryPercent, primaryPercent);

  return (
    <>
      <div className={styles.secondaryFillClip}>
        <div
          className={styles.secondaryFill}
          style={{
            left: `${fillLeftPercent}%`,
            right: `${fillRightPercent}%`,
          }}
        />
      </div>
      <MeterLabelIndicator
        arrowAlign="bottom"
        percent={secondaryPercent}
        value={valueToString(secondaryValue)}
      />
    </>
  );
}
