import { useCallback, useEffect, useState } from 'react';

import type { RoutineRunnerHandle } from '@sbrc/services';
import {
  getWebSocketRoutineRunnerHandle,
  getWebRTCRoutineRunnerHandle,
} from '@sbrc/services';

import { useFeatureFlag } from './useFeatureFlag';

interface Args {
  robotID: string | null;
  forceWebRTC?: boolean;
}

/**
 * Connect to a live running robot.
 * Re-renders when connection state changes
 *
 * @returns A RoutineRunnerHandle
 */
export function useLiveRoutineRunnerHandle({
  robotID,
  forceWebRTC,
}: Args): RoutineRunnerHandle {
  const webRTCFlag = useFeatureFlag('webRTC');

  const handle =
    webRTCFlag || forceWebRTC
      ? getWebRTCRoutineRunnerHandle(robotID)
      : getWebSocketRoutineRunnerHandle();

  // used to force a re-render when the WebRTCRoutineRunnerHandle changes
  const [, updateToken] = useState({});
  const rerender = useCallback(() => updateToken({}), []);

  // whenever the handle has a change that requires a re-render, re-render
  useEffect(() => {
    return handle.onChange(rerender);
  }, [handle, rerender]);

  return handle;
}
