import { Vector3 } from 'three';

import type { CartesianPosition } from '@sb/geometry';
import { convertPositionToVector3 as toVector } from '@sb/geometry';
import { interpolateCatmullRomSpline } from '@sb/geometry/interpolateCatmullRomSpline';

export const MIN_NUM_POINTS = 3;
// If this value is too large (like >0.1) the arm may stop at the waypoints
// due to acceleration and jerk limits
export const SPACING_BETWEEN_POINTS = 0.01;

/**
 * Find the number of interpolated points based on the requested spacing and the
 * distance between the two points
 * @param v0
 * @param v1
 * @param spacingBetweenPoints
 */
function getNumberOfPoints(
  v0: Vector3,
  v1: Vector3,
  spacingBetweenPoints: number,
): number {
  return Math.max(
    Math.ceil(v0.distanceTo(v1) / spacingBetweenPoints),
    MIN_NUM_POINTS,
  );
}

/**
 * Finds the blend radius point which is on in the line (endPoint, startPoint)
 * and a distance of blendRadius from endPoint
 * @param startPoint
 * @param endPoint
 * @param blendRadius
 */
export function findBlendRadiusPoint(
  startPoint: CartesianPosition,
  endPoint: CartesianPosition,
  blendRadius: number,
): CartesianPosition {
  const direction = new Vector3(
    startPoint.x - endPoint.x,
    startPoint.y - endPoint.y,
    startPoint.z - endPoint.z,
  );

  const ratio = blendRadius / direction.length();

  if (!Number.isFinite(ratio)) {
    return endPoint;
  }

  return {
    x: endPoint.x + ratio * direction.x,
    y: endPoint.y + ratio * direction.y,
    z: endPoint.z + ratio * direction.z,
  };
}

/**
 * Blends the path from the waypoints defined by p0, p1, p2
 * More details can be found [here](https://www.notion.so/standardbots/Move-Step-Blend-Radius-163849f328ff419a870ab2ce84ea929a#456f749a87e448618f8641e60e870399)
 * @param p0
 * @param p1
 * @param p2
 * @param blendRadius
 * @param blendRatio
 * @param numPoints
 */
export function blendRadiusMove(
  p0: CartesianPosition,
  p1: CartesianPosition,
  p2: CartesianPosition,
  blendRadius: number,
  spacingBetweenPoints: number = SPACING_BETWEEN_POINTS,
): CartesianPosition[] {
  const brp0 = findBlendRadiusPoint(p0, p1, blendRadius);
  const brp1 = findBlendRadiusPoint(p2, p1, blendRadius);

  const v0 = toVector(p0);
  const v2 = toVector(p2);

  const vip0 = toVector(brp0);
  const vip1 = toVector(brp1);

  // get the handler points to blend initial and last curves from
  const hp0 = v0.clone().sub(vip0.clone().sub(v0));
  const hp1 = v2.clone().sub(vip1.clone().sub(v2));

  // get three curves and combine the points for the result
  return [
    ...interpolateCatmullRomSpline(
      hp0,
      v0,
      vip0,
      vip1,
      getNumberOfPoints(v0, vip0, spacingBetweenPoints),
    ),
    ...interpolateCatmullRomSpline(
      v0,
      vip0,
      vip1,
      v2,
      getNumberOfPoints(vip0, vip1, spacingBetweenPoints),
      false,
    ),
    ...interpolateCatmullRomSpline(
      vip0,
      vip1,
      v2,
      hp1,
      getNumberOfPoints(vip1, v2, spacingBetweenPoints),
      false,
    ),
  ];
}
