/* eslint-disable no-underscore-dangle */

import { debounce, isEqual } from 'lodash';
import SimplePeer from 'simple-peer';
import { v4 as uuid } from 'uuid';

import type { Logger } from '@sb/logger';
import { HEARTBEAT_SIGNAL } from '@sb/routine-runner';
import { EventEmitter, timeout, wait } from '@sb/utilities';
import { API_ENDPOINT } from '@sbrc/utils';

import type { ConnectionStatus } from './ConnectionStatus';
import { RoutineRunnerHandle } from './RoutineRunnerHandle';

interface VideoEvents {
  mediaStream: MediaStream | null;
}

interface ConnectionStatusEvents {
  // called after anything that might change the connection status changes
  change(): void;
}

type RTCState = 'pre-connect' | 'connecting' | 'connected' | 'disconnected';

/**
 * A handle for a remote RoutineRunner.
 *
 * It also extends [[RoutineRunnerPacketSender]] with botman-specific methods, mostly
 * relating to the camera.
 */
export class WebRTCRoutineRunnerHandle extends RoutineRunnerHandle {
  private getWebRTCConfig: () => Promise<RTCConfiguration>;

  private _rtcState: RTCState = 'pre-connect';

  private connectionStatusEvents = new EventEmitter<ConnectionStatusEvents>();

  private videoEvents = new EventEmitter<VideoEvents>();

  public constructor(props: {
    getWebRTCConfig: () => Promise<RTCConfiguration>;
    logger: Logger;
  }) {
    const logger = props.logger.createChildLogger();
    logger.enableConsole();
    logger.label = 'WebRTC';

    super(logger);

    this.getWebRTCConfig = props.getWebRTCConfig;

    this.resetConnectingTimeout();

    this.onClose(() => {
      this.videoEvents.emit('mediaStream', null);
    });

    this.connectLoop();
  }

  public getName(): Promise<string> {
    return Promise.resolve('Live (WebRTC)');
  }

  /**
   * Information regarding the direct connection between the client and
   * Botman (primarily as it relates to WebRTC).
   *
   * @override
   */
  public getConnectionStatus(): ConnectionStatus {
    if (this.rtcState === 'pre-connect') {
      return { kind: 'constructing' };
    }

    if (this.rtcState === 'connected') {
      return { kind: 'connected' };
    }

    if (this.rtcState === 'connecting') {
      return { kind: 'connecting' };
    }

    return { kind: 'disconnected' };
  }

  /**
   * @param cb gets called whenever the connection status changes
   *
   * @override
   * @returns a cancelation function
   */
  public onConnectionChange(
    cb: (connectionStatus: ConnectionStatus) => void,
  ): () => void {
    let lastStatus = this.getConnectionStatus();
    cb(lastStatus);

    return this.connectionStatusEvents.on('change', () => {
      const newStatus = this.getConnectionStatus();

      if (!isEqual(newStatus, lastStatus)) {
        cb(newStatus);
        lastStatus = newStatus;
      }
    });
  }

  private get rtcState() {
    return this._rtcState;
  }

  private set rtcState(rtcState: RTCState) {
    this._rtcState = rtcState;
    this.connectionStatusEvents.emit('change');
  }

  private colorVideo?: Promise<MediaStream>;

  /**
   * Request botman to add a color video MediaStream to the WebRTC connection
   */
  public async streamColorVideo(): Promise<MediaStream> {
    // we only want to request the color video once, so we cache the promise
    if (!this.colorVideo) {
      this.colorVideo = (async () => {
        this.requestResponse({ kind: 'streamColorVideo' });

        const stream = await this.videoEvents.next('mediaStream');

        if (!stream) {
          throw new Error('Stream closed before color video started streaming');
        }

        return stream;
      })();
    }

    return this.colorVideo;
  }

  private depthVideo?: Promise<MediaStream>;

  /**
   * Request botman to add a depth video MediaStream to the WebRTC connection
   */
  public streamDepthVideo(): Promise<MediaStream> {
    // we only want to request the depth video once, so we cache the promise
    if (!this.depthVideo) {
      this.depthVideo = (async () => {
        this.requestResponse({ kind: 'streamDepthVideo' });

        const stream = await this.videoEvents.next('mediaStream');

        if (!stream) {
          throw new Error('Stream closed before depth video started streaming');
        }

        return stream;
      })();
    }

    return this.depthVideo;
  }

  public onMediaStreamChange(
    onChange: (mediaStream: VideoEvents['mediaStream']) => void,
  ) {
    return this.videoEvents.on('mediaStream', onChange);
  }

  private async connectLoop() {
    while (!this.isDestroyed) {
      try {
        await this.connect();
        await wait(1000);
      } catch (e) {
        this.logger.error(e);
      }
    }
  }

  /**
   * Initiates a connection.
   * Returns a promise which will be pending while connecting or connected
   */
  private async connect() {
    if (
      this.rtcState === 'connecting' ||
      this.rtcState === 'connected' ||
      this.isDestroyed
    ) {
      return;
    }

    this.rtcState = 'connecting';

    let offPacket = () => {};

    const ws = new WebSocket(`${API_ENDPOINT}webrtc-signaling`);

    const wsConnectPromise = new Promise<void>((resolve) => {
      ws.onopen = () => resolve();
    });

    const webRTCConfig = await this.getWebRTCConfig();

    const peer = new SimplePeer({
      initiator: true,
      config: webRTCConfig,
    });

    try {
      await timeout(5000, 'Signaling timeout').race(wsConnectPromise);

      this.resetConnectingTimeout();

      const connectionID = uuid();
      this.logger.info('new connection', connectionID);

      await new Promise<void>((resolve) => {
        // Destroy the WebRTC connection if nothing received for a while
        const scheduleAutoDisconnect = debounce(() => {
          this.logger.info('Auto disconnect (nothing received for 5s)');
          resolve();
        }, 5000);

        scheduleAutoDisconnect();

        peer.on('signal', (signal) => {
          this.emitSBDevToolsEvent({
            kind: 'WebRTC#outgoingSignal',
            collect: true,
            signal,
            connectionID,
          });

          ws.send(JSON.stringify(signal));
        });

        peer.on('stream', (stream) => {
          this.videoEvents.emit('mediaStream', stream);
        });

        ws.onmessage = (ev) => {
          const signal = JSON.parse(ev.data.toString());

          if (!peer.destroyed) {
            peer.signal(signal);
          }

          this.emitSBDevToolsEvent({
            kind: 'WebRTC#incomingSignal',
            collect: true,
            signal,
            connectionID,
          });
        };

        ws.onerror = () => {
          this.logger.error('Failed to connect to robot: Signaling error');
          resolve();
        };

        // Destroy the WebRTC connection on ICE failure/disconnect
        peer.on('iceStateChange', (iceState) => {
          switch (iceState) {
            case 'failed':
            case 'disconnected':
            case 'closed':
              this.logger.info(`ICE ${iceState}`);
              resolve();
              break;
            default:
          }
        });

        peer.once('connect', () => {
          this.emitSBDevToolsEvent({
            kind: 'WebRTC#connect',
            collect: true,
            connectionID,
          });

          this.rtcState = 'connected';

          if (this.arePacketsHandled()) {
            this.logger.warn(
              'Multiple outgoing packet handlers being attached. Multiple peers may have been created, duplicating bandwidth',
            );
          }

          offPacket = this.onPacket((packet) => {
            this.emitSBDevToolsEvent({
              kind: 'outgoingPacket',
              packet,
            });

            try {
              peer.send(packet);
            } catch (e) {
              resolve();
            }
          });

          peer.on('data', (packet) => {
            scheduleAutoDisconnect();

            // heartbeat is sent by botman every second *if nothing else is sent*
            // confirms that botman is still there to avoid auto disconnect
            // it doesn't need to be emitted
            if (HEARTBEAT_SIGNAL.equals(packet)) {
              return;
            }

            this.receivePacket(packet);
          });
        });

        peer.once('error', (error) => {
          this.logger.error('Peer errored:', error);
          resolve();
        });

        peer.once('end', () => {
          this.emitSBDevToolsEvent({
            kind: 'WebRTC#disconnect',
            collect: true,
            connectionID,
          });

          resolve();
        });
      });
    } finally {
      offPacket();

      this.logger.info(
        'No longer handling packets',
        this.packets.listenerCount('send'),
      );

      ws.close();
      peer.destroy();

      this.rtcState = 'disconnected';
    }
  }

  private connectingTimeoutId: any;

  /**
   * Allow 10 seconds for connection to happen,
   * otherwise set as disconnected
   */
  private resetConnectingTimeout() {
    if (this.connectingTimeoutId) {
      clearTimeout(this.connectingTimeoutId);
    } else {
      this.destructors.push(() => clearTimeout(this.connectingTimeoutId));
    }

    this.connectingTimeoutId = setTimeout(() => {
      if (this.rtcState === 'pre-connect' || this.rtcState === 'connecting') {
        this.rtcState = 'disconnected';
      }
    }, 10_000);
  }
}
