import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function WaypointIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        d="M11 2.5C11 1.11929 12.1193 0 13.5 0C14.8807 0 16 1.11929 16 2.5C16 3.88071 14.8807 5 13.5 5C12.1193 5 11 3.88071 11 2.5ZM13.5 1C12.6716 1 12 1.67157 12 2.5C12 3.32843 12.6716 4 13.5 4C14.3284 4 15 3.32843 15 2.5C15 1.67157 14.3284 1 13.5 1Z"
      />
      <path
        fillRule="evenodd"
        d="M0 13.5C0 12.1193 1.11929 11 2.5 11C3.88071 11 5 12.1193 5 13.5C5 14.8807 3.88071 16 2.5 16C1.11929 16 0 14.8807 0 13.5ZM2.5 12C1.67157 12 1 12.6716 1 13.5C1 14.3284 1.67157 15 2.5 15C3.32843 15 4 14.3284 4 13.5C4 12.6716 3.32843 12 2.5 12Z"
      />
      <path d="M3.65901 1.65901C4.08097 1.23705 4.65326 1 5.25 1C5.84674 1 6.41903 1.23705 6.84099 1.65901C7.26295 2.08097 7.5 2.65326 7.5 3.25V12.75C7.5 13.612 7.84241 14.4386 8.4519 15.0481C9.0614 15.6576 9.88805 16 10.75 16C11.612 16 12.4386 15.6576 13.0481 15.0481C13.6576 14.4386 14 13.612 14 12.75V6.5C14 6.22386 13.7761 6 13.5 6C13.2239 6 13 6.22386 13 6.5V12.75C13 13.3467 12.7629 13.919 12.341 14.341C11.919 14.7629 11.3467 15 10.75 15C10.1533 15 9.58097 14.7629 9.15901 14.341C8.73705 13.919 8.5 13.3467 8.5 12.75V3.25C8.5 2.38805 8.15759 1.5614 7.5481 0.951903C6.9386 0.34241 6.11195 0 5.25 0C4.38805 0 3.5614 0.34241 2.9519 0.951903C2.34241 1.5614 2 2.38805 2 3.25V9.5C2 9.77614 2.22386 10 2.5 10C2.77614 10 3 9.77614 3 9.5V3.25C3 2.65326 3.23705 2.08097 3.65901 1.65901Z" />
    </Icon>
  );
}
