import { calculateOR2FG7StateFromCommand } from '../OnRobot2FG7';
import { calculateOR3FG15StateFromCommand } from '../OnRobot3FG15';

import type { OnRobotDualQuickChangerCommand } from './Command';
import type { OnRobotDualQuickChangerState } from './State';

export function calculateORDualQuickChangerStateFromCommand(
  command: OnRobotDualQuickChangerCommand,
): Partial<OnRobotDualQuickChangerState> {
  return {
    kind: 'OnRobotDualQuickChanger',
    [command.active]:
      command.command.kind === 'OnRobot2FG7Command'
        ? calculateOR2FG7StateFromCommand(command.command)
        : calculateOR3FG15StateFromCommand(command.command),
  };
}
