import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getStateKind(
  state: RoutineRunnerState | null,
): RoutineRunnerState['kind'] | null {
  return state ? state.kind : null;
}

export default function useRobotStateKind(
  args: UseRoutineRunnerHandleArguments,
): RoutineRunnerState['kind'] | null {
  return useRobotState(args, getStateKind, Object.is);
}
