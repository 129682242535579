import { useCallback } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { areJointAnglesEqual } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export function useIsRobotAtJointAngles(
  routineRunnerHandleArgs: UseRoutineRunnerHandleArguments,
  ...jointAnglesList: Array<ArmJointPositions | null | undefined>
): boolean {
  const selector = useCallback(
    (state: RoutineRunnerState | null) => {
      if (!state) {
        return false;
      }

      return jointAnglesList.some((jointAngles) =>
        areJointAnglesEqual(state.kinematicState.jointAngles, jointAngles),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jointAnglesList.toString()],
  );

  return useRobotState(routineRunnerHandleArgs, selector, Object.is);
}
