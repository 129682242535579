import { getApp } from '../utils';

export async function updatePIN(pin: string, newPIN: string): Promise<void> {
  await getApp().authenticate({
    strategy: 'pin',
    username: 'default',
    pin,
    newPIN,
  });
}
