import { MathUtils } from 'three';

import { Logger } from '@sb/logger';
import { ANTIGRAVITY_SPEEDS_DEFAULT, JOINT_NUMBERS } from '@sb/motion-planning';
import type {
  ArmJointLimits,
  ArmJointPositions,
  ArmJointVelocities,
  JointNumber,
  MotionPlan,
} from '@sb/motion-planning';
import type { MotionResult } from '@sb/routine-runner/types/MotionResult';
import { six, wait } from '@sb/utilities';

export class RO1Simulator {
  private logger = new Logger();

  public jointPositions: ArmJointPositions = six(0);

  public jointVelocities: ArmJointVelocities = six(0);

  public antigravitySpeeds: ArmJointVelocities = ANTIGRAVITY_SPEEDS_DEFAULT;

  private isMoving = false;

  // Joint limits copied from @sb/visualizer/arm/modelone.urdf
  public readonly jointLimits: ArmJointLimits = [
    { min: -6.28318530718, max: 6.28318530718 },
    { min: -2.35, max: 2.35 },
    { min: -2.9, max: 2.9 },
    { min: -6.28318530718, max: 6.28318530718 },
    { min: -6.28318530718, max: 6.28318530718 },
    { min: -6.28318530718, max: 6.28318530718 },
  ];

  public brakedJoints = six(true);

  public constructor() {
    this.logger.label = 'RO1Simulator';
    this.logger.enableConsole();
  }

  public get isBraked() {
    return this.brakedJoints.some((j) => j);
  }

  public stopMotion() {
    this.jointVelocities = six(0);
    this.isMoving = false;
  }

  public async playMotionPlan(motionPlan: MotionPlan): Promise<MotionResult> {
    if (this.isMoving) {
      return {
        kind: 'stateError',
        motionCompleted: false,
        totalWaypoints: 0,
        waypointsCompleted: 0,
        stateError: {
          previousState: 'MOVING',
        },
      };
    }

    this.isMoving = true;

    const endWaypoint = motionPlan[motionPlan.length - 1];

    if (motionPlan.length > 1) {
      // frame rate = 25hz
      const TIME_INTERVAL_MS = 1000 / 25;

      let waypointIndex = 1;
      let waypointA = motionPlan[0];
      let waypointB = motionPlan[waypointIndex];

      for (
        let timestamp = 0;
        timestamp < endWaypoint.timestamp;
        timestamp += TIME_INTERVAL_MS / 1000
      ) {
        if (!this.isMoving) {
          this.stopMotion();

          return {
            kind: 'stopped',
            motionCompleted: false,
            totalWaypoints: motionPlan.length,
            waypointsCompleted: waypointIndex,
          };
        }

        while (waypointB.timestamp < timestamp) {
          waypointIndex += 1;
          waypointA = waypointB;
          waypointB = motionPlan[waypointIndex];
        }

        // t is the fraction between the waypointA and waypointB at this timestamp
        const t = MathUtils.inverseLerp(
          waypointA.timestamp,
          waypointB.timestamp,
          timestamp,
        );

        // eslint-disable-next-line @typescript-eslint/no-loop-func
        const jointPositions = JOINT_NUMBERS.map((j) =>
          MathUtils.lerp(waypointA.angles[j], waypointB.angles[j], t),
        ) as ArmJointPositions;

        const jointVelocities = waypointA.velocities;

        this.jointPositions = jointPositions;
        this.jointVelocities = jointVelocities;

        await wait(TIME_INTERVAL_MS);
      }
    }

    this.isMoving = false;
    this.jointVelocities = six(0);
    this.jointPositions = endWaypoint.angles;

    return {
      kind: 'success',
      motionCompleted: true,
      totalWaypoints: motionPlan.length,
      waypointsCompleted: motionPlan.length,
    };
  }

  public async setIsJointBraked(
    jointNumber: JointNumber,
    isBraked: boolean,
  ): Promise<void> {
    await wait(50);

    if (this.brakedJoints[jointNumber] !== isBraked) {
      this.brakedJoints = [...this.brakedJoints];
      this.brakedJoints[jointNumber] = isBraked;

      this.logger.info(
        `Set joint ${jointNumber} ${isBraked ? 'braked' : 'unbraked'}`,
      );
    }
  }

  public authorizeAntigravityMode(): Promise<void> {
    return wait(50);
  }

  public deauthorizeAntigravityMode(): Promise<void> {
    return wait(50);
  }
}
