import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AccessoriesIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <path d="M11.3533 1.52523C11.158 1.32997 10.8414 1.32997 10.6462 1.52523L8.52483 3.64655C8.32957 3.84181 8.32957 4.1584 8.52483 4.35366L10.6462 6.47498C10.8414 6.67024 11.158 6.67024 11.3533 6.47498L13.4746 4.35366C13.6698 4.1584 13.6698 3.84181 13.4746 3.64655L11.3533 1.52523ZM9.93905 0.818124C10.5248 0.232338 11.4746 0.232338 12.0604 0.818124L14.1817 2.93944C14.7675 3.52523 14.7675 4.47498 14.1817 5.06076L12.0604 7.18209C11.4746 7.76787 10.5248 7.76787 9.93905 7.18209L7.81773 5.06076C7.23194 4.47498 7.23194 3.52523 7.81773 2.93944L9.93905 0.818124ZM1.5 1.99996C1.22386 1.99996 1 2.22382 1 2.49996V5.49996C1 5.7761 1.22386 5.99996 1.5 5.99996H4.5C4.77614 5.99996 5 5.7761 5 5.49996V2.49996C5 2.22382 4.77614 1.99996 4.5 1.99996H1.5ZM0 2.49996C0 1.67153 0.671573 0.999959 1.5 0.999959H4.5C5.32843 0.999959 6 1.67153 6 2.49996V5.49996C6 6.32839 5.32843 6.99996 4.5 6.99996H1.5C0.671573 6.99996 0 6.32839 0 5.49996V2.49996ZM1.5 9.99996C1.22386 9.99996 1 10.2238 1 10.5V13.5C1 13.7761 1.22386 14 1.5 14H4.5C4.77614 14 5 13.7761 5 13.5V10.5C5 10.2238 4.77614 9.99996 4.5 9.99996H1.5ZM0 10.5C0 9.67153 0.671573 8.99996 1.5 8.99996H4.5C5.32843 8.99996 6 9.67153 6 10.5V13.5C6 14.3284 5.32843 15 4.5 15H1.5C0.671573 15 0 14.3284 0 13.5V10.5ZM9.5 9.99996C9.22386 9.99996 9 10.2238 9 10.5V13.5C9 13.7761 9.22386 14 9.5 14H12.5C12.7761 14 13 13.7761 13 13.5V10.5C13 10.2238 12.7761 9.99996 12.5 9.99996H9.5ZM8 10.5C8 9.67153 8.67157 8.99996 9.5 8.99996H12.5C13.3284 8.99996 14 9.67153 14 10.5V13.5C14 14.3284 13.3284 15 12.5 15H9.5C8.67157 15 8 14.3284 8 13.5V10.5Z" />
    </Icon>
  );
}
