import type {
  ArmJointAccelerations,
  ArmJointVelocities,
} from '@sb/motion-planning';
import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
} from '@sb/motion-planning';
import type { SpeedProfile } from '@sb/routine-runner';

/**
 * Combine the passed speed profiles together with the absolute max limits.
 * For each profile value, get the minimum of the speed profiles
 */
export const combineSpeedProfiles = (
  speeds: Array<SpeedProfile>,
): SpeedProfile => {
  const maxJointSpeeds = ABSOLUTE_MAX_JOINT_SPEEDS.map((absoluteMax, index) => {
    return Math.min(
      ...speeds.map((s) => Math.abs(s.maxJointSpeeds[index])),
      absoluteMax,
    );
  }) as ArmJointVelocities;

  const maxJointAccelerations = ABSOLUTE_MAX_JOINT_ACCELERATIONS.map(
    (absoluteMax, index) => {
      return Math.min(
        ...speeds.map((s) => Math.abs(s.maxJointAccelerations[index])),
        absoluteMax,
      );
    },
  ) as ArmJointAccelerations;

  const maxTooltipSpeed = Math.min(
    ABSOLUTE_MAX_TOOLTIP_SPEED,
    ...speeds.map((s) => Math.abs(s.maxTooltipSpeed)),
  );

  return {
    maxJointSpeeds,
    maxTooltipSpeed,
    maxJointAccelerations,
  };
};
