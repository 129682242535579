import type { OnRobot3FG15Command } from '../Command';
import type { OnRobot3FG15Configuration } from '../constants';

/**
 * Calculate the raw grip diameter according to a gripper offset diameter command.
 * The target is in offset diameters, to calculate raw diameter, we remove the fingertip offset.
 */
export function calculateTargetGripDiameterRawMeters(
  gripperCommand: OnRobot3FG15Command,
  fingerConfiguration: OnRobot3FG15Configuration,
): number {
  const withOffset = gripperCommand.targetDiameter;

  if (gripperCommand.gripKind === 'inward') {
    return withOffset + fingerConfiguration.fingertipDiameter;
  }

  return withOffset - fingerConfiguration.fingertipDiameter;
}
