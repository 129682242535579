import cx from 'classnames';
import { useState } from 'react';

import { AddIcon, MinusIcon } from '@sb/ui/icons';

import Button from '../Button';

import { calculatePercent } from './calculatePercent';
import { MeterLabelIndicator } from './MeterLabelIndicator';
import { SecondaryValueMeter } from './SecondaryValueMeter';
import { useSliderInteractions } from './useSliderInteractions';

import styles from './RangeSpinner.module.css';

export interface RangeSpinnerProps {
  areValuesEqual?: boolean;
  ariaLabel?: string;
  children?: React.ReactNode;
  childrenBefore?: React.ReactNode;
  className?: string;
  isDisabled?: boolean;
  isPulseDisabled?: boolean;
  isSpinMinusDisabled?: boolean;
  isSpinPlusDisabled?: boolean;
  max: number;
  min: number;
  onChange?: (value: number) => void;
  onSpinnerHold?: (isPositive: boolean) => void;
  onSpinnerRelease?: () => void;
  primaryValue: number;
  secondaryValue?: number;
  valueToString?: (value: number) => string;
}

export function RangeSpinner({
  areValuesEqual,
  ariaLabel,
  children,
  childrenBefore,
  className,
  isDisabled,
  isPulseDisabled,
  isSpinMinusDisabled,
  isSpinPlusDisabled,
  max,
  min,
  onChange,
  onSpinnerHold,
  onSpinnerRelease,
  primaryValue,
  secondaryValue = NaN,
  valueToString = String,
}: RangeSpinnerProps) {
  const [isInteracting, setIsInteracting] = useState<boolean>(false);

  const primaryPercent = calculatePercent(primaryValue, min, max);

  const hasSecondaryValue = !Number.isNaN(secondaryValue);

  const buttonVariant = hasSecondaryValue ? 'primary' : 'blackPrimary';

  const sliderValue = hasSecondaryValue ? secondaryValue : primaryValue;

  const { onMouseDown, onTouchStart } = useSliderInteractions({
    min,
    max,
    isDisabled,
    onChange,
    setIsInteracting,
  });

  return (
    <div
      className={cx(
        styles.rangeSpinner,
        hasSecondaryValue && styles.hasSecondaryValue,
        isDisabled && styles.disabled,
        className,
      )}
    >
      <div className={styles.counter}>
        {childrenBefore}

        <Button
          disabled={isDisabled || isSpinMinusDisabled}
          onHold={() => {
            onSpinnerHold?.(false);
            setIsInteracting(true);
          }}
          onRelease={() => {
            onSpinnerRelease?.();
            setIsInteracting(false);
          }}
          startIcon={<MinusIcon />}
          variant={buttonVariant}
          isPulseDisabled={isPulseDisabled}
          aria-label={`Decrement: ${ariaLabel ?? 'slider'}`}
        />

        <div
          className={cx(
            styles.meter,
            onMouseDown && styles.isSlider,
            isDisabled && styles.isDisabled,
          )}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          role="slider"
          aria-valuenow={sliderValue}
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuetext={valueToString(sliderValue)}
        >
          <div className={styles.pointerEventsNone}>
            {!hasSecondaryValue || (!isInteracting && areValuesEqual) ? (
              <>
                <MeterLabelIndicator
                  arrowAlign="top"
                  percent={primaryPercent}
                  value=""
                />

                <div className={styles.primaryFillClip}>
                  <div
                    style={{ width: `${primaryPercent}%` }}
                    className={styles.primaryFill}
                  />
                </div>

                <div className={styles.meterLabel}>
                  {valueToString(primaryValue)}
                </div>
              </>
            ) : (
              <>
                <MeterLabelIndicator
                  arrowAlign="top"
                  percent={primaryPercent}
                  value={valueToString(primaryValue)}
                />
                {hasSecondaryValue && (
                  <SecondaryValueMeter
                    primaryPercent={primaryPercent}
                    secondaryValue={secondaryValue}
                    min={min}
                    max={max}
                    valueToString={valueToString}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <Button
          disabled={isDisabled || isSpinPlusDisabled}
          onHold={() => {
            onSpinnerHold?.(true);
            setIsInteracting(true);
          }}
          onRelease={() => {
            onSpinnerRelease?.();
            setIsInteracting(false);
          }}
          startIcon={<AddIcon />}
          variant={buttonVariant}
          isPulseDisabled={isPulseDisabled}
          aria-label={`Increment: ${ariaLabel ?? 'slider'}`}
        />

        {children}
      </div>
    </div>
  );
}
