import * as zod from 'zod';

import type { RGBAImage } from './InferenceEngineInterface';

export type PointCloudResponse = {
  rgb: Buffer;
  xyz: Buffer;
  width: number;
  height: number;
};

export const CAMERA_OPTIONS = [
  'depthExposure',
  'depthGain',
  'laserPower',
  'maximumDistance',
] as const;

export const CameraOption = zod.enum(CAMERA_OPTIONS);

export type CameraOption = zod.infer<typeof CameraOption>;

export const CameraOptionState = zod.object({
  min: zod.number(),
  max: zod.number(),
  current: zod.number(),
});

export type CameraOptionState = zod.infer<typeof CameraOptionState>;

/**
 * The current state of the camera
 */
export const CameraState = zod.object({
  fx: zod.number(),
  fy: zod.number(),
  cx: zod.number(),
  cy: zod.number(),
  width: zod.number().positive(),
  height: zod.number().positive(),
  // observed frames per second
  fps: zod.number(),
  maximumDistance: CameraOptionState,

  depth: zod.object({
    isAutoExposure: zod.boolean(),
    exposure: CameraOptionState,
    gain: CameraOptionState,
    laserPower: CameraOptionState,
    isAutoWhiteBalance: zod.boolean(),
  }),

  color: zod.object({
    isAutoExposure: zod.boolean(),
    exposure: CameraOptionState,
    brightness: CameraOptionState,
    gain: CameraOptionState,
    gamma: CameraOptionState,
    hue: CameraOptionState,
    sharpness: CameraOptionState,
    contrast: CameraOptionState,
    saturation: CameraOptionState,
    isAutoWhiteBalance: zod.boolean(),
    whiteBalance: CameraOptionState,
    isBacklightCompensation: zod.boolean(),
  }),
});

export type CameraState = zod.infer<typeof CameraState>;

/**
 * Input for changing the camera options
 */
export const CameraOptions = zod.object({
  depth: zod
    .object({
      isAutoExposure: zod.boolean().optional(),
      exposure: zod.number().optional(),
      gain: zod.number().optional(),
      laserPower: zod.number().optional(),
      isAutoWhiteBalance: zod.boolean().optional(),
    })
    .optional(),

  color: zod
    .object({
      isAutoExposure: zod.boolean().optional(),
      exposure: zod.number().optional(),
      brightness: zod.number().optional(),
      gain: zod.number().optional(),
      gamma: zod.number().optional(),
      hue: zod.number().optional(),
      sharpness: zod.number().optional(),
      contrast: zod.number().optional(),
      saturation: zod.number().optional(),
      isAutoWhiteBalance: zod.boolean().optional(),
      whiteBalance: zod.number().optional(),
      isBacklightCompensation: zod.boolean().optional(),
    })
    .optional(),

  maximumDistance: zod.number().optional(),
});

export type CameraOptions = zod.infer<typeof CameraOptions>;

export default interface CameraInterface {
  getState(): CameraState;

  setOptions(options: CameraOptions): Promise<void>;

  getPointCloud(): Promise<PointCloudResponse> | PointCloudResponse;
  getColorFrame(): Promise<RGBAImage> | RGBAImage;
  getDepthFrame(): Promise<RGBAImage> | RGBAImage;

  /**
   * Attach a listener for color frames that gets called whenever a new frame is
   * polled and copied.
   *
   * @returns Cancel function
   */
  onColorFrame(cb: (colorFrame: RGBAImage) => void): () => void;

  /**
   * Attach a listener for depth frames that gets called whenever a new frame is
   * polled and copied.
   *
   * @returns Cancel function
   */
  onDepthFrame(cb: (depthFrame: RGBAImage) => void): () => void;

  /**
   * Attach a listener for point clouds that gets called whenever a new cloud is
   * polled and copied.
   *
   * @returns Cancel function
   */
  onPointCloud(cb: (pointCloud: PointCloudResponse) => void): () => void;
}
