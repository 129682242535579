import cx from 'classnames';

import styles from './BackgroundCover.module.css';

interface BackgroundCoverProps {
  children: React.ReactNode;
  className?: string;
  src?: string;
}

/**
 * Component for displaying a full-page background image.
 */
const BackgroundCover = ({
  children,
  className,
  src,
}: BackgroundCoverProps) => {
  return (
    <main
      className={cx(styles.backgroundCover, className)}
      style={{ backgroundImage: `url(${src})` }}
    >
      {children}
    </main>
  );
};

export default BackgroundCover;
