import type { LoopControlStepArguments } from '@sb/routine-runner';
import type { LoopControlStep } from '@sb/types';

export const convertLoopControlConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: LoopControlStep.Arguments,
): LoopControlStepArguments => {
  if (!configuration) {
    throw new Error(`LoopControl step is not configured. stepID: ${stepID}`);
  }

  return { kind: configuration.kind };
};
