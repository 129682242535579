import { NotAuthenticated } from '@feathersjs/errors';

import { getAuthenticationService } from './utils';

export async function validatePIN(pin: string): Promise<boolean> {
  try {
    await getAuthenticationService().create(
      {
        strategy: 'pin',
        username: 'default',
        pin,
      },
      {},
    );

    return true;
  } catch (e) {
    if (e instanceof NotAuthenticated) {
      return false;
    }

    throw e;
  }
}
