import cx from 'classnames';

import { InfoIcon } from '@sb/ui/icons';

import Loader from './Loader';
import Typography from './Typography';

import styles from './NoItems.module.css';

interface NoItemsProps {
  children: React.ReactNode;
  className?: string;
  isLoading?: boolean;
  labelClassName?: string;
}

const NoItems = ({
  children,
  className,
  isLoading,
  labelClassName,
}: NoItemsProps) => {
  return (
    <div className={cx(styles.noItems, className)}>
      {isLoading ? (
        <Loader size="small" />
      ) : (
        <>
          <InfoIcon className={styles.infoIcon} />
          <Typography
            className={cx(styles.noItemsLabel, labelClassName)}
            role="heading"
          >
            {children}
          </Typography>
        </>
      )}
    </div>
  );
};

export default NoItems;
