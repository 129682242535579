import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getCurrentStepID(state: RoutineRunnerState | null): string | null {
  return state?.kind === 'RoutineRunning' ? state.currentStepID : null;
}

export default function useRobotCurrentStepID(
  args: UseRoutineRunnerHandleArguments,
): string | null {
  return useRobotState(args, getCurrentStepID, Object.is);
}
