import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function VisualizerIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M5.5 9a.5.5 0 0 1 .09.992L5.5 10H3.301l-1.574 3h12.548l-1.577-3H10.5a.5.5 0 0 1-.492-.41L10 9.5a.5.5 0 0 1 .41-.492L10.5 9H13a.5.5 0 0 1 .393.19l.05.077 2.365 4.5.031.055c.103.203.161.434.161.677a1.5 1.5 0 0 1-1.5 1.5h0-13A1.5 1.5 0 0 1 0 14.5c0-.295.085-.57.232-.802l2.325-4.43a.5.5 0 0 1 .351-.259L3 9h2.5zm9 5h-13a.5.5 0 0 0-.413.218l-.006.015-.024.035A.5.5 0 0 0 1 14.5a.5.5 0 0 0 .5.5h0 13a.5.5 0 1 0 0-1h0zM7.803.04a.5.5 0 0 1 .394 0h0l3.5 1.5.059.03.025.016c.014.01.029.02.042.032l.024.022.031.033.027.035.022.033.025.047.012.027a.5.5 0 0 1 .018.055l-.023-.065a.5.5 0 0 1 .031.099c.006.032.009.064.009.097l-.003-.053.003.039V2v4.5a.5.5 0 0 1-.303.46h0l-3.5 1.5-.045.017-.052.014-.027.005-.053.005H8h-.019a.5.5 0 0 1-.054-.005L8 8.5a.5.5 0 0 1-.1-.01c-.033-.007-.065-.017-.097-.03l.045.017-.034-.012-.011-.005-3.5-1.5A.5.5 0 0 1 4 6.5h0V2c0-.024.001-.042.003-.059l.006-.038.008-.033a.51.51 0 0 1 .019-.056l.012-.027a.5.5 0 0 1 .025-.046l-.032.063a.5.5 0 0 1 .054-.096l.027-.034a.45.45 0 0 1 .03-.032l.025-.023c.013-.012.028-.022.042-.032.026-.018.054-.033.084-.046l-.059.03a.5.5 0 0 1 .049-.026l.01-.004zM5 2.756V6.17l2.5 1.072v-3.42L5 2.756zm6 0L8.5 3.823v3.419L11 6.17V2.756zM8 1.043l-2.229.955 2.23.951 2.228-.951L8 1.043z" />
    </Icon>
  );
}
