import type * as zod from 'zod';

import { EventEmitter, wait } from '@sb/utilities';

import { FailureKind } from '../../FailureKind';
import type { Conditional, StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments =
  | {
      milliseconds: number;
    }
  | {
      condition: Conditional;
    };

type Variables = zod.infer<typeof Variables>;

const TIMEOUT_ERROR_RATIO: number = 1.05;
const TIMEOUT_WARNING_RATIO: number = 1.025;

export default class WaitStep extends Step<Arguments, Variables> {
  private events = new EventEmitter<{
    stop: void;
    complete: void;
    failure: StepFailure;
  }>();

  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  protected initializeVariableState(): void {
    this.variables = {};
  }

  /**
   * @internal
   */
  private timeout: ReturnType<typeof setTimeout> | null = null;

  /**
   * Used for pause/resume handling
   * @internal
   */
  private lastStartTime = new Date();

  /**
   * Used for pause/resume handling
   * @internal
   */
  private elapsedMilliseconds = 0;

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const offFailure = this.events.on('failure', (failure) => fail(failure));

    const promise = this.events.race('failure', 'complete');

    this._resume();

    await promise;

    offFailure();
  }

  public _pause(): void {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);

      const elapsedSinceStart =
        new Date().getTime() - this.lastStartTime.getTime();

      this.elapsedMilliseconds += elapsedSinceStart;
    }
  }

  public _resume(): void {
    this.lastStartTime = new Date();
    let stopped = false;

    this.events.once('stop', () => {
      stopped = true;
    });

    if ('milliseconds' in this.args) {
      this.timeout = setTimeout(() => {
        if (stopped) {
          return;
        }

        this.events.emit('complete');
      }, this.args.milliseconds - this.elapsedMilliseconds);
    }

    if (!('condition' in this.args)) {
      return;
    }

    const { condition } = this.args;

    (async () => {
      while (!stopped) {
        try {
          if (await this.routineContext.evaluateConditional(condition)) {
            this.events.emit('complete');

            return;
          }

          await wait(100);
        } catch (error) {
          this.events.emit('failure', {
            failure: {
              kind: FailureKind.InvalidRoutineLoadedFailure,
            },
            failureReason: error.message,
            error,
          });

          break;
        }
      }
    })();
  }

  public _stop() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.events.emit('stop');
  }

  public hasSelfTimeout(): boolean {
    if (this.getWaitTime()) {
      return true;
    }

    return false;
  }

  public getSelfTimeoutWarning(): number {
    const waitTime = this.getWaitTime();

    return waitTime ? waitTime * TIMEOUT_WARNING_RATIO : 0;
  }

  public getSelfTimeout(): number {
    const waitTime = this.getWaitTime();

    return waitTime ? waitTime * TIMEOUT_ERROR_RATIO : 0;
  }

  private getWaitTime(): number | undefined {
    if ('milliseconds' in this.args) {
      return this.args.milliseconds;
    }

    return undefined;
  }
}
