import type { Step } from '@sb/types';

/** Flat list containing all steps created for a routine. */
export const listAllSteps = (
  steps: Step.ConvertedSummary[],
): Step.ConvertedSummary[] => {
  const allSteps: Step.ConvertedSummary[] = [];

  const getNestedSteps = (step: Step.ConvertedSummary) => {
    allSteps.push(step);

    if (step.steps) {
      step.steps.forEach(getNestedSteps);
    }
  };

  steps.forEach(getNestedSteps);

  return allSteps.flat(Infinity);
};
