import { clamp } from 'lodash';
import { useEffect, useState } from 'react';

import { countDecimalPlaces, roundToDecimalPlaces } from '@sb/utilities';

import type { InputFieldProps } from './InputField';
import { InputField } from './InputField';

const VALID_NUMBER = /^-?[0-9]*\.?[0-9]*$/;
const VALID_INTEGER = /^-?[0-9]*$/;

export interface NumberInputProps
  extends Omit<InputFieldProps, 'value' | 'onChange' | 'onClear'> {
  /**
   * If `NaN` then the field will display empty
   */
  value: number;
  onChange: (value: number) => void;
  /**
   * By default, `onChange(0)` will be called when the field is cleared.
   * If you need to handle cleared input differently, supply a handler here.
   */
  onClear?: () => void;
  /**
   * Maximum decimal places to show.
   * This *doesn't* affect how many decimal digits the user can type while the field is focused.
   */
  decimalPlaces?: number;
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  /**
   * If supplied then this component will check that value is within the min-max range.
   */
  onValidationChange?: (isValid: boolean) => void;
  /**
   * If supplied then will show as placeholder when value is `NaN
   */
  placeholderValue?: number;
}

export function NumberInput({
  value,
  onChange,
  onClear,
  decimalPlaces,
  disabled,
  min,
  max,
  step,
  onValidationChange,
  hasError,
  helperText,
  onIncrement,
  onDecrement,
  placeholderValue,
  ...inputFieldProps
}: NumberInputProps) {
  const [localValue, setLocalValue] = useState<string | null>(null);

  const roundedValue =
    decimalPlaces !== undefined
      ? roundToDecimalPlaces(value, decimalPlaces)
      : value;

  const stringValue =
    localValue ?? (Number.isNaN(value) ? '' : String(roundedValue));

  const error = (() => {
    // don't do local error checking if a validation handler hasn't been supplied
    if (!onValidationChange) {
      return undefined;
    }

    if (min !== undefined && value < min) {
      return 'Value is below minimum.';
    }

    if (max !== undefined && value > max) {
      return 'Value is above maximum.';
    }

    return undefined;
  })();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;

    const regex = decimalPlaces === 0 ? VALID_INTEGER : VALID_NUMBER;

    // check user typed in a valid number or partial number (e.g. `-` or `.`)
    if (!regex.test(newValue)) {
      return;
    }

    setLocalValue(newValue);

    if (newValue === '') {
      if (onClear) {
        // if an onClear callback is supplied then call that
        onClear();
      } else {
        // otherwise fallback to default behaviour
        onChange(0);
      }
    } else {
      const newValueAsNumber = Number(newValue);

      if (!Number.isNaN(newValueAsNumber)) {
        // typed in a valid number, invoke onChange
        const newRoundedValue =
          decimalPlaces !== undefined
            ? roundToDecimalPlaces(newValueAsNumber, decimalPlaces)
            : newValueAsNumber;

        onChange(newRoundedValue);
      }
    }
  };

  useEffect(() => {
    onValidationChange?.(error === undefined);
  }, [onValidationChange, error]);

  const incrementDecrement = (direction: number) => {
    if (disabled) {
      return undefined;
    }

    if (!step) {
      return undefined;
    }

    return () => {
      const delta = direction * step;

      const baseValue =
        Number.isNaN(roundedValue) && placeholderValue !== undefined
          ? placeholderValue
          : roundedValue;

      const newValue = (Math.round(baseValue / delta) + 1) * delta;

      // number of decimal digits in step (e.g. 0.25 -> 2; 0.1 -> 1)
      const stepDecimalPlaces = countDecimalPlaces(step);

      const clampedNewValue = clamp(
        roundToDecimalPlaces(newValue, stepDecimalPlaces),
        min ?? -Infinity,
        max ?? Infinity,
      );

      if (!Number.isNaN(clampedNewValue)) {
        onChange(clampedNewValue);
      }
    };
  };

  const handleIncrement = onIncrement ?? incrementDecrement(1);
  const handleDecrement = onDecrement ?? incrementDecrement(-1);

  return (
    <InputField
      placeholder={placeholderValue?.toString()}
      {...inputFieldProps}
      value={stringValue}
      onChange={handleChange}
      onBlur={() => setLocalValue(null)}
      disabled={disabled}
      hasError={hasError || error !== undefined}
      helperText={helperText || error}
      onIncrement={handleIncrement}
      isIncrementDisabled={value >= (max ?? NaN)}
      onDecrement={handleDecrement}
      isDecrementDisabled={value <= (min ?? NaN)}
    />
  );
}
