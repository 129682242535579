import cx from 'classnames';

import Modal from './Modal/Modal';

import styles from './Alert.module.css';

interface AlertProps {
  children: React.ReactNode;
  className?: string;
  isOpen: boolean;
}

const Alert = ({ children, className, isOpen }: AlertProps) => {
  return (
    <Modal
      className={cx(styles.alert, className)}
      isOpen={isOpen}
      onClose={() => {}}
    >
      <div className={styles.alertContent} role="alert">
        {children}
      </div>
    </Modal>
  );
};

export default Alert;
