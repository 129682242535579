import cx from 'classnames';
import type { ComponentProps } from 'react';

import Typography from '../Typography';

import styles from './ToggleButtonGroup.module.css';

interface ToggleButtonGroupProps extends ComponentProps<'div'> {
  isHighlighted?: boolean;
  label?: string;
}

const ToggleButtonGroup = ({
  children,
  isHighlighted,
  label,
}: ToggleButtonGroupProps) => {
  return (
    <>
      {label && <Typography variant="small">{label}</Typography>}
      <div
        className={cx(styles.toggleButtonGroup, {
          [styles.highlighted]: isHighlighted,
        })}
      >
        {children}
      </div>
    </>
  );
};

export default ToggleButtonGroup;
