import { useCallback, useRef } from 'react';

interface UseRenderCountResponse {
  renderCount: number;
  rendersPerSecond: number;
  resetCount: () => void;
}

/**
 * Util for debugging renders in React components. You can check
 * how many times a component was rendered and reset the countdown
 * on specific actions to measure how many renders we're getting per
 * seconds (i.e. when using real-time actions like playing a routine).
 *
 * Usage:
 *
 * ```tsx
 * const { renderCount, rendersPerSecond, resetCount } = useRenderCount();
 *
 * const onPlay = () => {
 *   playSomeRealTimeAction();
 *   resetCount(); // Reset the counter to have a better measure per second.
 * };
 *
 * <div>
 *   <p>Render count: {renderCount}</p>
 *   <p>Render per seconds: {rendersPerSecond}</p>
 *
 *   <button onClick={onPlay}>Play</button>
 * </div>
 * ```
 */
const useRenderCount = (): UseRenderCountResponse => {
  const renderCount = useRef<number>(0);
  const startedAt = useRef<number>(new Date().getTime());

  renderCount.current += 1;
  const now = new Date().getTime();
  const timeDiffMs = now - startedAt.current;
  const timeDifInSeconds = timeDiffMs / 1000;

  const rendersPerSecond = renderCount.current / timeDifInSeconds;

  const resetCount = useCallback(() => {
    renderCount.current = 0;
  }, []);

  return { renderCount: renderCount.current, rendersPerSecond, resetCount };
};

export default useRenderCount;
