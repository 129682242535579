import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SwitchIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M15.854 3.146a.5.5 0 0 1 0 .707l-3 3a.5.5 0 1 1-.707-.707L14.793 3.5 12.146.854a.5.5 0 1 1 .707-.707l3 3z" />
      <path d="M16 3.5a.5.5 0 0 1-.5.5h-12a.5.5 0 1 1 0-1h12a.5.5 0 0 1 .5.5zM3.854 9.146a.5.5 0 0 1 0 .707L1.207 12.5l2.646 2.646a.5.5 0 1 1-.707.707l-3-3a.5.5 0 0 1 0-.707l3-3a.5.5 0 0 1 .707 0z" />
      <path d="M13 12.5a.5.5 0 0 1-.5.5H.5a.5.5 0 1 1 0-1h12a.5.5 0 0 1 .5.5z" />
    </Icon>
  );
}
