import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PositionListSingleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4.5C6.067 4.5 4.5 6.067 4.5 8C4.5 9.933 6.067 11.5 8 11.5C9.933 11.5 11.5 9.933 11.5 8C11.5 6.067 9.933 4.5 8 4.5ZM3.5 8C3.5 5.51472 5.51472 3.5 8 3.5C10.4853 3.5 12.5 5.51472 12.5 8C12.5 10.4853 10.4853 12.5 8 12.5C5.51472 12.5 3.5 10.4853 3.5 8Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C8.27614 1 8.5 1.22386 8.5 1.5V3.5C8.5 3.77614 8.27614 4 8 4C7.72386 4 7.5 3.77614 7.5 3.5V1.5C7.5 1.22386 7.72386 1 8 1Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8C1 7.72386 1.22386 7.5 1.5 7.5H3.5C3.77614 7.5 4 7.72386 4 8C4 8.27614 3.77614 8.5 3.5 8.5H1.5C1.22386 8.5 1 8.27614 1 8Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C12 7.72386 12.2239 7.5 12.5 7.5H14.5C14.7761 7.5 15 7.72386 15 8C15 8.27614 14.7761 8.5 14.5 8.5H12.5C12.2239 8.5 12 8.27614 12 8Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 12C8.27614 12 8.5 12.2239 8.5 12.5V14.5C8.5 14.7761 8.27614 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5V12.5C7.5 12.2239 7.72386 12 8 12Z"
        fill="#151516"
      />
    </Icon>
  );
}
