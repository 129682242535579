import cx from 'classnames';
import { useEffect, useState } from 'react';

import { useIsPortraitMode } from '@sb/ui/hooks';

import { WizardContext } from './shared';

import styles from './Wizard.module.css';

interface WizardProps {
  children: React.ReactNode;
}

const Wizard = ({ children }: WizardProps) => {
  const isPortraitMode = useIsPortraitMode();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isPortraitMode);

  // Collapse the wizard when entering portrait mode and
  // expand it when exiting portrait mode.
  useEffect(() => {
    setIsCollapsed(isPortraitMode);
  }, [isPortraitMode]);

  return (
    <WizardContext.Provider value={{ isCollapsed, setIsCollapsed }}>
      <main className={cx(styles.wizard, { [styles.collapsed]: isCollapsed })}>
        {children}
      </main>
    </WizardContext.Provider>
  );
};

export default Wizard;
