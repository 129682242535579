import * as zod from 'zod';

import { TestAutomationData } from '@sb/feathers-types';
import { GripperState } from '@sb/integrations/gripper-general';
import { ArmJointPositions, ArmJointVelocities } from '@sb/motion-planning';
import { three, two } from '@sb/utilities';

const CANSimulatorCommand = zod.object({
  kind: zod.literal('CANSimulatorCommand'),
  method: zod.string(),
  args: zod.array(zod.any()),
});

const EquipmentSimulatorCommand = zod.object({
  kind: zod.literal('EquipmentSimulatorCommand'),
  method: zod.string(),
  args: zod.array(zod.any()),
});

const ErrorMessage = zod.object({
  kind: zod.literal('Error'),
  message: zod.string(),
});

const FeathersTestAutomation = zod.object({
  kind: zod.literal('FeathersTestAutomation'),
  data: TestAutomationData,
});

const StateMessage = zod.object({
  kind: zod.literal('State'),
  encoderNotReady: zod.boolean(),
  eStops: zod.array(zod.boolean()).length(2),
  disturbances: zod.number().array(),
  brakedJoints: zod.boolean().array(),
  failedEncoders: zod.boolean().array(),
  ioState: zod.object({
    externalToRobot: zod.array(zod.boolean()).length(16),
    robotToExternal: zod.array(zod.boolean()).length(16),
    on: zod.boolean(),
  }),
  jointAngles: ArmJointPositions,
  jointVelocities: ArmJointVelocities,
  gripperState: GripperState,
  controlBoxButtons: zod.tuple(three(zod.boolean())),
  wristButtons: zod.tuple(two(zod.boolean())),
});

export const SimulatorAPIMessage = zod.union([
  CANSimulatorCommand,
  EquipmentSimulatorCommand,
  ErrorMessage,
  FeathersTestAutomation,
  StateMessage,
]);

export type SimulatorAPIMessage = zod.infer<typeof SimulatorAPIMessage>;

export type SimulatorAPIState = zod.infer<typeof StateMessage>;
