import cx from 'classnames';

import { margin } from '@sb/ui/styles';

import Typography from '../Typography';

interface WizardContentTitleProps {
  children: React.ReactNode;
  className?: string;
}

const WizardContentTitle = ({
  children,
  className,
}: WizardContentTitleProps) => {
  return (
    <Typography className={cx(className, margin.bottom.small)} component="h2">
      {children}
    </Typography>
  );
};

export default WizardContentTitle;
