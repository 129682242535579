import type { OnRobot3FG15Command } from '../OnRobot3FG15';

import type { OnRobotDualQuickChangerCommand } from '.';

export function calculateORDualChangerCommandFromOR3FG15Command(
  childCommand: OnRobot3FG15Command,
  active: 'primary' | 'secondary',
): OnRobotDualQuickChangerCommand {
  const command: OnRobotDualQuickChangerCommand = {
    kind: 'OnRobotDualQuickChangerCommand',
    active,
    command: childCommand,
  };

  return command;
}
