import { useEffect, useState } from 'react';

import type { SafetySettings } from '@sb/types';
import { getSafetySettings } from '@sbrc/services';

import useToast from './useToast';

export default function useSafetySettings(
  robotID: string | undefined,
): SafetySettings.ConvertedResponse | null {
  const { setToast } = useToast();

  const [safetySettings, setSafetySettings] =
    useState<SafetySettings.ConvertedResponse | null>(null);

  useEffect(() => {
    if (!robotID) {
      // no safety settings for Vizbot
      setSafetySettings(null);

      return undefined;
    }

    let cancelled = false;

    (async () => {
      try {
        const savedSettings = await getSafetySettings(robotID);

        if (!cancelled) {
          setSafetySettings(savedSettings);
        }
      } catch (error) {
        setToast({
          kind: 'error',
          message: error.message,
        });
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [robotID, setToast]);

  return safetySettings;
}
