import { debounce, memoize } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { Logger } from '@sb/logger';
import { wait } from '@sb/utilities';
import { API_ENDPOINT, globalCache } from '@sbrc/utils';

import type { ConnectionStatus } from './ConnectionStatus';
import { RoutineRunnerHandle } from './RoutineRunnerHandle';

export class WebSocketRoutineRunnerHandle extends RoutineRunnerHandle {
  private connectionStatus = new BehaviorSubject<ConnectionStatus>({
    kind: 'constructing',
  });

  public constructor(logger: Logger) {
    super(logger);

    this.connect();
  }

  private connect() {
    // don't do anything when running on server
    if (typeof window === 'undefined') {
      return;
    }

    if (this.isDestroyed) {
      return;
    }

    this.logger.info('Connecting...');
    this.connectionStatus.next({ kind: 'connecting' });

    const ws = new WebSocket(`${API_ENDPOINT}routine-runner`);

    const connectingTimeoutID = setTimeout(() => {
      if (ws.readyState === WebSocket.CONNECTING) {
        this.logger.info('Timed out while connecting');
        ws.close(4001, 'Connecting timeout');
      }
    }, 5_000);

    const destructors: Array<() => void> = [];

    // teardown is memoized so it is only called once
    const teardownAndReconnect = memoize(async () => {
      this.logger.info('Teardown', ws.readyState);

      if (ws.readyState !== ws.CLOSING && ws.readyState !== ws.CLOSED) {
        ws.close(4000, 'Teardown');
      }

      for (const destructor of destructors) {
        destructor();
      }

      if (this.getConnectionStatus().kind !== 'connecting') {
        this.connectionStatus.next({ kind: 'disconnected' });
      }

      await wait(1000);
      this.connect();
    });

    // Destroy the connection if nothing received for a while
    const scheduleAutoDisconnect = debounce(() => {
      this.logger.info('Auto disconnect (nothing received for 5s)');
      teardownAndReconnect();
    }, 5_000);

    ws.onopen = () => {
      this.logger.info('Connected');
      clearTimeout(connectingTimeoutID);
      this.connectionStatus.next({ kind: 'connected' });
    };

    ws.onmessage = (ev) => {
      scheduleAutoDisconnect();
      this.receivePacket(ev.data);
    };

    if (this.arePacketsHandled()) {
      this.logger.warn(
        'Multiple outgoing packet handlers being attached. Multiple peers may have been created, duplicating bandwidth',
      );
    }

    destructors.push(
      this.onPacket((packet) => {
        this.emitSBDevToolsEvent({
          kind: 'outgoingPacket',
          packet,
        });

        if (ws.readyState === WebSocket.OPEN) {
          ws.send(packet);
        }
      }),
    );

    destructors.push(
      this.onDestroy(() => {
        ws.close();
      }),
    );

    ws.onerror = () => {
      this.logger.warn('Connection failed');
    };

    ws.onclose = () => {
      teardownAndReconnect();
    };
  }

  public getName(): Promise<string> {
    return Promise.resolve('Live (Web Socket)');
  }

  public getConnectionStatus(): ConnectionStatus {
    return this.connectionStatus.value;
  }

  public onConnectionChange(
    cb: (connectionStatus: ConnectionStatus) => void,
  ): () => void {
    const subscription = this.connectionStatus.subscribe(cb);

    return () => subscription.unsubscribe();
  }
}

export function getWebSocketRoutineRunnerHandle(): WebSocketRoutineRunnerHandle {
  return globalCache('webSocketRoutineRunnerHandle', ({ reset }) => {
    const logger = new Logger();
    logger.enableConsole();
    logger.label = 'Web Socket Routine Runner Handle';

    const handle = new WebSocketRoutineRunnerHandle(logger);

    handle.onDestroy(() => reset());

    return handle;
  });
}
