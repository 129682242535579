import cx from 'classnames';
import type { ReactElement } from 'react';
import { cloneElement, useMemo } from 'react';

import styles from './Badge.module.css';

export type BadgeVariant =
  | 'black'
  | 'blue'
  | 'gray'
  | 'grayLight'
  | 'green'
  | 'greenLight'
  | 'red'
  | 'redLight'
  | 'white'
  | 'yellow'
  | 'yellowLight';

interface BadgeProps {
  children?: React.ReactNode;
  className?: string;
  startIcon?: ReactElement;
  variant?: BadgeVariant;
  'data-testid'?: string;
}

const Badge = ({
  children,
  className,
  startIcon,
  variant = 'white',
  'data-testid': testID,
}: BadgeProps) => {
  const StartIconComponent = useMemo(() => {
    return (
      startIcon && cloneElement(startIcon, { className: styles.startIcon })
    );
  }, [startIcon]);

  return (
    <div
      data-testid={testID}
      className={cx(styles.badge, styles[variant], className)}
    >
      {StartIconComponent}
      {children}
    </div>
  );
};

export default Badge;
