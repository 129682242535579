import type { Unsubscribe, User } from '@sb/types';

import { mapPaginated } from '../utils';

import { convertUserResponse, getUsersService } from './utils';

export function onGetControlPanelUsers(
  onSnapshot: (users: User.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getUsersService()
    .watch()
    .find({
      query: {
        hasControlPanelPermission: true,
      },
    })
    .subscribe((response) => {
      onSnapshot(mapPaginated(response, convertUserResponse));
    });

  return () => subscription.unsubscribe();
}
