import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function StepViewIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path d="M0.585786 1.08579C0.960859 0.710714 1.46957 0.5 2 0.5H3.5C3.77614 0.5 4 0.723858 4 1C4 1.27614 3.77614 1.5 3.5 1.5H2C1.73478 1.5 1.48043 1.60536 1.29289 1.79289C1.10536 1.98043 1 2.23478 1 2.5V4C1 4.27614 0.776142 4.5 0.5 4.5C0.223858 4.5 0 4.27614 0 4V2.5C0 1.96957 0.210714 1.46086 0.585786 1.08579ZM10 1C10 0.723858 10.2239 0.5 10.5 0.5H12C12.5304 0.5 13.0391 0.710714 13.4142 1.08579C13.7893 1.46086 14 1.96957 14 2.5V4C14 4.27614 13.7761 4.5 13.5 4.5C13.2239 4.5 13 4.27614 13 4V2.5C13 2.23478 12.8946 1.98043 12.7071 1.79289C12.5196 1.60536 12.2652 1.5 12 1.5H10.5C10.2239 1.5 10 1.27614 10 1ZM2 5.5C2 5.22386 2.22386 5 2.5 5H11.5C11.7761 5 12 5.22386 12 5.5V10.5C12 10.7761 11.7761 11 11.5 11H2.5C2.22386 11 2 10.7761 2 10.5V5.5ZM3 6V10H11V6H3ZM0.5 11.5C0.776142 11.5 1 11.7239 1 12V13.5C1 13.7652 1.10536 14.0196 1.29289 14.2071C1.48043 14.3946 1.73478 14.5 2 14.5H3.5C3.77614 14.5 4 14.7239 4 15C4 15.2761 3.77614 15.5 3.5 15.5H2C1.46957 15.5 0.960859 15.2893 0.585786 14.9142C0.210714 14.5391 0 14.0304 0 13.5V12C0 11.7239 0.223858 11.5 0.5 11.5ZM13.5 11.5C13.7761 11.5 14 11.7239 14 12V13.5C14 14.0304 13.7893 14.5391 13.4142 14.9142C13.0391 15.2893 12.5304 15.5 12 15.5H10.5C10.2239 15.5 10 15.2761 10 15C10 14.7239 10.2239 14.5 10.5 14.5H12C12.2652 14.5 12.5196 14.3946 12.7071 14.2071C12.8946 14.0196 13 13.7652 13 13.5V12C13 11.7239 13.2239 11.5 13.5 11.5Z" />
    </Icon>
  );
}
