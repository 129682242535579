import type { Step } from '@sb/types';

import type {
  OnRobotVGP20ChannelCommand,
  OnRobotVGP20Command,
} from '../../types';

import type { ActuateVacuumArguments } from './Arguments';

export namespace ActuateVacuumStepDatabase {
  export type ActuationCommandKind = 'RELEASE' | 'GRIP' | 'IDLE';
  export interface Arguments extends Step.CommonArguments {
    argumentKind: 'ActuateVacuum';
    oneCommandKind: ActuationCommandKind;
    twoCommandKind: ActuationCommandKind;
    threeCommandKind: ActuationCommandKind;
    fourCommandKind: ActuationCommandKind;
    overallSuctionPercentage: number;
  }

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): ActuateVacuumArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateVacuum step is not configured. stepID: ${stepID}'`,
      );
    }

    const getChannelCommand = (
      channel: keyof OnRobotVGP20Command['suctionActions'],
    ): OnRobotVGP20ChannelCommand => {
      const commandKind = configuration[`${channel}CommandKind`];

      return {
        commandKind,
        suctionPercentage:
          commandKind === 'GRIP' ? configuration.overallSuctionPercentage : 0,
      };
    };

    return {
      gripperCommand: {
        kind: 'OnRobotVGP20Command',
        suctionActions: {
          one: getChannelCommand('one'),
          two: getChannelCommand('two'),
          three: getChannelCommand('three'),
          four: getChannelCommand('four'),
        },
      },
    };
  };
}
