import useRobotStateKind from './useRobotStateKind';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export default function useIsRobotMoving(
  args: UseRoutineRunnerHandleArguments,
) {
  const robotStateKind = useRobotStateKind(args);

  const isRunningAdHocCommand =
    robotStateKind === 'RunningAdHocCommand' || robotStateKind === 'Recovering';

  return {
    isRobotMoving: isRunningAdHocCommand || robotStateKind === 'RoutineRunning',
    isRunningAdHocCommand,
  };
}
