import { clamp } from 'lodash';

import type { DeviceCommand, DeviceState } from '@sb/integrations/device';
import type { DeviceSimulator } from '@sb/integrations/simulator';
import { Logger } from '@sb/logger';
import { convertBytes, wait } from '@sb/utilities';

import {
  EwellixDataID,
  EwellixMotionDirection,
} from '../implementation/consts';
import { EwellixKinematics } from '../implementation/EwellixKinematics';
import type { EwellixLiftTLTState } from '../types';

import { EwellixSerialPortSimulatorDataHandler } from './EwellixSerialPortSimulatorDataHandler';

export class EwellixLiftTLTSimulator implements DeviceSimulator {
  public kind = 'EwellixLiftTLT' as const;

  public kinematics = new EwellixKinematics({
    getHeightMeters: () => this.liftHeight,
  });

  private logger = new Logger();

  private liftHeight: number = this.kinematics.minHeightMeters;

  private isMoving = false;

  public constructor() {
    this.logger.label = 'EwellixLiftTLTSimulator';
    this.logger.enableConsole();
  }

  public getState(): EwellixLiftTLTState {
    return {
      kind: 'EwellixLiftTLT',
      heightMeters: this.liftHeight,
      isMoving: this.isMoving,
      minHeightMeters: this.kinematics.minHeightMeters,
      maxHeightMeters: this.kinematics.maxHeightMeters,
    };
  }

  public setState(state: Partial<DeviceState>) {
    if (state.kind !== 'EwellixLiftTLT') {
      throw new Error(
        `Unexpected kind for updated vertical lift state kind. Got: ${state.kind}, expecting: "EwellixLiftTLT"`,
      );
    }

    if (state.heightMeters !== undefined) {
      this.liftHeight = state.heightMeters;
    }
  }

  public stop() {
    this.isMoving = false;

    return true;
  }

  private actuationCount = 0;

  public async actuate(command: DeviceCommand): Promise<void> {
    if (command?.kind !== 'EwellixLiftTLTCommand') {
      throw new Error(
        `Cannot actuate simulated seventh axis lift with this kind of command: ${JSON.stringify(
          command,
        )}`,
      );
    }

    const { heightMeters: targetHeight } = command;

    const liftVelocity =
      this.kinematics.maxVelocityMetersPerMS * command.speedPercentage;

    // interpolate and iterate through interpolation on an interval

    this.isMoving = true;

    const startingHeight = this.liftHeight;

    const startingTime = Date.now();

    this.actuationCount += 1;
    const currentActuation = this.actuationCount;

    while (this.isMoving && this.liftHeight !== targetHeight) {
      const deltaTime = Date.now() - startingTime;

      const incrementMeters =
        startingHeight > targetHeight
          ? -liftVelocity * deltaTime
          : liftVelocity * deltaTime;

      this.liftHeight = clamp(
        startingHeight + incrementMeters,
        Math.min(startingHeight, targetHeight),
        Math.max(startingHeight, targetHeight),
      );

      await wait(50);

      // a new actuation has been started so we can abandon this one
      if (currentActuation !== this.actuationCount) {
        return;
      }
    }

    this.isMoving = false;
  }

  public updateConfig() {}

  private targetPositions: [number, number] = [0, 0];

  private targetSpeed: [number, number] = [0, 0];

  public handleSerialPortData = EwellixSerialPortSimulatorDataHandler.handle({
    get: (address) => {
      let result: Uint8Array;

      if (address === EwellixDataID.ACTUAL_POSITION) {
        result = new Uint8Array(6 * 4);

        const positionPerActuator =
          this.kinematics.convertMetersToEncoderFlanks(this.liftHeight);

        result.set(convertBytes.fromUint32LE(positionPerActuator[0]), 0);
        result.set(convertBytes.fromUint32LE(positionPerActuator[1]), 4);
      } else if (address === EwellixDataID.SPEED) {
        result = new Uint8Array(6 * 2);
        const speed = this.isMoving ? 100 : 0;
        result.set(convertBytes.fromUint16LE(speed), 0);
        result.set(convertBytes.fromUint16LE(speed), 2);
      } else if (address === EwellixDataID.ACTUATOR_STATUS) {
        result = new Uint8Array(6);
        const status = this.isMoving ? 0x11 : 0x01;
        result[0] = status;
        result[1] = status;
      } else {
        const msg = `RG command unsupported dataID 0x${address.toString(16)}`;
        this.logger.error(msg);
        throw new Error(msg);
      }

      return result;
    },

    transfer: (address, payload) => {
      if (address === EwellixDataID.REMOTE_POSITION + 1) {
        this.targetPositions[0] = convertBytes.toUint32LE(payload, 0);
      } else if (address === EwellixDataID.REMOTE_POSITION + 2) {
        this.targetPositions[1] = convertBytes.toUint32LE(payload, 0);
      } else if (address === EwellixDataID.REMOTE_SPEED + 1) {
        this.targetSpeed[0] = convertBytes.toUint16LE(payload, 0);
      } else if (address === EwellixDataID.REMOTE_SPEED + 2) {
        this.targetSpeed[1] = convertBytes.toUint16LE(payload, 0);
      } else {
        const msg = `RT command unsupported dataID 0x${address.toString(16)}`;
        throw new Error(msg);
      }
    },

    execute: (_functionID, direction) => {
      if (direction === EwellixMotionDirection.MOVE_TO_REMOTE_POSITION) {
        const speedPercentage = clamp(
          (this.targetSpeed[0] + this.targetSpeed[1]) / 200,
          0,
          1,
        );

        const heightMeters = this.kinematics.convertEncoderFlanksToMeters(
          this.targetPositions,
        );

        this.actuate({
          kind: 'EwellixLiftTLTCommand',
          speedPercentage,
          heightMeters,
        }).catch((e) => {
          this.logger.error('RE command failed', e);
        });
      } else {
        const msg = `RE command unsupported direction ${direction}`;
        this.logger.error(msg);
        throw new Error(msg);
      }
    },

    stop: () => this.stop(),
    open: () => {},
    cyclic: () => {},
    abort: () => this.stop(),
  });
}
