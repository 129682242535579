export async function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const a = new FileReader();

    a.onload = (e) => {
      if (typeof e.target?.result === 'string') {
        resolve(e.target.result);
      } else {
        reject(new Error('Could not convert file for upload'));
      }
    };

    a.readAsDataURL(blob);
  });
}
