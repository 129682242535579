import { useEffect, useState } from 'react';

import type { ConnectionStatus } from '@sbrc/services';

import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';

export default function useRobotConnectionStatus(
  args: UseRoutineRunnerHandleArguments,
): ConnectionStatus {
  const routineRunnerHandle = useRoutineRunnerHandle(args);

  const [connectionStatus, setConnectionStatus] = useState(
    routineRunnerHandle.getConnectionStatus(),
  );

  useEffect(() => {
    const removeConnectionChangeHandler =
      routineRunnerHandle.onConnectionChange((newStatus) => {
        setConnectionStatus(newStatus);
      });

    return () => {
      removeConnectionChangeHandler();
    };
  }, [routineRunnerHandle]);

  return connectionStatus;
}
