import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PlayCircleIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M8 16.5a8 8 0 1 1 0-16 8 8 0 1 1 0 16zm0-1a7 7 0 1 0 0-14 7 7 0 1 0 0 14zM6 5.098L11.443 8.5 6 11.902V5.098zm1 5L9.557 8.5 7 6.902v3.196z" />
    </Icon>
  );
}
