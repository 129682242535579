/**
 * Cartesian pose:
 * Represents a position and orientation in 6-dimensional cartesian space (3 dimensions
 * for position, 3 dimensions for orientation).
 *
 * Rather than using 3 numbers (roll, pitch, yaw) for orientation, we use 4 numbers
 * (a quaternion) which has a number of computational benefits.
 *
 * This is a slightly unique packing format; squashes the position and orientation as a Vector3
 * and quaternion.
 *
 * It calls the Vector3's components <x y z>
 * and the quaternion's components <w i j k> (as opposed to <w x y z> as is more typical)
 */
import { Vector3, Quaternion, Matrix4 } from 'three';
import * as zod from 'zod';

import { CartesianPosition } from './CartesianPosition';
import { UnitQuaternion } from './Quaternion';

export const CartesianPose = zod.intersection(
  UnitQuaternion,
  CartesianPosition,
);

export type CartesianPose = zod.infer<typeof CartesianPose>;

const unit = new Vector3(1, 1, 1);

export const ZERO_ROTATION = UnitQuaternion.parse({
  i: 0,
  j: 0,
  k: 0,
  w: 1,
});

export const ZERO_OFFSET = {
  x: 0,
  y: 0,
  z: 0,
};

export const ZERO_POSE: CartesianPose = {
  ...ZERO_ROTATION,
  ...ZERO_OFFSET,
};

export function cartesianPoseToMatrix4(pose: CartesianPose): Matrix4 {
  const quaternion = new Quaternion(pose.i, pose.j, pose.k, pose.w);
  const position = new Vector3(pose.x, pose.y, pose.z);

  return new Matrix4().compose(position, quaternion, unit);
}

export function matrix4ToCartesianPose(matrix: Matrix4): CartesianPose {
  const translation = new Vector3();
  const rotation = new Quaternion();

  translation.setFromMatrixPosition(matrix);
  rotation.setFromRotationMatrix(matrix);

  return {
    i: rotation.x,
    j: rotation.y,
    k: rotation.z,
    w: rotation.w,
    ...translation,
  };
}

/**
 * applyCompoundPose
 * Apply cartesian transformation to cartesian pose.
 */
export function applyCompoundPose(
  pose: CartesianPose,
  transform: CartesianPose,
): CartesianPose {
  const poseMatrix = cartesianPoseToMatrix4(pose);
  const transformMatrix = cartesianPoseToMatrix4(transform);

  const appliedMatrix = transformMatrix.multiply(poseMatrix);

  const translation = new Vector3();
  const rotation = new Quaternion();

  translation.setFromMatrixPosition(appliedMatrix);
  rotation.setFromRotationMatrix(appliedMatrix);

  return {
    i: rotation.x,
    j: rotation.y,
    k: rotation.z,
    w: rotation.w,
    ...translation,
  };
}

const firstVec = new Vector3(0, 0, 0);
const secondVec = new Vector3(0, 0, 0);

export function distanceBetweenPoses(
  first: CartesianPose,
  second: CartesianPose,
): number {
  firstVec.x = first.x;
  firstVec.y = first.y;
  firstVec.z = first.z;

  secondVec.x = second.x;
  secondVec.y = second.y;
  secondVec.z = second.z;

  return firstVec.distanceTo(secondVec);
}

export function invertPose(pose: CartesianPose): CartesianPose {
  return matrix4ToCartesianPose(cartesianPoseToMatrix4(pose).invert());
}

export function tooltipCoordinatesToBaseCoordinates(
  pose: CartesianPose,
): CartesianPose {
  return {
    i: pose.k,
    j: pose.i,
    k: pose.j,
    w: pose.w,
    x: pose.z,
    y: pose.x,
    z: pose.y,
  };
}
