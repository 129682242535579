import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function CompletedIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 1 1 8 0zm3.642 4.851L6.6 10.021 4.358 7.723l-.716.698L6.6 11.455l5.758-5.906-.716-.698z" />
    </Icon>
  );
}
