import type { RoutineRunnerHandle } from '@sbrc/services';

import type { OnRobot3FG15Configuration } from '../constants';
import {
  OR_3FG15_CONFIGURATION_DEFAULT,
  OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  OR_3FG15_FINGER_POSITION_DEFAULT,
} from '../constants';
import type { OnRobot3FG15State } from '../State';

export function getOR3FG15ConfigSnapshot(
  routineRunnerHandle: RoutineRunnerHandle,
): OnRobot3FG15Configuration {
  const gripperState =
    routineRunnerHandle.getState()?.kinematicState.gripperState;

  if (gripperState?.kind === 'OnRobot3FG15') {
    return {
      fingerPosition: gripperState.fingerPosition,
      fingertipDiameter: gripperState.fingertipDiameter,
    };
  }

  return OR_3FG15_CONFIGURATION_DEFAULT;
}

export function getOR3FG15ConfigWithRoutineRunnerState(
  gripperState: OnRobot3FG15State | null,
): OnRobot3FG15Configuration {
  return {
    fingerPosition:
      gripperState?.fingerPosition ?? OR_3FG15_FINGER_POSITION_DEFAULT,
    fingertipDiameter:
      gripperState?.fingertipDiameter ?? OR_3FG15_FINGERTIP_DIAMETER_DEFAULT,
  };
}
