import type { EquipmentData } from '@sb/feathers-types';
import { ZERO_POSE, applyCompoundPose } from '@sb/geometry';
import type { CartesianPose } from '@sb/geometry';
import { DeviceConfiguration } from '@sb/integrations/device';
import { OR2FG7_DEFAULT_TOOLTIP_POSE } from '@sb/integrations/OnRobot2FG7';
import { OR3FG15_DEFAULT_TOOLTIP_POSE } from '@sb/integrations/OnRobot3FG15';
import {
  OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
  OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
} from '@sb/integrations/OnRobotDualQuickChanger/constants';
import { OR_SCREWDRIVER_DEFAULT_TOOLTIP_POSE } from '@sb/integrations/OnRobotScrewdriver';
import { ORVGP20_DEFAULT_TOOLTIP_POSE } from '@sb/integrations/OnRobotVGP20';
import type { Equipment } from '@sb/types';

function backfillDefaultTooltipConfig(
  config: any,
  basePose: CartesianPose = ZERO_POSE,
): any {
  // don't overwrite existing
  if ('tooltipConfig' in config) {
    return config;
  }

  switch (config.kind) {
    case 'OnRobot2FG7':
      return {
        ...config,
        tooltipConfig: {
          tooltipPose: applyCompoundPose(OR2FG7_DEFAULT_TOOLTIP_POSE, basePose),
        },
      };

    case 'OnRobot3FG15':
      return {
        ...config,
        tooltipConfig: {
          tooltipPose: applyCompoundPose(
            OR3FG15_DEFAULT_TOOLTIP_POSE,
            basePose,
          ),
        },
      };

    case 'OnRobotScrewdriver':
      return {
        ...config,
        tooltipConfig: {
          tooltipPose: applyCompoundPose(
            OR_SCREWDRIVER_DEFAULT_TOOLTIP_POSE,
            basePose,
          ),
        },
      };

    case 'OnRobotVGP20':
      return {
        ...config,
        tooltipConfig: {
          tooltipPose: applyCompoundPose(
            ORVGP20_DEFAULT_TOOLTIP_POSE,
            basePose,
          ),
        },
      };

    case 'OnRobotDualQuickChanger':
      return {
        ...config,
        grippers: {
          primary: backfillDefaultTooltipConfig(
            config.grippers.primary,
            OR_DUAL_QUICK_CHANGER_PRIMARY_POSE,
          ),
          secondary: backfillDefaultTooltipConfig(
            config.grippers.secondary,
            OR_DUAL_QUICK_CHANGER_SECONDARY_POSE,
          ),
        },
      };

    default:
      return config;
  }
}

export function convertEquipmentResponse(
  equipment: EquipmentData,
): Equipment.ConvertedResponse {
  const config = backfillDefaultTooltipConfig(equipment.config);
  const parsedConfig = DeviceConfiguration.safeParse(config);

  if (parsedConfig.success) {
    return {
      id: equipment.id,
      config: parsedConfig.data,
      isEnabled: equipment.isEnabled,
    };
  }

  return {
    id: equipment.id,
    config: {
      kind: 'InvalidConfiguration',
      name: config.name,
      invalidConfiguration: config,
    },
    isEnabled: false,
  };
}
