/* eslint-disable no-underscore-dangle */
import type { LoggingBackend } from './backends';
import type { ConsoleOptions } from './console';

export type LoggerContext = Map<string, string>;

export type LogKind = 'debug' | 'info' | 'warn' | 'error';

export abstract class AbstractLogger {
  protected hasInitialized = false;

  protected _label = '';

  public abstract addBackend(backend: LoggingBackend): void;

  public abstract addContext(key: string, value: string): void;

  public abstract enableConsole(opts?: ConsoleOptions): void;

  public get label(): string {
    return this._label;
  }

  public set label(label: string) {
    if (this.hasInitialized) {
      throw new Error(
        'Cannot add label to a logger that has already been initialized',
      );
    }

    this._label = label;
  }

  public abstract debug(...parts: Array<any>): void;

  public abstract info(...parts: Array<any>): void;

  public abstract warn(...parts: Array<any>): void;

  public abstract error(...parts: Array<any>): void;

  public abstract event({
    kind,
    parts,
  }: {
    kind: LogKind;
    parts: Array<any>;
  }): void;

  /**
   * Create a new logger with the same context
   */
  public abstract createChildLogger(label?: string): AbstractLogger;
}
