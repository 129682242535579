export interface TimeoutHandle {
  promise: Promise<never>;
  cancel: () => void;
  race<T>(...promises: Array<Promise<T>>): Promise<T>;
}

export function timeout(
  ms: number,
  message: string = `timeout of ${ms}ms reached`,
): TimeoutHandle {
  const error = new Error(message);
  let canceled = false;
  let timeoutHandle: any;

  const promise = new Promise<never>((_resolve, reject) => {
    // this happens synchronously, but some Promise implementations might
    // technically do this asynchronously. just being ridiculously cautious
    if (canceled) {
      return;
    }

    timeoutHandle = setTimeout(() => {
      reject(error);
    }, ms);
  });

  const cancel = () => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    } else {
      canceled = true;
    }
  };

  return {
    cancel,
    promise,
    race: async <T>(...promises: Array<Promise<T>>): Promise<T> => {
      const returnValue = await Promise.race([...promises, promise]);
      cancel();

      return returnValue;
    },
  };
}
