import { useEffect, useState } from 'react';
import type { StoryblokResult } from 'storyblok-js-client';
import StoryblokClient from 'storyblok-js-client';

type StoryblockRequestKind = 'single' | 'group';

const useStoryblokFetch = (
  toFetch: string,
  requestType: StoryblockRequestKind,
) => {
  const [articlesToReturn, setArticlesToReturn] =
    useState<StoryblokResult | null>(null);

  useEffect(() => {
    let isFetching: boolean = true;

    const BASE_URI =
      requestType === 'group'
        ? 'cdn/stories/'
        : `cdn/stories/help-articles/${toFetch}`;

    const OPTIONS = requestType === 'group' ? { starts_with: toFetch } : {};

    const fetchHelpArticles = async (): Promise<void> => {
      const client = new StoryblokClient({
        accessToken: process.env.NEXT_PUBLIC_STORYBLOK_API_KEY,
        cache: {
          clear: 'auto',
          type: 'memory',
        },
      });

      const fetchedArticles: StoryblokResult = await client.get(
        BASE_URI,
        OPTIONS,
      );

      if (isFetching) {
        setArticlesToReturn(fetchedArticles);
      }
    };

    fetchHelpArticles();

    return () => {
      isFetching = false;
    };
  }, [toFetch, requestType]);

  const dataToReturn =
    requestType === 'group'
      ? articlesToReturn?.data.stories
      : articlesToReturn?.data.story;

  return dataToReturn;
};

export default useStoryblokFetch;
