import type { TypographyProps } from '../Typography';
import Typography from '../Typography';

interface WizardContentTextProps extends TypographyProps {}

export default function WizardContentText({
  children,
  color = 'black',
  variant = 'medium',
  className,
  ...other
}: WizardContentTextProps) {
  return (
    <Typography
      color={color}
      variant={variant}
      className={className}
      {...other}
    >
      {children}
    </Typography>
  );
}
