import * as zod from 'zod';

export const SoftwareUpdateData = zod.object({
  activeBuildID: zod.string(),
  activeBuildName: zod.string(),
  targetBuildID: zod.string(),
  targetBuildName: zod.string(),
  status: zod.enum([
    'pending',
    'downloading',
    'downloaded',
    'installing',
    'installed',
  ]),
  statusAt: zod.string(),
  isInstallRequested: zod.boolean(),
  lastErrorAt: zod.string().optional(),
  lastErrorMessage: zod.string().optional(),
  strategy: zod.string(),
  updatedAt: zod.string(),
});

export type SoftwareUpdateData = zod.infer<typeof SoftwareUpdateData>;
