class Clock {
  private timestamp = Date.now();

  /**
   * Reset the clock to now
   */
  public reset(): void {
    this.timestamp = Date.now();
  }

  /**
   * Returns the number of milliseconds since the clock was created or reset,
   * and resets the clock
   */
  public tick(): number {
    const newTimestamp = Date.now();
    const delta = newTimestamp - this.timestamp;
    this.timestamp = newTimestamp;

    return delta;
  }

  /**
   * Returns the time the clock was created or reset
   */
  public lastTime(): number {
    return this.timestamp;
  }

  /**
   * Returns the number of milliseconds since the clock was created or reset
   */
  public timeSince(): number {
    return Date.now() - this.timestamp;
  }
}

export function createClock() {
  return new Clock();
}
