export enum EwellixCommand {
  /** Remote data get */
  REMOTE_DATA_GET = 'RG',
  /** Remote data transfer */
  REMOTE_DATA_TRANSFER = 'RT',
  /** Remote cyclic */
  REMOTE_CYCLIC = 'RC',
  /** Remote execute function */
  REMOTE_EXECUTE_FUNCTION = 'RE',
  /** Remote stop function */
  REMOTE_STOP_FUNCTION = 'RS',
  /** Remote mode open */
  REMOTE_MODE_OPEN = 'RO',
  /** Remote mode abort */
  REMOTE_MODE_ABORT = 'RA',
}

export enum EwellixSafetyLevel {
  /** If a communication timeout occurs, all movements are terminated, only the RC, RA and RO commands are available */
  FULL = 0,
  /** If a communication timeout occurs, all movements are terminated */
  ALL_MOVEMENT = 1,
  /** If a communication timeout occurs, only remote motion is terminated */
  REMOTE_MOVEMENT = 2,
}

export enum EwellixDataID {
  FIRMWARE_INFO = 0x0001,
  CONFIGURATION_INFO = 0x0002,
  ACTUAL_POSITION = 0x0010,
  LAST_ERRORS = 0x00d0,
  SPEED = 0x00f0,
  CONVERSION_FACTOR = 0x1010,
  ACTUATOR_STATUS = 0x0170,
  ACTUATOR_STATUS2 = 0x00e0,
  REMOTE_SPEED = 0x3010,
  REMOTE_POSITION = 0x3020,
}

export enum EwellixFunction {
  F1 = 1,
  F2 = 2,
  F3 = 3,
  F4 = 4,
  F5 = 5,
  F6 = 6,
  F7 = 7,
  F8 = 8,
  F9 = 9,
  F10 = 10,
}

export enum EwellixActuator {
  A1 = 1,
  A2 = 2,
  A3 = 3,
  A4 = 4,
  A5 = 5,
  A6 = 6,
}

export const EWELLIX_UNUSED_PARAM = 0xff;

export enum EwellixMotionDirection {
  MOVE_TO_POSITION_IN = 0x01,
  MOVE_TO_POSITION_OUT = 0x02,
  MOVE_TO_POSITION_MEM1 = 0x03,
  MOVE_TO_POSITION_MEM2 = 0x04,
  MOVE_TO_POSITION_MEM3 = 0x05,
  MOVE_TO_POSITION_MEM4 = 0x06,
  MOVE_TO_POSITION_INTERMEDIATE_IN = 0x07,
  MOVE_TO_POSITION_INTERMEDIATE_OUT = 0x08,
  MOVE_TO_REMOTE_POSITION = 0x09,
}

export enum EwellixResponseCode {
  /** Command acknowledged */
  ACK = 0x06,
  /** Checksum error */
  CSE = 0x80,
  /** Parameter data error */
  PDE = 0x81,
  /** Parameter count error */
  PCE = 0x82,
  /** Invalid command error */
  ICE = 0x83,
  /** Permission error */
  PE = 0x84,
}

export const SIMULATED_SERIAL_PORT_DEVICE_ID = 0x17;

function generateActuatorsConfiguration(
  stroke1: number,
  min1: number,
  max1: number,
  stroke2: number,
  min2: number,
  max2: number,
) {
  return [
    {
      index: 0,
      actuator: EwellixActuator.A1,
      function: EwellixFunction.F1,
      strokeMeters: stroke1,
      minEncoderFlanks: min1,
      maxEncoderFlanks: max1,
      encoderFlanksPerMeter: (max1 - min1) / stroke1,
    },
    {
      index: 1,
      actuator: EwellixActuator.A2,
      function: EwellixFunction.F2,
      strokeMeters: stroke2,
      minEncoderFlanks: min2,
      maxEncoderFlanks: max2,
      encoderFlanksPerMeter: (max2 - min2) / stroke2,
    },
  ];
}

/**
 * data from 3D drawings at https://www.ewellix.com/en/products/lifting-columns/tlt
 * TODO measure true `minEncoderFlanks`/`maxEncoderFlanks` for 300-600 configurations
 */
export const EWELLIX_CONFIGURATIONS = {
  300: {
    baseHeightMeters: 0.39,
    strokeMeters: 0.3,
    actuators: generateActuatorsConfiguration(0.15, 7, 1175, 0.15, 7, 1165),
  },
  400: {
    baseHeightMeters: 0.44,
    strokeMeters: 0.4,
    actuators: generateActuatorsConfiguration(0.2, 7, 1175, 0.2, 7, 1165),
  },
  500: {
    baseHeightMeters: 0.49,
    strokeMeters: 0.5,
    actuators: generateActuatorsConfiguration(0.25, 7, 1175, 0.25, 7, 1165),
  },
  600: {
    baseHeightMeters: 0.54,
    strokeMeters: 0.6,
    actuators: generateActuatorsConfiguration(0.3, 7, 1175, 0.3, 7, 1165),
  },
  700: {
    baseHeightMeters: 0.59,
    strokeMeters: 0.7,
    actuators: generateActuatorsConfiguration(0.35, 7, 1175, 0.35, 7, 1165),
  },
};
