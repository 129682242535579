import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function RobotIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M9.232 3.5L11.084.723a.5.5 0 0 1 .537-.208l4 1a.5.5 0 1 1-.243.97L11.72 1.57 10.1 4l1.62 2.43 3.658-.915a.5.5 0 1 1 .243.97l-4 1a.5.5 0 0 1-.537-.208L9.232 4.5H4.5a.5.5 0 1 1 0-1h4.732zm-1.295 9.26a.5.5 0 0 1-.875.484l-4.037-7.3a.5.5 0 1 1 .875-.484l4.037 7.3zm-5.45-6.287C1.113 6.472 0 5.36 0 3.986S1.113 1.5 2.486 1.5s2.486 1.113 2.486 2.486S3.86 6.472 2.486 6.472zm0-1c.82 0 1.486-.665 1.486-1.486S3.307 2.5 2.486 2.5 1 3.165 1 3.986s.665 1.486 1.486 1.486zM13.5 12.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-12A.5.5 0 0 1 1 16v-3a.5.5 0 0 1 .5-.5h12zm-.5 1H2v2h11v-2z" />
    </Icon>
  );
}
