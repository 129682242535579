import type { ArmJointPositions } from '@sb/motion-planning';

const RADIANS_TO_ONE_TENTH_DEGREE_FACTOR = 1800;

const ROBOT_JITTERING_BUFFER = 80;

/**
 * The live robot does not get to exact points provided by the motion planner.
 * Therefore, we use the end position tolerance defined in the `robotics` project of 0.1 degrees to
 * qualify:
 *
 * 1. the live robot and Vizbot positions as successfully synced
 * 2. that target positions have been as successfully reached
 *
 * Additionally, we add a small buffer to the epsilon to account for the robot's constant "jittering".
 *
 *  Note: The epsilon is in radians:
 * `Math.PI / 1800` radians is the equivalent of 0.100 degrees.
 * `Math.PI / 1720` radians is the equivalent of 0.105 degrees.
 */
export const JOINT_ROUNDING_EPSILON_RADIANS =
  Math.PI / (RADIANS_TO_ONE_TENTH_DEGREE_FACTOR - ROBOT_JITTERING_BUFFER);

/**
 * Compare two angles, or two joint angle tuples
 */
export function areJointAnglesEqual(
  position1: number | ArmJointPositions | null | undefined,
  position2: number | ArmJointPositions | null | undefined,
): boolean {
  // compare two (single) angles
  if (typeof position1 === 'number' && typeof position2 === 'number') {
    const difference = position2 - position1;

    return Math.abs(difference) < JOINT_ROUNDING_EPSILON_RADIANS;
  }

  // compare two joint angle tuples
  if (Array.isArray(position1) && Array.isArray(position2)) {
    return position1.every((jointAngle, index) =>
      areJointAnglesEqual(jointAngle, position2[index]),
    );
  }

  return false;
}
