import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function EmergencyStopIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1C4.13401 1 1 4.13401 1 8C1 9.75299 1.64437 11.3555 2.70917 12.5835L12.5835 2.70917C11.3555 1.64437 9.75299 1 8 1ZM13.2906 3.41626L3.41626 13.2906C4.64434 14.3556 6.24691 15 8 15C11.866 15 15 11.866 15 8C15 6.24691 14.3556 4.64434 13.2906 3.41626ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
      />
    </Icon>
  );
}
