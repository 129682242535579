import { isEqual } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

interface UseMultiselectResponse<T> {
  getSelectedIndex: (item: T) => number;
  isMultipleSelected: boolean;
  isSelected: (item: T) => boolean;
  removeItem: (index: number) => void;
  selectedItems: T[];
  selectItem: (item: T) => void;
  toggleItem: (item: T) => void;
}

/**
 * Reusable business logic for handling the selection of multiple items.
 */
const useMultiselect = <T>(): UseMultiselectResponse<T> => {
  const [selectedItems, setSelectedItems] = useState<T[]>([]);

  // Check if there are any selected items.
  const isMultipleSelected = useMemo(() => {
    return Boolean(selectedItems.length);
  }, [selectedItems]);

  /** Removes an item from the specified index and updates the list of selected items. */
  const removeItem = useCallback((index: number) => {
    setSelectedItems((previous) => {
      const newItems = [...previous];
      newItems.splice(index, 1);

      return newItems;
    });
  }, []);

  /**
   * Find if an item is selected and return its index from the selected items array.
   * This is useful when we need to remove an item from the selected list because
   * we need to know its specific index.
   */
  const getSelectedIndex = useCallback(
    (item: T): number => {
      return selectedItems.findIndex((selected) => isEqual(selected, item));
    },
    [selectedItems],
  );

  /* Find out if an item is already selected */
  const isSelected = useCallback(
    (item: T): boolean => {
      // If we can get its index (index > -1), then it's selected.
      return getSelectedIndex(item) > -1;
    },
    [getSelectedIndex],
  );

  /**
   * Add an item to the array of selected items.
   * If you'd like to unselect it in case it's already selected,
   * then use the `toggleItem()` method instead.
   */
  const selectItem = useCallback((item: T) => {
    setSelectedItems((previous) => [...previous, item]);
  }, []);

  /** Toggle an item (selects or remove it from the selected array) */
  const toggleItem = useCallback(
    (item: T): void => {
      return isSelected(item)
        ? removeItem(getSelectedIndex(item))
        : selectItem(item);
    },
    [getSelectedIndex, isSelected, removeItem, selectItem],
  );

  return {
    getSelectedIndex,
    isMultipleSelected,
    isSelected,
    removeItem,
    selectedItems,
    selectItem,
    toggleItem,
  };
};

export default useMultiselect;
