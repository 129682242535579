import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PlayIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 14 22">
      <path d="M2.045 3.59V18.41l9.2-7.41-9.2-7.41zm-1.5 17.956V.455L13.636 11 .545 21.546z" />
    </Icon>
  );
}
