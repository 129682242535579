import { useEffect } from 'react';

import { useDebouncedCallback } from './useDebouncedCallback';

/**
 * Runs a debounced fn when args change
 */
export function useDebouncedEffect<Args extends any[]>(
  fn: (...args: Args) => void,
  waitMS: number,
  argsArray: Args,
): void {
  const debouncedFn = useDebouncedCallback(fn, waitMS);

  useEffect(
    () => {
      debouncedFn(...argsArray);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedFn, ...argsArray],
  );
}
