import type { Routine, Step } from '@sb/types';

import { DECORATOR_STEP_LIST } from './constants';

/**
 * Copy the steps, up to and excluding the first step with action required
 */
export function truncateStepsWithActionRequired(
  steps: Step.ConvertedSummary[],
  actionRequiredByStepID:
    | Record<string, Routine.ActionRequiredStep>
    | undefined,
) {
  if (!actionRequiredByStepID) {
    return steps;
  }

  let reachedActionRequiredStep = false;

  const walkSteps = (
    stepsToWalk: Step.ConvertedSummary[],
  ): Step.ConvertedSummary[] => {
    const newSteps: Step.ConvertedSummary[] = [];

    for (const step of stepsToWalk) {
      // if this step has action required then stop here
      if (actionRequiredByStepID[step.id]) {
        reachedActionRequiredStep = true;
        break;
      }

      const subSteps = walkSteps(step.steps);

      // if this decorator step now has no subSteps then stop here
      if (
        subSteps.length === 0 &&
        DECORATOR_STEP_LIST.includes(step.stepKind)
      ) {
        reachedActionRequiredStep = true;
        break;
      }

      newSteps.push({ ...step, steps: subSteps });

      // if subSteps included actionRequired step then stop here
      if (reachedActionRequiredStep) {
        break;
      }
    }

    return newSteps;
  };

  return walkSteps(steps);
}
