import cx from 'classnames';

import styles from './Flex.module.css';

type Props = {
  direction: 'vertical' | 'horizontal';
  children: React.ReactNode;
  className?: string;
  center?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  component?: any;
};

export const Flex = (props: Props) => {
  const {
    direction,
    children,
    className,
    center,
    fullHeight,
    fullWidth,
    component,
  } = props;

  const Component = component ?? 'div';

  return (
    <Component
      className={cx({
        [styles.baseContainer]: true,
        [styles.fullHeight]: fullHeight,
        [styles.fullWidth]: fullWidth,
        [styles.baseContainerVertical]: direction === 'vertical',
        [styles.baseContainerHorizontal]: direction === 'horizontal',
        [className || '']: className !== undefined,
        [styles.horizontalCenter]: direction === 'horizontal' && center,
      })}
    >
      {children}
    </Component>
  );
};
