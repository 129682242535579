import cx from 'classnames';

import styles from './Flex.module.css';

type Props = {
  grow?: number;
  shrink?: number;
  children?: React.ReactNode;
  className?: string;
  component?: any;
  overflowHidden?: boolean;
  overflowScroll?: boolean;
};

export const FlexChild = (props: Props) => {
  const {
    children,
    className,
    grow,
    shrink,
    component,
    overflowHidden,
    overflowScroll,
  } = props;

  const Component = component ?? 'div';

  return (
    <Component
      className={cx({
        [className || '']: className !== undefined,
        [styles.overflowHidden]: overflowHidden,
        [styles.overflowScroll]: overflowScroll,
      })}
      style={{
        flexGrow: grow ?? 1,
        flexShrink: shrink ?? 1,
      }}
    >
      {children}
    </Component>
  );
};
