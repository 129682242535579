export enum Severity {
  /** Log error that is fatal to the operation, or the serivce/application. */
  Error = 'Error',
  /** Issue that can potentially cause application oddities, but for which I am automatically recovering. (Such as switching from a primary to backup server, retrying an operation, missing secondary data, etc.) */
  Warning = 'Warning',
  /** Log generally useful information to log (service start/stop, configuration assumptions, etc). Generally used to understand normal operations while diagnosing an issue, or gathering metrics. */
  Information = 'Info',
  /** Diagnostic data. */
  Debug = 'Debug',
}

const splunkSeverityLookup = {
  [Severity.Error]: 'ERROR',
  [Severity.Warning]: 'WARN',
  [Severity.Information]: 'INFO',
  [Severity.Debug]: 'DEBUG',
} as const;

/**
 * Create a namespaced key for logging
 * Usage:
 *  const ns = namespace('updaterbot.install');
 *  info(ns`daemon.down`, 'Stopping daemon', { services: [...] });
 *  ...
 *  info(ns`daemon.up`, 'Starting daemon', { services: [...] });
 */
export const namespace =
  (ns: string) =>
  (literals: string | readonly string[], ..._args: any[]) => {
    if (literals.length !== 1) {
      throw new Error(
        'namespace can only be used with a single string literal',
      );
    }

    if (typeof literals[0] !== 'string') {
      throw new Error(
        'namespace can only be used with a single string literal',
      );
    }

    return `${ns}.${literals[0]}`;
  };

export interface LogData {
  severity: Severity;
  key: string;
  message: string;
  context: { [key: string]: string };
}

/**
 * Augment + format logs for specific types during JSON serialization
 * @param value
 * @returns
 */
const jsonLogReplacer = (_key: string, value: any): any => {
  if (value instanceof Error) {
    return {
      ...value,
      stack: value.stack,
      message: value.message,
      name: value.name,
    };
  }

  return value;
};

export const log = (
  severity: Severity,
  key: string,
  message: string,
  context: any,
) => {
  // eslint-disable-next-line no-console
  console.log(
    JSON.stringify(
      {
        severity: splunkSeverityLookup[severity],
        _raw: message, // splunk "raw" field, this will be rendered in list view
        timestamp: new Date().toISOString(),
        key,
        context,
      },
      jsonLogReplacer,
    ),
  );
};

const makeSeverityLogger =
  (severity: Severity) => (key: string, message: string, context?: any) =>
    log(severity, key, message, context);

/** Log error that is fatal to the operation, or the serivce/application. */
export const error = makeSeverityLogger(Severity.Error);
/** Log issue that can potentially cause application issues, but for which we automatically recover. */
export const warn = makeSeverityLogger(Severity.Warning);
/** Log generally useful information to log (service start/stop, configuration assumptions, etc). Generally used to understand normal operations while diagnosing an issue, or gathering metrics. */
export const info = makeSeverityLogger(Severity.Information);
/** Log diagnostic data. */
export const debug = makeSeverityLogger(Severity.Debug);

export const makeAssertLogger =
  (severity: Severity) =>
  (condition: boolean, key: string, message: string, context: any) => {
    if (!condition) log(severity, key, message, context);
  };

/** Log error and throw error. */
export const panic = (
  condition: boolean,
  key: string,
  message: string,
  context?: any,
) => {
  if (condition) {
    error(key, message, context);
    throw new Error(key);
  }
};

/** Log error if condition is false. */
export const assertError = makeAssertLogger(Severity.Error);

/** Log warning if condition is false. */
export const assertWarn = makeAssertLogger(Severity.Warning);

/** Log info if condition is false. */
export const assertInfo = makeAssertLogger(Severity.Information);
