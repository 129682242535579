import { getRobotsService } from './utils';

export async function unloadRoutineFromRobot(robotID: string): Promise<void> {
  await getRobotsService().patch(
    null,
    {
      latestRoutineID: null,
      latestRoutineName: null,
    },
    { query: { robotID } },
  );
}
