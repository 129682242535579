import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function BulletsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M2 12a2 2 0 1 1 0 4 2 2 0 1 1 0-4zm0 1a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm13.5 1a.5.5 0 0 1 .09.992L15.5 15h-9a.5.5 0 0 1-.09-.992L6.5 14h9zM2 6a2 2 0 1 1 0 4 2 2 0 1 1 0-4zm0 1a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm13.5 1a.5.5 0 0 1 .09.992L15.5 9h-9a.5.5 0 0 1-.09-.992L6.5 8h9zM2 0a2 2 0 1 1 0 4 2 2 0 1 1 0-4zm0 1a1 1 0 1 0 0 2 1 1 0 1 0 0-2zm13.5 1a.5.5 0 0 1 .09.992L15.5 3h-9a.5.5 0 0 1-.09-.992L6.5 2h9z" />
    </Icon>
  );
}
