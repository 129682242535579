import { Euler, Quaternion } from 'three';

import type { CartesianPose } from '@sb/geometry';
import {
  convertDegreesToRadians,
  convertRadiansToDegrees,
} from '@sb/utilities';

export interface EulerPose {
  X: number;
  Y: number;
  Z: number;
  Rx: number;
  Ry: number;
  Rz: number;
}

/**
 * The routine-runner/visualizer uses a quaternion in the pose
 * while the frontend uses Euler angles in degrees.
 * We need to convert between the two representations
 */
export const convertEulerPose = {
  fromNullableCartesian(cartesian?: CartesianPose | null): EulerPose {
    if (!cartesian) {
      return {
        X: Number.NaN,
        Y: Number.NaN,
        Z: Number.NaN,
        Rx: Number.NaN,
        Ry: Number.NaN,
        Rz: Number.NaN,
      };
    }

    return convertEulerPose.fromCartesian(cartesian);
  },

  fromCartesian(cartesian: CartesianPose): EulerPose {
    const q = new Quaternion();
    q.set(cartesian.i, cartesian.j, cartesian.k, cartesian.w);
    const e = new Euler();
    e.setFromQuaternion(q);

    return {
      X: cartesian.x,
      Y: cartesian.y,
      Z: cartesian.z,
      Rx: convertRadiansToDegrees(e.x),
      Ry: convertRadiansToDegrees(e.y),
      Rz: convertRadiansToDegrees(e.z),
    };
  },

  toCartesian(euler: EulerPose): CartesianPose {
    const e = new Euler();

    e.set(
      convertDegreesToRadians(euler.Rx),
      convertDegreesToRadians(euler.Ry),
      convertDegreesToRadians(euler.Rz),
    );

    const q = new Quaternion();
    q.setFromEuler(e);

    return {
      x: euler.X,
      y: euler.Y,
      z: euler.Z,
      i: q.x,
      j: q.y,
      k: q.z,
      w: q.w,
    };
  },
};
