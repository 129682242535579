import type { GripperState } from './GripperState';

/**
 * The robot can't get to the exact position provided by the routine-runner because of rounding issues.
 * So, we are calculating the approximate variance using the `DIAMETER_TOLERANCE_METERS` value
 * we have in the `OnRobot3FG15.ts` implementation, which is `0.003`.
 */
const GRIPPER_DIAMETER_EPSILON = 0.003;

export function areGripperStatesEqual(
  state1?: GripperState,
  state2?: GripperState,
): boolean {
  if (!state1 && !state2) {
    return true;
  }

  if (!state1 || !state2) {
    return false;
  }

  if (state1.kind !== 'OnRobot3FG15' || state2.kind !== 'OnRobot3FG15') {
    return false;
  }

  if (state1.gripKind !== state2.gripKind) {
    return false;
  }

  if (
    Math.abs(state1.diameterMeters - state2.diameterMeters) >
    GRIPPER_DIAMETER_EPSILON
  ) {
    return false;
  }

  return true;
}
