import type { JSRunner } from '@sb/routine-runner';

import { getService } from '../utils';

export const getJSRunnerService = getService('jsRunner');

export const jsRunner: JSRunner = {
  evaluate: async (args) => {
    const { result } = await getJSRunnerService().create(args);

    return result;
  },
};
