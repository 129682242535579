import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function HomeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M15.417 6.306L8.001.373.584 6.306v9.777h6.167l-.001-4.667h2.5l.001 4.667h6.167V6.306zM8 2.293l5.917 4.733v7.557H10.75l.001-4.667h-5.5l-.001 4.667H2.084V7.025L8 2.293z" />
    </Icon>
  );
}
