import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PositionListPalletIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 1C1.22386 1 1 1.22386 1 1.5V5.5C1 5.77614 1.22386 6 1.5 6H5.5C5.77614 6 6 5.77614 6 5.5V1.5C6 1.22386 5.77614 1 5.5 1H1.5ZM0 1.5C0 0.671573 0.671573 0 1.5 0H5.5C6.32843 0 7 0.671573 7 1.5V5.5C7 6.32843 6.32843 7 5.5 7H1.5C0.671573 7 0 6.32843 0 5.5V1.5Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 1C10.2239 1 10 1.22386 10 1.5V5.5C10 5.77614 10.2239 6 10.5 6H14.5C14.7761 6 15 5.77614 15 5.5V1.5C15 1.22386 14.7761 1 14.5 1H10.5ZM9 1.5C9 0.671573 9.67157 0 10.5 0H14.5C15.3284 0 16 0.671573 16 1.5V5.5C16 6.32843 15.3284 7 14.5 7H10.5C9.67157 7 9 6.32843 9 5.5V1.5Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 10C1.22386 10 1 10.2239 1 10.5V14.5C1 14.7761 1.22386 15 1.5 15H5.5C5.77614 15 6 14.7761 6 14.5V10.5C6 10.2239 5.77614 10 5.5 10H1.5ZM0 10.5C0 9.67157 0.671573 9 1.5 9H5.5C6.32843 9 7 9.67157 7 10.5V14.5C7 15.3284 6.32843 16 5.5 16H1.5C0.671573 16 0 15.3284 0 14.5V10.5Z"
        fill="#151516"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 10C10.2239 10 10 10.2239 10 10.5V14.5C10 14.7761 10.2239 15 10.5 15H14.5C14.7761 15 15 14.7761 15 14.5V10.5C15 10.2239 14.7761 10 14.5 10H10.5ZM9 10.5C9 9.67157 9.67157 9 10.5 9H14.5C15.3284 9 16 9.67157 16 10.5V14.5C16 15.3284 15.3284 16 14.5 16H10.5C9.67157 16 9 15.3284 9 14.5V10.5Z"
        fill="#151516"
      />
    </Icon>
  );
}
