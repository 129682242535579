/* eslint-disable class-methods-use-this */
import type {
  DeviceConfiguration,
  DynamicBaseState,
} from '@sb/integrations/device';
import type { GripperState } from '@sb/integrations/gripper-general';
import { Logger } from '@sb/logger';
import type { ArmJointPositions } from '@sb/motion-planning';
import type {
  ExternalToRobotPort,
  RelayPort,
  IOLevel,
} from '@sb/routine-runner';

import type { ConnectionStatus } from './ConnectionStatus';
import { RoutineRunnerHandle } from './RoutineRunnerHandle';

/**
 * A handle for Vizbot. It has mostly the same methods as
 * RoutineRunner, but it translates all behavior to packets for communication
 */
export class VizbotRoutineRunnerHandle extends RoutineRunnerHandle {
  public constructor() {
    const logger = new Logger();
    logger.label = 'VizbotRoutineRunnerHandle';
    super(logger);
  }

  public getConnectionStatus(): ConnectionStatus {
    return { kind: 'connected' };
  }

  public onConnectionChange(
    cb: (connectionStatus: ConnectionStatus) => void,
  ): () => void {
    cb({ kind: 'connected' });

    return () => {};
  }

  // eslint-disable-next-line class-methods-use-this
  public getName(): Promise<string> {
    return Promise.resolve('Vizbot');
  }

  public setJointPositions(positions: ArmJointPositions): Promise<void> {
    return this.requestResponse({
      kind: 'setJointPositions',
      positions,
    });
  }

  public setDynamicBaseState(
    dynamicBaseState: DynamicBaseState,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'setDynamicBaseState',
      dynamicBaseState,
    });
  }

  public resetEquipmentList(
    deviceConfigs: DeviceConfiguration[],
  ): Promise<void> {
    return this.requestResponse({
      kind: 'resetEquipmentList',
      deviceConfigs,
    });
  }

  public setGripperState(gripperState: Partial<GripperState>): Promise<void> {
    return this.requestResponse({
      kind: 'setGripperState',
      gripperState,
    });
  }

  public stopArm(): Promise<void> {
    return this.requestResponse({
      kind: 'stopArm',
    });
  }

  public setInputIO(
    label: ExternalToRobotPort | RelayPort,
    level: IOLevel,
  ): Promise<void> {
    return this.requestResponse({
      kind: 'setInputIO',
      label,
      level,
    });
  }
}
