import { useState, useCallback, useEffect } from 'react';

const PORTRAIT_MODE_WIDTH = 768;

/**
 * Listen to media query changes and return a `boolean`
 * if the target is reached (`true`) or not (`false`).
 */
export function useMediaQuery(width: number): boolean {
  const [targetReached, setTargetReached] = useState<boolean>(false);

  const updateTarget = useCallback((event: MediaQueryListEvent) => {
    setTargetReached(event.matches);
  }, []);

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${width}px)`);
    media.addEventListener('change', updateTarget);

    // Check on mount (callback is not called until a change occurs)
    if (media.matches) {
      setTargetReached(true);
    }

    return () => media.removeEventListener('change', updateTarget);
  }, [updateTarget, width]);

  return targetReached;
}

export function useIsPortraitMode() {
  return useMediaQuery(PORTRAIT_MODE_WIDTH);
}
