import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ScrewdriverIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 8 15" {...props}>
      <path d="M2.49998 8.04783V9.08579L0.928985 10.6568L1.51359 14.1644C1.59395 14.6466 2.01114 15 2.49998 15H5.49998C5.98882 15 6.40601 14.6466 6.48638 14.1644L7.07098 10.6568L5.49998 9.08579V8.04782C5.18913 8.19549 4.85305 8.29869 4.49998 8.3492V9.5L5.99998 11L5.49998 14H2.49998L1.99998 11L3.49998 9.5V8.3492C3.14691 8.2987 2.81084 8.1955 2.49998 8.04783Z" />
      <path d="M6.75501 2.7257L7.65657 0.622069L6.73743 0.228149L5.97912 1.99753C6.27332 2.19958 6.53509 2.44544 6.75501 2.7257Z" />
      <path d="M1.24505 2.72566L0.343506 0.622068L1.26265 0.228149L2.02094 1.9975C1.72674 2.19956 1.46498 2.44541 1.24505 2.72566Z" />
      <path
        fillRule="evenodd"
        d="M5.50005 4.88464C5.50005 4.05622 4.82847 3.38464 4.00005 3.38464C3.17162 3.38464 2.50005 4.05622 2.50005 4.88464C2.50005 5.71307 3.17162 6.38464 4.00005 6.38464C4.82847 6.38464 5.50005 5.71307 5.50005 4.88464ZM4.00005 2.38464C5.38076 2.38464 6.50005 3.50393 6.50005 4.88464C6.50005 6.26536 5.38076 7.38464 4.00005 7.38464C2.61933 7.38464 1.50005 6.26536 1.50005 4.88464C1.50005 3.50393 2.61933 2.38464 4.00005 2.38464Z"
      />
    </Icon>
  );
}
