import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function EditIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 14 16">
      <path d="M.875 15.063a.5.5 0 1 1 0-1h12.25a.5.5 0 1 1 0 1H.875zM9.54 1.084l2.625 2.625a.5.5 0 0 1 0 .707L4.73 11.854a.5.5 0 0 1-.232.132l-3.5.875a.5.5 0 0 1-.606-.606l.875-3.5a.5.5 0 0 1 .132-.232l7.438-7.437a.5.5 0 0 1 .707 0zM2.2 9.13l-.64 2.557 2.557-.64 6.986-6.986-1.918-1.918L2.2 9.13z" />
    </Icon>
  );
}
