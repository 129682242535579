import type { RoutineRunnerState, SafeguardState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getSafeguardState(
  state: RoutineRunnerState | null,
): RoutineRunnerState['kinematicState']['safeguardState'] | null {
  return state ? state.kinematicState.safeguardState : null;
}

export function useRobotSafeguardState(
  args: UseRoutineRunnerHandleArguments,
): SafeguardState | null {
  return useRobotState(args, getSafeguardState, Object.is);
}
