import { getApp } from '../utils';

import { currentUserID } from './utils';

export async function loginWithPassword(
  email: string,
  password: string,
): Promise<void> {
  const result = await getApp().authenticate({
    strategy: 'local',
    email,
    password,
  });

  currentUserID().set(result.user.id);
}
