import type { Logger, LogKind } from './main';

/**
 * A LoggingBackend is a sink for logging data provided by applications.
 */
export abstract class LoggingBackend {
  /**
   * Set up the logger, registering any context ahead of time if necessary
   */
  public abstract initialize(logger: Logger): void;

  public abstract log(args: {
    kind: LogKind;
    parts: Array<any>;
    logger: Logger;
  }): void;

  public get acceptedLogKinds(): Set<LogKind> {
    return new Set(['debug', 'info', 'warn', 'error']);
  }
}
