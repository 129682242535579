import * as zod from 'zod';

export const UserData = zod.object({
  id: zod.string(),
  disabledBy: zod.string().nullable(),
  distanceUnit: zod.string().nullable(),
  email: zod.string().nullable(),
  hasAccountPermission: zod.boolean(),
  hasConnectToRobotPermission: zod.boolean(),
  hasControlPanelPermission: zod.boolean(),
  invitedBy: zod.string().nullable(),
  isProfileSetup: zod.boolean(),
  isUserVerified: zod.boolean(),
  name: zod.string().nullable(),
  password: zod.string().optional(), // omitted from feathers responses
  pin: zod.string().optional(), // omitted from feathers responses
  phone: zod.string().nullable(),
  photo: zod.string().nullable(),
  position: zod.string().nullable(),
  username: zod.string().nullable(),
});

export type UserData = zod.infer<typeof UserData>;
