/**
 * External to robot port: A label corresponding to an input port on the IO board
 */
import * as zod from 'zod';

export const EXTERNAL_TO_ROBOT_PORT_NAMES = [
  'Input 1',
  'Input 2',
  'Input 3',
  'Input 4',
  'Input 5',
  'Input 6',
  'Input 7',
  'Input 8',
  'Input 9',
  'Input 10',
  'Input 11',
  'Input 12',
  'Input 13',
  'Input 14',
  'Input 15',
  'Input 16',
] as const;

export const EXTERNAL_TO_ROBOT_PORT_COUNT = EXTERNAL_TO_ROBOT_PORT_NAMES.length;

export const ExternalToRobotPort = zod.enum(EXTERNAL_TO_ROBOT_PORT_NAMES);

export type ExternalToRobotPort = zod.infer<typeof ExternalToRobotPort>;
