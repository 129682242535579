import type {
  SerialPortSimulatorData,
  DeviceSimulator,
} from '@sb/integrations/simulator';
import { EventEmitter } from '@sb/utilities';

export class EquipmentSerialPortSimulator {
  public constructor(getDevices: () => Array<DeviceSimulator | null>) {
    this.getDevices = getDevices;
  }

  private getDevices: () => Array<DeviceSimulator | null>;

  private events = new EventEmitter<{
    dataOut: SerialPortSimulatorData;
  }>();

  private emitDataOut = (dataOut: SerialPortSimulatorData) => {
    this.events.emit('dataOut', dataOut);
  };

  public handleDataIn(dataIn: SerialPortSimulatorData) {
    for (const device of this.getDevices()) {
      device?.handleSerialPortData?.(dataIn, this.emitDataOut);
    }
  }

  public onDataOut(handleDataOut: (dataOut: SerialPortSimulatorData) => void) {
    return this.events.on('dataOut', handleDataOut);
  }
}
