import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function IOIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M11.5 0a.5.5 0 0 1 .492.41L12 .5V4h3.5a.5.5 0 0 1 .09.992L15.5 5H14v2a6 6 0 0 1-3.835 5.592l-.165.059V15.5a.5.5 0 0 1-.41.492L9.5 16h-3a.5.5 0 0 1-.492-.41L6 15.5v-2.849l-.165-.059a6 6 0 0 1-2.447-1.758l-.174-.219a6 6 0 0 1-1.208-3.337L2 7V5H.5a.5.5 0 0 1-.09-.992L.5 4H4V.5a.5.5 0 0 1 .992-.09L5 .5V4h6V.5a.5.5 0 0 1 .5-.5zM13 5H3v2a5 5 0 0 0 3.636 4.807.5.5 0 0 1 .357.399l.007.082V15h2v-2.712a.5.5 0 0 1 .286-.452l.077-.029a5 5 0 0 0 3.629-4.536L13 7V5z" />
    </Icon>
  );
}
