import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ArrowUpFilledIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 7" {...props}>
      <path d="M5 4.76837e-07L0.5 6.5L9.5 6.5L5 4.76837e-07Z" />
    </Icon>
  );
}
