import { useInterval } from '@sb/ui/hooks';
import { ResetIcon } from '@sb/ui/icons';

import Button from '../Button';

import { clampAndRoundValue } from './clampAndRoundValue';
import { INTERVAL_OPTIONS } from './consts';
import type { RangeSpinnerProps } from './RangeSpinner';

export interface UseDualValueRangeSpinnerProps {
  primaryValue: number;
  secondaryValue: number | undefined;
  min: number;
  max: number;
  step: number;
  isDisabled?: boolean;
  isEqual?: boolean;
  onChange: (state: number | ((previous: number) => number)) => void;
}

export function useDualValueRangeSpinner({
  primaryValue,
  secondaryValue = primaryValue,
  min,
  max,
  step,
  isDisabled,
  isEqual,
  onChange,
}: UseDualValueRangeSpinnerProps): RangeSpinnerProps {
  const { stopInterval, startInterval } = useInterval();

  const areValuesEqual =
    isEqual ?? Math.abs(primaryValue - secondaryValue) < step;

  return {
    primaryValue,
    secondaryValue,
    min,
    max,
    isDisabled,
    isSpinMinusDisabled: secondaryValue <= min,
    isSpinPlusDisabled: secondaryValue >= max,
    onChange: (newValue) => {
      onChange(clampAndRoundValue(newValue, min, max, step));
    },
    areValuesEqual,
    onSpinnerHold: (isPositive) => {
      startInterval(
        () =>
          onChange((prevValue) => {
            const newValue = prevValue + (isPositive ? step : -step);

            return clampAndRoundValue(newValue, min, max, step);
          }),
        INTERVAL_OPTIONS,
      );
    },
    onSpinnerRelease: stopInterval,
    isPulseDisabled: true,
    children: (
      <Button
        disabled={areValuesEqual || isDisabled}
        onClick={() => onChange(primaryValue)}
        variant="gray"
        startIcon={<ResetIcon />}
        aria-label="Reset"
      />
    ),
  };
}
