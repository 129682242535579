import { camelCase } from 'lodash';

import type { Routine, RoutineVariable } from '@sb/types';

export function getVariablesFromRoutineSpace(space: Routine.SpaceItem[]) {
  return space.map<RoutineVariable.SpaceVariableInformation>((spaceItem) => ({
    kind: 'space',
    displayName: spaceItem.name,
    spaceItem,
    variableKind: 'composite',
    variableName: `routine.positionLists['${spaceItem.id}']`,
    userIdentifier: `Space_${camelCase(spaceItem.name)}`,
  }));
}
