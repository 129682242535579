import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ForwardIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 8 12">
      <path d="M5.757 6L.294 10.966l.673.74L7.243 6 .966.294l-.673.74z" />
    </Icon>
  );
}
