import type { DeviceKind } from './device';

const END_EFFECTOR_KINDS: DeviceKind[] = [
  'OnRobot3FG15',
  'OnRobot2FG7',
  'NoGripper',
  'OnRobotDualQuickChanger',
  'OnRobotScrewdriver',
  'OnRobotVGP20',
];

export const isEndEffector = (kind: DeviceKind) => {
  return END_EFFECTOR_KINDS.includes(kind);
};

export const isDynamicBase = (kind: DeviceKind) => {
  return kind === 'EwellixLiftTLT';
};
