import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function UploadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M1.76707 9.58616L1.41352 9.93972L2.12063 10.6468L2.47418 10.2933L1.76707 9.58616ZM8.0603 4.00004L8.41385 3.64649L8.0603 3.29294L7.70675 3.64649L8.0603 4.00004ZM13.6464 10.2933L14 10.6468L14.7071 9.93972L14.3535 9.58616L13.6464 10.2933ZM2.47418 10.2933L8.41385 4.3536L7.70675 3.64649L1.76707 9.58616L2.47418 10.2933ZM7.70675 4.3536L13.6464 10.2933L14.3535 9.58616L8.41385 3.64649L7.70675 4.3536ZM7.5603 4.00006L7.56057 16L8.56057 15.9999L8.5603 4.00003L7.5603 4.00006ZM16 0L-2.18557e-08 6.99382e-07L2.18557e-08 1L16 1L16 0Z" />
    </Icon>
  );
}
