import type {
  ArmJointPositions,
  MotionPlannerInterface,
} from '@sb/motion-planning';
import type { Six } from '@sb/utilities';

import type { FailureKind } from './FailureKind';
import type { RecoveryType } from './RecoveryType';
import type {
  CollisionEvent,
  GeneralControlSystemEvent,
} from './types/ControlSystemEvent';
import type { MotionResult } from './types/MotionResult';

type PlanningFailure = {
  kind: FailureKind.PlanningFailure;
};

type ExecutionFailure = {
  kind: FailureKind.ExecutionFailure;
  motionResult?: MotionResult;
};

type ControlSystemFailure = {
  kind: FailureKind.ControlSystemFailure;
  event?: GeneralControlSystemEvent;
};

type GripperFailure = {
  kind: FailureKind.GripperFailure;
  message: string;
};

type OutOfJointLimitsFailure = {
  kind: FailureKind.OutOfJointLimitsFailure;
  jointAngles: ArmJointPositions;
  limitViolations: Six<number | null>;
  recoverable: boolean;
};

type CollisionFailure = {
  kind: FailureKind.CollisionFailure;
  event?: CollisionEvent;
  /**
   * Collisions which may give information but also may not.
   *
   * e.g. if specified as [{ elementNames: ['link_0', 'floor'] }] means link_0 and the floor
   * are colliding.
   *
   * However, if unspecified, it simply means we don't know what the collisions are but we
   * do still know it's colliding.
   */
  collisions?: Array<{
    elementNames: [string, string];
  }>;
  jointAngles: ArmJointPositions;
};

type MotionPlannerFailure<
  M extends keyof MotionPlannerInterface = keyof MotionPlannerInterface,
> = {
  kind: FailureKind.MotionPlannerFailure;
  request: {
    method: M;
    args: Parameters<MotionPlannerInterface[M]>;
  };
};

type InvalidRoutineLoadedFailure = {
  kind: FailureKind.InvalidRoutineLoadedFailure;
};

type IOFailure = {
  kind: FailureKind.IOFailure;
};

type InferenceFailure = {
  kind: FailureKind.InferenceFailure;
};

type CameraFailure = {
  kind: FailureKind.CameraFailure;
};

type HaasFailure = {
  kind: FailureKind.HaasFailure;
};

type InternalFailure = {
  kind: FailureKind.InternalFailure;
};

type EStopTriggered = {
  kind: FailureKind.EStopTriggered;
  source: string;
};

type BotmanHeartbeatLost = {
  kind: FailureKind.BotmanHeartbeatLost;
};

export type FailureDetails =
  | PlanningFailure
  | ExecutionFailure
  | ControlSystemFailure
  | GripperFailure
  | OutOfJointLimitsFailure
  | CollisionFailure
  | MotionPlannerFailure
  | CameraFailure
  | HaasFailure
  | EStopTriggered
  | BotmanHeartbeatLost
  | InvalidRoutineLoadedFailure
  | IOFailure
  | InferenceFailure
  | InternalFailure;

export function getRecoveryType(details: FailureDetails): RecoveryType {
  switch (details.kind) {
    case 'OutOfJointLimitsFailure':
      if (details.recoverable) {
        return 'GuidedMode';
      }

      return 'NotRecoverable';

    case 'CollisionFailure':
      return 'GuidedMode';
    case 'InternalFailure':
      return 'Restart';
    default:
      return 'Recoverable';
  }
}
