import * as zod from 'zod';

import { RobotToExternalPort, IOLevel } from '../../types';

export default zod.object({
  changes: zod.array(
    zod.object({
      label: RobotToExternalPort,
      level: IOLevel,
    }),
  ),
});
