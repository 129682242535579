import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function LinkIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path d="M12.703 9.612a1 1 0 0 1-1.406-1.422l1.984-1.962a2.29 2.29 0 0 0 0-3.335c-.98-.945-2.582-.945-3.57.01L7.685 4.807a1 1 0 0 1-1.37-1.457L8.33 1.454c1.754-1.692 4.584-1.692 6.338 0 1.775 1.712 1.775 4.502.01 6.205l-1.975 1.953zm-4.388 1.456a1 1 0 1 1 1.37 1.457L7.67 14.42c-1.754 1.692-4.584 1.692-6.338 0-1.775-1.712-1.775-4.502-.01-6.205l1.975-1.953a1 1 0 0 1 1.406 1.422L2.72 9.647a2.29 2.29 0 0 0 0 3.335c.98.945 2.582.945 3.57-.01l2.025-1.905zm-2.794.303a.75.75 0 0 1-1.041-1.08l6-5.787a.75.75 0 0 1 1.041 1.08l-6 5.787z" />
    </Icon>
  );
}
