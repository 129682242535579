import cx from 'classnames';
import { useEffect, useState } from 'react';

import Loader from '../Loader';
import Typography from '../Typography';

import styles from './WizardContent.module.css';

interface WizardContentStaticState {
  kind: 'idle' | 'loading' | 'ready';
  message?: string;
}

interface WizardContentErrorState {
  kind: 'error';
  message: string;
}

type WizardContentState = WizardContentStaticState | WizardContentErrorState;

interface WizardContentProps {
  children: React.ReactNode;
  className?: string;
  error?: string | null;
  isLoading?: boolean;
}

const WizardContent = ({
  children,
  className,
  error,
  isLoading,
}: WizardContentProps) => {
  const [state, setState] = useState<WizardContentState>({ kind: 'idle' });

  useEffect(() => {
    if (error) return setState({ kind: 'error', message: error });
    if (isLoading) return setState({ kind: 'loading' });

    return setState({ kind: 'ready' });
  }, [error, isLoading]);

  return (
    <section className={cx(styles.wizardContent, className)}>
      {state.kind === 'loading' && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}

      {state.kind === 'error' && (
        <Typography color="alertPrimary">{state.message}</Typography>
      )}

      {state.kind === 'ready' && children}
    </section>
  );
};

export default WizardContent;
