import type {
  DeviceCommand,
  DeviceConfiguration,
  DynamicBaseState,
} from '@sb/integrations/device';
import { getSimulatorByKind } from '@sb/integrations/getSimulatorByKind';
import type { GripperState } from '@sb/integrations/gripper-general';
import type {
  HaasAddress,
  HaasInterface,
} from '@sb/integrations/HaasMill/types';
import type {
  ModbusRegisterRequest,
  ModbusResponse,
} from '@sb/integrations/modbus/types';
import type { DeviceSimulator } from '@sb/integrations/simulator';
import { isDynamicBase, isEndEffector } from '@sb/integrations/util';
import type { DeviceKinematics } from '@sb/motion-planning';
import type { EquipmentInterface } from '@sb/routine-runner';
import { isNotUndefined } from '@sb/utilities';

import { EquipmentSerialPortSimulator } from './EquipmentSerialPortSimulator';
import { SimulatedHaas } from './Haas';

export class EquipmentSimulator implements EquipmentInterface {
  private gripper: DeviceSimulator | null = null;

  private dynamicBase: DeviceSimulator | null = null;

  private deviceConfigs: DeviceConfiguration[] = [];

  public resetEquipmentList(
    deviceConfigs: DeviceConfiguration[],
  ): Promise<void> {
    this.deviceConfigs = deviceConfigs;

    let gripper: DeviceSimulator | null = null;
    let dynamicBase: DeviceSimulator | null = null;

    for (const deviceConfig of deviceConfigs) {
      if (isEndEffector(deviceConfig.kind) && !gripper) {
        if (this.gripper?.kind === deviceConfig.kind) {
          gripper = this.gripper;
          gripper.updateConfig(deviceConfig);
        } else {
          // Create new instance if new type or gripper doesn't exist
          gripper = getSimulatorByKind(deviceConfig.kind).getSimulator(
            deviceConfig,
          );
        }
      }

      if (isDynamicBase(deviceConfig.kind) && !dynamicBase) {
        if (this.dynamicBase?.kind === deviceConfig.kind) {
          dynamicBase = this.dynamicBase;
          this.dynamicBase.updateConfig(deviceConfig);
        } else {
          // Create new instance if new type or dynamic base doesn't exist
          dynamicBase = getSimulatorByKind(deviceConfig.kind).getSimulator(
            deviceConfig,
          );
        }
      }
    }

    this.gripper = gripper;
    this.dynamicBase = dynamicBase;

    return Promise.resolve();
  }

  public destroy() {
    this.stop();
  }

  public setGripperState(state: Partial<GripperState>): void {
    if (state) {
      this.gripper?.setState(state);
    }
  }

  public getGripperState(): GripperState {
    return (this.gripper?.getState() as GripperState) ?? null;
  }

  public getEquipmentList() {
    return this.deviceConfigs;
  }

  public async stop(): Promise<void> {
    this.gripper?.stop();
    this.dynamicBase?.stop();

    return Promise.resolve();
  }

  public async actuate(command: DeviceCommand) {
    if (command.kind === `${this.gripper?.kind}Command`) {
      await this.gripper!.actuate(command);
    } else if (command.kind === `${this.dynamicBase?.kind}Command`) {
      await this.dynamicBase!.actuate(command);
    } else {
      throw new Error(`Device not initialized to handle ${command.kind}`);
    }
  }

  public handleModbusRegisterRequest(
    request: ModbusRegisterRequest,
  ): ModbusResponse | void {
    if (!this.gripper) {
      console.warn('No equipment initialized to handle modbus request.'); // eslint-disable-line no-console

      return undefined;
    }

    const addresses =
      this.gripper.getModbusAddressSubscriptions?.() || new Set();

    if (addresses.has(request.targetAddress)) {
      return this.gripper.handleModbusRegisterRequest?.(request);
    }

    return undefined;
  }

  public serialPort = new EquipmentSerialPortSimulator(() => [
    this.gripper,
    this.dynamicBase,
  ]);

  public setDynamicBaseState(state: DynamicBaseState) {
    this.dynamicBase?.setState(state);
  }

  public getDynamicBaseState(): DynamicBaseState | null {
    return (this.dynamicBase?.getState() as DynamicBaseState) ?? null;
  }

  public getDeviceKinematics(): DeviceKinematics<DeviceCommand>[] {
    return [this.dynamicBase?.kinematics, this.gripper?.kinematics].filter(
      isNotUndefined,
    );
  }

  private haasCache = new Map<string, Promise<HaasInterface>>();

  public getHaas(address: HaasAddress): Promise<HaasInterface> {
    const existing = this.haasCache.get(address.endpoint);

    if (existing) {
      return existing;
    }

    const promise = SimulatedHaas.construct();
    this.haasCache.set(address.endpoint, promise);

    return promise;
  }
}
