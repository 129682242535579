import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function RotateLeftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 16" {...props}>
      <path d="M4.66 11.12c.465 0 .82.153 1.066.46s.372.743.378 1.315v.785c0 .59-.122 1.041-.366 1.354s-.602.469-1.072.469c-.465 0-.82-.153-1.066-.46s-.372-.746-.378-1.318v-.785c0-.596.123-1.048.369-1.356s.604-.463 1.069-.463zm-3.228 0c.281 0 .531.07.75.211s.389.343.51.607.183.565.185.905v.313c0 .711-.177 1.27-.53 1.676s-.854.623-1.503.65l-.208.003v-.706l.188-.003c.736-.033 1.135-.379 1.195-1.037a1.07 1.07 0 0 1-.773.325c-.377 0-.679-.129-.905-.387S0 13.068 0 12.629c0-.279.061-.535.182-.768a1.34 1.34 0 0 1 .513-.545 1.41 1.41 0 0 1 .738-.196zm3.228.686c-.199 0-.347.077-.442.232s-.144.392-.149.718v1.037c0 .348.047.606.141.776s.248.252.457.252.356-.081.448-.243.14-.41.144-.744v-1.014c0-.354-.049-.61-.146-.771s-.246-.243-.451-.243zM1.5.5a.5.5 0 0 1 .492.41L2 1v3.275C3.369 2.539 5.329 1.5 7.5 1.5A7.5 7.5 0 0 1 15 9a7.47 7.47 0 0 1-1.825 4.903.5.5 0 0 1-.756-.654A6.47 6.47 0 0 0 14 9a6.5 6.5 0 0 0-6.5-6.5c-2.085 0-3.955 1.131-5.145 3.001L6.5 5.5a.5.5 0 0 1 .492.41L7 6a.5.5 0 0 1-.41.492L6.5 6.5h-5l-.071-.006.005.001.015.002h0l.007.001.044.002-.026-.001-.018-.001-.011-.001h0l-.011-.002-.01-.001h0 .005l-.037-.006h0a.49.49 0 0 1-.048-.013l-.045-.017-.021-.01-.014-.007-.022-.013h0l.036.019a.5.5 0 0 1-.088-.056l-.036-.032h0a.44.44 0 0 1-.027-.028L1.1 6.3h0c-.01-.013-.019-.026-.027-.039s-.011-.019-.016-.029l-.021-.046s-.01-.025-.014-.038-.009-.03-.012-.046l-.006-.038A.5.5 0 0 1 1 6.015V1a.5.5 0 0 1 .5-.5zm-.073 11.303c-.17 0-.31.078-.419.234s-.164.349-.164.583.053.424.158.574.258.223.451.223c.137 0 .254-.035.352-.105a.7.7 0 0 0 .226-.255v-.349c0-.287-.055-.51-.164-.668a.51.51 0 0 0-.439-.237zm5.878-.683c.213 0 .396.079.551.237s.234.347.234.571-.078.414-.234.568-.338.229-.551.229-.396-.076-.557-.229a.76.76 0 0 1-.24-.568.78.78 0 0 1 .237-.571c.158-.158.345-.237.56-.237zm0 .434c-.104 0-.19.037-.261.111a.37.37 0 0 0-.105.264.35.35 0 0 0 .105.258.36.36 0 0 0 .261.105.34.34 0 0 0 .255-.103.36.36 0 0 0 .103-.261c0-.102-.034-.189-.103-.264s-.151-.111-.255-.111z" />
    </Icon>
  );
}
