import type { ArmJointPositions } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';
import { areJointAnglesEqual } from '@sbrc/utils';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getJointAngles(
  state: RoutineRunnerState | null,
): ArmJointPositions | null {
  return state ? state.kinematicState.jointAngles : null;
}

/**
 * Get the joint angles from the live robot or Vizbot.
 *
 * **Warning**: this hook will cause your component to re-render multiple
 * times since joint angles are updated multiple times per second. Before
 * you're using this hook, make sure you really need real-time updates
 * for joint angles.
 */
export default function useRobotJointAngles(
  args: UseRoutineRunnerHandleArguments,
): ArmJointPositions | null {
  return useRobotState<ArmJointPositions | null>(
    args,
    getJointAngles,
    areJointAnglesEqual,
  );
}
