import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function WarningIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path d="M8 0h0a8 8 0 0 1 8 8h0A8 8 0 1 1 0 8h0a8 8 0 0 1 8-8h0zm0 13a1 1 0 1 0 0-2 1 1 0 1 0 0 2zm.5-4V3h-1v6h1z" />
    </Icon>
  );
}
