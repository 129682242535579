/**
 * Grid: A validated specification for a 2D grid in Cartesian space.
 *
 *     a
 *    ╱ ╲
 *   ╱╲ ╱╲
 *  b  ╳  d
 *   ╲╱ ╲╱
 *    ╲ ╱
 *     c
 *
 * The grid is a parallelogram in 3D space, specified by three corners `a`, `b` and `d`.
 * The fourth corner `c` is inferred from the others.
 * The grid "rows" run between corners `a` and `b`; the "columns" run between corners `a` and `d`.
 */
import { isUndefined } from 'lodash';
import * as Three from 'three';
import * as zod from 'zod';

import { CartesianPosition } from './CartesianPosition';

const GridCorners = zod.object({
  a: CartesianPosition.optional(),
  b: CartesianPosition.optional(),
  c: CartesianPosition.optional(),
  d: CartesianPosition.optional(),
});

type GridCorners = zod.infer<typeof GridCorners>;

type ThreeGrid = {
  a: Three.Vector3;
  b: Three.Vector3;
  c: Three.Vector3;
  d: Three.Vector3;
  rows: Three.Vector3;
  columns: Three.Vector3;
};

// returns a vector going from one to two
function between(one: Three.Vector3, two: Three.Vector3) {
  return new Three.Vector3().subVectors(two, one);
}

// converts raw corners to Three.Vector3s
function convertGridToThree(configuration: GridCorners): ThreeGrid | null {
  let a: Three.Vector3 | undefined;
  let b: Three.Vector3 | undefined;
  let c: Three.Vector3 | undefined;
  let d: Three.Vector3 | undefined;
  let rows: Three.Vector3 | undefined;
  let columns: Three.Vector3 | undefined;

  if (configuration.a) {
    a = new Three.Vector3(
      configuration.a.x,
      configuration.a.y,
      configuration.a.z,
    );
  }

  if (configuration.b) {
    b = new Three.Vector3(
      configuration.b.x,
      configuration.b.y,
      configuration.b.z,
    );
  }

  if (configuration.c) {
    c = new Three.Vector3(
      configuration.c.x,
      configuration.c.y,
      configuration.c.z,
    );
  }

  if (configuration.d) {
    d = new Three.Vector3(
      configuration.d.x,
      configuration.d.y,
      configuration.d.z,
    );
  }

  if (a && b && d) {
    rows = between(a, b);
    columns = between(a, d);
    c = a.clone().add(rows).add(columns);
  } else if (a && b && c) {
    rows = between(a, b);
    columns = between(b, c);
    d = a.clone().add(columns);
  } else if (a && c && d) {
    rows = between(d, c);
    columns = between(a, d);
    b = a.clone().add(rows);
  } else if (b && c && d) {
    rows = between(d, c);
    columns = between(b, c);
    a = b.clone().sub(rows);
  }

  if (a && b && c && d && rows && columns) {
    return { a, b, c, d, rows, columns };
  }

  return null;
}

// the corners of the grid from which we derive the grid dimensions
// and calculate the positions
export const Grid = zod.object({
  corners: GridCorners.refine((data) => {
    return [data.a, data.b, data.c, data.d].filter(isUndefined).length <= 1;
  }, 'Must specify at least three corners'),
  rows: zod.number().int().positive(),
  columns: zod.number().int().positive(),
});

export type Grid = zod.infer<typeof Grid>;

export type GridEntry = {
  row: number;
  column: number;
  position: Three.Vector3;
};

/**
 * Iterate over a grid definition, returning an entry for each point
 * on a vertex of the grid.
 */
export function* iterateGrid(grid: Grid): Generator<GridEntry> {
  const threeGrid = convertGridToThree(grid.corners);

  if (!threeGrid) {
    return;
  }

  // Unit vectors row-/column-wise.
  // i.e. the amount to move in each direction for each row/column.
  //
  // We subtract 1 from rows and from columns because otherwise the far corners
  // will never be reached, just one away.
  //
  // For example, if the grid is at <1, 1> with a row/column size of 2, using
  // 2 as the denominator here scales the vectors by 0.5, resulting in points like
  // this:
  //
  //     < 0, 0 >
  //     < 0, 0.5 >
  //     < 0.5, 0 >
  //     < 0.5, 0.5 >
  //
  // But what we really want is
  //
  //    < 0, 0 >
  //    < 0, 1 >
  //    < 1, 0 >
  //    < 1, 1 >
  //
  // which tells us the unit should have length 1, not 0.5.
  //
  // However, if it's 1, we still want it to be 1 (we should never actually use the value)
  const rowUnit = threeGrid.rows.multiplyScalar(1 / Math.max(grid.rows - 1, 1));

  const columnUnit = threeGrid.columns.multiplyScalar(
    1 / Math.max(grid.columns - 1, 1),
  );

  const rowCoordinate = rowUnit.clone();
  const columnCoordinate = rowUnit.clone();

  for (let row = 0; row < grid.rows; row += 1) {
    rowCoordinate.copy(rowUnit).multiplyScalar(row);

    for (let column = 0; column < grid.columns; column += 1) {
      columnCoordinate.copy(columnUnit).multiplyScalar(column);

      const position = threeGrid.a
        .clone()
        .add(rowCoordinate)
        .add(columnCoordinate);

      yield {
        row,
        column,
        position,
      };
    }
  }
}

export function getGridBoundingBox(gridCorners: GridCorners, padding = 0) {
  const threeGrid = convertGridToThree(gridCorners);

  if (!threeGrid) {
    return null;
  }

  if (!padding) {
    return {
      a: threeGrid.a,
      b: threeGrid.b,
      c: threeGrid.c,
      d: threeGrid.d,
    };
  }

  const rowPadding = threeGrid.rows.clone().normalize().multiplyScalar(padding);

  const columnPadding = threeGrid.columns
    .clone()
    .normalize()
    .multiplyScalar(padding);

  return {
    a: threeGrid.a.clone().sub(rowPadding).sub(columnPadding),
    b: threeGrid.b.clone().add(rowPadding).sub(columnPadding),
    c: threeGrid.c.clone().add(rowPadding).add(columnPadding),
    d: threeGrid.d.clone().sub(rowPadding).add(columnPadding),
  };
}
