import cx from 'classnames';

import { margin } from '@sb/ui/styles';

import Typography from '../Typography';

import styles from './WizardCard.module.css';

interface WizardCardProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
}

export function WizardCard({ children, className, title }: WizardCardProps) {
  return (
    <div className={cx(styles.wizardCard, className)}>
      <Typography
        isBold
        isUppercase
        hasNoLineHeight
        className={margin.bottom.small}
        variant="extraSmall"
      >
        {title}
      </Typography>

      {children}
    </div>
  );
}
