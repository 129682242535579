/**
 * Image Template manifest:
 *
 * The manifest for an image that can be used to classify other images.
 * Specified in terms of a cropbox on an RGBA image.
 */
import * as zod from 'zod';

import { RGBAImageManifest } from './RGBAImageManifest';

export const ImageTemplateManifest = zod.object({
  label: zod.string(),
  templateImage: RGBAImageManifest,
  minX: zod.number().int().nonnegative(),
  minY: zod.number().int().nonnegative(),
  width: zod.number().int().nonnegative(),
  height: zod.number().int().nonnegative(),
});

export type ImageTemplateManifest = zod.infer<typeof ImageTemplateManifest>;
