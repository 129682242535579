import type { IfStepArguments } from '@sb/routine-runner';
import type { IfStep } from '@sb/types';

import { buildConditional } from '../steps';

export const convertIfConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: IfStep.Arguments,
): IfStepArguments => {
  if (!configuration) {
    throw new Error(`If step is not configured. stepID: ${stepID}`);
  }

  return {
    condition: buildConditional(JSON.parse(configuration.condition)),
  };
};
