import { differenceWith } from 'lodash';

import type { RoutineData } from '@sb/feathers-types';
import type { Step } from '@sb/types';
import {
  ACTION_REQUIRED_STEP_MESSAGE_DEFAULT,
  listAllSteps,
  reverseConvertStepSummary,
} from '@sbrc/utils';

import { patchBuilder } from '../utils';

import { getRoutinesService } from './utils';

export async function updateRoutineSteps(
  routineID: string,
  oldSteps: Step.ConvertedSummary[],
  newSteps: Step.ConvertedSummary[],
): Promise<void> {
  const changes = patchBuilder<RoutineData>();

  // will be set to current time server side
  changes.set('configurationUpdatedAt', 'now');

  changes.set('steps', reverseConvertStepSummary(newSteps));

  /**
   * Custom comparator for checking if a step was either added or removed from the step list.
   */
  const isStepEqual = (oldStep: Step.Summary, newStep: Step.Summary) =>
    oldStep.id === newStep.id;

  // Having a flat list of all steps makes it easier for finding out added and removed steps.
  const allOldSteps = listAllSteps(oldSteps);
  const allNewSteps = listAllSteps(newSteps);
  const addedSteps = differenceWith(allNewSteps, allOldSteps, isStepEqual);
  const removedSteps = differenceWith(allOldSteps, allNewSteps, isStepEqual);

  // Mark new steps as "action required".
  addedSteps.forEach((step) => {
    changes.set(`actionRequiredByStepID.${step.id}`, {
      kind: 'missingConfiguration',
      message: ACTION_REQUIRED_STEP_MESSAGE_DEFAULT,
    });
  });

  // Remove the step configuration when a step is deleted.
  removedSteps.forEach((step) => {
    changes.unset(`stepConfigurations.${step.id}`);
  });

  // Remove deleted steps from the "action required" field.
  removedSteps.forEach((step) => {
    changes.unset(`actionRequiredByStepID.${step.id}`);
  });

  await getRoutinesService().patch(routineID, changes.buildPatch());
}
