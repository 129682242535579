import type { ConditionalArgument } from '@sb/types';

export const isValidConditional = (
  configuration?: ConditionalArgument.ConditionalBuilderState[][],
): boolean => {
  if (!configuration) return false;

  // Checks whether the configuration includes a conditional group
  // that includes null as any of its values
  return !configuration.some((conditionalGroup) =>
    conditionalGroup.some((condition) =>
      Object.values(condition).includes(null),
    ),
  );
};
