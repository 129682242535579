import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ReorderIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 12 12">
      <path d="M12 1.5H0v-1h12v1zm0 5H0v-1h12v1zm0 5H0v-1h12v1z" />
    </Icon>
  );
}
