export * from './getCurrentUserID';
export * from './loginAsAdmin';
export * from './loginWithPassword';
export * from './loginWithPIN';
export * from './loginWithThirdPartyProvider';
export * from './logout';
export * from './onGetCurrentUserID';
export * from './sendPasswordResetEmail';
export * from './setupNewUserAccount';
export * from './updatePIN';
export * from './validatePIN';

export const USE_PIN_AUTHENTICATION = true;
