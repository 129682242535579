import * as zod from 'zod';

import type {
  LoadedRoutineState,
  RoutineRunnerState,
} from '../../RoutineRunnerState';

export const JavaScriptExpression = zod.object({
  kind: zod.literal('JavaScript'),
  expression: zod.string(),
});

export type JavaScriptExpression = zod.infer<typeof JavaScriptExpression>;

export type JSRunner = {
  evaluate(args: {
    expression: string;
    context: RoutineRunnerState & { routine?: LoadedRoutineState };
  }): Promise<unknown>;
};
