import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

interface CycleStats {
  cycleCount: number;
  runTimeSeconds: number;
}

function getCycleStats(state: RoutineRunnerState | null): CycleStats | null {
  if (!state) {
    return null;
  }

  if (state.kind !== 'RoutineRunning') {
    return { cycleCount: 0, runTimeSeconds: 0 };
  }

  const cycleCount = state.cycleCount || 0;
  const runTimeSeconds = state.runTimeSeconds || 0;

  return {
    cycleCount,
    // Rounding makes sure the state isn't updated so often since
    // the Routine Runner emits a very long decimal (i.e. 1.0000000000000765443)
    runTimeSeconds: Math.round(runTimeSeconds),
  };
}

export default function useRobotCycleStats(
  args: UseRoutineRunnerHandleArguments,
): CycleStats | null {
  return useRobotState(args, getCycleStats);
}
