import { useContext } from 'react';

import { GlobalContext } from '@sbrc/contexts';

const useNotifications = () => {
  const { notifications } = useContext(GlobalContext);

  return notifications;
};

export default useNotifications;
