import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SyncIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path d="M2.25 12.75C3.453 14.2 5.14 15.063 7 15.063c3.66 0 6.625-2.966 6.625-6.625h-1c0 3.107-2.518 5.625-5.625 5.625-1.765 0-3.354-.94-4.387-2.5H5.25v-1h-3.5a.5.5 0 0 0-.5.5v3.5h1V12.75zm9.5-9.498C10.547 1.8 8.86.938 7 .938 3.34.938.375 3.904.375 7.563h1c0-3.107 2.518-5.625 5.625-5.625 1.765 0 3.354.94 4.387 2.5H8.75v1h3.5a.5.5 0 0 0 .5-.5v-3.5h-1V3.25z" />
    </Icon>
  );
}
