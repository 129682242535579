import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function MicrosoftIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M11.4 24H0V12.6h11.4V24zM24 24H12.6V12.6H24V24zM11.4 11.4H0V0h11.4v11.4zm12.6 0H12.6V0H24v11.4z" />
    </Icon>
  );
}
