/* eslint-disable no-console */

import type {
  HaasInterface,
  HaasStatus,
} from '@sb/integrations/HaasMill/types';
import { EventEmitter, wait } from '@sb/utilities';

/**
 * This is a simulated version of the Haas that just prints what it's doing in the console
 */
export class SimulatedHaas implements HaasInterface {
  private events = new EventEmitter<{
    error: Error;
    programComplete: string;
    status: HaasStatus;
  }>();

  public runningProgram: string | undefined = undefined;

  public inCellSafe: boolean = false;

  public startCellSafe(): void {
    this.inCellSafe = true;
  }

  public stopCellSafe(): void {
    this.inCellSafe = false;
  }

  public onMachineStatus(callback: (status: HaasStatus) => void): () => void {
    return this.events.on('status', callback);
  }

  public onProgramComplete(
    callback: (programName: string) => void,
  ): () => void {
    return this.events.on('programComplete', callback);
  }

  public onError(callback: (error: Error) => void): () => void {
    return this.events.on('error', callback);
  }

  public async runProgram(programName: string): Promise<void> {
    if (this.runningProgram) {
      throw new Error('Haas told to run two programs at the same time');
    }

    this.runningProgram = programName;
    console.log('Haas is running program', programName);

    await wait(200);

    console.log('Haas finished running program', programName);

    this.runningProgram = undefined;
    this.events.emit('programComplete', programName);
  }

  public async stop(): Promise<void> {
    console.log('Haas stopped');
    await wait(100);
    this.runningProgram = undefined;
  }

  public static async construct(): Promise<SimulatedHaas> {
    // simulate connecting over Ethernet
    await wait(100);

    return new SimulatedHaas();
  }
}
