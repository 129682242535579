import cx from 'classnames';

import styles from './CardContent.module.css';

type CardVariant = 'white' | 'gray';

interface CardContentProps {
  children: React.ReactNode;
  className?: string;
  isScrollable?: boolean;
  variant?: CardVariant;
}

const CardContent = ({
  children,
  className,
  variant,
  isScrollable,
}: CardContentProps) => {
  return (
    <div
      className={cx(
        styles.cardContent,
        {
          [styles.scrollable]: isScrollable,
        },
        styles[variant ?? 'white'],
        className,
      )}
    >
      {children}
    </div>
  );
};

export default CardContent;
