import * as zod from 'zod';

// the result of the Q500 three-in-one command
export const HaasStatus = zod.object({
  program: zod.string(),
  status: zod.string(),
  parts: zod.number().int().nonnegative(),
});

export type HaasStatus = zod.infer<typeof HaasStatus>;
