import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function StarIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 13 13" {...props}>
      <path d="M3.20624 11.9135L6.26952 10.3034L9.3328 11.9135C9.87655 12.1995 10.5258 11.7401 10.4203 11.1237L9.83435 7.71355L12.3117 5.29949C12.757 4.8659 12.5109 4.10887 11.8968 4.01981L8.47264 3.5206L6.94218 0.417475C6.66796 -0.135639 5.87342 -0.142666 5.59686 0.417475L4.0664 3.5206L0.642201 4.01981C0.0281359 4.10887 -0.217957 4.8659 0.227354 5.29949L2.7047 7.71355L2.11876 11.1237C2.01329 11.7401 2.66249 12.2018 3.20624 11.9135Z" />
    </Icon>
  );
}
