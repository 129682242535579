import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function CompanyIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M4 1.5V8a.5.5 0 1 1-1 0V1a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v5a.5.5 0 1 1-1 0V1.5H4zm-1.5 12a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2zm9-2a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2zm0 2a.5.5 0 1 1 0-1h2a.5.5 0 1 1 0 1h-2zm-5 3a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1h-3zm0-12a.5.5 0 1 1 0-1h3a.5.5 0 1 1 0 1h-3zm9 3a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-6A.5.5 0 0 1 9 16V8a.5.5 0 0 1 .5-.5h6zm-.5 1h-5v7h5v-7zm-8.5 1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-6A.5.5 0 0 1 0 16v-6a.5.5 0 0 1 .5-.5h6zm-5.5 1v5h5v-5H1z" />
    </Icon>
  );
}
