import type {
  RoutineRunnerState,
  RoutineRunning,
  SpeedProfile,
} from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

interface RoutineRunningState
  extends Pick<
    RoutineRunning,
    | 'currentStepID'
    | 'isPaused'
    | 'isPreflightTestRun'
    | 'shouldCheckGuidedMode'
    | 'shouldNextArmMoveBeGuidedMode'
    | 'startTime'
  > {
  speedProfile: SpeedProfile;
}

function getRoutineRunningState(
  state: RoutineRunnerState | null,
): RoutineRunningState | null {
  if (state && state.kind === 'RoutineRunning') {
    return {
      currentStepID: state.currentStepID,
      isPaused: state.isPaused,
      isPreflightTestRun: state.isPreflightTestRun,
      shouldCheckGuidedMode: state.shouldCheckGuidedMode,
      shouldNextArmMoveBeGuidedMode: state.shouldNextArmMoveBeGuidedMode,
      startTime: state.startTime,
      speedProfile: state.configuration.routineSpeedProfile,
    };
  }

  return null;
}

export default function useRobotRoutineRunningState(
  args: UseRoutineRunnerHandleArguments,
): RoutineRunningState | null {
  return useRobotState(args, getRoutineRunningState);
}
