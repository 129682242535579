import type { Step } from './step';

export namespace WaitStep {
  export interface Arguments extends Step.CommonArguments {
    argumentKind: 'Wait';
    milliseconds: number | null;
    /**
     * Condition: will override `milliseconds` if non-null
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: string | null;
  }

  export function isMillisecondsArguments(
    args: Arguments,
  ): args is Arguments & { milliseconds: number } {
    return typeof args.milliseconds === 'number';
  }

  export function isConditionArguments(
    args: Arguments,
  ): args is Arguments & { condition: string } {
    return typeof args.condition === 'string';
  }
}
