import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function DownloadIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M14.6468 6.12061L7.99998 12.7674L1.3532 6.12061L2.06031 5.4135L7.99998 11.3532L13.9397 5.4135L14.6468 6.12061Z" />
      <path d="M8.49971 0.0603638V12.0603H7.49971L7.49971 0.0603638L8.49971 0.0603638Z" />
      <path d="M16 16L4.37112e-08 16L0 15L16 15V16Z" />
    </Icon>
  );
}
