import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function MoreIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 10 2" {...props}>
      <path d="M1.25.25a.75.75 0 1 1 0 1.5.75.75 0 1 1 0-1.5zM5 .25a.75.75 0 1 1 0 1.5.75.75 0 1 1 0-1.5zm3.75 0a.75.75 0 1 1 0 1.5.75.75 0 1 1 0-1.5z" />
    </Icon>
  );
}
