import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getPayloadMassKG(state: RoutineRunnerState | null): number | null {
  return state ? state.configuration.payload.mass : null;
}

export default function useRobotPayloadState(
  args: UseRoutineRunnerHandleArguments,
): number | null {
  return useRobotState(args, getPayloadMassKG, Object.is);
}
