import type { Unsubscribe, Equipment } from '@sb/types';

import { mapPaginated, retryOnTimeout } from '../utils';

import { convertEquipmentResponse, getEquipmentService } from './utils';

export function onGetAllEquipment(
  onSnapshot: (equipment: Equipment.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getEquipmentService()
    .watch()
    .find({ query: { $sort: { updatedAt: -1 } } })
    .pipe(retryOnTimeout())
    .subscribe((response) => {
      onSnapshot(mapPaginated(response, convertEquipmentResponse));
    });

  return () => subscription.unsubscribe();
}

export function onGetAllEnabledEquipment(
  onSnapshot: (equipment: Equipment.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(retryOnTimeout())
    .subscribe((response) => {
      onSnapshot(mapPaginated(response, convertEquipmentResponse));
    });

  return () => subscription.unsubscribe();
}
