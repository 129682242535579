import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function VariablesIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <path d="M14.167 13.833a.5.5 0 0 1 .09.992l-.09.008h-8a.5.5 0 0 1-.09-.992l.09-.008h8zm0-3a.5.5 0 0 1 .09.992l-.09.008h-8a.5.5 0 0 1-.09-.992l.09-.008h8zm0-3a.5.5 0 0 1 .09.992l-.09.008h-8a.5.5 0 0 1-.09-.992l.09-.008h8zm0-3a.5.5 0 0 1 .09.992l-.09.008h-8a.5.5 0 0 1-.09-.992l.09-.008h8zM2.667.5a.5.5 0 0 1 .4.2h0l1 1.333a.5.5 0 0 1 0 .6h0l-1 1.333a.5.5 0 0 1-.4.2h0H1a.5.5 0 0 1-.5-.5h0V1A.5.5 0 0 1 1 .5h0zm-.251 1h-.917v1.666h.917l.625-.833-.625-.833zm11.751.333a.5.5 0 0 1 .09.992l-.09.008h-8a.5.5 0 0 1-.09-.992l.09-.008h8z" />
    </Icon>
  );
}
