import { camelCase } from 'lodash';

import type { Routine, RoutineVariable } from '@sb/types';

export function getVariablesFromRoutineEnvironmentVariables(
  environmentVariables: Routine.EnvironmentVariable[],
) {
  return environmentVariables.map<RoutineVariable.EnvironmentVariableInformation>(
    (variable) => ({
      kind: 'environment',
      displayName: variable.name,
      environmentVariable: variable,
      variableKind: 'composite',
      variableName: `variables['${variable.id}'].value`,
      userIdentifier: `Environment_${camelCase(variable.name)}`,
    }),
  );
}
