import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PositionListIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8 2C7.17157 2 6.5 2.67157 6.5 3.5C6.5 4.32843 7.17157 5 8 5C8.82843 5 9.5 4.32843 9.5 3.5C9.5 2.67157 8.82843 2 8 2ZM5.5 3.5C5.5 2.11929 6.61929 1 8 1C9.38071 1 10.5 2.11929 10.5 3.5C10.5 4.88071 9.38071 6 8 6C6.61929 6 5.5 4.88071 5.5 3.5Z" />
      <path d="M3.5 11C2.67157 11 2 11.6716 2 12.5C2 13.3284 2.67157 14 3.5 14C4.32843 14 5 13.3284 5 12.5C5 11.6716 4.32843 11 3.5 11ZM1 12.5C1 11.1193 2.11929 10 3.5 10C4.88071 10 6 11.1193 6 12.5C6 13.8807 4.88071 15 3.5 15C2.11929 15 1 13.8807 1 12.5Z" />
      <path d="M12.5 11C11.6716 11 11 11.6716 11 12.5C11 13.3284 11.6716 14 12.5 14C13.3284 14 14 13.3284 14 12.5C14 11.6716 13.3284 11 12.5 11ZM10 12.5C10 11.1193 11.1193 10 12.5 10C13.8807 10 15 11.1193 15 12.5C15 13.8807 13.8807 15 12.5 15C11.1193 15 10 13.8807 10 12.5Z" />
      <path d="M5 13C5 12.7239 5.22386 12.5 5.5 12.5H10.5C10.7761 12.5 11 12.7239 11 13C11 13.2761 10.7761 13.5 10.5 13.5H5.5C5.22386 13.5 5 13.2761 5 13Z" />
      <path d="M9.2765 5.05277C9.52349 4.92928 9.82383 5.02939 9.94732 5.27638L12.4473 10.2764C12.5708 10.5234 12.4707 10.8237 12.2237 10.9472C11.9767 11.0707 11.6764 10.9706 11.5529 10.7236L9.0529 5.72359C8.9294 5.4766 9.02951 5.17627 9.2765 5.05277Z" />
      <path d="M6.72372 5.05277C6.97071 5.17627 7.07082 5.4766 6.94732 5.72359L4.44732 10.7236C4.32383 10.9706 4.02349 11.0707 3.7765 10.9472C3.52951 10.8237 3.4294 10.5234 3.5529 10.2764L6.0529 5.27638C6.17639 5.02939 6.47673 4.92928 6.72372 5.05277Z" />
    </Icon>
  );
}
