import { useContext } from 'react';

import { GlobalContext } from '@sbrc/contexts';

const useAuthentication = () => {
  const { settings, userID } = useContext(GlobalContext);

  const isControlPanelUser: boolean = Boolean(
    settings?.permissions.controlPanel,
  );

  return { isControlPanelUser, settings, userID };
};

export default useAuthentication;
