import type { LoggingBackend } from './backends';
import type { ConsoleOptions } from './console';
import { ConsoleBackend } from './console';
import { AbstractLogger } from './types';

export type { ConsoleOptions } from './console';

export type { AbstractLogger } from './types';

export { ConsoleBackend };

export type LoggerContext = Map<string, string>;

export type LogKind = 'debug' | 'info' | 'warn' | 'error';

/* eslint-disable no-underscore-dangle */

export class Logger extends AbstractLogger {
  private static numLoggers = 0;

  public constructor(public context: LoggerContext = new Map()) {
    super();
    this.label = `Logger ${Logger.numLoggers}`;
    Logger.numLoggers += 1;
  }

  private backends: Array<LoggingBackend> = [];

  public addBackend(backend: LoggingBackend): void {
    if (this.hasInitialized) {
      throw new Error(
        'Cannot add backend to a logger that has already been initialized',
      );
    }

    this.backends.push(backend);
  }

  public enableConsole(args?: ConsoleOptions): void {
    const extant = this.backends.find(
      (backend) => backend instanceof ConsoleBackend,
    );

    if (!extant) {
      this.addBackend(new ConsoleBackend(args));
    }
  }

  public addContext(key: string, value: string): void {
    if (this.hasInitialized) {
      throw new Error(
        'Cannot add context to a logger that has already been initialized',
      );
    }

    this.context.set(key, value);
  }

  /**
   * Initialize backends.
   *
   * This should occur lazily so clients can addContext, change label, and add backends
   * before logging.
   */
  private initialize(): void {
    this.hasInitialized = true;

    this.backends.forEach((backend) => {
      backend.initialize(this);
    });
  }

  public debug(...parts: Array<any>): void {
    this.event({
      kind: 'debug',
      parts,
    });
  }

  public info(...parts: Array<any>): void {
    this.event({
      kind: 'info',
      parts,
    });
  }

  public warn(...parts: Array<any>): void {
    this.event({
      kind: 'warn',
      parts,
    });
  }

  public error(...parts: Array<any>): void {
    this.event({
      kind: 'error',
      parts,
    });
  }

  /**
   * Log an event with any data
   */
  public event({ kind, parts }: { kind: LogKind; parts: Array<any> }): void {
    if (!this.hasInitialized) {
      this.initialize();
    }

    this.backends
      .filter((backend) => backend.acceptedLogKinds.has(kind))
      .forEach((backend) => {
        backend.log({
          kind,
          parts,
          logger: this,
        });
      });
  }

  /**
   * Create a new logger with the same context
   */
  public createChildLogger(label?: string): Logger {
    const logger = new Logger(new Map(this.context));

    if (label) {
      logger.label = label;
    }

    return logger;
  }
}
