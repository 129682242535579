import type { Step } from './step';

/**
 * Types matching the routine runner schema for "Loop" operations.
 */
export namespace LoopStep {
  /** This interface is used when either "loop forever" or "loop N times" are selected. */
  export interface Arguments extends Step.CommonArguments {
    argumentKind: 'Loop';
    /**
     * If null or Infinity then "Loop forever"; if non-null then "loop N times"
     * (note - Feathers cannot store Infinity, which is why this is nullable)
     */
    times: number | null;
    /**
     * "Use rules": will override `times` if non-null
     * The actual type is ConditionalBuilderState[][] but Firestore doesn't support nested
     * arrays, so we need to serialize/desarialize this property when using it.
     */
    condition: string | null;
  }

  export function isFixedTimesArguments(
    args: Arguments,
  ): args is Arguments & { times: number } {
    return Number.isFinite(args.times);
  }

  export function isConditionArguments(
    args: Arguments,
  ): args is Arguments & { condition: string } {
    return typeof args.condition === 'string';
  }
}
