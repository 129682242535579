import { useInterval } from '@sb/ui/hooks';

import { clampAndRoundValue } from './clampAndRoundValue';
import { INTERVAL_OPTIONS } from './consts';
import type { RangeSpinnerProps } from './RangeSpinner';

interface UseSingleValueRangeSpinnerProps {
  value: number;
  min: number;
  max: number;
  step: number;
  onChange: (state: (previous: number) => number) => void;
}

export function useSingleValueRangeSpinner({
  value,
  min,
  max,
  step,
  onChange,
}: UseSingleValueRangeSpinnerProps): RangeSpinnerProps {
  const { stopInterval, startInterval } = useInterval();

  return {
    primaryValue: value,
    min,
    max,
    isSpinMinusDisabled: value <= min,
    isSpinPlusDisabled: value >= max,
    onChange: (newValue) => {
      onChange(() => clampAndRoundValue(newValue, min, max, step));
    },
    onSpinnerHold: (isPositive) => {
      startInterval(
        () =>
          onChange((prevValue) => {
            const newValue = prevValue + (isPositive ? step : -step);

            return clampAndRoundValue(newValue, min, max, step);
          }),
        INTERVAL_OPTIONS,
      );
    },
    onSpinnerRelease: stopInterval,
    isPulseDisabled: true,
  };
}
