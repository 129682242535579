import type { OnRobotScrewdriverCommand, OnRobotScrewdriverState } from '.';

export function calculateORScrewdriverStateFromCommand(
  command: Partial<OnRobotScrewdriverCommand>,
): Partial<OnRobotScrewdriverState> {
  const state: Partial<OnRobotScrewdriverState> = {
    kind: 'OnRobotScrewdriver',
  };

  if (command.commandKind === 'STOP') {
    return state;
  }

  return {
    ...state,
    targetForce: command.targetForce,
    targetTorque: command.targetTorque,
    shankPosition: command.shankPosition,
  };
}
