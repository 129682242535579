import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function RemoveIcon(props: IconProps) {
  return (
    <Icon {...props}>
      <path d="M17 11H7a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2zm-5 13C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm0-2c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" />
    </Icon>
  );
}
