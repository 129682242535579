import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SpeedIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 14" {...props}>
      <path d="M10.384 4.035l-.002 1.193-.144 3.366c-.135 1.469-.361 2.654-.697 3.451-.5 1.188-1.869 1.745-3.056 1.244S4.739 11.42 5.24 10.233c.336-.797 1.026-1.787 1.983-2.909L9.531 4.87h0l.852-.835zM9.36 6.461l-.519.549-.858.962c-.896 1.05-1.536 1.968-1.822 2.648s.032 1.461.711 1.746 1.46-.032 1.746-.711.497-1.779.623-3.154l.117-1.947h0l.001-.095zM7.333.667a7.83 7.83 0 0 1 6.979 4.273.5.5 0 1 1-.89.455 6.83 6.83 0 0 0-12.178-.001.5.5 0 1 1-.89-.455A7.83 7.83 0 0 1 7.333.667z" />
    </Icon>
  );
}
