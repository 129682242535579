import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function TrashIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 17" {...props}>
      <path d="M5 3.5V1a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v2.5h4.5v1H.5v-1H5zm1 0h4v-2H6v2zM2 7h1v7a1.5 1.5 0 0 0 1.5 1.5h7A1.5 1.5 0 0 0 13 14V7h1v7a2.5 2.5 0 0 1-2.5 2.5h-7A2.5 2.5 0 0 1 2 14V7z" />
    </Icon>
  );
}
