import type { SerialPortSimulatorData } from '@sb/integrations/simulator';
import { convertBytes } from '@sb/utilities';

import type {
  EwellixFunction,
  EwellixMotionDirection,
} from '../implementation/consts';
import {
  EwellixCommand,
  EwellixResponseCode,
  SIMULATED_SERIAL_PORT_DEVICE_ID,
} from '../implementation/consts';

interface CommandHandlers {
  get(address: number): Uint8Array;
  transfer(address: number, payload: Uint8Array): void;
  cyclic(): void;
  execute(functionID: EwellixFunction, direction: EwellixMotionDirection): void;
  stop(functionID: EwellixFunction): void;
  open(): void;
  abort(): void;
}

export class EwellixSerialPortSimulatorDataHandler {
  public static handle(commandHandlers: CommandHandlers) {
    return (
      dataIn: SerialPortSimulatorData,
      onDataOut: (dataOut: SerialPortSimulatorData) => void,
    ) => {
      const handler = new EwellixSerialPortSimulatorDataHandler(
        commandHandlers,
        dataIn,
        onDataOut,
      );

      handler.handle();
    };
  }

  private constructor(
    private commandHandlers: CommandHandlers,
    private dataIn: SerialPortSimulatorData,
    private onDataOut: (dataOut: SerialPortSimulatorData) => void,
  ) {}

  public handle() {
    if (this.dataIn.deviceID !== SIMULATED_SERIAL_PORT_DEVICE_ID) {
      return;
    }

    try {
      const cmd = String.fromCharCode(
        this.dataIn.message[0],
        this.dataIn.message[1],
      );

      switch (cmd) {
        case EwellixCommand.REMOTE_DATA_GET: {
          const payload = this.commandHandlers.get(
            convertBytes.toUint16LE(this.dataIn.message, 2),
          );

          this.emit(EwellixResponseCode.ACK, payload);
          break;
        }

        case EwellixCommand.REMOTE_DATA_TRANSFER: {
          this.commandHandlers.transfer(
            convertBytes.toUint16LE(this.dataIn.message, 4),
            this.dataIn.message.slice(6),
          );

          this.emit(EwellixResponseCode.ACK);
          break;
        }

        case EwellixCommand.REMOTE_CYCLIC:
          this.commandHandlers.cyclic();
          this.emit(EwellixResponseCode.ACK);
          break;

        case EwellixCommand.REMOTE_EXECUTE_FUNCTION:
          this.commandHandlers.execute(
            this.dataIn.message[2],
            this.dataIn.message[3],
          );

          this.emit(EwellixResponseCode.ACK);
          break;

        case EwellixCommand.REMOTE_STOP_FUNCTION:
          this.commandHandlers.stop(this.dataIn.message[2]);

          this.emit(EwellixResponseCode.ACK);
          break;

        case EwellixCommand.REMOTE_MODE_OPEN:
          this.commandHandlers.open();

          this.emit(EwellixResponseCode.ACK);
          break;

        case EwellixCommand.REMOTE_MODE_ABORT:
          this.commandHandlers.abort();

          this.emit(EwellixResponseCode.ACK);
          break;

        default:
          this.emit(EwellixResponseCode.ICE);
          break;
      }
    } catch (e) {
      this.emit(EwellixResponseCode.PCE);
    }
  }

  private emit(responseCode: EwellixResponseCode, payload?: Uint8Array) {
    const ctpAndPayload = payload?.length
      ? [...convertBytes.fromUint16LE(payload.length), ...payload]
      : [];

    this.onDataOut({
      deviceID: SIMULATED_SERIAL_PORT_DEVICE_ID,
      message: Uint8Array.from([
        this.dataIn.message[0],
        this.dataIn.message[1],
        responseCode,
        ...ctpAndPayload,
        0, // checksum
        0, // checksum
      ]),
    });
  }
}
