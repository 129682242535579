interface PatchData {
  stepIDs: string[];
  completedIDs: string[];
}

const DEFAULT_PATCH_DATA: PatchData = {
  stepIDs: [],
  completedIDs: [],
};

function getStorageKey(routineID: string) {
  return `step-wizard-${routineID}`;
}

function saveInfo(routineID: string, data: PatchData) {
  const storageKey = getStorageKey(routineID);
  sessionStorage.setItem(storageKey, JSON.stringify(data));
}

export function stepWizardGetStepInfo(routineID: string): PatchData {
  const storageKey = getStorageKey(routineID);
  const jsonData = sessionStorage.getItem(storageKey);

  if (!jsonData) {
    return DEFAULT_PATCH_DATA;
  }

  const data = JSON.parse(jsonData);

  if (
    !data ||
    !Array.isArray(data.stepIDs) ||
    !Array.isArray(data.completedIDs)
  ) {
    return DEFAULT_PATCH_DATA;
  }

  return {
    stepIDs: data.stepIDs,
    completedIDs: data.completedIDs,
  };
}

/**
 * Keep the list of steps we are configuring (and have completed configuring) in SessionStorage
 * Call `stepWizardSetStepIDs` before navigating to the step wizard.
 * This allows the data to persist across page refreshes without creating a huge URL.
 */
export function stepWizardSetStepIDs(
  routineID: string,
  stepIDs: string[],
): void {
  saveInfo(routineID, { ...DEFAULT_PATCH_DATA, stepIDs });
}

export function stepWizardSetCompleted(
  routineID: string,
  stepID: string,
  subStep?: number,
) {
  const stepInfo = stepWizardGetStepInfo(routineID);

  if (!stepInfo.completedIDs.includes(stepID)) {
    stepInfo.completedIDs.push(stepID);
  }

  if (subStep) {
    const stepSubStepID = `${stepID}.${subStep}`;

    if (!stepInfo.completedIDs.includes(stepSubStepID)) {
      stepInfo.completedIDs.push(stepSubStepID);
    }
  }

  saveInfo(routineID, stepInfo);
}
