import { clamp } from 'lodash';

import type { DeviceCommand, DeviceState } from '@sb/integrations/device';
import { ModbusFunctionCode } from '@sb/integrations/modbus/constants';
import { handleModbusRegisterRequest } from '@sb/integrations/modbus/utility';
import type {
  DeviceSimulator,
  SimulatorConstructorArgs,
} from '@sb/integrations/simulator';
import type { AbstractLogger } from '@sb/logger';
import { Logger } from '@sb/logger';
import { setBit, ON, OFF, wait, createClock } from '@sb/utilities';

import { OnRobot2FG7Command } from '../Command';
import {
  DEFAULT_OR2FG7_DIAMETER_TOLERANCE_METERS,
  FORCE_RANGE,
  HUNDREDTH_MM_TO_M,
  OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE,
  M_TO_HUNDREDTH_MM,
  M_TO_TENTH_MM,
  OR2FG7_CONTROL_COMMAND,
  OR2FG7_FINGER_ORIENTATION,
  OR2FG7_STATUS_BITS,
  OR2FG7_TARGET_ADDRESS,
  OUTWARD_MOUNT_OUTWARD_GRIP_DIAMETER_RANGE,
  Register,
  TENTH_MM_TO_M,
  OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT,
  OR_2FG7_FINGER_HEIGHT_METERS_DEFAULT,
  OR_2FG7_FINGER_LENGTH_METERS_DEFAULT,
  OR_2FG7_FINGER_POSITION_DEFAULT,
} from '../constants';
import type { OnRobot2FG7State } from '../State';

export class OnRobot2FG7Simulator implements DeviceSimulator<OnRobot2FG7State> {
  public kind = 'OnRobot2FG7' as const;

  // NOTE: All distance values are stored internally as Meters and converted on the fly to
  // the units expected for each register.
  private state: OnRobot2FG7State = {
    kind: 'OnRobot2FG7',
    isConnected: true,
    gripKind: 'inward',
    isBusy: false,
    isGripDetected: false,
    force: 45,
    maxForce: 80,
    width: {
      inner: 0.045,
      outer: 0.045,
    },
    minWidth: {
      inner: OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE.min,
      outer: OUTWARD_MOUNT_OUTWARD_GRIP_DIAMETER_RANGE.min,
    },
    maxWidth: {
      inner: OUTWARD_MOUNT_INWARD_GRIP_DIAMETER_RANGE.max,
      outer: OUTWARD_MOUNT_OUTWARD_GRIP_DIAMETER_RANGE.max,
    },

    fingerLength: OR_2FG7_FINGER_LENGTH_METERS_DEFAULT,
    fingerHeight: OR_2FG7_FINGER_HEIGHT_METERS_DEFAULT,
    fingerOrientation: OR_2FG7_FINGER_POSITION_DEFAULT,
    fingertipOffset: OR_2FG7_FINGERTIP_OFFSET_METERS_DEFAULT,

    linearSensorError: false,
    uncalibratedError: false,
  };

  private nextCommand = {
    force: 45,
    speed: 0.25, // stored as 0-1
    width: 0.045, // stored as meters
    initialWidth: 0.045, // set upon actuate() or writing to Command register, used for tracking animation progress
  };

  private getWidth(): number {
    return this.state.gripKind === 'inward'
      ? this.state.width.inner
      : this.state.width.outer;
  }

  private incrementWidth(delta: number): void {
    this.state.width.inner += delta;
    this.state.width.outer += delta;
  }

  private logger: AbstractLogger;

  public constructor(args: SimulatorConstructorArgs) {
    this.logger = args.logger ?? new Logger();
    this.logger.label = 'OnRobot2FG7Simulator';
    this.logger.enableConsole();
  }

  public updateConfig() {}

  public getState(): OnRobot2FG7State {
    return this.state;
  }

  public setState(state: Partial<DeviceState>) {
    if (state.kind !== 'OnRobot2FG7') {
      throw new Error('Tried to set invalid state for simulated 2FG7');
    }

    if (state.isConnected !== undefined)
      this.state.isConnected = state.isConnected;
    if (state.gripKind) this.state.gripKind = state.gripKind;
    if (state.force) this.state.force = state.force;
    if (state.width) this.state.width = state.width;
    if (state.isGripDetected) this.state.isGripDetected = state.isGripDetected;
  }

  public getFingerAngle(): number {
    return 0;
  }

  public getModbusAddressSubscriptions() {
    return new Set([OR2FG7_TARGET_ADDRESS]);
  }

  public handleModbusRegisterRequest = handleModbusRegisterRequest(
    () => this.state.isConnected,
    {
      // WRITE-ONLY
      [Register.TARGET_WIDTH]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;

          this.nextCommand.width = data * TENTH_MM_TO_M;

          return request;
        },
      },
      [Register.TARGET_FORCE]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.nextCommand.force = data;

          return request;
        },
      },
      [Register.TARGET_SPEED]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.nextCommand.speed = data / 100;

          return request;
        },
      },
      [Register.COMMAND]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;

          switch (data) {
            case OR2FG7_CONTROL_COMMAND.GRIP_INWARD:
            case OR2FG7_CONTROL_COMMAND.GRIP_OUTWARD: {
              this.actuate({
                kind: 'OnRobot2FG7Command',
                gripKind:
                  data === OR2FG7_CONTROL_COMMAND.GRIP_INWARD
                    ? 'inward'
                    : 'outward',
                targetDiameter: this.nextCommand.width,
                targetSpeed: this.nextCommand.speed,
                targetForce: this.nextCommand.force,
              }).catch((error) => {
                this.logger.error('Actuation error', error);
              });

              return request;
            }

            case OR2FG7_CONTROL_COMMAND.STOP: {
              this.stop();

              return request;
            }

            default:
              throw new Error(`Unknown command type: ${data}`);
          }
        },
      },
      // READ-ONLY
      [Register.STATUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          // ignore error bits for now in simulation
          let bits = 0;

          bits = setBit(
            bits,
            OR2FG7_STATUS_BITS.BUSY,
            this.state.isBusy ? ON : OFF,
          );

          bits = setBit(
            bits,
            OR2FG7_STATUS_BITS.GRIP_DETECTED,
            this.state.isGripDetected ? ON : OFF,
          );

          return { ...request, data: bits };
        },
      },
      [Register.EXTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.width.inner * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.INTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.width.outer * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.MIN_EXTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.minWidth.inner * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.MIN_INTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.minWidth.outer * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.MAX_EXTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.maxWidth.inner * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.MAX_INTERNAL_WIDTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.maxWidth.outer * M_TO_TENTH_MM;

          return { ...request, data };
        },
      },
      [Register.MAX_FORCE]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.maxForce;

          return { ...request, data };
        },
      },
      [Register.FORCE]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.force;

          return { ...request, data };
        },
      },
      // READ/WRITE
      [Register.FINGER_HEIGHT]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.fingerHeight * M_TO_TENTH_MM;

          return { ...request, data };
        },
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.state.fingerHeight = data * TENTH_MM_TO_M;

          return request;
        },
      },
      [Register.FINGER_LENGTH]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.fingerLength * M_TO_TENTH_MM;

          return { ...request, data };
        },
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.state.fingerLength = data * TENTH_MM_TO_M;

          return request;
        },
      },
      [Register.FINGERTIP_OFFSET]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data = this.state.fingertipOffset * M_TO_HUNDREDTH_MM;

          return { ...request, data };
        },
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.state.fingertipOffset = data * HUNDREDTH_MM_TO_M;

          return request;
        },
      },
      [Register.FINGER_ORIENTATION]: {
        [ModbusFunctionCode.Read]: (request) => {
          const data =
            this.state.fingerOrientation === 'inward'
              ? OR2FG7_FINGER_ORIENTATION.INWARD
              : OR2FG7_FINGER_ORIENTATION.OUTWARD;

          return { ...request, data };
        },
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;

          this.state.fingerOrientation =
            data === OR2FG7_FINGER_ORIENTATION.INWARD ? 'inward' : 'outward';

          return request;
        },
      },
    },
  );

  public async actuate(command: DeviceCommand): Promise<void> {
    const parsedCommand = OnRobot2FG7Command.parse(command);

    const { gripKind, targetDiameter, targetSpeed, targetForce } =
      parsedCommand;

    this.state.gripKind = gripKind;

    const force = clamp(targetForce ?? 0, FORCE_RANGE.min, FORCE_RANGE.max);
    const speed = clamp(targetSpeed, 0.1, 1);

    const width = clamp(
      targetDiameter,
      this.state.minWidth[gripKind === 'inward' ? 'inner' : 'outer'],
      this.state.maxWidth[gripKind === 'inward' ? 'inner' : 'outer'],
    );

    const clock = createClock();

    const TIME_INTERVAL_MS = 20;
    const MAX_SPEED_METERS_PER_MILLISECOND = 0.00005; // 5cm/s

    this.state.isBusy = true;

    this.state.isGripDetected = false;

    while (this.state.isBusy) {
      const remainingDistance = width - this.getWidth();

      if (
        Math.abs(remainingDistance) < DEFAULT_OR2FG7_DIAMETER_TOLERANCE_METERS
      ) {
        this.incrementWidth(remainingDistance);
        this.state.force = force;

        // Mock grip
        await wait(100);
        this.state.isGripDetected = true;

        this.state.isBusy = false;
      } else {
        const deltaTime = clock.tick();

        const maxDistance =
          deltaTime * speed * MAX_SPEED_METERS_PER_MILLISECOND;

        const distance =
          remainingDistance > 0
            ? Math.min(remainingDistance, maxDistance)
            : Math.max(remainingDistance, -maxDistance);

        this.incrementWidth(distance);
      }

      await wait(TIME_INTERVAL_MS);
    }
  }

  public stop() {
    this.state.isBusy = false;
  }
}
