import type {
  Classification,
  ClassificationRequest,
  ClassificationResponse,
  ImageTemplate,
} from '@sb/routine-runner';
import {
  CLASSIFICATION_INFERENCE_SCORE_MAXIMUM,
  CLASSIFICATION_INFERENCE_SCORE_MINIMUM,
} from '@sb/routine-runner';

export default class VisualizerClassification implements Classification {
  private templates: Record<string, ImageTemplate> = {};

  private getNextScreenState: () => Promise<string>;

  public constructor(getNextScreenState: () => Promise<string>) {
    this.getNextScreenState = getNextScreenState;
  }

  /**
   * Simulate a matching score for a label. When the current state is the same as
   * the label, then it will have a maximum score.
   */
  private simulateLabelScore = (label: string, screenState: string): number => {
    return label === screenState
      ? CLASSIFICATION_INFERENCE_SCORE_MAXIMUM
      : CLASSIFICATION_INFERENCE_SCORE_MINIMUM;
  };

  public addTemplate(template: ImageTemplate) {
    this.templates[template.label] = template;
  }

  public removeTemplate(label: string) {
    delete this.templates[label];
  }

  public clearTemplates() {
    this.templates = {};
  }

  public async classifyImage({
    labels,
    currentImage,
  }: ClassificationRequest): Promise<ClassificationResponse> {
    const nextScreenState = await this.getNextScreenState();

    const labelScores: ClassificationResponse['labelScores'] = labels.map(
      (label) => {
        return {
          label,
          score: this.simulateLabelScore(label, nextScreenState),
        };
      },
    );

    return {
      debugImage: currentImage,
      labelScores,
    };
  }
}
