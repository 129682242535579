import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function StopIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 13 14" {...props}>
      <path d="M13 0H1C0.4 0 0 0.4 0 1V13C0 13.6 0.4 14 1 14H13C13.6 14 14 13.6 14 13V1C14 0.4 13.6 0 13 0Z" />
    </Icon>
  );
}
