import type { DeviceConfiguration, DeviceKind } from './device';
import { EwellixLiftTLTSimulator } from './EwellixLiftTLT/simulation/EwellixLiftTLTSimulator';
import { OnRobot2FG7Simulator } from './OnRobot2FG7/simulation/OnRobot2FG7Simulator';
import { OnRobot3FG15Simulator } from './OnRobot3FG15/simulation/OnRobot3FG15Simulator';
import { OnRobotDualQuickChangerSimulator } from './OnRobotDualQuickChanger/simulation/OnRobotDualQuickChangerSimulator';
import { OnRobotScrewdriverSimulator } from './OnRobotScrewdriver/simulation/OnRobotScrewdriverSimulator';
import { OnRobotVGP20Simulator } from './OnRobotVGP20/simulation/OnRobotVGP20Simulator';
import type { DeviceSimulator, SimulatorConstructorArgs } from './simulator';

interface SimulatorConstructor {
  new (args: SimulatorConstructorArgs): DeviceSimulator;
  prototype: DeviceSimulator;
}

const SIMULATOR_CLASS_MAP: Partial<Record<DeviceKind, SimulatorConstructor>> = {
  EwellixLiftTLT: EwellixLiftTLTSimulator,
  OnRobot2FG7: OnRobot2FG7Simulator,
  OnRobot3FG15: OnRobot3FG15Simulator,
  OnRobotDualQuickChanger: OnRobotDualQuickChangerSimulator,
  OnRobotScrewdriver: OnRobotScrewdriverSimulator,
  OnRobotVGP20: OnRobotVGP20Simulator,
};

export const getSimulatorByKind = (
  kind: DeviceKind,
): {
  getSimulator(configuration: DeviceConfiguration): DeviceSimulator;
  isInstance(instance: DeviceSimulator): boolean;
} => {
  const SimulatorClass = SIMULATOR_CLASS_MAP[kind];

  if (!SimulatorClass) {
    throw new Error(`Unsupported kind for equipment ${kind}`);
  }

  return {
    getSimulator: (configuration) => new SimulatorClass({ configuration }),
    isInstance: (instance) => instance instanceof SimulatorClass,
  };
};
