import type { Robot } from '@sb/types';

import { generateIOPort } from './generateIOPort';

export function cleanIOPortsForUpdate(
  ioPorts?: Robot.IOPort[],
): Robot.IOPort[] | undefined {
  if (!ioPorts) {
    return undefined;
  }

  return ioPorts.map((ioPort, index) => {
    // don't update customIO ports to robots table
    if (ioPort.deviceName) {
      return generateIOPort(ioPort.kind, index);
    }

    return ioPort;
  });
}
