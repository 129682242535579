import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function DoubleArrowNextIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path d="M7.634 14.66L13.818 8 7.634 1.34l.733-.68L15.182 8l-6.816 7.34-.733-.68zM6.818 8L.634 1.34l.733-.68L8.182 8l-6.816 7.34-.733-.68L6.818 8z" />
    </Icon>
  );
}
