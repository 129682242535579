import { ZERO_ROTATION } from '@sb/geometry';
import { getPercentageToUnitStringConverter } from '@sb/utilities';

export const COMPUTEBOX_HOST_DEFAULT = 'COMPUTEBOX.localdomain';

export const ORVGP20_CHANNELS = [
  { channel: 'one', name: 'A' },
  { channel: 'two', name: 'B' },
  { channel: 'three', name: 'C' },
  { channel: 'four', name: 'D' },
] as const;

export const suctionAmountStringConvert = getPercentageToUnitStringConverter(
  '%',
  0,
);

export const ORVGP20_DEFAULT_TOOLTIP_POSE = {
  ...ZERO_ROTATION,
  x: -0.036,
  y: 0,
  z: 0.08,
};
