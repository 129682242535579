import { v4 as uuid } from 'uuid';

import type { Unsubscribe } from '@sb/types';
import { EventEmitter } from '@sb/utilities';

interface UserSessionEmitterState {
  isMovingRobot: boolean;
  robotID: string | null;
}

const SESSION_ID = uuid();

const userSessionEmitter = new EventEmitter<UserSessionEmitterState>();

/**
 * The Remote Control displays a list of active user sessions in some pages.
 *
 * Each session can have additional information based on which actions a user
 * is performing. For example, we can display connected users only when they're
 * controlling the live robot or a different layout when they're running an
 * ad-hoc command.
 */
export class UserSession {
  /**
   * This session ID should be unique per each visit to the Remote Control.
   * We use this ID to make updates to our backend regarding this user session.
   */
  public static getID(): string {
    return SESSION_ID;
  }

  /** Tell us if a user is controlling a live robot or Vizbot. */
  public static controlRobot(robotID: string | null): void {
    userSessionEmitter.emit('robotID', robotID);
  }

  /** Tell us if a user is moving the robot. */
  public static moveRobot(isMovingRobot: boolean): void {
    userSessionEmitter.emit('isMovingRobot', isMovingRobot);
  }

  /** Listen to updates when the user switches between live robot and Vizbot. */
  public static onControlRobotChange(
    onChange: (robotID: string | null) => void,
  ): Unsubscribe {
    return userSessionEmitter.on('robotID', onChange);
  }

  /** Listen to updates when the user starts/stops moving the robot. */
  public static onMoveRobotChange(
    onChange: (isMovingRobot: boolean) => void,
  ): Unsubscribe {
    return userSessionEmitter.on('isMovingRobot', onChange);
  }
}
