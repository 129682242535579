import type { Routine, Step } from '@sb/types';

import { DECORATOR_STEP_LIST } from './constants';
import { listAllSteps } from './listAllSteps';

/** Is decorator step that doesn't have nested steps. */
export function isInvalidDecoratorStep(step: Step.ConvertedSummary): boolean {
  return DECORATOR_STEP_LIST.includes(step.stepKind) && step.steps.length === 0;
}

/** Is an Add Offset decorator step that doesn't have a nested Move Arm step. */
export function isInvalidAddOffsetStep(step: Step.ConvertedSummary): boolean {
  if (step.stepKind !== 'AddOffset') return false;

  return !step.steps.some((nestedStep) => nestedStep.stepKind === 'MoveArmTo');
}

/** Is LoopControl step that isn't inside a loop. */
export function isInvalidLoopControlStep(step: Step.ConvertedSummary): boolean {
  // Only check `LoopControl` steps.
  if (step.stepKind !== 'LoopControl') return false;

  // Check if this step is a child of a 'Loop' step.
  const isParentALoopStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'Loop',
  );

  // If we can't find any 'Loop' steps in parent nodes, then
  // this step is invalid.
  return !isParentALoopStep;
}

export function isInvalidWaypointStep(
  routine: Routine.ConvertedResponse,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): boolean {
  if (
    !stepConfiguration ||
    stepConfiguration.stepKind !== 'MoveArmTo' ||
    !stepConfiguration.args?.isWaypoint
  ) {
    return false;
  }

  const allSteps = listAllSteps(routine.steps);

  let isWaypointFound = false;

  // find first step after waypoint which isn't a decorator step
  const firstNonDecoratorStepAfterWaypoint = allSteps.find((step) => {
    if (step.id === stepConfiguration.id) {
      isWaypointFound = true;

      return false;
    }

    return isWaypointFound && !DECORATOR_STEP_LIST.includes(step.stepKind);
  });

  return (
    !firstNonDecoratorStepAfterWaypoint ||
    firstNonDecoratorStepAfterWaypoint.stepKind !== 'MoveArmTo'
  );
}

export function isBlendRadiusStep(
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): boolean {
  return !!(
    stepConfiguration &&
    stepConfiguration.stepKind === 'MoveArmTo' &&
    stepConfiguration.args?.isWaypoint &&
    stepConfiguration.args?.blend?.radius
  );
}

export function isInvalidOffsetMoveArmStep(
  step: Step.ConvertedSummary,
  stepConfiguration: Step.ConvertedConfiguration | undefined,
): boolean {
  if (!stepConfiguration || stepConfiguration.stepKind !== 'MoveArmTo') {
    return false;
  }

  const hasAddOffsetParentStep = step.parentSteps.some(
    (parentStep) => parentStep?.stepKind === 'AddOffset',
  );

  return (
    hasAddOffsetParentStep &&
    (stepConfiguration.args?.shouldMatchJointAngles ?? false)
  );
}

export function isInvalidRunInBackgroundStep(
  step: Step.ConvertedSummary,
): boolean {
  if (step.stepKind !== 'RunInBackground') {
    return false;
  }

  const INVALID_CHILD_KINDS: Step.Kind[] = [
    'AddOffset',
    'MoveArmTo',
    'PressButton',
    'WaitForConfirmation',
  ];

  const hasInvalidChild = (parentStep: Step.ConvertedSummary): boolean => {
    return parentStep.steps.some(
      (childStep) =>
        INVALID_CHILD_KINDS.includes(childStep.stepKind) ||
        hasInvalidChild(childStep),
    );
  };

  return hasInvalidChild(step);
}
