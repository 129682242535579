interface Args {
  isSlowMode: boolean;
  isSpeedLimit: boolean;
  defaultValue: number;
  collaborativeValue: number;
  fullValue: number | null;
  slowValue: number | null;
}

/**
 * Calculate a speed or acceleration limit
 * There is some complexity around the default value
 * in 'slow speed mode' which this function encapsulates
 */
export function calculateSpeedProfileLimitValue({
  isSlowMode,
  isSpeedLimit,
  defaultValue,
  collaborativeValue,
  fullValue,
  slowValue,
}: Args): number {
  if (isSlowMode) {
    // if a slow limit is defined then use it
    if (slowValue !== null) {
      return slowValue;
    }

    // else, for a speed limit, then use the smaller of
    // the collaborative speed and half the full limit
    if (isSpeedLimit) {
      return Math.min(collaborativeValue, (fullValue ?? defaultValue) / 2);
    }

    return Math.min(collaborativeValue, fullValue ?? defaultValue);
  }

  // else, use the full limit (or default if no full value defined)
  return fullValue ?? defaultValue;
}
