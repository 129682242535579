import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function BoxIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        d="M7.11005 0.219534C7.25082 0.149153 7.4165 0.149153 7.55726 0.219534L15.5573 4.21953C15.7267 4.30423 15.8337 4.47736 15.8337 4.66675V11.3334C15.8337 11.509 15.7415 11.6718 15.5909 11.7622L8.92424 15.7622C8.77765 15.8501 8.59629 15.8571 8.44339 15.7806L0.443385 11.7806C0.273993 11.6959 0.166992 11.5228 0.166992 11.3334V4.00008C0.166992 3.8107 0.273993 3.63756 0.443385 3.55287L4.10486 1.72213C4.10808 1.72048 4.11131 1.71886 4.11456 1.71728L7.11005 0.219534ZM4.33366 2.72576L1.78503 4.00008L8.66699 7.44106L11.2156 6.16675L4.33366 2.72576ZM12.3337 5.60773L5.45169 2.16675L7.33366 1.22576L14.2156 4.66675L12.3337 5.60773ZM12.5528 6.61621C12.556 6.61463 12.5592 6.61302 12.5624 6.61137L14.8337 5.47577V11.0503L9.16699 14.4503L9.16699 8.3091L12.5528 6.61621ZM8.16699 8.3091L1.16699 4.8091V11.0244L8.16699 14.5244L8.16699 8.3091Z"
      />
    </Icon>
  );
}
