import cx from 'classnames';
import { useCallback, useRef, useState } from 'react';

import type { SelectAndInputSizeVariant } from '@sb/ui';
import { SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT } from '@sb/ui';
import { useClickOutside } from '@sb/ui/hooks';
import { ArrowDownFilledIcon, ClearIcon, SearchIcon } from '@sb/ui/icons';

import Typography from '../Typography';

import styles from './Select.module.css';

type LabelPosition = 'left' | 'top' | 'right';

export interface SelectProps {
  activeLabel?: React.ReactNode | null;
  children: React.ReactNode;
  className?: string;
  dropdownClassName?: string;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  isMultiple?: boolean;
  isMultipleSelected?: boolean;
  label?: React.ReactNode;
  labelPosition?: LabelPosition;
  placeholder?: string;
  sizeVariant?: SelectAndInputSizeVariant;
  onFilter?: (filter: string) => void;
  onReset?: () => void;
  autoFocus?: boolean;
}

export function Select({
  activeLabel,
  children,
  className,
  dropdownClassName,
  isDisabled,
  isHighlighted,
  isMultiple,
  isMultipleSelected,
  label,
  labelPosition = 'top',
  placeholder,
  sizeVariant = SELECT_AND_INPUT_DEFAULT_SIZE_VARIANT,
  onFilter,
  onReset,
  autoFocus,
}: SelectProps) {
  const selectRef = useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const closeSelect = useCallback(() => setOpen(false), []);
  useClickOutside([selectRef], closeSelect);

  return (
    <div
      ref={selectRef}
      className={cx(
        styles.select,
        styles[`${labelPosition}Label`],
        styles[sizeVariant],
        className,
        {
          [styles.open]: isOpen,
          [styles.closed]: !isOpen,
          [styles.hasSearch]: onFilter,
        },
      )}
    >
      {label && (
        <Typography className={styles.label} component="label">
          {label}
        </Typography>
      )}

      <div className={cx(styles.content, dropdownClassName)}>
        <button
          className={cx(styles.button, {
            [styles.highlighted]: isHighlighted,
            [styles.placeholder]: !activeLabel,
          })}
          disabled={isDisabled}
          onClick={() => setOpen(!isOpen)}
          type="button"
          autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
        >
          <div
            className={cx(styles.activeLabel, {
              [styles.multipleSelected]: isMultipleSelected,
            })}
          >
            {activeLabel || placeholder}
          </div>

          {onReset && !isDisabled && (
            <ClearIcon
              className={styles.clearIcon}
              onClick={(e) => {
                e.stopPropagation();
                onReset();
              }}
            />
          )}

          <ArrowDownFilledIcon className={styles.arrowDown} size="extraSmall" />
        </button>

        <div className={styles.expanded}>
          {isOpen && (
            <>
              {onFilter && (
                <div className={styles.search}>
                  <input
                    className={styles.filter}
                    onChange={(e) => onFilter(e.target.value)}
                    placeholder="Search..."
                  />
                  <SearchIcon className={styles.searchIcon} />
                </div>
              )}

              <ul
                className={styles.list}
                onClick={isMultiple ? undefined : closeSelect}
              >
                {children}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Select;
