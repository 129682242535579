import { JOINT_NUMBERS } from '@sb/motion-planning';
import { SafeguardPair } from '@sb/routine-runner';
import type { SafetySettings } from '@sb/types';
import { createNullSafetySettings } from '@sbrc/utils';

import { asPaginated } from '../utils';

import { getSafetySettingsService } from './utils';

const ARRAY_FIELD_IDS = [
  'maxJointSpeeds',
  'maxJointAccelerations',
  'maxJointTorques',
  'slowJointSpeeds',
  'slowJointAccelerations',
  'slowJointTorques',
  'collisionThresholds',
  'accelerationThreshold',
] as const;

export async function getSafetySettings(
  robotID: string,
): Promise<SafetySettings.ConvertedResponse> {
  const response = await getSafetySettingsService().find({
    query: { robotID, $limit: 1 },
  });

  const dbSettings = asPaginated(response).data[0];

  const settings = createNullSafetySettings();

  if (dbSettings) {
    for (const fieldID of ARRAY_FIELD_IDS) {
      for (const j of JOINT_NUMBERS) {
        settings[fieldID][j] = dbSettings[fieldID][j] ?? null;
      }
    }

    settings.factoryPreset = dbSettings.factoryPreset;

    settings.maxTooltipSpeed = dbSettings.maxTooltipSpeed;

    settings.slowTooltipSpeed = dbSettings.slowTooltipSpeed;

    settings.safeguardRules = dbSettings.safeguardRules;

    settings.updatedAt = new Date(dbSettings.updatedAt);
  }

  // ensure a safeguard rule exists for every pair
  settings.safeguardRules = SafeguardPair.options.map((pair) => {
    return (
      settings.safeguardRules.find((r) => r.pair === pair) ?? {
        pair,
        kind: 'none',
        isAutoReset: false,
      }
    );
  });

  return settings;
}
