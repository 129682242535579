import cx from 'classnames';

import { ArrowBackIcon } from '@sb/ui/icons';

import IconButton from './IconButton';

import styles from './CardReturn.module.css';

interface CardReturnProps {
  children?: React.ReactNode;
  className?: string;
  onReturn: () => void;
}

/**
 * Display a return button for a `Card` component.
 */
const CardReturn = ({ children, className, onReturn }: CardReturnProps) => {
  return (
    <div className={cx(styles.cardReturn, className)}>
      <IconButton onClick={onReturn}>
        <ArrowBackIcon size="small" />
      </IconButton>
      {children}
    </div>
  );
};

export default CardReturn;
