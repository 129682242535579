import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function MoveArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path d="M7.063 1.313a.5.5 0 0 1 .815-.388h0l7 5.688a.5.5 0 0 1 0 .776h0l-7 5.687a.5.5 0 0 1-.815-.388h0v-3h-3a.5.5 0 0 1-.492-.41h0l-.008-.09V4.813a.5.5 0 0 1 .5-.5h0 3zm1 1.051v2.449a.5.5 0 0 1-.5.5h0-3v3.375h3a.5.5 0 0 1 .492.41h0l.008.09v2.448L13.769 7 8.063 2.364zM1.438 4.313a.5.5 0 0 1 .492.41l.008.09v4.375a.5.5 0 0 1-.992.09l-.008-.09V4.813a.5.5 0 0 1 .5-.5z" />
    </Icon>
  );
}
