import type { DeviceCommand, DeviceState } from '@sb/integrations/device';
import { ModbusFunctionCode } from '@sb/integrations/modbus/constants';
import { handleModbusRegisterRequest } from '@sb/integrations/modbus/utility';
import {
  COMMAND_TORQUE_NM_DEFAULT,
  COMMAND_SHANK_POSITION_MM_DEFAULT,
  COMMAND_FORCE_N_DEFAULT,
} from '@sb/integrations/OnRobotScrewdriver/constants';
import type {
  DeviceSimulator,
  SimulatorConstructorArgs,
} from '@sb/integrations/simulator';
import type {
  AbstractAnimation,
  CallbackPropsType,
} from '@sb/simulator/animation';
import { Animation } from '@sb/simulator/animation';

import { OnRobotScrewdriverCommand } from '../Command';
import {
  MM_TO_UM,
  mNM_TO_NM,
  NM_TO_mNM,
  Register,
  SCREWDRIVER_CONTROL_COMMAND,
  UM_TO_MM,
} from '../implementation/constants';
import type { OnRobotScrewdriverState } from '../State';

const distanceTolerance = 0.0001;

const getCommandKindFromName = (commandName: string): number => {
  if (commandName in SCREWDRIVER_CONTROL_COMMAND) {
    return SCREWDRIVER_CONTROL_COMMAND[
      commandName as keyof typeof SCREWDRIVER_CONTROL_COMMAND
    ];
  }

  throw new Error(`Unknown command name: ${commandName}`);
};

export class OnRobotScrewdriverSimulator
  implements DeviceSimulator<OnRobotScrewdriverState>
{
  public kind = 'OnRobotScrewdriver' as const;

  private animator: AbstractAnimation<void>;

  public handleModbusRegisterRequest = handleModbusRegisterRequest(
    () => this.state.isConnected,
    {
      [Register.SHANK_POSITION]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.writeRegisters.zPosition = data;

          return { ...request, data };
        },
      },
      [Register.Z_FORCE]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.writeRegisters.zForce = data;

          return { ...request, data };
        },
      },
      [Register.SCREW_LENGTH]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.writeRegisters.screwLength = data * UM_TO_MM;

          return { ...request, data };
        },
      },
      [Register.TARGET_TORQUE]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.writeRegisters.targetTorque = data * mNM_TO_NM;

          return { ...request, data };
        },
      },
      [Register.COMMAND]: {
        [ModbusFunctionCode.Write]: (request) => {
          const { data } = request;
          this.handleCommand(data);

          return { ...request, data };
        },
      },
      [Register.STATUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return { ...request, data: this.handleStatus() };
        },
      },
      [Register.CURRENT_TORQUE]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            data: Math.round(this.state.currentTorque * NM_TO_mNM),
          };
        },
      },
      [Register.SHANK_Z_POSITION]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            data: Math.round(this.state.shankPosition * MM_TO_UM),
          };
        },
      },
      [Register.TORQUE_ANGLE_GRADIENT]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            data: Math.round(this.state.torqueAngleGradient * NM_TO_mNM),
          };
        },
      },
      [Register.ACHIEVED_TORQUE]: {
        [ModbusFunctionCode.Read]: (request) => {
          return {
            ...request,
            data: Math.round(this.state.achievedTorque * NM_TO_mNM),
          };
        },
      },
      [Register.ADDITIONAL_RESULTS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return { ...request, data: this.state.additionalResults };
        },
      },
      [Register.QUICK_CHANGER_VERSION]: {
        [ModbusFunctionCode.Read]: (request) => {
          return { ...request, data: this.state.quickChangerVersion ?? 0 };
        },
      },
    },
  );

  public getModbusAddressSubscriptions() {
    return new Set([0x41]);
  }

  public constructor({ animator, configuration }: SimulatorConstructorArgs) {
    if (configuration.kind !== 'OnRobotScrewdriver') {
      throw new Error(
        `Unexpected kind for simulated Screwdriver. Got: ${configuration.kind}, expecting: "OnRobotScrewdriver"`,
      );
    }

    this.animator = animator || new Animation<void>(50);
    this.animator.setCallback(this.animate.bind(this));
  }

  private state: OnRobotScrewdriverState = {
    kind: 'OnRobotScrewdriver',
    isConnected: true,
    isBusy: false,
    error: undefined,
    status: 0,
    additionalResults: 0,
    currentTorque: 0,

    targetTorque: COMMAND_TORQUE_NM_DEFAULT,
    shankPosition: COMMAND_SHANK_POSITION_MM_DEFAULT,
    torqueAngleGradient: 0,
    achievedTorque: COMMAND_TORQUE_NM_DEFAULT,
    targetForce: COMMAND_FORCE_N_DEFAULT,

    quickChangerVersion: undefined,
    uncalibratedError: false,
  };

  private writeRegisters = {
    zForce: COMMAND_FORCE_N_DEFAULT,
    zPosition: COMMAND_SHANK_POSITION_MM_DEFAULT,
    screwLength: 0,
    targetTorque: COMMAND_TORQUE_NM_DEFAULT,
    command: SCREWDRIVER_CONTROL_COMMAND.STOP,
  };

  private shouldUpdateShankPosition = false;

  private handleCommand(command: number): void {
    this.writeRegisters.command = command;

    switch (command) {
      case SCREWDRIVER_CONTROL_COMMAND.TIGHTEN:
      case SCREWDRIVER_CONTROL_COMMAND.PICKUP:
      case SCREWDRIVER_CONTROL_COMMAND.LOOSEN:
        this.state.isBusy = true;
        this.shouldUpdateShankPosition = true;

        this.animator.start();

        return;
      case SCREWDRIVER_CONTROL_COMMAND.STOP:
        this.stop();

        return;
      default:
        throw new Error(`Unknown command for OnRobotScrewdriver: ${command}`);
    }
  }

  private handleStatus(): number {
    return this.state.isBusy ? 1 : 0;
  }

  public updateConfig() {}

  public getState(): OnRobotScrewdriverState {
    return this.state;
  }

  public setState(newState: Partial<DeviceState>) {
    if (newState.kind !== 'OnRobotScrewdriver') {
      throw new Error(
        `Unexpected kind for updated screwdriver state kind. Got: ${newState.kind}, expecting: "OnRobotScrewdriver"`,
      );
    }

    const {
      currentTorque,
      targetTorque,
      shankPosition,
      torqueAngleGradient,
      achievedTorque,
      targetForce,
      isConnected,
    } = newState;

    if (isConnected !== undefined) this.state.isConnected = isConnected;
    if (currentTorque) this.state.currentTorque = currentTorque;
    if (targetTorque) this.state.targetTorque = targetTorque;
    if (torqueAngleGradient)
      this.state.torqueAngleGradient = torqueAngleGradient;
    if (achievedTorque) this.state.achievedTorque = achievedTorque;
    if (targetForce) this.state.targetForce = targetForce;
    if (shankPosition) this.state.shankPosition = shankPosition;
  }

  public async actuate(command: DeviceCommand) {
    const {
      targetForce,
      targetTorque,
      screwLength,
      commandKind,
      shankPosition,
    } = OnRobotScrewdriverCommand.parse(command);

    if (screwLength) {
      this.writeRegisters.screwLength = screwLength;
    }

    if (targetForce) {
      this.writeRegisters.zForce = targetForce;
    }

    if (shankPosition) {
      this.writeRegisters.zPosition = shankPosition;
    }

    if (targetTorque) {
      this.writeRegisters.targetTorque = targetTorque;
    }

    this.handleCommand(getCommandKindFromName(commandKind));

    return Promise.resolve();
  }

  // assume 55mm in 1 sec
  private animate(props: CallbackPropsType<void>) {
    const secondFraction = props.timeSinceLastFrame / 1000.0;

    const screwDisplacements = {
      [SCREWDRIVER_CONTROL_COMMAND.TIGHTEN]: this.writeRegisters.screwLength,
      [SCREWDRIVER_CONTROL_COMMAND.LOOSEN]: -this.writeRegisters.screwLength,
      [SCREWDRIVER_CONTROL_COMMAND.PICKUP]: 0,
      [SCREWDRIVER_CONTROL_COMMAND.STOP]: 0,
    };

    const targetPosition =
      this.writeRegisters.zPosition +
      screwDisplacements[this.writeRegisters.command];

    if (this.shouldUpdateShankPosition) {
      const direction = targetPosition > this.state.shankPosition ? 1 : -1;

      const remaining = Math.abs(targetPosition - this.state.shankPosition);

      if (remaining > distanceTolerance) {
        const delta = Math.min(secondFraction * 55, remaining);

        this.state.shankPosition += direction * delta;

        return;
      }
    }

    this.shouldUpdateShankPosition = false;

    this.state.achievedTorque = this.writeRegisters.targetTorque;
    this.state.isBusy = false;
    this.animator.stop();
  }

  public stop() {
    this.animator.stop();
    this.state.isBusy = false;
  }
}
