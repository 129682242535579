import type { Routine, Unsubscribe } from '@sb/types';

import { mapPaginated, retryOnTimeout } from '../utils';

import { convertRoutineResponse, getRoutinesService } from './utils';

export function onGetRoutineTemplates(
  onSnapshot: (routines: Routine.ConvertedResponse[]) => void,
): Unsubscribe {
  const subscription = getRoutinesService()
    .watch()
    .find({
      query: {
        isTemplate: true,
        $sort: { name: 1 },
      },
    })
    .pipe(retryOnTimeout())
    .subscribe((response) => {
      onSnapshot(mapPaginated(response, convertRoutineResponse));
    });

  return () => subscription.unsubscribe();
}
