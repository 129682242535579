import type { DeviceCommand, DeviceState } from '@sb/integrations/device';
import { handleModbusRegisterRequest } from '@sb/integrations/modbus/utility';
import type {
  DeviceSimulator,
  SimulatorConstructorArgs,
} from '@sb/integrations/simulator';
import {
  setBit,
  OFF,
  ON,
  getBit,
  wait,
  createClock,
  roundToDecimalPlaces,
} from '@sb/utilities';

import { ModbusFunctionCode } from '../../modbus/constants';
import type { ModbusRegisterRequest, ModbusResponse } from '../../modbus/types';
import {
  Registers,
  ChannelCommandBits,
  StatusBits,
  channelKeys,
  CommandRegisters,
  VacuumPercentRegisters,
  OR_VGP20_TARGET_ADDRESS,
} from '../implementation/constants';
import { OnRobotVGP20Command } from '../types';
import type { OnRobotVGP20State } from '../types';

export class OnRobotVGP20Simulator
  implements DeviceSimulator<OnRobotVGP20State>
{
  public kind = 'OnRobotVGP20' as const;

  private state: OnRobotVGP20State = {
    kind: 'OnRobotVGP20',
    error: undefined,
    isConnected: true,
    isBusy: false,
    currentAction: undefined,
    suctionForcePercentage: {
      one: 0,
      two: 0,
      three: 0,
      four: 0,
    },
  };

  private targetSuction = { ...this.state.suctionForcePercentage };

  public constructor({ configuration }: SimulatorConstructorArgs) {
    if (configuration.kind !== 'OnRobotVGP20') {
      throw new Error(
        `Unexpected configuration. Got: ${configuration.kind}, expecting: "OnRobotVGP20"`,
      );
    }
  }

  public updateConfig() {}

  public getState(): OnRobotVGP20State {
    return this.state;
  }

  public setState(newState: Partial<DeviceState>) {
    if (newState.kind !== 'OnRobotVGP20') {
      throw new Error(
        `Unexpected kind for updated OnRobotVGP20 state kind. Got: ${newState.kind}, expecting: "OnRobotVGP20"`,
      );
    }

    this.state = { ...this.state, ...newState };
  }

  public getModbusAddressSubscriptions() {
    return new Set([OR_VGP20_TARGET_ADDRESS]);
  }

  private handleModbusChannelCommand(
    request: ModbusRegisterRequest,
  ): ModbusResponse {
    const { registerAddress, data } = request;

    // actuate or release - IGNORE_BIT will be ON if ignored
    if (getBit(data, ChannelCommandBits.IGNORE_BIT) === OFF) {
      const forceBits = setBit(data, ChannelCommandBits.REQUIRE_BIT, OFF);
      const force = roundToDecimalPlaces(forceBits / 100, 2);

      for (const key of channelKeys) {
        if (registerAddress === CommandRegisters[key]) {
          this.targetSuction[key] = force;
        }
      }

      this.animate();
    }

    return {
      ...request,
      functionCode: ModbusFunctionCode.Write,
    };
  }

  private handleModbusChannelSuctionPercentRequest(
    request: ModbusRegisterRequest,
  ): ModbusResponse {
    const { registerAddress } = request;

    for (const key of channelKeys) {
      if (registerAddress === VacuumPercentRegisters[key]) {
        return {
          ...request,
          functionCode: ModbusFunctionCode.Read,
          data: Math.round(this.state.suctionForcePercentage[key] * 100),
        };
      }
    }

    throw new Error(`Unknown channel register address ${registerAddress}`);
  }

  private handleModbusChannelStatus(
    request: ModbusRegisterRequest,
  ): ModbusResponse {
    let bits = 0;
    let offset = 0;

    for (const key of channelKeys) {
      const suctionPct = this.state.suctionForcePercentage[key];

      if (suctionPct > 0) {
        bits = setBit(bits, offset, this.state.isBusy ? OFF : ON);
      } else {
        bits = setBit(bits, offset + 2, this.state.isBusy ? OFF : ON);
      }

      offset += 4;
      // TODO - consider simulating error - might just grab target suction
    }

    return {
      ...request,
      functionCode: ModbusFunctionCode.Read,
      data: bits,
    };
  }

  private handleModbusStatus(request: ModbusRegisterRequest): ModbusResponse {
    const bits = setBit(0, StatusBits.BUSY, this.state.isBusy ? ON : OFF);

    return {
      ...request,
      data: bits,
      functionCode: ModbusFunctionCode.Read,
    };
  }

  public handleModbusRegisterRequest = handleModbusRegisterRequest(
    () => this.state.isConnected,
    {
      // WRITE
      [Registers.CONTROL_A]: {
        [ModbusFunctionCode.Write]: (request) => {
          return this.handleModbusChannelCommand(request);
        },
      },
      [Registers.CONTROL_B]: {
        [ModbusFunctionCode.Write]: (request) => {
          return this.handleModbusChannelCommand(request);
        },
      },
      [Registers.CONTROL_C]: {
        [ModbusFunctionCode.Write]: (request) => {
          return this.handleModbusChannelCommand(request);
        },
      },
      [Registers.CONTROL_D]: {
        [ModbusFunctionCode.Write]: (request) => {
          return this.handleModbusChannelCommand(request);
        },
      },
      // READ
      [Registers.STATUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusStatus(request);
        },
      },
      [Registers.CHANNEL_STATUS]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusChannelStatus(request);
        },
      },
      [Registers.VACUUM_A_PERCENT]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusChannelSuctionPercentRequest(request);
        },
      },
      [Registers.VACUUM_B_PERCENT]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusChannelSuctionPercentRequest(request);
        },
      },
      [Registers.VACUUM_C_PERCENT]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusChannelSuctionPercentRequest(request);
        },
      },
      [Registers.VACUUM_D_PERCENT]: {
        [ModbusFunctionCode.Read]: (request) => {
          return this.handleModbusChannelSuctionPercentRequest(request);
        },
      },
    },
  );

  public actuate(command: DeviceCommand): Promise<void> {
    const parsedCommand = OnRobotVGP20Command.parse(command);

    for (const key of channelKeys) {
      const action = parsedCommand.suctionActions[key];

      if (action.commandKind !== 'IDLE') {
        this.targetSuction[key] = action.suctionPercentage;
      }
    }

    return this.animate();
  }

  public stop() {}

  private currentAnimation = 0;

  private async animate() {
    this.currentAnimation += 1;
    const thisAnimation = this.currentAnimation;

    this.state.isBusy = true;

    const clock = createClock();

    let complete = false;

    await wait(20);

    while (this.currentAnimation === thisAnimation && !complete) {
      const timeSinceLastFrame = clock.tick();

      complete = true;

      for (const key of channelKeys) {
        const diff =
          this.targetSuction[key] - this.state.suctionForcePercentage[key];

        const absDiff = Math.abs(diff);
        const direction = diff > 0 ? 1 : -1;

        if (diff !== 0) {
          complete = false;

          // 50% per second
          const increment =
            Math.min(absDiff, timeSinceLastFrame * 0.0005) * direction;

          this.state.suctionForcePercentage[key] = roundToDecimalPlaces(
            this.state.suctionForcePercentage[key] + increment,
            2,
          );
        }
      }

      await wait(50);
    }

    if (this.currentAnimation === thisAnimation) {
      this.state.isBusy = false;
    }
  }
}
