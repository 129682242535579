import type { CartesianPose } from '@sb/geometry';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getTooltipState(
  state: RoutineRunnerState | null,
): CartesianPose | null {
  return state ? state.kinematicState.tooltipPoint : null;
}

const EPSILON = 0.0001;

const POSE_PROPS: Array<keyof CartesianPose> = [
  'i',
  'j',
  'k',
  'w',
  'x',
  'y',
  'z',
];

function areTooltipStatesEqual(
  stateA: CartesianPose | null,
  stateB: CartesianPose | null,
): boolean {
  if (!stateA && !stateB) {
    return true;
  }

  if (!stateA || !stateB) {
    return false;
  }

  return POSE_PROPS.every(
    (prop) => Math.abs(stateA[prop] - stateB[prop]) < EPSILON,
  );
}

export default function useRobotTooltipState(
  args: UseRoutineRunnerHandleArguments,
): CartesianPose | null {
  return useRobotState(args, getTooltipState, areTooltipStatesEqual);
}
