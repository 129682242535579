import cx from 'classnames';

import styles from './CardCover.module.css';

interface CardCoverProps {
  children?: React.ReactNode;
  className?: string;
  src: string;
}

const CardCover = ({ children, className, src }: CardCoverProps) => {
  return (
    <div
      className={cx(styles.cardCover, className)}
      style={{ backgroundImage: `url(${src})` }}
    >
      {children}
    </div>
  );
};

export default CardCover;
