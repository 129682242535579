import type { ColorVariant } from '../variants';

import styles from './textColor.module.css';

export const strokeColor: Record<ColorVariant, string> = {
  primary: styles.primary,
  primaryDark: styles.primaryDark,
  secondary: styles.secondary,
  secondaryDark: styles.secondaryDark,
  alertPrimary: styles.alertPrimary,
  alertPrimaryDark: styles.alertPrimaryDark,
  alertSecondary: styles.alertSecondary,
  alertSecondaryDark: styles.alertSecondaryDark,
  warning: styles.warning,
  success: styles.success,
  neutral: styles.neutral,
  neutralDark: styles.neutralDark,
  white: styles.white,
  black: styles.black,
  gray: styles.gray,
};
