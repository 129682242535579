import Typography from './Typography';

interface AlertProps {
  children: React.ReactNode;
  className?: string;
  isBold?: boolean;
}

const TRUNCATE_LINE_DEFAULT = 3;

const AlertTitle = ({ children, className, isBold }: AlertProps) => {
  return (
    <Typography
      className={className}
      color="black"
      truncateMultiline={TRUNCATE_LINE_DEFAULT}
      textAlign="center"
      variant="large"
      isBold={isBold}
    >
      {children}
    </Typography>
  );
};

export default AlertTitle;
