import type { CartesianPosition } from '@sb/geometry/CartesianPosition';
import { convertPositionToVector3 as toVector } from '@sb/geometry/CartesianPosition';

function getKnotInterval(
  ti: number,
  pi: CartesianPosition,
  pj: CartesianPosition,
  alpha: number,
): number {
  const dx = pj.x - pi.x;
  const dy = pj.y - pi.y;
  const dz = pj.z - pi.z;

  const l = (dx ** 2 + dy ** 2 + dz ** 2) ** 0.5;

  return ti + l ** alpha;
}

function* getIntervalTimes(
  start: number,
  stop: number,
  numPoints: number,
  includeStart: boolean = true,
) {
  const diff = (stop - start) / (numPoints - 1);

  if (includeStart) {
    yield start;
  }

  for (let i = 1; i < numPoints; i += 1) {
    yield start + diff * i;
  }
}

/**
 * Interpolate a number of points along a Catmull-Rom spline from points
 * p1 to p2.
 * @param p0
 * @param p1
 * @param p2
 * @param p3
 * @param numPoints
 * @param alpha
 * @param includeStart
 */
export function* interpolateCatmullRomSpline(
  p0: CartesianPosition,
  p1: CartesianPosition,
  p2: CartesianPosition,
  p3: CartesianPosition,
  numPoints: number,
  includeStart: boolean = true,
  alpha: number = 0.5,
) {
  const v0 = toVector(p0);
  const v1 = toVector(p1);
  const v2 = toVector(p2);
  const v3 = toVector(p3);

  if (v1.distanceTo(v2) === 0) {
    if (includeStart) {
      yield p0;
    }

    yield p1;

    return;
  }

  const time0: number = 0.0;
  const t1: number = getKnotInterval(time0, p0, p1, alpha);
  const t2: number = getKnotInterval(t1, p1, p2, alpha);
  const t3: number = getKnotInterval(t2, p2, p3, alpha);

  for (const time of getIntervalTimes(t1, t2, numPoints, includeStart)) {
    const a1 = v0
      .clone()
      .multiplyScalar((t1 - time) / (t1 - time0))
      .add(v1.clone().multiplyScalar((time - time0) / (t1 - time0)));

    const a2 = v1
      .clone()
      .multiplyScalar((t2 - time) / (t2 - t1))
      .add(v2.clone().multiplyScalar((time - t1) / (t2 - t1)));

    const a3 = v2
      .clone()
      .multiplyScalar((t3 - time) / (t3 - t2))
      .add(v3.clone().multiplyScalar((time - t2) / (t3 - t2)));

    const b1 = a1
      .clone()
      .multiplyScalar((t2 - time) / (t2 - time0))
      .add(a2.clone().multiplyScalar((time - time0) / (t2 - time0)));

    const b2 = a2
      .clone()
      .multiplyScalar((t3 - time) / (t3 - t1))
      .add(a3.clone().multiplyScalar((time - t1) / (t3 - t1)));

    yield b1
      .clone()
      .multiplyScalar((t2 - time) / (t2 - t1))
      .add(b2.clone().multiplyScalar((time - t1) / (t2 - t1)));
  }
}
