import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function RotateRightIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M12.07 11.12c.465 0 .82.153 1.066.46s.372.743.378 1.315v.785c0 .59-.122 1.041-.366 1.354s-.601.469-1.072.469c-.465 0-.82-.153-1.066-.46s-.372-.746-.378-1.318v-.785c0-.596.123-1.048.369-1.356s.604-.463 1.069-.463zm-3.228 0c.281 0 .531.07.75.211s.389.343.51.607.183.565.185.905v.313c0 .711-.177 1.27-.53 1.676s-.854.623-1.503.65l-.208.003v-.706l.188-.003c.736-.033 1.135-.379 1.195-1.037a1.07 1.07 0 0 1-.773.325c-.377 0-.679-.129-.905-.387s-.34-.609-.34-1.049c0-.279.061-.535.182-.768a1.34 1.34 0 0 1 .513-.545 1.41 1.41 0 0 1 .738-.196zm3.228.686c-.199 0-.347.077-.442.232s-.143.392-.149.718v1.037c0 .348.047.606.141.776s.248.252.457.252.356-.081.448-.243.14-.41.144-.744v-1.014c0-.354-.049-.61-.146-.771s-.246-.243-.451-.243zM14 .5a.5.5 0 0 1 .492.41L14.5 1v5 .022l-.001.019h0l-.001.011-.008.051h0l-.012.045-.014.039-.021.045-.016.029-.027.039-.027.033h0l-.026.027-.037.032-.03.022-.043.026-.035.017-.022.009h0l-.014.004.033-.014-.036.015-.055.016h0l-.032.006-.048.005L14 6.5H9a.5.5 0 0 1-.09-.992L9 5.5l4.145.001C11.955 3.631 10.085 2.5 8 2.5A6.5 6.5 0 0 0 1.5 9a6.47 6.47 0 0 0 1.581 4.249.5.5 0 0 1-.756.654A7.47 7.47 0 0 1 .5 9 7.5 7.5 0 0 1 8 1.5c2.171 0 4.132 1.04 5.501 2.776L13.5 1a.5.5 0 0 1 .41-.492L14 .5zM8.837 11.803c-.17 0-.31.078-.419.234s-.164.349-.164.583.053.424.158.574.258.223.451.223c.137 0 .254-.035.352-.105a.7.7 0 0 0 .226-.255v-.349c0-.287-.055-.51-.164-.668a.51.51 0 0 0-.439-.237zm5.878-.683c.213 0 .396.079.551.237s.234.347.234.571-.078.414-.234.568-.338.229-.551.229-.396-.076-.557-.229-.24-.344-.24-.568a.78.78 0 0 1 .237-.571c.158-.158.345-.237.559-.237zm0 .434c-.104 0-.19.037-.261.111a.37.37 0 0 0-.106.264.35.35 0 0 0 .106.258.36.36 0 0 0 .261.105.34.34 0 0 0 .255-.103.36.36 0 0 0 .103-.261c0-.102-.034-.189-.103-.264s-.151-.111-.255-.111z" />
    </Icon>
  );
}
