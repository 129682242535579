import type { CartesianPose } from '@sb/geometry';
import type { DeviceCommand, DynamicBaseState } from '@sb/integrations/device';
import type { GripperState } from '@sb/integrations/gripper-general';
import type {
  ArmJointLimits,
  ArmJointPositions,
  ArmJointVelocities,
  FrameOfReference,
  MotionDirectionValidations,
  MotionKind,
} from '@sb/motion-planning';
import type { RecoveryType } from '@sb/routine-runner/RecoveryType';

import type { CameraState } from './CameraInterface';
import type { FailureDetails } from './FailureDetails';
import type {
  JointSafetyLimits,
  AccelerationCollisionThresholds,
} from './RobotInterface';
import type { Routine } from './Routine';
import type { SpeedProfile } from './speed-profile';
import type { TaggedRoutineStepVariables } from './Step';
import type {
  ExternalToRobotPort,
  IOLevel,
  PayloadState,
  RelayPort,
  RobotToExternalPort,
  SafeguardPair,
  SafetyPort,
  SafeguardRule,
  SafeguardState,
} from './types';

/**
 * Different kinds of RoutineRunnerState want to use the same variables, so this is a
 * common collection of Routine oriented state variables
 *
 * @category Routine Runner State Options
 */
export interface LoadedRoutineState extends Routine {
  hasRunGuided: boolean;
  startStepID: string;
}

/**
 * @category Ad-hoc Commands
 */
export type MoveToJointSpacePoint = {
  kind: 'MoveToJointSpacePoint';
  jointPositions: ArmJointPositions;
};

/**
 * @category Ad-hoc Commands
 */
export type MoveToCartesianSpacePose = {
  kind: 'MoveToCartesianSpacePose';
  pose: CartesianPose;
  motionKind: MotionKind;
};

/**
 * @category Ad-hoc Commands
 */
export type MoveToolRelative = {
  kind: 'MoveToolRelative';
  frame: FrameOfReference;
  offset: CartesianPose;
  maxTooltipVelocity: number;
};

/**
 * @category Ad-hoc Commands
 */
export type MoveJointRelative = {
  kind: 'MoveJointRelative';
  jointNumber: number;
  maxJointVelocity: number;
};

/**
 * @category Ad-hoc Commands
 */
export type ActuateGripper = {
  kind: 'ActuateGripper';
  /**
   * The target gripper state
   */
  gripperCommand: DeviceCommand;
};

/**
 * The routine runner is currently doing an ad-hoc command like moving to a single position
 * or jogging a joint.
 *
 * When these are active, not calling `validateGuidedMode()` frequently will result
 * in stopping and transitioning to previousState.
 *
 * @category Ad-hoc Commands
 */
export type AdHocCommand =
  | MoveToJointSpacePoint
  | MoveToCartesianSpacePose
  | MoveToolRelative
  | MoveJointRelative
  | ActuateGripper;

/**
 * This is when the robot is ready to command
 *
 * @category Routine Runner State Options
 */
export interface Idle {
  kind: 'Idle';
  /**
   * If specified, the routine should be started at or near this position (and it's currently
   * far enough away that the preflight check is failing)
   */

  validToolDirections: MotionDirectionValidations;

  // If this is populated, it explains ahead of time why `playRoutine` will fail
  cannotStartReasons: Array<RoutineRunningPauseKind>;
}

/**
 * @category Routine Runner State Options
 */
export interface RunningAdHocCommand {
  kind: 'RunningAdHocCommand';
  command: AdHocCommand;
  /**
   * Whether guided mode is being checked.
   */
  shouldCheckGuidedMode: boolean;
}

/**
 * @category Routine Runner State Options
 */
export interface Antigravity {
  kind: 'Antigravity';
}

export type RoutineRunningPauseKind =
  | 'botmanHeartbeatLost'
  | 'brakesEngaged'
  | 'collision'
  | 'eStopTriggered'
  | `ioPauseTriggered_${SafeguardPair}`
  | 'ioStateStale'
  | 'guidedModeInvalidation'
  | 'motionPlannerFailure'
  | 'preflightTestRunCompleted'
  | 'routineCompleted'
  | 'controlSystemEvent'
  | 'waitForConfirmation'
  | 'user';

export function humanReadablePauseKind(kind: RoutineRunningPauseKind): string {
  switch (kind) {
    case 'botmanHeartbeatLost':
      return 'Botman heartbeat lost';
    case 'brakesEngaged':
      return 'Brakes engaged';
    case 'collision':
      return 'Collision';
    case 'eStopTriggered':
      return 'E-Stop triggered';
    case 'guidedModeInvalidation':
      return 'Guided mode invalidation';
    case 'motionPlannerFailure':
      return 'Motion planner failure';
    case 'controlSystemEvent':
      return 'General control system event';
    case 'routineCompleted':
      return 'Routine completed';
    case 'waitForConfirmation':
      return 'Waiting for confirmation';
    case 'user':
      return 'User paused the routine';
    case 'ioPauseTriggered_DIGITAL_IN_1_2':
      return 'Digital input 1 or 2 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_3_4':
      return 'Digital input 3 or 4 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_5_6':
      return 'Digital input 5 or 6 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_7_8':
      return 'Digital input 7 or 8 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_9_10':
      return 'Digital input 9 or 10 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_11_12':
      return 'Digital input 11 or 12 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_13_14':
      return 'Digital input 13 or 14 triggered';
    case 'ioPauseTriggered_DIGITAL_IN_15_16':
      return 'Digital input 15 or 16 triggered';
    case 'ioStateStale':
      return 'Power board connection lost';
    default:
      return kind;
  }
}

export interface RoutineRunningPauseDetails {
  kind: RoutineRunningPauseKind;
  reason?: string;
  isAutoResume?: boolean;
}

/**
 * @category Routine Runner State Options
 */
export interface RoutineRunning {
  kind: 'RoutineRunning';
  shouldCheckGuidedMode: boolean;
  isPaused: boolean;
  pauseDetails: RoutineRunningPauseDetails[];
  currentStepID: string;
  variables: { [stepID: string]: TaggedRoutineStepVariables };
  startTime: string; // ISO-8601 date string
  runTimeSeconds: number; // Run time in seconds (does not include paused time)
  cycleCount?: number;
  totalExpectedCycles?: number;
  shouldNextArmMoveBeGuidedMode?: boolean;
  isPreflightTestRun: boolean;
}

/**
 * Describes the robot being in some critical failure.
 *
 * The user should get a persistent notification until it's cleared, and the robot
 * is considered inactive until it's reset.
 *
 * @category Routine Runner State Options
 */
export interface Failure {
  kind: 'Failure';

  failureTraceID?: string;

  failure: FailureDetails;
  /**
   * The failed step, if there is one.
   *
   * Not specified if the failure occurred during pre-flight or during an ad-hoc movement
   * (or just while idled, for some reason).
   */
  failedStep?: string;
  failureReason: string;
  /**
   * The type of recovery required if possible
   */
  recoveryType: RecoveryType;
}

/**
 * The routine runner is recovering from a failure
 *
 * @category Routine Runner State Options
 */
export type Recovering = {
  kind: 'Recovering';
  shouldCheckGuidedMode: boolean;
  // stashed failure state to put back if recovery stops in the middle
  failedState: Pick<
    Failure,
    'recoveryType' | 'failure' | 'failedStep' | 'failureReason'
  >;
};

/**
 * This is the state of the control system, common to all other states.
 * TODO: Rename to ControlSystemState/ControlState/ArmState?
 */
export interface KinematicState {
  jointAngles: ArmJointPositions;
  jointVelocities: ArmJointVelocities;
  tooltipPoint: CartesianPose;
  gripperState: GripperState;
  brakesEngaged: boolean;
  dynamicBaseState: DynamicBaseState | null;
  ioState: Record<
    ExternalToRobotPort | RobotToExternalPort | SafetyPort | RelayPort,
    IOLevel
  >;
  supportsSafeguardRules: boolean;
  safeguardState: SafeguardState;
  jointLimits: ArmJointLimits;
}

/**
 * This is the state of the configuration options as most recently set by
 * the user or routine.
 */
export interface ConfigurationState {
  payload: PayloadState;
  /**
   * Speed profile that limits routine motions (and no other motions)
   */
  routineSpeedProfile: SpeedProfile;
  /**
   * Joint limits that are applied to the firmware and control system
   */
  jointSafetyLimits: JointSafetyLimits;

  // Speed limits to use for antigravity
  antigravitySpeeds: ArmJointVelocities;

  // rules for how to treat IO ports in terms of possible safety equipment plugged into them
  // (e.g. "switch to slow when area scanner attached to inputs 3-4 goes low")
  safeguardRules: Array<SafeguardRule>;
  accelerationCollisionThresholds: AccelerationCollisionThresholds;
}

export type RoutineRunnerStateKind = RoutineRunnerState['kind'];

/**
 * The state that the user wants to know to show the UI
 */
export type RoutineRunnerState = (
  | Idle
  | RunningAdHocCommand
  | RoutineRunning
  | Antigravity
  | Failure
  | Recovering
) & {
  kinematicState: KinematicState;
  cameraState: CameraState;
  configuration: ConfigurationState;
  // a bounded array of events to give a timeline of any failures with
  // appropriate gaps to avoid memory leaks
};
