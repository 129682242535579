/* eslint-disable no-bitwise */

export const ON = 1;
export const OFF = 0;

export type Bit = typeof ON | typeof OFF;

export function setBit(val: number, position: number, bit: Bit) {
  const mask = 1 << position;

  if (bit) {
    return val | mask;
  }

  return val & ~mask;
}

export function toBit(high: boolean): Bit {
  return high ? ON : OFF;
}

export function getBit(val: number, position: number): Bit {
  const mask = 1 << position;

  return val & mask ? ON : OFF;
}

/**
 * Convert an array of bits (with the least-significant-bit at index 0) to a number
 */
export function bitsToNumber(bits: (Bit | boolean)[]): number {
  let result = 0;

  for (const [index, bit] of bits.entries()) {
    if (bit) {
      result = setBit(result, index, ON);
    }
  }

  return result;
}

// outputs least significant bit first
export function numberToBits(num: number, numberOfBits: number): Array<Bit> {
  const arr: Array<Bit> = [];

  for (let bit = 0; bit < numberOfBits; bit += 1) {
    if ((num & (1 << bit)) !== 0) {
      arr.push(ON);
    } else {
      arr.push(OFF);
    }
  }

  return arr;
}
