/**
 * State of gripper at the start of planner request
 */
import * as zod from 'zod';

import { OnRobot2FG7State } from '../OnRobot2FG7';
import { OnRobot3FG15State } from '../OnRobot3FG15';
import { OnRobotDualQuickChangerState } from '../OnRobotDualQuickChanger';
import { OnRobotScrewdriverState } from '../OnRobotScrewdriver';
import { OnRobotVGP20State } from '../OnRobotVGP20/types';

export const GripperStateNonNull = zod.union([
  OnRobot2FG7State,
  OnRobot3FG15State,
  OnRobotScrewdriverState,
  OnRobotDualQuickChangerState,
  OnRobotVGP20State,
]);

export const GripperState = GripperStateNonNull.nullable();

export type GripperStateNonNull = zod.infer<typeof GripperStateNonNull>;
export type GripperState = zod.infer<typeof GripperState>;
