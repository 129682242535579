import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function LockIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 14 16">
      <path d="M1.5 7C1.36739 7 1.24021 7.05268 1.14645 7.14645C1.05268 7.24022 1 7.36739 1 7.5V14.5C1 14.6326 1.05268 14.7598 1.14645 14.8536C1.24021 14.9473 1.36739 15 1.5 15H12.5C12.6326 15 12.7598 14.9473 12.8536 14.8536C12.9473 14.7598 13 14.6326 13 14.5V7.5C13 7.36739 12.9473 7.24022 12.8536 7.14645C12.7598 7.05268 12.6326 7 12.5 7H1.5ZM0.43934 6.43934C0.720644 6.15804 1.10218 6 1.5 6H12.5C12.8978 6 13.2794 6.15804 13.5607 6.43934C13.842 6.72064 14 7.10217 14 7.5V14.5C14 14.8978 13.842 15.2794 13.5607 15.5607C13.2794 15.842 12.8978 16 12.5 16H1.5C1.10218 16 0.720644 15.842 0.43934 15.5607C0.158035 15.2794 0 14.8978 0 14.5V7.5C0 7.10218 0.158035 6.72064 0.43934 6.43934Z" />
      <path d="M7 1C6.20435 1 5.44129 1.31607 4.87868 1.87868C4.31607 2.44129 4 3.20435 4 4V6.5C4 6.77614 3.77614 7 3.5 7C3.22386 7 3 6.77614 3 6.5V4C3 2.93913 3.42143 1.92172 4.17157 1.17157C4.92172 0.421427 5.93913 0 7 0C8.06087 0 9.07828 0.421427 9.82843 1.17157C10.5786 1.92172 11 2.93913 11 4V6.5C11 6.77614 10.7761 7 10.5 7C10.2239 7 10 6.77614 10 6.5V4C10 3.20435 9.68393 2.44129 9.12132 1.87868C8.55871 1.31607 7.79565 1 7 1Z" />
    </Icon>
  );
}
