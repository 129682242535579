import type { OnRobotVGP20Command, OnRobotVGP20State } from './types';

export function calculateORVGP20StateFromCommand(
  command: OnRobotVGP20Command,
): Partial<OnRobotVGP20State> {
  const state: Partial<OnRobotVGP20State> = {
    kind: 'OnRobotVGP20',
    suctionForcePercentage: {
      one: command.suctionActions.one.suctionPercentage,
      two: command.suctionActions.two.suctionPercentage,
      three: command.suctionActions.three.suctionPercentage,
      four: command.suctionActions.four.suctionPercentage,
    },
  };

  return state;
}
