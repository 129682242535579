import type { Routine } from '@sb/types';
import {
  MAX_ROUTINE_DESCRIPTION_LENGTH,
  MAX_ROUTINE_NAME_LENGTH,
  MIN_ROUTINE_NAME_LENGTH,
} from '@sbrc/utils';

import { asPaginated } from '../../utils';

import { getRoutinesService } from './getRoutinesService';

/**
 * An account cannot have more than one routine with the same name.
 * This functions rejects if a the routine name is duplicated
 */
export async function validateRoutine(
  routine: Partial<Routine.EditableFields>,
): Promise<void> {
  const { name: routineName, description } = routine;

  if (description !== undefined) {
    const excessLength = description.length - MAX_ROUTINE_DESCRIPTION_LENGTH;

    if (excessLength > 0) {
      throw new Error(
        `The routine description is ${excessLength} characters too long.`,
      );
    }
  }

  if (routineName !== undefined) {
    const excessLength = routineName.length - MAX_ROUTINE_NAME_LENGTH;

    if (excessLength > 0) {
      throw new Error(
        `The routine name is ${excessLength} characters too long.`,
      );
    }

    if (routineName.length < MIN_ROUTINE_NAME_LENGTH) {
      throw new Error(
        `The routine name must be at least ${MIN_ROUTINE_NAME_LENGTH} character long.`,
      );
    }

    const routines = await getRoutinesService().find({
      query: { name: routineName, $limit: 1 },
    });

    if (asPaginated(routines).total > 0) {
      throw new Error(
        "There's an existing routine with this name. Please choose a different name.",
      );
    }
  }
}
