import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ReferenceIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8 3C8.27614 3 8.5 3.22386 8.5 3.5V15.5C8.5 15.7761 8.27614 16 8 16C7.72386 16 7.5 15.7761 7.5 15.5V3.5C7.5 3.22386 7.72386 3 8 3Z" />
      <path d="M0.724754 0.0825812C0.865068 -0.00994065 1.04248 -0.0257774 1.19696 0.0404292L8 2.95602L14.803 0.0404292C14.9575 -0.0257774 15.1349 -0.00994065 15.2752 0.0825812C15.4156 0.175103 15.5 0.33193 15.5 0.500002V12.5C15.5 12.7 15.3808 12.8808 15.197 12.9596L8.19696 15.9596C8.07119 16.0135 7.92881 16.0135 7.80304 15.9596L0.80304 12.9596C0.619198 12.8808 0.5 12.7 0.5 12.5V0.500002C0.5 0.33193 0.584441 0.175103 0.724754 0.0825812ZM1.5 1.25827V12.1703L8 14.956L14.5 12.1703V1.25827L8.19696 3.95957C8.07119 4.01348 7.92881 4.01348 7.80304 3.95957L1.5 1.25827Z" />
    </Icon>
  );
}
