import type { RoutineVariable, Step } from '@sb/types';

import { VARIABLE_INFO_LIST_BY_STEP_KIND } from './constants';
import { listAllSteps } from './listAllSteps';

/**
 * This function receives a list of steps from a routine as the input and it
 * returns a list of variables that can be used to configure a robot.
 *
 * Those variables are represented by the current state of another step. For
 * example, we could create an "if" conditional using the state of a
 * "Move Arm to Position" step doing something like this (pseudo code):
 *
 * if (MoveArmToPosition.currentActivity === 'moving') then do something
 *
 * Steps can have an output variable that can become a reusable variable here. However,
 * not all steps support that. You can see /types/step.ts:RoutineVariable.Kind and
 * VARIABLE_INFO_LIST_BY_STEP_KIND for a list of supported stepKinds.
 */
export const getVariablesFromRoutineSteps = (
  steps: Step.ConvertedSummary[],
): RoutineVariable.VariablesForStepInformation[] => {
  /** First we're flattening all steps to make it easier to list/filter them. */
  const flatListOfSteps = listAllSteps(steps);

  /** Filter out unsupported steps (those that don't have an output variable). */
  const supportedSteps = flatListOfSteps.filter((step) => {
    // Return only steps whose stepKind value is present in VARIABLE_INFO_LIST_BY_STEP_KIND
    return VARIABLE_INFO_LIST_BY_STEP_KIND[step.stepKind].length;
  });

  /**
   * Convert the step data into a RoutineVariable (format we use for
   * displaying a list of variables in the frontend).
   */
  const variableList: RoutineVariable.VariablesForStepInformation[] =
    supportedSteps.map((step) => {
      const { description, id, stepKind, name: stepName, stepPosition } = step;

      return {
        kind: 'step',
        stepDescription: description,
        stepID: id,
        stepKind,
        stepName,
        stepPosition,
        variables: VARIABLE_INFO_LIST_BY_STEP_KIND[stepKind].map(
          (variable) => ({
            ...variable,
            identifier: `variables['${id}'].${variable.variableName}`,
            userIdentifier: `Step_${stepPosition}_${variable.variableName}`,
          }),
        ),
      };
    });

  return variableList;
};
