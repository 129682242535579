import { clamp } from 'lodash';

import type { CartesianPose } from '@sb/geometry';
import type { BaseOffsetProposal, DeviceKinematics } from '@sb/motion-planning';
import { isApproximatelyEqual } from '@sb/utilities';

import type { EwellixLiftTLTCommand } from '../types';

import { EWELLIX_CONFIGURATIONS } from './consts';

interface EwellixKinematicsConstructorArgs {
  getHeightMeters: () => number;
  configurationID?: keyof typeof EWELLIX_CONFIGURATIONS;
}

export class EwellixKinematics
  implements DeviceKinematics<EwellixLiftTLTCommand>
{
  private getHeightMeters: () => number;

  public configurationID: keyof typeof EWELLIX_CONFIGURATIONS;

  public constructor(props: EwellixKinematicsConstructorArgs) {
    this.getHeightMeters = props.getHeightMeters;
    this.configurationID = props.configurationID ?? 700;
  }

  private get configuration() {
    return EWELLIX_CONFIGURATIONS[this.configurationID];
  }

  public get actuators() {
    return this.configuration.actuators;
  }

  public get minHeightMeters() {
    return this.configuration.baseHeightMeters;
  }

  public get maxHeightMeters() {
    return (
      this.configuration.baseHeightMeters + this.configuration.strokeMeters
    );
  }

  /** According to the technical data at https://www.ewellix.com/en/products/lifting-columns/tlt,
   * EwellixTLT allows speed max of 25mm per second, but in testing it actually goes
   * much faster than this - about 100mm per second
   */
  public get maxVelocityMetersPerMS() {
    return 100 * 0.001 * 0.001;
  }

  public convertEncoderFlanksToMeters(positions: Array<number>): number {
    const { baseHeightMeters, actuators } = this.configuration;

    let totalMeters = baseHeightMeters;

    for (const actuator of actuators) {
      const position = positions[actuator.index] ?? 0;

      const encoderFlanks =
        clamp(position, actuator.minEncoderFlanks, actuator.maxEncoderFlanks) -
        actuator.minEncoderFlanks;

      totalMeters += encoderFlanks / actuator.encoderFlanksPerMeter;
    }

    return totalMeters;
  }

  public convertMetersToEncoderFlanks(targetMeters: number): number[] {
    const { baseHeightMeters, actuators } = this.configuration;

    const positions = actuators.map((actuator) => {
      const extendMeters = clamp(
        (targetMeters - baseHeightMeters) / actuators.length,
        0,
        actuator.strokeMeters,
      );

      return (
        extendMeters * actuator.encoderFlanksPerMeter +
        actuator.minEncoderFlanks
      );
    });

    return positions;
  }

  private heightToPose(heightMeters: number): CartesianPose {
    return {
      x: 0,
      y: 0,
      z: heightMeters,
      w: 1,
      i: 0,
      j: 0,
      k: 0,
    };
  }

  public name = 'EwellixLiftTLT';

  public getBaseOffset(): CartesianPose {
    return this.heightToPose(this.getHeightMeters());
  }

  public getBaseOffsetProposals(): BaseOffsetProposal<EwellixLiftTLTCommand>[] {
    const currentHeightMeters = this.getHeightMeters();
    const { minHeightMeters, maxHeightMeters } = this;

    const proposals: BaseOffsetProposal<EwellixLiftTLTCommand>[] = [
      {
        baseOffset: this.getBaseOffset(),
        command: null,
        durationEstimateMS: 0,
      },
    ];

    let iteration = 0;

    while (true) {
      const delta = 0.1 * iteration;

      const proposedHeightMeters = Math.min(
        minHeightMeters + delta,
        maxHeightMeters,
      );

      if (
        !isApproximatelyEqual(proposedHeightMeters, currentHeightMeters, 0.03)
      ) {
        proposals.push({
          baseOffset: this.heightToPose(proposedHeightMeters),
          command: {
            kind: 'EwellixLiftTLTCommand',
            heightMeters: proposedHeightMeters,
            speedPercentage: 1,
          },
          durationEstimateMS:
            Math.abs(proposedHeightMeters - currentHeightMeters) /
            this.maxVelocityMetersPerMS,
        });
      }

      if (proposedHeightMeters === maxHeightMeters) {
        break;
      }

      iteration += 1;
    }

    return proposals;
  }
}
