import { NotImplemented } from '@feathersjs/errors';

export type ThirdPartyAuthProvider = 'google' | 'microsoft';

// eslint-disable-next-line @typescript-eslint/require-await
export async function loginWithThirdPartyProvider(
  _provider: ThirdPartyAuthProvider,
): Promise<void> {
  throw new NotImplemented();
}
