import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

function getIsBraked(state: RoutineRunnerState | null): boolean | null {
  return state ? state.kinematicState.brakesEngaged : null;
}

export default function useIsRobotBraked(
  args: UseRoutineRunnerHandleArguments,
): boolean | null {
  return useRobotState(args, getIsBraked, Object.is);
}
