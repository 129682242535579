import * as zod from 'zod';

export const EquipmentConfig = zod
  .object({
    kind: zod.string(),
    name: zod.string().optional(),
  })
  .passthrough();

export const EquipmentData = zod.object({
  id: zod.string(),
  config: EquipmentConfig,
  isEnabled: zod.boolean(),
});

export type EquipmentData = zod.infer<typeof EquipmentData>;
