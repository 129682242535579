/**
 * Common options for motion plans
 */

import * as zod from 'zod';

import { ArmJointAccelerations } from './ArmJointAccelerations';
import { ArmJointVelocities } from './ArmJointVelocities';
import { GripperOpenness } from './GripperOpenness';

export const MotionPlanOptions = zod.object({
  gripperOpenness: GripperOpenness,
  maxVelocities: ArmJointVelocities,
  maxAccelerations: ArmJointAccelerations,
  // Maximum tooltip velocity in meters per second
  maxTooltipVelocity: zod.number(),
});

export type MotionPlanOptions = zod.infer<typeof MotionPlanOptions>;
