import useRobotCycleStats from './useRobotCycleStats';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export default function useRobotCycleCount(
  args: UseRoutineRunnerHandleArguments,
): number | null {
  const cycleStats = useRobotCycleStats(args);

  return cycleStats ? cycleStats.cycleCount : null;
}
