import { useCallback } from 'react';

import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import useToast from './useToast';

interface EStopRobotCallback {
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

/**
 * Stop Vizbot or live robot and display a user feedback:
 * i.e. a toast with progress, success, and error messages.
 */
const useEmergencyStopRobot = (args: UseRoutineRunnerHandleArguments) => {
  const routineRunnerHandle = useRoutineRunnerHandle(args);

  const { setToast, toastState } = useToast();

  const emergencyStopRobot = useCallback(
    async (callback?: EStopRobotCallback) => {
      try {
        setToast({ kind: 'progress', message: 'E-stopping the robot...' });

        await routineRunnerHandle.emergencyStop('Remote Control');

        setToast({ kind: 'success', message: 'Robot successfully stopped!' });

        if (callback?.onSuccess) callback.onSuccess();
      } catch (error) {
        setToast({ kind: 'error', message: error.message });

        if (callback?.onError) callback.onError();
      } finally {
        if (callback?.onFinally) callback.onFinally();
      }
    },
    [routineRunnerHandle, setToast],
  );

  return { emergencyStopRobot, isStopping: toastState?.kind === 'progress' };
};

export default useEmergencyStopRobot;
