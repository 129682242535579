import { useMemo } from 'react';

import type { TaggedRoutineStepVariables } from '@sb/routine-runner';

import useRobotCurrentStepID from './useRobotCurrentStepID';
import useRobotVariables from './useRobotVariables';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export default function useRobotCurrentStepVariables(
  args: UseRoutineRunnerHandleArguments,
): TaggedRoutineStepVariables | null {
  const currentStepID = useRobotCurrentStepID(args);
  const variables = useRobotVariables(args);

  const currentStepVariables: TaggedRoutineStepVariables | null =
    useMemo(() => {
      if (!currentStepID || !variables) {
        return null;
      }

      return variables[currentStepID];
    }, [currentStepID, variables]);

  return currentStepVariables;
}
