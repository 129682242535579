import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ShutterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M8 .938c3.901 0 7.063 3.162 7.063 7.063S11.901 15.063 8 15.063.938 11.901.938 8 4.099.938 8 .938zm3.044 8.037L8.164 14.06a6.05 6.05 0 0 0 4.549-2.247l-1.67-2.839zm-2.366 2.149l-5.845.048c.91 1.479 2.437 2.538 4.223 2.818l1.623-2.866zm4.983-5.299l-3.293.026 2.963 5.039a6.03 6.03 0 0 0 .728-2.662L14.063 8a6.05 6.05 0 0 0-.402-2.175zM2.669 5.11c-.467.859-.732 1.843-.732 2.89a6.05 6.05 0 0 0 .402 2.175l3.293-.027-1.076-1.826a.5.5 0 0 1-.048-.082zm6.543.75l-2.46.02-1.212 2.138 1.246 2.12 2.46-.02 1.212-2.139-1.246-2.119zM7.836 1.94a6.05 6.05 0 0 0-4.549 2.247l1.668 2.837zm1.11.071L7.322 4.876l2.085-.016a.5.5 0 0 1 .158-.002l3.603-.028c-.91-1.479-2.437-2.538-4.223-2.818z" />
    </Icon>
  );
}
