import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function VerticalLiftIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 1C5.22386 1 5 1.22386 5 1.5V4H4.5C4.22386 4 4 4.22386 4 4.5V8H3.5C3.22386 8 3 8.22386 3 8.5V14.5C3 14.7761 3.22386 15 3.5 15H12.5C12.7761 15 13 14.7761 13 14.5V8.5C13 8.22386 12.7761 8 12.5 8H12V4.5C12 4.22386 11.7761 4 11.5 4H11V1.5C11 1.22386 10.7761 1 10.5 1H5.5ZM10 4V2H6V4H10ZM5 5V8H11V5H5ZM4 9H12V14H4V9Z"
      />
    </Icon>
  );
}
