import type { Paginated } from '@feathersjs/feathers';

import { isPaginated } from './isPaginated';

/**
 * Feathers `find` response may be a plain array or paginated
 * This utility normalizes any response to 'paginated'
 */
export function asPaginated<T>(value: T | T[] | Paginated<T>): Paginated<T> {
  if (Array.isArray(value)) {
    return {
      data: value,
      limit: value.length,
      skip: 0,
      total: value.length,
    };
  }

  if (isPaginated(value)) {
    return value;
  }

  return {
    data: [value],
    limit: 1,
    skip: 0,
    total: 1,
  };
}
