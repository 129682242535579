/**
 * This module is largely adapted from the npm module Hagen
 *
 * License:
 *
 * Copyright © 2018 John Mars
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 * ©
 */
import chalk from 'chalk';
import { detect as detectBrowser } from 'detect-browser';
import colorizeJson from 'json-colorizer';

import { LoggingBackend } from './backends';
import type { Logger, LogKind } from './main';

/* eslint-disable no-console */

const isBrowser = detectBrowser();

const colorList = {
  blue: {
    50: `#e8f0fe`,
    100: `#d2e3fc`,
    500: `#4285f4`,
    600: `#1a73e8`,
    700: `#1967d2`,
    800: `#185abc`,
    900: `#174ea6`,
  },
  red: {
    50: `#fce8e6`,
    100: `#fad2cf`,
    500: `#ea4335`,
    600: `#d93025`,
    700: `#c5221f`,
    800: `#b31412`,
    900: `#af0e0e`,
  },
  yellow: {
    50: `#fef7e0`,
    100: `#feefc3`,
    500: `#fbbc04`,
    600: `#f9ab00`,
    700: `#f29900`,
    800: `#ea8600`,
    900: `#e37400`,
  },
  green: {
    50: `#e6f4ea`,
    100: `#ceead6`,
    500: `#34a853`,
    600: `#1e8e3e`,
    700: `#188038`,
    800: `#137333`,
    900: `#0d652d`,
  },
  grey: {
    50: `#f8f9fa`,
    100: `#f1f3f4`,
    200: `#e8eaed`,
    300: `#dadce0`,
    400: `#bdc1c6`,
    500: `#9aa0a6`,
    600: `#80868b`,
    700: `#5f6368`,
    800: `#3c4043`,
    900: `#202124`,
  },
  orange: {
    50: `#feefe3`,
    100: `#fedfc8`,
    500: `#fa7b17`,
    600: `#e8710a`,
    700: `#d56e06`,
    800: `#c26401`,
    900: `#b06000`,
  },
  pink: {
    50: `#fde7f3`,
    100: `#fdcfe8`,
    500: `#f439a0`,
    600: `#e52592`,
    700: `#d01884`,
    800: `#b80672`,
    900: `#9c166b`,
  },
  purple: {
    50: `#f3e8fd`,
    100: `#e9d2fd`,
    500: `#a142f4`,
    600: `#9334e6`,
    700: `#8430ce`,
    800: `#7627bb`,
    900: `#681da8`,
  },
  cyan: {
    50: `#e4f7fb`,
    100: `#cbf0f8`,
    500: `#24c1e0`,
    600: `#12b5cb`,
    700: `#129eaf`,
    800: `#098591`,
    900: `#007b83`,
  },
};

const textColors = Object.freeze({
  LIGHT: colorList.grey[50],
  DARK: colorList.grey[900],
});

const reservedColors = Object.freeze({
  WARN: { bg: `#ccff00`, text: textColors.DARK },
  DEBUG: { bg: `#34cceb`, text: textColors.DARK },
  ERROR: { bg: `#ff0099`, text: textColors.LIGHT },
  INFO: { bg: `#000000`, text: textColors.LIGHT },
});

type Color = { bg: string; text: string };

// ========= HELPERS =========

// Writes to console colored text, from the passed-in logger
function logg(
  logger: (...args: Array<any>) => void,
  timestamp: boolean,
  color: Color,
  label: string,
  messages: Array<any>,
) {
  if (isBrowser?.name === `node`) {
    // if using node.js
    const errors: Array<Error> = [];

    const args = [
      messages
        .map((message: any) => {
          if (message instanceof Error) {
            errors.push(message);

            return `${message.name} (${message.message})`;
          }

          if (message instanceof Date) {
            return message.toISOString();
          }

          if (typeof message === 'object') {
            try {
              return colorizeJson(JSON.stringify(message));
            } catch {
              // do default if JSON.stringify throws
            }
          }

          return `${message}`;
        })
        .join(' '),
    ];

    args.unshift(chalk.bgHex(color.bg).hex(color.text).bold(` ${label} `));

    if (timestamp) {
      args.unshift(new Date().toISOString());
    }

    logger(...args);

    errors.forEach((error) => {
      logger(
        chalk.bgHex(color.bg).hex(color.text).bold(` ${label} (${error.name})`),
        error.stack,
      );
    });
  } else {
    if (timestamp) {
      messages.unshift(new Date());
    }

    const formatString = `%c ${label} %c %o`;

    // if using the browser
    logger(
      formatString,
      `
        background-color: ${color.bg};
        color: ${color.text};
        font-weight: bold;
      `,
      `
        background-color: inherit;
        color: inherit;
        font-weight: inherit;
      `,
      ...messages,
    );
  }
}

export type ConsoleOptions = {
  levels?: Array<LogKind>;
  timestamps?: boolean;
};

export class ConsoleBackend extends LoggingBackend {
  private levels: Array<LogKind>;

  private timestamps: boolean;

  public constructor({
    levels = ['info', 'warn', 'error'],
    timestamps = true,
  }: ConsoleOptions = {}) {
    super();
    this.levels = levels;
    this.timestamps = timestamps;
  }

  // necessary to extend `LoggingBackend`
  // eslint-disable-next-line @typescript-eslint/no-empty-function,class-methods-use-this
  public initialize(): void {}

  public log({
    kind,
    parts,
    logger,
  }: {
    kind: LogKind;
    parts: Array<any>;
    logger: Logger;
  }): void {
    if (!this.levels.includes(kind)) {
      return;
    }

    this[kind](logger.label, parts);
  }

  private info(label: string, message: Array<any>): void {
    const prefix = `i ${label}`;
    logg(console.info, this.timestamps, reservedColors.INFO, prefix, message);
  }

  private warn(label: string, message: Array<any>): void {
    const prefix = `! ${label}`;
    logg(console.warn, this.timestamps, reservedColors.WARN, prefix, message);
  }

  private error(label: string, message: Array<any>): void {
    const prefix = `✕ ${label}`;
    logg(console.error, this.timestamps, reservedColors.ERROR, prefix, message);
  }

  private debug(label: string, message: Array<any>): void {
    const prefix = `${label}`;
    logg(console.debug, this.timestamps, reservedColors.DEBUG, prefix, message);
  }
}
