import { useCallback } from 'react';

import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import useToast from './useToast';

const useRobotUnbrake = ({
  robotID,
  isVizbot,
}: UseRoutineRunnerHandleArguments) => {
  const routineRunnerHandle = useRoutineRunnerHandle({
    robotID,
    isVizbot,
  });

  const { setToast } = useToast();

  const displayRobotName = isVizbot ? 'Visualizer' : 'Live Robot';

  const brakeRobot = useCallback(async () => {
    try {
      setToast({ kind: 'progress', message: 'Braking robot...' });

      await routineRunnerHandle.brake();

      setToast({
        kind: 'success',
        message: `${displayRobotName} has successfully braked.`,
      });
    } catch (e) {
      setToast({
        kind: 'error',
        message: `${displayRobotName} could not brake. Please try again.`,
      });
    }
  }, [displayRobotName, routineRunnerHandle, setToast]);

  const unbrakeRobot = useCallback(async () => {
    try {
      setToast({ kind: 'progress', message: 'Unbraking robot...' });

      await routineRunnerHandle.unbrake();

      setToast({
        kind: 'success',
        message: `${displayRobotName} has successfully unbraked.`,
      });
    } catch (e) {
      setToast({
        kind: 'error',
        message: `${displayRobotName} could not unbrake. Please try again`,
      });
    }
  }, [displayRobotName, routineRunnerHandle, setToast]);

  return { brakeRobot, unbrakeRobot };
};

export default useRobotUnbrake;
