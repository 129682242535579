import type { Step } from '@sb/types';

import type { CommandKind } from '../../Command';

import type { ActuateScrewdriverArguments } from './Arguments';

export namespace ActuateScrewdriverStepDatabase {
  export type ActuationCommandKind = CommandKind;

  export interface Arguments extends Step.CommonArguments {
    argumentKind: 'ActuateScrewdriver';
    commandKind: CommandKind;
    targetTorque: number; // STORED AS Nm (0.1-5) // 100 to 5000 Nmm
    targetForce: number; // STORED AS N 18 to 30 N
    screwLength: number; // STORED AS MM (0-35) // 0 to 35000 muM
    shankPosition: number; // 0 to 55mm
  }

  export const convertStepToRoutineRunnerArgs = (
    configuration: Arguments | void,
    stepID: string,
  ): ActuateScrewdriverArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateScrewdriver step is not configured. stepID: ${stepID}'`,
      );
    }

    return {
      gripperCommand: {
        kind: 'OnRobotScrewdriverCommand',
        commandKind: configuration.commandKind,
        targetForce: configuration.targetForce,
        screwLength: configuration.screwLength,
        targetTorque: configuration.targetTorque,
      },
    };
  };
}
