export const RETRY_INTERVAL_MS = 10000;

export const INITIALIZATION_TIMEOUT_MS = 1000;

export const MODBUS_TIMEOUT_MS = 5000;

export const ACTUATION_TIMEOUT_MS = 5000;

export type ChannelKey = 'one' | 'two' | 'three' | 'four';

export const channelKeys = ['one', 'two', 'three', 'four'] as const;

export const OR_VGP20_TARGET_ADDRESS = 0x41;

export enum Registers {
  // WRITE
  CONTROL_A = 0x00,
  CONTROL_B = 0x01,
  CONTROL_C = 0x02,
  CONTROL_D = 0x03,

  // READ
  CHANNEL_STATUS = 0x0100,
  STATUS = 0x0101,

  VACUUM_A_PERCENT = 0x0102,
  VACUUM_B_PERCENT = 0x0103,
  VACUUM_C_PERCENT = 0x0104,
  VACUUM_D_PERCENT = 0x0105,
}

export const CommandRegisters = {
  one: Registers.CONTROL_A,
  two: Registers.CONTROL_B,
  three: Registers.CONTROL_C,
  four: Registers.CONTROL_D,
};

export const commandRegisterSet = new Set(Object.values(CommandRegisters));

export const VacuumPercentRegisters = {
  one: Registers.VACUUM_A_PERCENT,
  two: Registers.VACUUM_B_PERCENT,
  three: Registers.VACUUM_C_PERCENT,
  four: Registers.VACUUM_D_PERCENT,
};

export const vacuumPercentRegisterSet = new Set(
  Object.values(VacuumPercentRegisters),
);

export enum StatusBits {
  BUSY = 0,
  PSU_ERROR = 14,
  QC_ERROR = 15,
}

export enum ChannelCommandBits {
  REQUIRE_BIT = 7,
  IGNORE_BIT = 15,
}

export enum ChannelStatusGrip {
  NOT_GRIPPED = 0,
  GRIP_TIMEOUT = 1,
  GRIP_DETECTED = 2,
  GRIP_LOST = 3,
}

export enum ChannelStatusRelease {
  NOT_RELEASED = 0,
  RELEASE_FAILED = 1,
  RELEASE_OK = 2,
  RESERVED = 3,
}
