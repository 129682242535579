import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function CustomIOIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M10.5 8.5C10.7761 8.5 11 8.27614 11 8C11 7.72386 10.7761 7.5 10.5 7.5C10.2239 7.5 10 7.72386 10 8C10 8.27614 10.2239 8.5 10.5 8.5Z" />
      <path d="M9 8C9 8.27614 8.77614 8.5 8.5 8.5C8.22386 8.5 8 8.27614 8 8C8 7.72386 8.22386 7.5 8.5 7.5C8.77614 7.5 9 7.72386 9 8Z" />
      <path d="M6 8C6 9.933 7.567 11.5 9.5 11.5C11.433 11.5 13 9.933 13 8C13 6.067 11.433 4.5 9.5 4.5C7.567 4.5 6 6.067 6 8ZM9.5 10.5C8.11929 10.5 7 9.38071 7 8C7 6.61929 8.11929 5.5 9.5 5.5C10.8807 5.5 12 6.61929 12 8C12 9.38071 10.8807 10.5 9.5 10.5Z" />
      <path d="M1.5 3.5C1.5 2.67157 2.17157 2 3 2C3.82843 2 4.5 2.67157 4.5 3.5V5.70551C5.36961 3.81363 7.2814 2.5 9.5 2.5C12.5376 2.5 15 4.96243 15 8C15 11.0376 12.5376 13.5 9.5 13.5C7.2814 13.5 5.36961 12.1864 4.5 10.2945V12.5C4.5 13.3284 3.82843 14 3 14C2.17157 14 1.5 13.3284 1.5 12.5V3.5ZM3 3C2.72386 3 2.5 3.22386 2.5 3.5V12.5C2.5 12.7761 2.72386 13 3 13C3.27614 13 3.5 12.7761 3.5 12.5V3.5C3.5 3.22386 3.27614 3 3 3ZM5 8C5 10.4853 7.01472 12.5 9.5 12.5C11.9853 12.5 14 10.4853 14 8C14 5.51472 11.9853 3.5 9.5 3.5C7.01472 3.5 5 5.51472 5 8Z" />
    </Icon>
  );
}
