import { clamp } from 'lodash';

import type { RobotData } from '@sb/feathers-types';
import type { Equipment, Robot } from '@sb/types';

import { getIOPorts } from './getIOPorts';

export function convertRobotResponse(
  equipment: Equipment.ConvertedResponse[],
): (data: RobotData) => Robot.ConvertedResponse {
  return (data) => {
    const ioInputs = getIOPorts<Robot.InputPort>(
      'Input',
      data.ioInputs,
      equipment,
    );

    const ioOutputs = getIOPorts<Robot.OutputPort>(
      'Output',
      data.ioOutputs,
      equipment,
    );

    return {
      id: data.robotID,
      ioInputs,
      ioOutputs,
      latestRoutine: data.latestRoutineID
        ? {
            id: data.latestRoutineID,
            name: data.latestRoutineName ?? '',
          }
        : null,
      latestRoutineLoadedAt: data.latestRoutineLoadedAt
        ? new Date(data.latestRoutineLoadedAt)
        : undefined,
      name: data.name,
      routineRunnerState: {
        kind: data.routineRunnerStateKind ?? 'Constructing',
        isPaused: data.routineIsPaused,
      },
      speedRestrictionPercentage: clamp(data.speedRestrictionPercentage, 0, 1),
      testRunCompletedAt: data.testRunCompletedAt
        ? new Date(data.testRunCompletedAt)
        : undefined,
      lastKnownLocalIP: data.lastKnownLocalIP,
    };
  };
}
