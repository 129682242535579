import type { Placement } from '@popperjs/core';
import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useClickOutside } from '@sb/ui/hooks';

import styles from './Dropdown.module.css';

interface DropdownProps {
  children: React.ReactNode;
  className?: string;
  content: React.ReactNode;
  isOpen: boolean;
  /** Set this to `true` if you don't want the active element to lose focus when clicking */
  preventDefaultOnMouseDown?: boolean;
  placement?: Placement;
  onClose: () => void;
  offset?: number;
  /** If this value changes and is truthy, popper forceUpdate will be called */
  popperForceUpdate?: any;
  'data-testid'?: string;
}

/** Override popperjs fallbacks for some placements, so longer dropdowns get more space to fit on the page */
const FALLBACK_PLACEMENTS: { [placement in Placement]?: Placement[] } = {
  'bottom-end': ['top-end', 'left-start', 'right-start'],
  'bottom-start': ['top-start', 'right-start', 'left-start'],
  bottom: ['top', 'right-start', 'left-start'],
  'top-end': ['bottom-end', 'left-end', 'right-end'],
  'top-start': ['bottom-start', 'right-end', 'left-end'],
  top: ['bottom', 'right-end', 'left-end'],
};

const Dropdown = ({
  children,
  className,
  content,
  isOpen,
  preventDefaultOnMouseDown,
  placement = 'bottom-end',
  onClose,
  offset = 3,
  popperForceUpdate,
  'data-testid': testID,
}: DropdownProps) => {
  const referenceElement = useRef<HTMLDivElement>(null);
  const popperElement = useRef<HTMLDivElement>(null);

  useClickOutside([popperElement, referenceElement], onClose);

  const skidding =
    placement.startsWith('bottom') || placement.startsWith('top') ? 1 : -1;

  const {
    styles: popperStyles,
    attributes,
    forceUpdate,
  } = usePopper(referenceElement.current, popperElement.current, {
    placement,
    strategy:
      'fixed' /* avoids dropdown triggering overflow on document.body */,
    modifiers: [
      {
        name: 'flip',
        options: { fallbackPlacements: FALLBACK_PLACEMENTS[placement] },
      },
      { name: 'offset', options: { offset: [skidding, offset] } },
      { name: 'preventOverflow', options: { padding: 10 } },
      { name: 'eventListeners', enabled: isOpen },
    ],
  });

  useEffect(() => {
    if (popperForceUpdate && forceUpdate) {
      forceUpdate();
    }
  }, [popperForceUpdate, forceUpdate]);

  useEffect(() => {
    // on opening, move container to the end of body so it is above any other poppers in z-order
    if (isOpen && popperElement.current) {
      document.body.appendChild(popperElement.current);

      // ensure correct position on open
      queueMicrotask(() => {
        forceUpdate?.();
      });
    }
  }, [isOpen, forceUpdate]);

  const handleMouseDown: React.MouseEventHandler | undefined =
    preventDefaultOnMouseDown ? (e) => e.preventDefault() : undefined;

  // close the popper if user clicks on an enabled button inside it
  // (you can override this by adding `event.stopPropagation()` inside your button click handler)
  const handleClickPopper: React.MouseEventHandler = (event) => {
    let target: EventTarget | null = event.target; // eslint-disable-line prefer-destructuring

    while (target) {
      if (target instanceof HTMLButtonElement) {
        if (!target.disabled) onClose();
        target = null;
      } else if (target instanceof HTMLElement) {
        target = target.parentElement;
      } else {
        target = null;
      }
    }
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions */

  return (
    <>
      <div
        ref={referenceElement}
        className={className}
        onMouseDown={handleMouseDown}
        data-testid={testID && `${testID}--dropdown`}
      >
        {children}
      </div>

      {createPortal(
        <div
          ref={popperElement}
          className={styles.dropdownContent}
          onClick={handleClickPopper}
          style={popperStyles.popper}
          onMouseDown={handleMouseDown}
          data-testid={testID && `${testID}--options`}
          {...attributes.popper}
          hidden={!isOpen}
        >
          {content}
        </div>,
        document.body,
      )}
    </>
  );
};

export default Dropdown;
