import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function DuplicateIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M13 13.5H5V12a.5.5 0 1 0-1 0v2a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5h-2a.5.5 0 1 0 0 1H13v8zM9.5.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-9A.5.5 0 0 1 0 10V1A.5.5 0 0 1 .5.5h9zM1 1.5v8h8v-8H1z" />
    </Icon>
  );
}
