import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function VideoIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 17 13" {...props}>
      <path d="M10 .556H2a1.5 1.5 0 0 0-1.5 1.5v9a1.5 1.5 0 0 0 1.5 1.5h8a1.5 1.5 0 0 0 1.5-1.5v-9a1.5 1.5 0 0 0-1.5-1.5zm0 1a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8zm6.493 1.414l.007.086v7a.5.5 0 0 1-.603.489l-.082-.025-5-2a.5.5 0 0 1 .285-.954l.086.026L15.5 9.317V3.794L11.186 5.52a.5.5 0 0 1-.609-.198l-.041-.08a.5.5 0 0 1 .198-.609l.08-.041 5-2a.5.5 0 0 1 .678.378z" />
    </Icon>
  );
}
