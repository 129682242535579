import { useContext, Fragment, useCallback } from 'react';

import { GlobalContext } from '@sbrc/contexts';

export function useVisualizerChildren() {
  const { visualizerChildren } = useContext(GlobalContext);

  const setVisualizerChildren = visualizerChildren.set;

  const setVisualizerChild = useCallback(
    (key: string, child: React.ReactNode) => {
      setVisualizerChildren((prevChildren) => {
        if (child) {
          return { ...prevChildren, [key]: child };
        }

        if (prevChildren[key]) {
          const newChildren = { ...prevChildren };
          delete newChildren[key];

          return newChildren;
        }

        return prevChildren;
      });
    },
    [setVisualizerChildren],
  );

  return {
    visualizerChildren: Object.entries(visualizerChildren.state).map(
      ([key, node]) => <Fragment key={key}>{node}</Fragment>,
    ),
    setVisualizerChild,
  };
}
