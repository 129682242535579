import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ControllerIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 17 17">
      <path d="M16.5 13.06v2.995a.5.5 0 0 1-.5.5H1a.5.5 0 0 1-.5-.5V13.06a.5.5 0 0 1 .025-.16c.02-.058.046-.106.078-.15L3.6 8.756a.5.5 0 0 1 .4-.2h3a.5.5 0 1 1 0 1H4.25l-2.25 3h13l-2.25-3H10a.5.5 0 1 1 0-1h3a.5.5 0 0 1 .4.2l2.997 3.996a.5.5 0 0 1 .077.143c.02.058.027.112.026.166zm-15 .495v2h14v-2h-14zm6-7.176v4.172h2v-4.17c-.312.1-.648.17-.998.17s-.69-.06-1.002-.172zm-1-.595a2.99 2.99 0 0 1-.996-2.232A3 3 0 0 1 8.502.556 3 3 0 0 1 11.5 3.554a2.99 2.99 0 0 1-1 2.235v5.263a.5.5 0 0 1-.5.5H7a.5.5 0 0 1-.5-.5V5.785zm2.002-.234A2 2 0 0 0 10.5 3.554a2 2 0 0 0-1.998-1.998 2 2 0 0 0-1.998 1.998 2 2 0 0 0 1.998 1.998z" />
    </Icon>
  );
}
