import { useEffect, useState } from 'react';

import type { Routine } from '@sb/types';
import { onGetRoutine } from '@sbrc/services';

export default function useRoutineData(
  routineID: string | null,
): Routine.ConvertedResponse | null | undefined {
  const [routineData, setRoutineData] = useState<
    Routine.ConvertedResponse | null | undefined
  >();

  useEffect(() => {
    if (!routineID) return () => {};

    const unsubscribe = onGetRoutine(routineID, setRoutineData);

    return () => unsubscribe();
  }, [routineID]);

  return routineData;
}
