import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ControlPanelIcon(props: IconProps) {
  return (
    <Icon viewBox="0 1 16 15" {...props}>
      <path d="M11 11.5h1.5a.5.5 0 1 0 0-1H11V9a.5.5 0 1 0-1 0v4a.5.5 0 1 0 1 0v-1.5zm-6-6H3.5a.5.5 0 1 0 0 1H5V8a.5.5 0 1 0 1 0V4a.5.5 0 1 0-1 0v1.5zm3 1a.5.5 0 1 1 0-1h4.5a.5.5 0 1 1 0 1H8zm0 4a.5.5 0 1 1 0 1H3.5a.5.5 0 1 1 0-1H8zm6.5 5a.5.5 0 0 0 .5-.5V2a.5.5 0 0 0-.5-.5h-13A.5.5 0 0 0 1 2v13a.5.5 0 0 0 .5.5h13zm0 1h-13A1.5 1.5 0 0 1 0 15V2A1.5 1.5 0 0 1 1.5.5h13A1.5 1.5 0 0 1 16 2v13a1.5 1.5 0 0 1-1.5 1.5z" />
    </Icon>
  );
}
