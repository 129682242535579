import * as zod from 'zod';

import { OnRobot2FG7Command } from '../OnRobot2FG7';
import { OnRobot3FG15Command } from '../OnRobot3FG15';

const CompatibleCommand = zod.union([OnRobot2FG7Command, OnRobot3FG15Command]);

export type CompatibleCommand = zod.infer<typeof CompatibleCommand>;

export const OnRobotDualQuickChangerCommand = zod.object({
  kind: zod.literal('OnRobotDualQuickChangerCommand'),

  active: zod.enum(['primary', 'secondary']),

  command: CompatibleCommand,
});

export type OnRobotDualQuickChangerCommand = zod.infer<
  typeof OnRobotDualQuickChangerCommand
>;
