import type { CartesianPosition } from '@sb/geometry';

import type { ArmTarget } from './ArmTarget';
import { PoseArmTarget } from './ArmTarget';

export const translateArmTarget = (
  target: ArmTarget,
  translation: CartesianPosition,
): ArmTarget => {
  // For now only support arm pose,
  // joint pose will require us to translate to nearest position and requirements unclear
  // grasp pose may allow us to control based on tooltip position
  const maybeArmTarget = PoseArmTarget.safeParse(target);

  if (maybeArmTarget.success) {
    const armTarget = maybeArmTarget.data;

    return {
      ...target,
      pose: {
        ...armTarget.pose,
        x: armTarget.pose.x + translation.x,
        y: armTarget.pose.y + translation.y,
        z: armTarget.pose.z + translation.z,
      },
    };
  }

  return target;
};
