import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SandboxIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path d="M0.0669908 4.0001C0.204985 3.76091 0.510754 3.67887 0.749945 3.81687L7.00008 7.42272L13.2502 3.81687C13.4894 3.67887 13.7952 3.76091 13.9332 4.0001C14.0712 4.23929 13.9891 4.54506 13.7499 4.68305L7.24994 8.43305C7.09531 8.52226 6.90485 8.52226 6.75022 8.43305L0.250222 4.68305C0.0110319 4.54506 -0.0710037 4.23929 0.0669908 4.0001Z" />
      <path d="M7 7.5C7.27614 7.5 7.5 7.72386 7.5 8V15.5C7.5 15.7761 7.27614 16 7 16C6.72386 16 6.5 15.7761 6.5 15.5V8C6.5 7.72386 6.72386 7.5 7 7.5Z" />
      <path d="M6.75014 0.0669072C6.90477 -0.0223024 7.09523 -0.0223024 7.24986 0.0669072L13.7499 3.81691C13.9046 3.9062 14 4.07131 14 4.25V11.75C14 11.9287 13.9046 12.0938 13.7499 12.1831L7.24986 15.9331C7.09523 16.0223 6.90477 16.0223 6.75014 15.9331L0.250139 12.1831C0.09536 12.0938 0 11.9287 0 11.75V4.25C0 4.07131 0.09536 3.9062 0.250139 3.81691L6.75014 0.0669072ZM1 4.53878V11.4612L7 14.9228L13 11.4612V4.53878L7 1.07724L1 4.53878Z" />
    </Icon>
  );
}
