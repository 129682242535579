import type { OnRobot3FG15Command } from '../Command';
import type { OR3FG15CommandKind } from '../constants';

export function calculateOR3FG15ActuationCommandKind(
  command: OnRobot3FG15Command,
): OR3FG15CommandKind {
  if (command.waitForGripToContinue) return 'grip';
  if (command.isFlexGrip) return 'flexGrip';

  return 'move';
}
