import type {
  RobotToExternalPort,
  SetIOStepArguments,
} from '@sb/routine-runner';
import type { SetIOStep } from '@sb/types';

export const convertSetIOConfigurationToRoutineRunner = (
  stepID: string,
  configuration?: SetIOStep.Arguments,
): SetIOStepArguments => {
  if (!configuration) {
    throw new Error(`SetIO step is not configured. stepID: ${stepID}`);
  }

  const changes: SetIOStepArguments['changes'] = configuration.outputs.map(
    (output) => {
      const label = `Output ${output.port}` as RobotToExternalPort;

      return { label, level: output.level };
    },
  );

  return { changes };
};
