import type { User } from '@sb/types';

import { getUsersService } from './utils';

export async function updateUser(userID: string, data: User.Update) {
  await getUsersService().patch(userID, {
    email: data.email,
    name: data.name,
    distanceUnit: data.distanceUnit,
  });
}
