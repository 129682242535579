import * as zod from 'zod';

import { six } from '@sb/utilities';

// A set of arm joint velocities in radians per second, in order from joint 0 to joint 5.
export const ArmJointVelocities = zod.tuple([
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
  zod.number(),
]);

export type ArmJointVelocities = zod.infer<typeof ArmJointVelocities>;

// radians / second
export const ABSOLUTE_MAX_JOINT_SPEEDS: ArmJointVelocities = [
  4.7, 4.7, 5, 6.8, 6.8, 6.8,
];

export const COLLABORATIVE_MAX_JOINT_SPEEDS: ArmJointVelocities = [
  4.7, 4.7, 5, 6.8, 6.8, 6.8,
];

export const DEFAULT_MAX_JOINT_SPEEDS = COLLABORATIVE_MAX_JOINT_SPEEDS;

export const ANTIGRAVITY_SPEEDS_DEFAULT: ArmJointVelocities = six(0.3);

// meters / second
export const ABSOLUTE_MAX_TOOLTIP_SPEED = 5;
export const COLLABORATIVE_MAX_TOOLTIP_SPEED = 1.25;
export const DEFAULT_MAX_TOOLTIP_SPEED = COLLABORATIVE_MAX_TOOLTIP_SPEED;
