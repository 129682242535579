import { Timeout } from '@feathersjs/errors';
import type { MonoTypeOperatorFunction } from 'rxjs';
import { delay, retryWhen, tap } from 'rxjs/operators';

/**
 * RxJS operator
 */
export function retryOnTimeout<T>(): MonoTypeOperatorFunction<T> {
  return retryWhen((error$) =>
    error$.pipe(
      tap((error) => {
        if (error instanceof Timeout) {
          // retry on timeout
          return;
        }

        // re-throw any other error
        throw error;
      }),
      delay(1000),
    ),
  );
}
