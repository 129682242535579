import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function QuestionIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 10 10">
      <path d="M5 0C2.243 0 0 2.243 0 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm-.208 7.917c-.345 0-.625-.28-.625-.625s.28-.625.625-.625.625.28.625.625-.28.625-.625.625zM6.588 4.22c-.13.206-.373.438-.734.695-.584.432-.568.527-.568.918H4.262c0-.305-.007-.54.158-.825.105-.183.3-.378.58-.583.337-.24.665-.473.665-.877 0-.38-.324-.514-.703-.514-.386 0-.827.126-1.32.38l-.42-.846c.9-.504 2.317-.732 3.093-.1.57.464.572 1.28.276 1.753z" />
    </Icon>
  );
}
