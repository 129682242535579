import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function UnlockIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 14 16">
      <path d="M11.813 14.063c.207 0 .375-.168.375-.375V7.563c0-.207-.168-.375-.375-.375H2.188c-.207 0-.375.168-.375.375v6.125c0 .207.168.375.375.375h9.625zm0 1H2.188c-.76 0-1.375-.616-1.375-1.375V7.563c0-.76.616-1.375 1.375-1.375h9.625c.76 0 1.375.616 1.375 1.375v6.125c0 .76-.616 1.375-1.375 1.375z" />
      <path d="M4.438 6.688V4.5c0-1.415 1.147-2.562 2.563-2.562 1.142 0 2.136.753 2.456 1.83a.5.5 0 1 0 .959-.285C9.97 1.984 8.587.938 7 .938 5.032.938 3.438 2.532 3.438 4.5v2.188a.5.5 0 1 0 1 0zM7 12.438c-1 0-1.812-.81-1.812-1.812S6 8.813 7 8.813s1.813.81 1.813 1.813S8 12.438 7 12.438zm0-1c.45 0 .813-.364.813-.812S7.45 9.813 7 9.813s-.812.364-.812.813.364.813.813.813z" />
    </Icon>
  );
}
