import type { USBDriveData } from '@sb/feathers-types';

import { getService, mapPaginated } from '../utils';

export const getUSBDrives = async (): Promise<USBDriveData[]> => {
  const usb = getService('usb')();

  const result = await usb.find();

  return mapPaginated(result, (r) => r);
};
