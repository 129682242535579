import type {
  ArmJointAccelerations,
  ArmJointLimits,
  ArmJointPositions,
  ArmJointTorques,
  ArmJointVelocities,
  MotionPlan,
} from '@sb/motion-planning';
import type { Eight, Six, Sixteen } from '@sb/utilities';

import type {
  ControlSystemEvent,
  PayloadState,
  RobotToExternalPort,
  IOLevel,
  ExternalToRobotPort,
  SafetyPort,
  RelayPort,
  MotionResult,
  SafeguardRule,
  SafeguardState,
  LEDColorPattern,
} from './types';

export type LimitsForSpeed = {
  tooltipSpeed: number | null;
  jointVelocities: Six<number | null>;
  jointTorques: Six<number | null>;
  collisionDetectionThreshold: Six<number | null>;
};

export interface JointSafetyLimits {
  slow: LimitsForSpeed;
  fast: LimitsForSpeed;
}

export type AccelerationCollisionThresholds = Six<number | null>;

export interface PlayMotionPlanArgs {
  motionPlan: MotionPlan;
  maxJointVelocities: ArmJointVelocities;
  maxJointAccelerations: ArmJointAccelerations;
  maxTooltipVelocity: number;
  pushingCollisionThresholds?: ArmJointTorques;
  checkTorqueLimitsPremotion?: boolean;
  checkTorqueLimitsRealtime?: boolean;
}

/**
 * Abstract class implemented by both the Simulator and RobotConnector that
 * receives the (real or simulated) robot's state and sends it commands.
 */
export abstract class RobotInterface {
  /**
   * Calls the handler whenever a control system event comes in from the robotics process.
   *
   * @return A cancellation function
   */
  public abstract onControlSystemEvent(
    handler: (event: ControlSystemEvent) => void,
  ): () => void;

  /**
   * Returns a boolean indicating whether the robot is in motion.
   *
   * @returns True if any of the velocities exceed a small epsilon, false
   * otherwise.
   */
  public isRobotMoving(): boolean {
    const epsilon = 1e-4;
    const velocities = this.getJointVelocities();

    return velocities.find((vel) => Math.abs(vel) > epsilon) !== null;
  }

  /**
   * Returns a boolean indicating whether the robot is currently braked.
   *
   * @returns True if the robot is braked, false otherwise.
   */
  public abstract isRobotBraked(): boolean;

  /**
   * Gets the state of the control box buttons.
   *
   * @returns An array of 8 booleans indicating whether the control box buttons are pressed.
   */
  public abstract getControlBoxButtonStates(): Eight<boolean>;

  /**
   * Gets the state of the wrist buttons.
   *
   * @returns An array of 8 booleans indicating whether the wrist buttons are pressed.
   */
  public abstract getWristButtonStates(): Eight<boolean>;

  /**
   * Stops the robot and enables the brakes.
   *
   * @resolves when the brakes have been enabled.
   */
  public abstract brake(): Promise<void>;

  /**
   * Stops and brakes the robot in response to an e-stop trigger.
   *
   * "source" describes the reason that the emergency stop happened
   */
  public abstract emergencyStop(info: { source: string }): Promise<void>;

  /**
   * Sets failure flag in arm-control-bot to sync with botman fail state.
   */
  public abstract setInFailureState(isInFailureState: boolean): Promise<void>;

  /**
   * Runs the disengage brake sequence.
   *
   * @resolves when the brakes have been disengaged.
   */
  public abstract unbrake(): Promise<void>;

  /**
   * Runs the specified motion plan on the robot.
   */
  public abstract playMotionPlan(
    args: PlayMotionPlanArgs,
  ): Promise<MotionResult>;

  /**
   * Stops the arm from moving, setting all joints' desired velocities to zero.
   *
   * @resolves  When the arm has stopped.
   */
  public abstract stopArm(): Promise<void>;

  /**
   * Returns the current joint positions.
   *
   * @returns The current joint positions.
   */
  public abstract getJointAngles(): ArmJointPositions;

  /**
   * Returns the current joint velocities.
   *
   * @returns The current joint velocities.
   */
  public abstract getJointVelocities(): ArmJointVelocities;

  /**
   * Returns the current joint disturbances.
   *
   * @returns The current joint disturbances.
   */
  public abstract getJointDisturbances(): ArmJointTorques;

  /**
   * Returns the joint position limits.
   *
   * @returns The current joint limits as { min, max } pairs.
   */
  public abstract getJointLimits(): ArmJointLimits;

  /**
   * Returns the current safeguard state of the safety/power board.
   *
   * e.g. if the robot is e-stopped
   */
  public abstract getSafeguardState(): SafeguardState;

  /**
   * Sets the state of the specified output IO device.
   *
   * @param label     The label of the device (e.g. "Output 2", "Relay 1") to write.
   * @param level     The level to set the specified IO ("high" or "low").
   */
  public abstract setOutputIO(
    changes: { label: RobotToExternalPort | RelayPort; level: IOLevel }[],
  ): Promise<void>;

  /**
   * Gets the state of all IO devices.
   *
   * @deprecated mostly in favor of `getDigitalInputs`/`getDigitalOutputs`
   *
   * @returns A struct describing all of the ports
   */
  public abstract getIO(): Record<
    ExternalToRobotPort | RobotToExternalPort | SafetyPort | RelayPort,
    IOLevel
  >;

  public abstract getDigitalInputs(): Sixteen<boolean>;
  public abstract getDigitalOutputs(): Sixteen<boolean>;

  public abstract supportsSafeguardRules(): boolean;
  public abstract setSafeguardRules(rules: Array<SafeguardRule>): void;

  /**
   * Set LED color pattern
   *
   * @param colorPattern A struct describing all color pattern to use
   * @returns A boolean marking success or failure
   */
  public abstract setLEDPattern(
    colorPattern: LEDColorPattern,
  ): Promise<boolean>;

  /**
   * Set information about the payload for use in dynamics.
   *
   * @param payloadState   An object describing the payload information.
   */
  public abstract anticipatePayload(payloadState: PayloadState): void;

  public abstract setAntigravitySpeeds(speeds: ArmJointVelocities): void;

  public abstract setIMUCollisionThresholds(
    thresholds: AccelerationCollisionThresholds,
  ): void;

  public abstract setJointSafetyLimits(
    limits: JointSafetyLimits,
  ): Promise<void>;

  /**
   * Overridable function that gets called when the routine runner gets
   * destroyed.
   */
  public destroy(): void {}
}
