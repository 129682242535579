import * as zod from 'zod';

import { TooltipConfiguration } from '@sb/integrations/gripper-general';

export const OnRobotVGP20Configuration = zod.object({
  kind: zod.literal('OnRobotVGP20'),
  name: zod.string().optional(),
  connectionOptions: zod.object({
    host: zod.string(),
    port: zod.number(),
  }),
  tooltipConfig: TooltipConfiguration.optional(),
});

export type OnRobotVGP20Configuration = zod.infer<
  typeof OnRobotVGP20Configuration
>;
