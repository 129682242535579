import { FailureKind } from '@sb/routine-runner/FailureKind';
import type { StepPlayArguments } from '@sb/routine-runner/Step/Step';
import Step from '@sb/routine-runner/Step/Step';

import { haasAddressHandle } from '../../implementation/haasAddressHandle';
import type { HaasInterface } from '../../types';

import { HaasRunProgramArguments } from './Arguments';
import { HaasRunProgramVariables } from './Variables';

export class HaasRunProgramStep extends Step<
  HaasRunProgramArguments,
  HaasRunProgramVariables
> {
  public static areSubstepsRequired = false;

  public static Arguments = HaasRunProgramArguments;

  public static Variables = HaasRunProgramVariables;

  protected initializeVariableState(): void {
    const { isProgramComplete = true } = this.variablesForInitialization;

    if (!isProgramComplete) {
      this.pickUpAlreadyRunningProgram();
    }

    this.variables = {
      // if isProgramComplete is false, we want to roll over
      // that status and assume this program is still running on the Haas.
      isProgramComplete,
    };
  }

  private alreadyRunningProgramPromise?: Promise<void>;

  private programPromise?: Promise<void>;

  public async _play({ fail }: StepPlayArguments): Promise<void> {
    const { waitUntilIdle, disableCellSafe, address, programName } = this.args;
    let haas: HaasInterface;

    try {
      haas = await this.routineContext.equipment.getHaas(address);
    } catch (error) {
      return fail({
        failure: {
          kind: FailureKind.HaasFailure,
        },
        failureReason: `Could not connect to Haas at ${address} over Telnet (error: ${error.message}). Is the Haas machine definitely accessible?`,
        error,
      });
    }

    if (this.alreadyRunningProgramPromise) {
      this.routineContext.logger.info(
        `${programName} already running. Waiting for it to stop...`,
      );

      try {
        await this.alreadyRunningProgramPromise;
      } catch (error) {
        return fail({
          failure: {
            kind: FailureKind.HaasFailure,
          },
          failureReason: `Haas was already running ${programName}, but the execution failed: ${error.message}`,
          error,
        });
      }
    }

    this.setVariable('isProgramComplete', false);

    const startedInCellSafe = haas.inCellSafe;

    if (disableCellSafe) {
      this.routineContext.logger.info(
        `Disabling cell safe for Haas program ${programName}`,
      );

      haas.stopCellSafe();
    }

    if (this.programPromise) {
      this.routineContext.logger.warn(
        `Haas program ${programName} played twice`,
      );
    }

    this.routineContext.logger.info(`Running Haas program ${programName}...`);

    this.programPromise = haas
      .runProgram(programName)
      .then(() => {
        this.routineContext.logger.info(`Haas program ${programName} complete`);
        this.setVariable('isProgramComplete', true);

        if (disableCellSafe && startedInCellSafe) {
          this.routineContext.logger.info(
            `Reenabling cell safe after ${programName}`,
          );

          haas.startCellSafe();
        }
      })
      .finally(() => {
        delete this.programPromise;
      });

    if (waitUntilIdle) {
      try {
        await this.programPromise;
      } catch (error) {
        return fail({
          failure: {
            kind: FailureKind.HaasFailure,
          },
          failureReason: `Failed to run the program: ${error.message}`,
          error,
        });
      }
    } else {
      this.programPromise.catch((error) => {
        this.routineContext.logger.error(
          `Failed to run ${programName} on Haas (${haasAddressHandle(
            address,
          )})`,
          error,
        );

        fail({
          failure: {
            kind: FailureKind.HaasFailure,
          },
          failureReason: `Failed to run the program: ${error.message}`,
          error,
          asynchronous: true,
        });
      });
    }

    return undefined;
  }

  public _stop(): void {
    delete this.programPromise;
  }

  /**
   * If we think the program is already running before the
   * step has been played (due to old variables, we can pick
   * it back up and try to determine when it stops).
   *
   * Resolves when the program stops.
   */
  private pickUpAlreadyRunningProgram() {
    this.alreadyRunningProgramPromise = this.routineContext.equipment
      .getHaas(this.args.address)
      .then((haas) => {
        if (haas.runningProgram !== this.args.programName) {
          return undefined;
        }

        if (this.args.disableCellSafe) {
          this.routineContext.logger.info(
            `Disabling cell safe for previous run of ${this.args.programName}`,
          );

          haas.stopCellSafe();
        }

        return new Promise<void>((resolve) => {
          const offProgramComplete = haas.onProgramComplete(() => {
            offProgramComplete();
            resolve();
            delete this.alreadyRunningProgramPromise;

            if (this.args.disableCellSafe) {
              this.routineContext.logger.info(
                `Reenabling cell safe after previous run of ${this.args.programName}`,
              );

              haas.startCellSafe();
            }
          });
        });
      })
      .finally(() => {
        this.setVariable('isProgramComplete', true);
      });

    // error will be properly caught when play() is called, but if there is
    // an error before the step gets played, this would result
    // in an UnhandledPromiseWarning (but no bad behavior).
    this.alreadyRunningProgramPromise.catch((_error) => {});
  }
}
