import * as zod from 'zod';

import { CustomIOConfiguration } from './CustomIO/types/Configuration';
import { EwellixLiftTLTCommand, EwellixLiftTLTState } from './EwellixLiftTLT';
import { EwellixLiftTLTConfiguration } from './EwellixLiftTLT/types/Configuration';
import { GripperStateNonNull } from './gripper-general';
import { InvalidConfiguration } from './InvalidConfiguration/types/Configuration';
import { NoGripperConfiguration } from './NoGripper/types/Configuration';
import { OnRobot2FG7Command } from './OnRobot2FG7';
import { OnRobot2FG7GrippersConfiguration } from './OnRobot2FG7/types/Configuration';
import { OnRobot3FG15Command } from './OnRobot3FG15';
import { OnRobot3FG15GrippersConfiguration } from './OnRobot3FG15/types/Configuration';
import { OnRobotDualQuickChangerCommand } from './OnRobotDualQuickChanger';
import { DualEquipmentConfiguration } from './OnRobotDualQuickChanger/types/Configuration';
import { OnRobotScrewdriverCommand } from './OnRobotScrewdriver';
import { OnRobotScrewdriverConfiguration } from './OnRobotScrewdriver/types/Configuration';
import { OnRobotVGP20Command } from './OnRobotVGP20';
import { OnRobotVGP20Configuration } from './OnRobotVGP20/types/Configuration';

export const DeviceCommand = zod.union([
  OnRobot3FG15Command,
  OnRobot2FG7Command,
  OnRobotDualQuickChangerCommand,
  OnRobotScrewdriverCommand,
  OnRobotVGP20Command,
  EwellixLiftTLTCommand,
]);

export type DeviceCommand = zod.infer<typeof DeviceCommand>;

export const DeviceConfiguration = zod.union([
  DualEquipmentConfiguration,
  OnRobotScrewdriverConfiguration,
  OnRobotVGP20Configuration,
  OnRobot2FG7GrippersConfiguration,
  OnRobot3FG15GrippersConfiguration,
  EwellixLiftTLTConfiguration,
  NoGripperConfiguration,
  CustomIOConfiguration,
  InvalidConfiguration,
]);

export type DeviceConfiguration = zod.infer<typeof DeviceConfiguration>;

// only one right now but may become a union in the future
export const DynamicBaseState = EwellixLiftTLTState;
export type DynamicBaseState = zod.infer<typeof DynamicBaseState>;

export const DeviceState = zod.union([GripperStateNonNull, DynamicBaseState]);
export type DeviceState = zod.infer<typeof DeviceState>;

export type DeviceKind = DeviceConfiguration['kind'];
