import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ArrowUpIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 12 8" {...props}>
      <path d="M6 1.993l4.966 5.463.74-.673L6 .507.294 6.784l.74.673z" />
    </Icon>
  );
}
