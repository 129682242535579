const LITTLE_ENDIAN = true;
const BIG_ENDIAN = false;

function fromFactory(
  width: number,
  setView: (view: DataView, num: number) => void,
) {
  return (num: number): Uint8Array => {
    const buffer = new ArrayBuffer(width);
    const view = new DataView(buffer);
    setView(view, num);

    return new Uint8Array(buffer);
  };
}

function toFactory(getView: (view: DataView, offset: number) => number) {
  return (arr: Uint8Array, offset = 0): number => {
    const view = new DataView(arr.buffer);

    return getView(view, offset + arr.byteOffset);
  };
}

/**
 * Helpers to convert between Uint8Arrays and numbers
 */
export const convertBytes = {
  fromUint8: (num: number): Uint8Array => {
    return Uint8Array.from([num]);
  },

  fromUint16LE: fromFactory(2, (view, num) =>
    view.setUint16(0, num, LITTLE_ENDIAN),
  ),

  fromUint16BE: fromFactory(2, (view, num) =>
    view.setUint16(0, num, BIG_ENDIAN),
  ),

  fromUint32LE: fromFactory(4, (view, num) =>
    view.setUint32(0, num, LITTLE_ENDIAN),
  ),

  fromFloat32LE: fromFactory(4, (view, num) =>
    view.setFloat32(0, num, LITTLE_ENDIAN),
  ),

  fromFloat64LE: fromFactory(8, (view, num) =>
    view.setFloat64(0, num, LITTLE_ENDIAN),
  ),

  toUint8: (arr: Uint8Array, offset = 0): number => {
    return arr[offset];
  },

  toUint16LE: toFactory((view, offset) =>
    view.getUint16(offset, LITTLE_ENDIAN),
  ),

  toInt16LE: toFactory((view, offset) => view.getInt16(offset, LITTLE_ENDIAN)),

  toUint16BE: toFactory((view, offset) => view.getUint16(offset, BIG_ENDIAN)),

  toInt16BE: toFactory((view, offset) => view.getInt16(offset, BIG_ENDIAN)),

  toUint32LE: toFactory((view, offset) =>
    view.getUint32(offset, LITTLE_ENDIAN),
  ),

  toInt32LE: toFactory((view, offset) => view.getInt32(offset, LITTLE_ENDIAN)),

  toFloat32LE: toFactory((view, offset) =>
    view.getFloat32(offset, LITTLE_ENDIAN),
  ),

  toFloat64LE: toFactory((view, offset) =>
    view.getFloat64(offset, LITTLE_ENDIAN),
  ),
};
