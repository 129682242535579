import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function GripperIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path
        d="M8.185 9.453l1.523 2.284 5.402-1.35a.5.5 0 0 1 .604.617h0l-.538 1.974a.5.5 0 0 1-.324.343h0l-6.462 2.154a.5.5 0 0 1-.612-.265h0l-1.779-3.854c.325-.096.63-.239.908-.42h0l1.591 3.448 5.783-1.928.243-.892-4.915 1.229a.5.5 0 0 1-.537-.208h0l-1.485-2.227c.244-.267.446-.572.599-.905h0zm-6.072.526a3.52 3.52 0 0 0 .728.776h0l-1.644.705a.5.5 0 1 1-.394-.919h0zM5 5.5a2.5 2.5 0 0 1 0 5 2.5 2.5 0 0 1 0-5zm0 1a1.5 1.5 0 0 0 0 3 1.5 1.5 0 1 0 0-3zM8.423.539l6.462 2.692a.5.5 0 0 1 .276.286l.538 1.436a.5.5 0 0 1-.589.661l-5.402-1.35-1.523 2.284c-.152-.333-.355-.638-.599-.905l1.485-2.227a.5.5 0 0 1 .537-.208l4.82 1.205-.128-.342-5.817-2.424-1.577 3.417c-.278-.181-.583-.323-.908-.42L7.777.791a.5.5 0 0 1 .646-.252zM.541 4.803a.5.5 0 0 1 .657-.263h0l1.644.705a3.52 3.52 0 0 0-.728.776h0L.803 5.46a.5.5 0 0 1-.263-.657z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
