import type { MotionSpeed, Step } from '@sb/types';

export const getMotionSpeedFromStepConfiguration = (
  stepConfiguration?: Step.ConvertedConfiguration,
): MotionSpeed | null => {
  const args = stepConfiguration?.args;

  if (args && 'motionSpeed' in args) {
    return args.motionSpeed ?? null;
  }

  return null;
};
