import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SwapIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M3.146.146a.5.5 0 0 1 .707 0l3 3a.5.5 0 0 1-.707.707L3.5 1.207.854 3.854a.5.5 0 0 1-.707 0 .5.5 0 0 1 0-.707l3-3zM3.5 0a.5.5 0 0 1 .5.5v12a.5.5 0 1 1-1 0V.5a.5.5 0 0 1 .5-.5zm5.646 12.146a.5.5 0 0 1 .707 0l2.646 2.646 2.646-2.646a.5.5 0 1 1 .707.707l-3 3a.5.5 0 0 1-.707 0l-3-3a.5.5 0 0 1 0-.707zM12.5 3a.5.5 0 0 1 .5.5v12a.5.5 0 1 1-1 0v-12a.5.5 0 0 1 .5-.5z" />
    </Icon>
  );
}
