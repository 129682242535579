import cx from 'classnames';
import type { ButtonHTMLAttributes } from 'react';

import styles from './ToggleButton.module.css';

interface ToggleButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean;
}

const ToggleButton = ({
  children,
  className,
  isSelected,
  ...other
}: ToggleButtonProps) => {
  return (
    <button
      className={cx(
        styles.toggleButton,
        { [styles.selected]: isSelected },
        className,
      )}
      {...other}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
