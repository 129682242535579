import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PhoneIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 17 16">
      <path d="M8.23 11.542l.276-.345.368.227-.644.12zM8.502 4a.5.5 0 0 1 .5-.5 4 4 0 0 1 4 4 .5.5 0 1 1-1 0 3 3 0 0 0-3-3 .5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5 7 7 0 0 1 4.95 2.05 7 7 0 0 1 2.05 4.95.5.5 0 0 1-1 0 6 6 0 0 0-1.757-4.243A6 6 0 0 0 9.002 1.5a.5.5 0 0 1-.5-.5zm.787 9.217a1.4 1.4 0 0 1 1.661-.404l2.723 1.205a1.4 1.4 0 0 1 .787 1.631l-.6 2.298a1.4 1.4 0 0 1-1.554 1.039A13.85 13.85 0 0 1 .514 4.201a1.4 1.4 0 0 1 1.037-1.555l2.3-.6a1.4 1.4 0 0 1 1.632.787L6.7 5.55a1.4 1.4 0 0 1-.405 1.662l-.98.783c.826 1.283 1.918 2.375 3.2 3.2l.783-.98zm-.278 1.95a.5.5 0 0 1-.644.119c-1.714-1.008-3.143-2.437-4.15-4.15a.5.5 0 0 1 .119-.644l1.325-1.06a.4.4 0 0 0 .116-.475L4.57 3.24a.4.4 0 0 0-.466-.225l-2.302.6a.4.4 0 0 0-.297.446 12.85 12.85 0 0 0 10.943 10.936.4.4 0 0 0 .445-.299l.6-2.3a.4.4 0 0 0-.224-.466l-2.723-1.205a.4.4 0 0 0-.474.115l-1.06 1.325z" />
    </Icon>
  );
}
