import type { Routine } from '@sb/types';

import { patchBuilder } from '../utils';

import { getRoutinesService } from './utils';

/**
 * Sometimes we need to add a single step to the action required field
 * in the routine document. For example, this happens when a step doesn't
 * pass a validation and we want to make sure it displays the "action required"
 * label in the frontend.
 */
export async function setStepAsActionRequired(
  routineID: string,
  stepID: string,
  actionRequired: Routine.ActionRequiredStep,
): Promise<void> {
  const patch = patchBuilder()
    .set(`actionRequiredByStepID.${stepID}`, actionRequired)
    .buildPatch();

  await getRoutinesService().patch(routineID, patch);
}
