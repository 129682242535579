import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function SaveIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M2 1C1.73478 1 1.48043 1.10536 1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V14C1 14.2652 1.10536 14.5196 1.29289 14.7071C1.48043 14.8946 1.73478 15 2 15H14C14.2652 15 14.5196 14.8946 14.7071 14.7071C14.8946 14.5196 15 14.2652 15 14V4.70711L11.2929 1H2ZM2 0H11.5C11.6326 0 11.7598 0.0526784 11.8536 0.146447L15.8536 4.14645C15.9473 4.24021 16 4.36739 16 4.5V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0Z" />
      <path d="M3.5 2C3.77614 2 4 2.22386 4 2.5V5H9V2.5C9 2.22386 9.22386 2 9.5 2C9.77614 2 10 2.22386 10 2.5V5.5C10 5.77614 9.77614 6 9.5 6H3.5C3.22386 6 3 5.77614 3 5.5V2.5C3 2.22386 3.22386 2 3.5 2Z" />
      <path d="M3 9.5C3 9.22386 3.22386 9 3.5 9H12.5C12.7761 9 13 9.22386 13 9.5V13.5C13 13.7761 12.7761 14 12.5 14C12.2239 14 12 13.7761 12 13.5V10H4V13.5C4 13.7761 3.77614 14 3.5 14C3.22386 14 3 13.7761 3 13.5V9.5Z" />
    </Icon>
  );
}
