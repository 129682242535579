/**
 * Retrieves every possible combination of pick-one-per-set.
 * Adapted from https://github.com/ehmicky/big-cartesian.
 * @see https://en.wikipedia.org/wiki/Cartesian_product
 */
export function* cartesianProduct<T extends Array<Iterable<unknown>>>(
  ...iterables: T
): Generator<{
  [index in keyof T]: T[index] extends Iterable<infer U> ? U : never;
}> {
  if (iterables.length === 0) {
    return;
  }

  if (iterables.length === 1) {
    for (const h of iterables[0]) {
      yield [h] as any;
    }

    return;
  }

  // the _last_ iterable is the 'head' so that this generator yields in the expected order

  const head = iterables[iterables.length - 1];
  const remainder = cartesianProduct(...iterables.slice(0, -1));

  for (const r of remainder) {
    for (const h of head) {
      yield [...r, h] as any;
    }
  }
}
