/**
 * Safety: A label corresponding to a safety input port on the IO board
 */
import * as zod from 'zod';

export const SAFETY_PORT_NAMES = [
  'E-Stop 1',
  'E-Stop 2',
  'Safeguard 1',
  'Safeguard 2',
] as const;

export const SAFETY_PORT_COUNT = SAFETY_PORT_NAMES.length;

export const SafetyPort = zod.enum(SAFETY_PORT_NAMES);

export type SafetyPort = zod.infer<typeof SafetyPort>;
