import cx from 'classnames';
import { cloneElement, forwardRef } from 'react';

import type { ColorVariant } from '@sb/ui';

import { textColor } from '../styles';

import styles from './MenuItem.module.css';

interface MenuItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  hasDivider?: boolean;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  variant?: ColorVariant;
  contentClassName?: string;
  'data-testid'?: string;
}

const MenuItem = forwardRef<HTMLButtonElement, MenuItemProps>((props, ref) => {
  const {
    children,
    className,
    contentClassName,
    disabled,
    hasDivider,
    startIcon,
    endIcon,
    variant = 'black',
    'data-testid': testID,
    ...other
  } = props;

  const StartIconComponent =
    startIcon &&
    cloneElement(startIcon, {
      className: cx(styles.startIcon, startIcon.props.className),
    });

  const EndIconComponent =
    endIcon &&
    cloneElement(endIcon, {
      className: cx(styles.endIcon, endIcon.props.className),
    });

  return (
    <button
      ref={ref}
      className={cx(
        !disabled && textColor[variant],
        hasDivider && styles.divider,
        styles.menuItem,
        className,
      )}
      disabled={disabled}
      data-testid={testID}
      {...other}
    >
      {StartIconComponent}
      <span
        data-testid={testID && `${testID}--label`}
        className={cx(styles.content, contentClassName)}
      >
        {children}
      </span>
      {EndIconComponent}
    </button>
  );
});

export default MenuItem;
