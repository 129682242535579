import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function RoutineIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path d="M9.25 3.291v7.417h-4.5V3.291h4.5zm-3.5 1v5.417h2.5V4.291h-2.5zm5.834 0v5.417h2.333v1h-3.333V3.291h3.333v1h-2.333zm-9.167 0H.084v-1h3.333v7.417H.084v-1h2.333V4.291zm9-3.916a2.5 2.5 0 0 1 2.5 2.5v8.25a2.5 2.5 0 0 1-2.5 2.5H2.584a2.5 2.5 0 0 1-2.5-2.5v-8.25a2.5 2.5 0 0 1 2.5-2.5h8.833zm0 1H2.584a1.5 1.5 0 0 0-1.5 1.5v8.25a1.5 1.5 0 0 0 1.5 1.5h8.833a1.5 1.5 0 0 0 1.5-1.5v-8.25a1.5 1.5 0 0 0-1.5-1.5z" />
    </Icon>
  );
}
