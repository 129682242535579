import * as zod from 'zod';

import { TooltipConfiguration } from '@sb/integrations/gripper-general';

export const OnRobot3FG15GrippersConfiguration = zod.object({
  kind: zod.literal('OnRobot3FG15'),
  name: zod.string().optional(),
  fingerConfiguration: zod.optional(
    zod.object({
      lengthInMeters: zod.number(),
      mountingPosition: zod.union([
        zod.literal(1),
        zod.literal(2),
        zod.literal(3),
      ]),
      offsetInMeters: zod.number(),
    }),
  ),
  tooltipConfig: TooltipConfiguration.optional(),
});

export type OnRobot3FG15GrippersConfiguration = zod.infer<
  typeof OnRobot3FG15GrippersConfiguration
>;
