import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ArrowDownFilledIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 9 7" {...props}>
      <path d="M4.5 6.5L9 0L0 0L4.5 6.5Z" />
    </Icon>
  );
}
