import cx from 'classnames';
import { useState } from 'react';

import { DownloadIcon, UploadIcon } from '@sb/ui/icons';

import styles from './FileInput.module.css';

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  fileName?: string;
  downloadURL?: string;
  uploadFile?: (file: File) => Promise<void>;
}

export function FileInput({
  className,
  fileName,
  downloadURL,
  uploadFile,
  disabled,
  onChange,
  ...inputProps
}: FileInputProps) {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event,
  ) => {
    const inputElement = event.currentTarget;

    const fileList: FileList | null = inputElement.files;

    if (!inputElement.files || !uploadFile) {
      return;
    }

    setIsUploading(true);

    try {
      if (fileList) {
        const inputFileList = Array.from(fileList);

        for (const file of inputFileList) {
          await uploadFile(file);
        }
      }
    } catch (error) {
      // uploadFile must handle its own error
      // eslint-disable-next-line no-console
      console.error(error);
    }

    setIsUploading(false);

    onChange?.(event);

    inputElement.value = '';
  };

  const getUploadLabel = () => {
    if (isUploading) {
      return 'Uploading…';
    }

    if (downloadURL) {
      return 'Replace';
    }

    return 'Upload';
  };

  return (
    <div className={cx(styles.container, className)}>
      {downloadURL ? (
        <>
          <a
            className={cx(styles.button, styles.downloadButton)}
            href={downloadURL}
            download={fileName ?? true}
          >
            <DownloadIcon size="extraSmall" />
          </a>

          <span className={styles.fileName}>
            {fileName ?? 'No file chosen'}
          </span>
        </>
      ) : (
        <span className={cx(styles.fileName, styles.noFileChosen)}>
          No file chosen
        </span>
      )}

      <label
        className={cx(
          styles.button,
          styles.uploadButton,
          (isUploading || disabled) && styles.isDisabled,
        )}
        role="button" // eslint-disable-line jsx-a11y/no-noninteractive-element-to-interactive-role
      >
        <UploadIcon size="extraSmall" />
        {getUploadLabel()}

        <input
          {...inputProps}
          type="file"
          className={styles.visuallyHidden}
          disabled={isUploading || disabled}
          onChange={handleChange}
        />
      </label>
    </div>
  );
}
