import { identity, isEqual as isDeepEqual } from 'lodash';
import { useEffect, useState } from 'react';

import type { RoutineRunnerState } from '@sb/routine-runner';

import useIsRobotConnected from './useIsRobotConnected';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

export default function useRobotState<TData = RoutineRunnerState | null>(
  args: UseRoutineRunnerHandleArguments,
  selector: (state: RoutineRunnerState | null) => TData = identity,
  isEqual: (dataA: TData, dataB: TData) => boolean = isDeepEqual,
): TData {
  const routineRunnerHandle = useRoutineRunnerHandle(args);
  const isConnected = useIsRobotConnected(args);

  const [data, setData] = useState<TData>(() =>
    selector(routineRunnerHandle.getState()),
  );

  useEffect(() => {
    const unsubscribe = routineRunnerHandle.onStateChange(
      selector,
      isEqual,
      setData,
    );

    return () => unsubscribe();
  }, [routineRunnerHandle, selector, isEqual]);

  return isConnected ? data : selector(null);
}
