import { useContext, useEffect } from 'react';

import type { ArmJointPositions } from '@sb/motion-planning';
import type { Routine } from '@sb/types';
import { GlobalContext } from '@sbrc/contexts';
import { VizbotRoutineRunnerHandle } from '@sbrc/services';

const nullHandle = new VizbotRoutineRunnerHandle();

interface VizBotHandleProps {
  getInitialJointAngles?: () => ArmJointPositions | undefined;
  routine?: Routine.ConvertedResponse | null;
}

export function useVizbotRoutineRunnerHandle({
  getInitialJointAngles,
  routine,
}: VizBotHandleProps = {}): VizbotRoutineRunnerHandle {
  const { vizbotRoutineRunnerHandle = nullHandle } = useContext(GlobalContext);

  const equipment = routine?.equipment;

  useEffect(() => {
    if (equipment) {
      vizbotRoutineRunnerHandle.resetEquipmentList(equipment);
    }
  }, [equipment, vizbotRoutineRunnerHandle]);

  useEffect(() => {
    const jointAngles = getInitialJointAngles?.();

    if (jointAngles) {
      vizbotRoutineRunnerHandle.setJointPositions(jointAngles);
    }
  }, [getInitialJointAngles, vizbotRoutineRunnerHandle]);

  return vizbotRoutineRunnerHandle;
}
