import { useEffect, useState } from 'react';

import { UserSession } from '@sbrc/utils';

export default function useIsCurrentUserRunningAdhocCommand() {
  const [isMovingRobot, setIsMovingRobot] = useState(false);

  useEffect(() => {
    return UserSession.onMoveRobotChange(setIsMovingRobot);
  }, []);

  return isMovingRobot;
}
