import type { GripperState } from '../gripper-general/GripperState';
import { calculateOR2FG7CommandFromState } from '../OnRobot2FG7';
// being more specific here fixes a dependency cyle that would
// not exist if the 3fg15 didn't reference the dual quick changer
import { calculateOR3FG15CommandFromState } from '../OnRobot3FG15/utils';

import type { OnRobotDualQuickChangerCommand } from './Command';
import { OnRobotDualQuickChangerState } from './State';

// TODO: Missing secondary child command
export function calculateORDualQuickChangerCommandFromState(
  state: GripperState,
): OnRobotDualQuickChangerCommand {
  if (!state) {
    throw new Error('Null gripper state');
  }

  const parsedState = OnRobotDualQuickChangerState.parse(state);

  switch (parsedState.primary?.kind) {
    case 'OnRobot3FG15': {
      return {
        kind: 'OnRobotDualQuickChangerCommand',
        active: 'primary',
        command: calculateOR3FG15CommandFromState(parsedState.primary),
      };
    }

    case 'OnRobot2FG7': {
      return {
        kind: 'OnRobotDualQuickChangerCommand',
        active: 'primary',
        command: calculateOR2FG7CommandFromState(parsedState.primary),
      };
    }

    default: {
      throw new Error(`Unsupported child gripper`);
    }
  }
}
