import type * as zod from 'zod';

import type { CartesianPosition } from '@sb/geometry';

import { FailureKind } from '../../FailureKind';
import type { Expression, StepFailure } from '../../types';
import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

export default class AddOffset extends Step<Arguments, Variables> {
  private translation: CartesianPosition = { x: 0, y: 0, z: 0 };

  public static areSubstepsRequired = true;

  public static Arguments = Arguments;

  public static Variables = Variables;

  public substeps: Array<Step<object, object>> = [];

  public initializeVariableState(): void {
    this.variables = {};
  }

  public async _play({ fail, playSubSteps }: StepPlayArguments): Promise<void> {
    const [error, translation] = await this.getTranslation();

    if (error) {
      return fail(error);
    }

    if (await super.shouldStopPlaying()) {
      return;
    }

    this.translation = translation!;
    this.routineContext.addMovementTranslation(this.translation);

    await playSubSteps();

    this.undoTranslation();
  }

  /**
   * Calculate forwards and inverse translations. Apply forward translation before
   * executing child steps, apply inverse after.
   * @returns [forwardTranslation, inverseTranslation]
   */
  private async getTranslation(): Promise<
    [StepFailure, void] | [void, CartesianPosition]
  > {
    const { multiplier, translationX, translationY, translationZ } = this.args;

    const [[errX, x], [errY, y], [errZ, z]] = await Promise.all([
      this.evaluateExpression(translationX, 'x', multiplier),
      this.evaluateExpression(translationY, 'y', multiplier),
      this.evaluateExpression(translationZ, 'z', multiplier),
    ]);

    const firstError = errX || errY || errZ;

    if (firstError) {
      return [firstError, undefined];
    }

    return [undefined, { x, y, z }];
  }

  private async evaluateExpression(
    rawValue: Expression | void,
    description: string,
    multiplier: number,
  ): Promise<[void | StepFailure, number]> {
    if (rawValue === undefined) {
      return [undefined, 0];
    }

    let evaluatedValue =
      (await this.routineContext.evaluateExpression(rawValue)) ?? 0;

    if (typeof evaluatedValue === 'string') {
      evaluatedValue = parseFloat(evaluatedValue);
    }

    if (typeof evaluatedValue !== 'number') {
      return [
        {
          failure: { kind: FailureKind.ExecutionFailure },
          failureReason: `Value for ${description} should be a number, received ${typeof evaluatedValue}`,
        },
        0,
      ];
    }

    if (Number.isNaN(evaluatedValue)) {
      return [
        {
          failure: { kind: FailureKind.ExecutionFailure },
          failureReason: `Value for ${description} should be a number`,
        },
        0,
      ];
    }

    evaluatedValue *= multiplier;

    return [undefined, evaluatedValue];
  }

  private undoTranslation() {
    this.routineContext.addMovementTranslation({
      x: 0 - this.translation.x,
      y: 0 - this.translation.y,
      z: 0 - this.translation.z,
    });
  }
}
