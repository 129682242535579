import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function UndoIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 15 16">
      <path d="M2.355 5c1.2-1.87 3.06-3 5.145-3a6.5 6.5 0 1 1 0 13A6.5 6.5 0 0 1 1 8.5H0a7.5 7.5 0 1 0 15 0A7.5 7.5 0 0 0 7.5 1C5.33 1 3.37 2.04 2 3.775V.5H1V6h5.5V5H2.355z" />
    </Icon>
  );
}
