import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function NextIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 7 12">
      <polygon points="-0.206129439 10.0371647 0.537164707 10.7061294 5.6726812 5 0.537164707 -0.706129439 -0.206129439 -0.0371647073 4.3273188 5" />
    </Icon>
  );
}
