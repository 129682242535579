import { useEffect, useState } from 'react';

import { calculatePercent } from './calculatePercent';

interface UseSliderInteractionsProps {
  min: number;
  max: number;
  isDisabled?: boolean;
  onChange?: (value: number) => void;
  setIsInteracting: (isInteracting: boolean) => void;
}

const PERCENT_TO_FRACTION_MULTIPLIER = 0.01;
const PRIMARY_MOUSE_BUTTON_ID = 1;

export function useSliderInteractions({
  min,
  max,
  isDisabled,
  onChange,
  setIsInteracting,
}: UseSliderInteractionsProps) {
  const [mouseHandler, setMouseHandler] = useState<
    null | ((e: MouseEvent) => void)
  >(null);

  let onMouseDown: React.MouseEventHandler<HTMLDivElement> | undefined;
  let onTouchStart: React.TouchEventHandler<HTMLDivElement> | undefined;

  if (onChange && !isDisabled) {
    const onChangeCalc = (clientX: number, left: number, right: number) => {
      const newFraction =
        calculatePercent(clientX, left, right) * PERCENT_TO_FRACTION_MULTIPLIER;

      const newValue = min + newFraction * (max - min);
      onChange(newValue);
    };

    onMouseDown = (event) => {
      if (event.buttons !== PRIMARY_MOUSE_BUTTON_ID) {
        return;
      }

      const { left, right } = event.currentTarget.getBoundingClientRect();

      const handleMove = ({ type, clientX, buttons }: MouseEvent) => {
        onChangeCalc(clientX, left, right);

        if (type === 'mouseup' || buttons !== PRIMARY_MOUSE_BUTTON_ID) {
          setMouseHandler(null);
          setIsInteracting(false);
        }
      };

      handleMove(event.nativeEvent);
      setMouseHandler(() => handleMove);
      setIsInteracting(true);
    };

    onTouchStart = (event) => {
      const targetElement = event.currentTarget;
      const { left, right } = targetElement.getBoundingClientRect();

      const handleMove = ({ type, targetTouches }: TouchEvent) => {
        const clientX = targetTouches[0]?.clientX;

        if (clientX !== undefined) {
          onChangeCalc(clientX, left, right);
        }

        if (
          type === 'touchend' ||
          type === 'touchcancel' ||
          clientX === undefined
        ) {
          targetElement.removeEventListener('touchmove', handleMove);
          targetElement.removeEventListener('touchend', handleMove);
          targetElement.removeEventListener('touchcancel', handleMove);
          setIsInteracting(false);
        }
      };

      targetElement.addEventListener('touchmove', handleMove);
      targetElement.addEventListener('touchend', handleMove);
      targetElement.addEventListener('touchcancel', handleMove);

      handleMove(event.nativeEvent);
      setIsInteracting(true);
    };
  }

  useEffect(() => {
    if (mouseHandler && !isDisabled) {
      document.addEventListener('mousemove', mouseHandler);
      document.addEventListener('mouseup', mouseHandler);

      return () => {
        document.removeEventListener('mousemove', mouseHandler);
        document.removeEventListener('mouseup', mouseHandler);
      };
    }

    return undefined;
  }, [mouseHandler, isDisabled]);

  return { onMouseDown, onTouchStart };
}
