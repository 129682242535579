import cx from 'classnames';

import styles from './AlertActions.module.css';

interface AlertActionsProps {
  children: React.ReactNode;
  className?: string;
}

const AlertActions = ({ className, children }: AlertActionsProps) => {
  return <div className={cx(styles.alertActions, className)}>{children}</div>;
};

export default AlertActions;
