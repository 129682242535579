import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function BrakeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 10" {...props}>
      <path d="M11.95.05a7 7 0 0 1 .213 9.676l-.213.223-.707-.707A6 6 0 0 0 11.436.96l-.193-.203.707-.707zM2.05.05l.707.707a6 6 0 0 0-.193 8.283l.193.203-.707.707A7 7 0 0 1 1.837.273L2.05.05zM7 .125c2.692 0 4.875 2.183 4.875 4.875S9.692 9.875 7 9.875 2.125 7.692 2.125 5 4.308.125 7 .125zm0 1C4.86 1.125 3.125 2.86 3.125 5S4.86 8.875 7 8.875 10.875 7.14 10.875 5 9.14 1.125 7 1.125zm0 5.188c.322 0 .583.261.583.583s-.261.583-.583.583-.583-.261-.583-.583.261-.583.583-.583zm.5-3.937v2.917h-1V2.375h1z" />
    </Icon>
  );
}
