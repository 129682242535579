import cx from 'classnames';

import { strokeColor } from '../styles';

import type { IconProps } from './Icon';
import { Icon } from './Icon';

import styles from './Icon.module.css';

export function CircuitBoardIcon(props: IconProps) {
  const { className, variant } = props;

  return (
    <Icon
      {...props}
      viewBox="0 0 16 16"
      className={cx(styles.stokeCurrentColor, className)}
    >
      <polyline
        points="15.5 5.5 15.5 11.409 11.5 15.5 4.5 15.5"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <polyline
        points="10.061 9.939 12.5 7.5 12.5 3.5 13.5 2.5"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-color="color-2"
      />
      <polyline
        points="2.5 13.5 3.5 11.5 3.5 8.5 5.939 6.061"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-color="color-2"
      />
      <polyline
        points="10.5 0.5 4.5 0.5 0.5 4.5 0.5 11.5"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={7}
        cy={5}
        r="1.5"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-color="color-2"
      />
      <circle
        cx={9}
        cy={11}
        r="1.5"
        fill="none"
        stroke={cx(variant && strokeColor[variant])}
        strokeLinecap="round"
        strokeLinejoin="round"
        data-color="color-2"
      />
    </Icon>
  );
}
