import { useRef, useState } from 'react';

export const useCopy = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [blinkingDidCopy, setBlinkingDidCopy] = useState(false);

  return [
    <input style={{ display: 'none' }} ref={inputRef} />,
    (text: string) => {
      if (!inputRef.current) return;
      inputRef.current.select();
      navigator.clipboard.writeText(text);
      setBlinkingDidCopy(true);
      setTimeout(() => setBlinkingDidCopy(false), 1_500);
    },
    blinkingDidCopy,
  ] as const;
};
