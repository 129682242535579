import cx from 'classnames';

import Typography from '../Typography';

import styles from './Multiselect.module.css';

interface MultiselectProps {
  children: React.ReactNodeArray;
  className?: string;
  max?: number;
}

const Multiselect = ({ children, className, max }: MultiselectProps) => {
  // Don't render more than the `max` items.
  const itemsToRender = max ? children.slice(0, max) : children;

  // How many items are hidden
  const hiddenItemsCount =
    max && children.length > max ? children.length - max : 0;

  return (
    <div className={cx(styles.multiselect, className)}>
      {itemsToRender}

      {Boolean(hiddenItemsCount) && (
        <Typography variant="medium">+{hiddenItemsCount}</Typography>
      )}
    </div>
  );
};

export default Multiselect;
