import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ShieldIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 17">
      <path d="M8.00008 3.5C8.19041 3.5 8.36423 3.60806 8.44846 3.77873L9.46915 5.84705L11.7521 6.17921C11.9404 6.20661 12.0969 6.33856 12.1556 6.51957C12.2144 6.70059 12.1653 6.89927 12.029 7.0321L10.3773 8.64165L10.7669 10.9156C10.799 11.1031 10.7219 11.2927 10.5679 11.4046C10.4139 11.5164 10.2098 11.5311 10.0413 11.4425L8.00008 10.3689L5.95883 11.4425C5.79038 11.5311 5.58625 11.5164 5.43226 11.4046C5.27827 11.2927 5.20112 11.1031 5.23326 10.9156L5.62287 8.64165L3.97113 7.0321C3.83483 6.89927 3.78575 6.70059 3.84453 6.51957C3.90331 6.33856 4.05976 6.20661 4.24809 6.17921L6.53101 5.84705L7.55171 3.77873C7.63593 3.60806 7.80976 3.5 8.00008 3.5ZM8.00008 5.12985L7.31146 6.52527C7.23864 6.67282 7.0979 6.7751 6.93507 6.79879L5.39459 7.02293L6.50903 8.1089C6.62689 8.22375 6.68069 8.38924 6.6529 8.55144L6.38999 10.0859L7.76734 9.36147C7.91304 9.28484 8.08713 9.28484 8.23283 9.36147L9.61017 10.0859L9.34726 8.55144C9.31947 8.38924 9.37327 8.22375 9.49113 8.1089L10.6056 7.02293L9.06509 6.79879C8.90227 6.7751 8.76152 6.67282 8.68871 6.52527L8.00008 5.12985Z" />
      <path d="M7.86263 0.51924C7.95241 0.493587 8.04759 0.493587 8.13737 0.51924L15.6374 2.66224C15.852 2.72357 16 2.91976 16 3.143C16 8.37002 14.0687 11.7066 12.1097 13.7355C11.1346 14.7455 10.1587 15.4255 9.42383 15.8543C9.05615 16.0687 8.74794 16.2208 8.52901 16.3203C8.41951 16.37 8.33224 16.4066 8.2709 16.4313C8.24022 16.4436 8.21602 16.453 8.19875 16.4595L8.1781 16.4673L8.17178 16.4696L8.16964 16.4703L8.16883 16.4706C8.1685 16.4708 8.16819 16.4709 8 16C7.83181 16.4709 7.8315 16.4708 7.83117 16.4706L7.83036 16.4703L7.82822 16.4696L7.8219 16.4673L7.80125 16.4595C7.78398 16.453 7.75978 16.4436 7.7291 16.4313C7.66776 16.4066 7.58049 16.37 7.47099 16.3203C7.25206 16.2208 6.94385 16.0687 6.57618 15.8543C5.84129 15.4255 4.86542 14.7455 3.8903 13.7355C1.93135 11.7066 0 8.37002 0 3.143C0 2.91976 0.147984 2.72357 0.362631 2.66224L7.86263 0.51924ZM8 16L7.83181 16.4709C7.94057 16.5097 8.05943 16.5097 8.16819 16.4709L8 16ZM8 15.4609C8.03389 15.4463 8.0725 15.4293 8.11552 15.4098C8.30675 15.3229 8.58448 15.1862 8.91992 14.9905C9.59129 14.5988 10.4904 13.973 11.3903 13.041C13.1356 11.2333 14.907 8.25128 14.9965 3.51913L8 1.52001L1.00354 3.51913C1.09298 8.25128 2.86445 11.2333 4.6097 13.041C5.50958 13.973 6.40871 14.5988 7.08007 14.9905C7.41552 15.1862 7.69325 15.3229 7.88448 15.4098C7.9275 15.4293 7.96611 15.4463 8 15.4609Z" />
    </Icon>
  );
}
