import type { DeviceConfiguration } from '@sb/integrations/device';
import { getEndEffector } from '@sb/integrations/frontend/util';
import type { OR3FG15GripKind } from '@sb/integrations/OnRobot3FG15';
import { OnRobotDualQuickChangerCommand } from '@sb/integrations/OnRobotDualQuickChanger';
import type { Step } from '@sb/types';

import type { ActuateGripperArguments } from './Arguments';

export namespace ActuateGripperStepDatabase {
  export interface Arguments extends Step.CommonArguments {
    argumentKind: 'ActuateGripper';
    selectedGripper: 'primary' | 'secondary'; // for dual gripper
    diameterMM: number;
    forceNewtons: number;
    gripKind: OR3FG15GripKind;
    payloadKg: number;
    targetDiameterToleranceMeters?: number;
    waitForGripToContinue?: boolean;
    isFlexGrip?: boolean;
  }

  export const convertActuateGripperConfigurationToRoutineRunner = (
    stepID: string,
    equipment: DeviceConfiguration[],
    configuration?: Arguments,
  ): ActuateGripperArguments => {
    if (!configuration) {
      throw new Error(
        `'ActuateGripper step is not configured. stepID: ${stepID}'`,
      );
    }

    const endEffector = getEndEffector(equipment);

    if (!endEffector) {
      throw new Error('Attach a gripper to utilize the Actuate Gripper step');
    }

    switch (endEffector.kind) {
      case 'OnRobot2FG7': {
        return {
          gripperCommand: {
            kind: 'OnRobot2FG7Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            waitForGripToContinue: configuration.waitForGripToContinue,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            // This is hard coded to a reasonable minimum, but could be added to FE
            // and wired through
            targetSpeed: 0.95,
          },
        };
      }

      case 'OnRobot3FG15': {
        return {
          gripperCommand: {
            kind: 'OnRobot3FG15Command',
            gripKind: configuration.gripKind,
            targetForce: configuration.forceNewtons,
            targetDiameter: configuration.diameterMM / 1000,
            targetDiameterTolerance:
              configuration.targetDiameterToleranceMeters,
            isFlexGrip: configuration.isFlexGrip,
            waitForGripToContinue: configuration.waitForGripToContinue,
          },
        };
      }

      case 'OnRobotDualQuickChanger': {
        const { gripperCommand } =
          ActuateGripperStepDatabase.convertActuateGripperConfigurationToRoutineRunner(
            stepID,
            [endEffector.grippers[configuration.selectedGripper]],
            configuration,
          );

        const parsedSubCommand =
          OnRobotDualQuickChangerCommand.shape.command.safeParse(
            gripperCommand,
          );

        if (!parsedSubCommand.success) {
          throw new Error(
            `Sub-command given to Dual quick changer is not supported: ${parsedSubCommand.error.toString()}`,
          );
        }

        return {
          gripperCommand: {
            kind: 'OnRobotDualQuickChangerCommand',
            active: configuration.selectedGripper,
            command: parsedSubCommand.data,
          },
        };
      }
      default: {
        throw new Error(
          `Gripper ${endEffector.kind} is not supported for this step`,
        );
      }
    }
  };
}
