import type { User } from '@sb/types';

import { getUsersService } from '../users/utils';

import { loginWithPassword } from './loginWithPassword';

export async function setupNewUserAccount(user: User.Setup): Promise<void> {
  await getUsersService().patch(user.uid, user);
  await loginWithPassword(user.email, user.password);
}
