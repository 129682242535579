import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ProgressIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 12 12">
      <path d="M6 1a5 5 0 0 0-4.619 3.087 5 5 0 0 0 1.084 5.449 5 5 0 0 0 5.449 1.084A5 5 0 0 0 11 6C11 4.674 10.47 3.403 9.534 2.466S7.326 1.002 6 1zm0 11A6 6 0 1 1 6 0a6 6 0 1 1 0 12zm0-1.714c-1.137 0-2.227-.452-3.03-1.255S1.714 7.137 1.714 6s.452-2.227 1.255-3.03S4.863 1.714 6 1.714V6l3.03 3.03c-.397.4-.87.715-1.39.93a4.27 4.27 0 0 1-1.64.326zM6 11A5 5 0 1 0 6 1a5 5 0 1 0 0 10z" />
    </Icon>
  );
}
