import useIsCurrentUserRunningAdhocCommand from './useIsCurrentUserRunningAdhocCommand';
import useIsRobotMoving from './useIsRobotMoving';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

/**
 * A user could have multiple tabs and sessions open. This hook checks if another session
 * is moving the robot. This is used to prevent the current session from controlling the
 * live robot when someone else is also using the robot.
 */
export default function useIsAnotherSessionRunningAdHocCommand(
  args: UseRoutineRunnerHandleArguments,
): boolean {
  const isCurrentSessionRunningAdHocCommand =
    useIsCurrentUserRunningAdhocCommand();

  const { isRunningAdHocCommand } = useIsRobotMoving(args);

  return (
    !args.isVizbot &&
    isRunningAdHocCommand &&
    !isCurrentSessionRunningAdHocCommand
  );
}
