import {
  EXTERNAL_TO_ROBOT_PORT_NAMES,
  ROBOT_TO_EXTERNAL_PORT_NAMES,
} from '@sb/routine-runner';
import type { Robot, Equipment } from '@sb/types';
import { flagValues } from '@sbrc/hooks/useFeatureFlag';

import { generateIOPort } from './generateIOPort';

export function getIOPorts<T extends Robot.IOPort>(
  kind: T['kind'],
  robotIOPorts: Robot.IOPort[] | null,
  equipment: Equipment.ConvertedResponse[],
): T[] {
  const portNames =
    kind === 'Input'
      ? EXTERNAL_TO_ROBOT_PORT_NAMES
      : ROBOT_TO_EXTERNAL_PORT_NAMES;

  const { enableCustomIO } = flagValues;

  const equipmentIOPorts = enableCustomIO
    ? equipment?.flatMap(({ config }) =>
        config.kind === 'CustomIO'
          ? config.ports.map((p) => ({
              ...p,
              deviceName: config.name ?? 'IO Device',
            }))
          : [],
      )
    : [];

  return portNames
    .map((_, index) => {
      const robotPort = robotIOPorts?.[index] ?? generateIOPort(kind, index);

      if (robotPort.safeguardKind) {
        return robotPort;
      }

      const equipmentPort = equipmentIOPorts?.find(
        (p) => p.kind === kind && p.port === robotPort.port,
      );

      return equipmentPort ?? robotPort;
    })
    .filter((p): p is T => p.kind === kind);
}
