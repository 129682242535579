import type { OnRobot2FG7Command, OnRobot2FG7State } from '.';

export function calculateOR2FG7CommandFromState(
  state: OnRobot2FG7State,
): OnRobot2FG7Command {
  const gripKind = state.gripKind ?? state.fingerOrientation;

  const command: OnRobot2FG7Command = {
    kind: 'OnRobot2FG7Command',
    gripKind,
    targetForce: state.force,
    targetDiameter:
      gripKind === 'inward' ? state.width.inner : state.width.outer,
    targetSpeed: 1,
  };

  return command;
}
