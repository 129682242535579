import * as zod from 'zod';

// the lowest score that image classification can spit out,
// indicating no resemblance between an image and a template.
export const CLASSIFICATION_INFERENCE_SCORE_MINIMUM = 0;

// the highest score that image classification can spit out,
// indicating perfect resemblance between an image and a template
export const CLASSIFICATION_INFERENCE_SCORE_MAXIMUM = 1;

export const ClassificationInferenceScore = zod
  .number()
  .min(CLASSIFICATION_INFERENCE_SCORE_MINIMUM)
  .max(CLASSIFICATION_INFERENCE_SCORE_MAXIMUM);

export type ClassificationInferenceScore = zod.infer<
  typeof ClassificationInferenceScore
>;
