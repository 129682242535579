import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function JointsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 25" {...props}>
      <path
        d="M9.188 23.213a2.49 2.49 0 0 1-.997 1.069 2.5 2.5 0 0 1-3.415-.915h0l-2.921-5.059c.359.124.744.191 1.145.191l.119-.002h0l1.523 2.638A2.5 2.5 0 0 1 6.95 19.6c.094 0 .188.005.279.015l-1.443-2.497a3.49 3.49 0 0 0 .548-1.051h0l2.628 4.55a2.49 2.49 0 0 1 .243 2.563zM19 19.6a2.5 2.5 0 1 1 0 5h0-9.6a3.51 3.51 0 0 0 .714-1h0H19a1.5 1.5 0 1 0 0-3h0-8.887a3.51 3.51 0 0 0-.714-1h0zm-12.05 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 1.327-.799 1.5 1.5 0 0 0-.037-1.433h0l-.112-.196c-.275-.348-.7-.572-1.178-.572zM3.7 2.753a2.5 2.5 0 1 1 4.83 1.294h0L5.423 15.638a2.49 2.49 0 0 1-.583 1.056l-.026.027-.046.047A2.49 2.49 0 0 1 3 17.5 2.5 2.5 0 0 1 .5 15c0-.283.047-.556.134-.809zM3 13.5a1.5 1.5 0 0 0-1.412.993l-.028.109a1.5 1.5 0 0 0 2.553 1.404c.241-.266.388-.62.388-1.007A1.5 1.5 0 0 0 3 13.5zM6.503 1.951a1.5 1.5 0 0 0-1.837 1.061h0l-2.59 9.665c.286-.114.598-.176.924-.176.801 0 1.514.377 1.972.963l2.592-9.675a1.5 1.5 0 0 0-1.061-1.837z"
        fillRule="evenodd"
      />
    </Icon>
  );
}
