import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ProTipIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 17 17" {...props}>
      <path
        fillRule="evenodd"
        d="M0.0691534 8.99963C0.0309292 4.58152 3.58153 0.968407 7.99963 0.929516C12.4177 0.890625 16.0303 4.44069 16.0685 8.8588C16.1068 13.2769 12.5562 16.89 8.13805 16.9289C3.71995 16.9678 0.107378 13.4177 0.0691534 8.99963Z
           M10.7923 7.79742C10.7923 8.43412 10.5822 9.03414 10.1889 9.53082C9.88555 9.91418 9.66721 10.3309 9.53553 10.7642H6.48374C6.36707 10.3392 6.1654 9.95251 5.88705 9.6325C5.08202 8.70912 4.97702 7.41907 5.61204 6.26736C5.81039 5.90568 6.10539 5.61067 6.46708 5.41233C7.45045 4.87064 8.52549 4.86231 9.4172 5.38899C10.2656 5.88901 10.7923 6.81238 10.7923 7.79742Z
           M6.60102 11.8787V12.1171C6.60102 12.6037 6.9977 13.0004 7.48605 13.0004H8.5111C8.74777 13.0004 8.97111 12.9088 9.13778 12.7404C9.30445 12.5721 9.39613 12.3487 9.39613 12.1121L9.39439 11.8787H6.60102Z"
      />
    </Icon>
  );
}
