import styles from './display.module.css';

export const display = {
  block: styles.block,
  flex: {
    column: styles.flexColumn,
    row: styles.flexRow,
    static: styles.static,
    noGrow: styles.noGrow,
    noShrink: styles.noShrink,
    flexible: styles.flexible,
    grow1: styles.flexGrow1,
    shrink1: styles.flexShrink1,
  },
  inline: styles.inline,
  inlineBlock: styles.inlineBlock,
  none: styles.none,
};
