import * as zod from 'zod';

import { TooltipConfiguration } from '@sb/integrations/gripper-general';

export const OnRobot2FG7GrippersConfiguration = zod.object({
  kind: zod.literal('OnRobot2FG7'),
  name: zod.string().optional(),
  fingerConfiguration: zod
    .object({
      mountingPosition: zod.union([
        zod.literal('inward'),
        zod.literal('outward'),
      ]),
    })
    .optional(),
  tooltipConfig: TooltipConfiguration.optional(),
});

export type OnRobot2FG7GrippersConfiguration = zod.infer<
  typeof OnRobot2FG7GrippersConfiguration
>;
