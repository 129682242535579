import { sumBy } from 'lodash';

import type { CartesianPose } from '@sb/geometry';
import type { AbstractLogger } from '@sb/logger';
import {
  cartesianProduct,
  isNotNull,
  isNotUndefined,
  six,
} from '@sb/utilities';

import type { ArmJointLimits } from './ArmJointLimits';
import type { ArmJointPositions } from './ArmJointPositions';
import type { ArmTarget } from './ArmTarget';
import { inverseKinematics } from './inverseKinematics';
import type { MotionPlan } from './MotionPlan';
import type {
  DeviceKinematics,
  BaseOffsetProposal,
  MotionPlannerInterface,
} from './MotionPlannerInterface';
import type { MotionPlanRequest } from './MotionPlanRequest';

interface ConstructorArgs<DeviceCommand> {
  logger: AbstractLogger;
  motionPlanner: MotionPlannerInterface;
  request: MotionPlanRequest;
  deviceKinematics: DeviceKinematics<DeviceCommand>[];
  onAcknowledged?: () => void;
  onWaypoint?: () => void;
}

export interface ArmAndDeviceMotionPlan<DeviceCommand> {
  deviceCommands: DeviceCommand[];
  motionPlan: MotionPlan;
}

/**
 * Motion planner which takes into account devices which can
 * change the position of the arm (e.g. vertical lift)
 *
 * Each attached device returns a list of possible positions
 * it can move the arm to ("base offset proposals")
 *
 * The proposals are looped through (in order of estimated duration),
 * the motion plan request is modified to take into account the base offset,
 * and we find the first offset where the motion is possible.
 *
 * Return the device command(s) which move the arm to position
 * and the motion plan from the modified request.
 */
export class ArmAndDeviceMotionPlanner<DeviceCommand> {
  private logger: AbstractLogger;

  private motionPlanner: MotionPlannerInterface;

  private request: MotionPlanRequest;

  private deviceKinematics: DeviceKinematics<DeviceCommand>[];

  private onAcknowledged?: () => void;

  private onWaypoint?: () => void;

  public constructor(props: ConstructorArgs<DeviceCommand>) {
    this.logger = props.logger.createChildLogger();
    this.logger.label = 'MotionAndDeviceCommandPlanner';
    this.logger.enableConsole();

    this.motionPlanner = props.motionPlanner;
    this.request = props.request;
    this.deviceKinematics = props.deviceKinematics;
    this.onAcknowledged = props.onAcknowledged;
    this.onWaypoint = props.onWaypoint;
  }

  private calculateCombinedDuration(
    proposals: BaseOffsetProposal<DeviceCommand>[],
  ): number {
    return sumBy(proposals, (p) => p.durationEstimateMS);
  }

  // the real joint limits don't matter too much here, we just filtering
  // before sending to the motion planner
  private static JOINT_LIMITS: ArmJointLimits = six({
    min: -2 * Math.PI,
    max: 2 * Math.PI,
  });

  private isPossiblePose(
    pose: CartesianPose,
    seedAngles: ArmJointPositions,
  ): boolean {
    return (
      inverseKinematics(
        pose,
        ArmAndDeviceMotionPlanner.JOINT_LIMITS,
        seedAngles,
      ).length > 0
    );
  }

  private getBaseOffsetProposals(): BaseOffsetProposal<DeviceCommand>[][] {
    const hasPoseTarget = this.request.targets.some(
      (target) => 'pose' in target,
    );

    if (!hasPoseTarget) {
      return [];
    }

    return this.deviceKinematics
      .map((kinematics) => kinematics.getBaseOffsetProposals?.())
      .filter(isNotUndefined);
  }

  public async plan(): Promise<ArmAndDeviceMotionPlan<DeviceCommand>> {
    const proposals = this.getBaseOffsetProposals();

    // for each combination of proposals from attached devices,
    // see if we can plan a motion to the target
    if (proposals.length > 0) {
      const combinedProposals = Array.from(cartesianProduct(...proposals));

      combinedProposals.sort(
        (p1, p2) =>
          this.calculateCombinedDuration(p1) -
          this.calculateCombinedDuration(p2),
      );

      const errorMessages = new Map<string, number>();

      for (const combinedProposal of combinedProposals) {
        try {
          const deviceCommands = combinedProposal
            .map((p) => p.command)
            .filter(isNotNull);

          this.logger.info('Try to plan motion with', { deviceCommands });

          const motionPlan = await this.planMotionWithCombinedProposal(
            this.request,
            combinedProposal,
          );

          return {
            deviceCommands,
            motionPlan,
          };
        } catch (e) {
          errorMessages.set(e.message, (errorMessages.get(e.message) ?? 0) + 1);
        }
      }

      const errorSummary = [...errorMessages]
        .map(([message, count]) => `${count} × ${message}`)
        .join('; ');

      throw new Error(
        `Unable to plan motion from any base offset proposals: ${errorSummary}`,
      );
    } else {
      const motionPlan = await this.planMotion(this.request);

      return {
        deviceCommands: [],
        motionPlan,
      };
    }
  }

  private async planMotion(request: MotionPlanRequest): Promise<MotionPlan> {
    const motionPlanResponse = this.motionPlanner.planMotion(request);

    if (this.onAcknowledged) {
      motionPlanResponse.on('acknowledged', this.onAcknowledged);
    }

    if (this.onWaypoint) {
      motionPlanResponse.on('waypoint', this.onWaypoint);
    }

    const motionPlan = await motionPlanResponse.complete();

    return motionPlan;
  }

  private async planMotionWithCombinedProposal(
    request: MotionPlanRequest,
    combinedProposal: BaseOffsetProposal<DeviceCommand>[],
  ): Promise<MotionPlan> {
    const modifiedTargets = request.targets.map<ArmTarget>((target) => {
      if ('pose' in target) {
        const pose = { ...target.pose };

        for (const proposal of combinedProposal) {
          pose.x -= proposal.baseOffset.x;
          pose.y -= proposal.baseOffset.y;
          pose.z -= proposal.baseOffset.z;
        }

        if (!this.isPossiblePose(pose, request.startingJointPositions)) {
          this.logger.debug('Pose not possible', { pose });
          throw Error('No inverse kinematics found for pose');
        }

        return {
          ...target,
          pose,
        };
      }

      return target;
    }) as MotionPlanRequest['targets'];

    const motionPlan = await this.planMotion({
      ...request,
      targets: modifiedTargets,
    });

    this.logger.info('Targets modified', {
      from: request.targets,
      to: modifiedTargets,
    });

    return motionPlan;
  }
}
