import cx from 'classnames';

import { ArrowDownFilledIcon, ArrowUpFilledIcon } from '@sb/ui/icons';

import Typography from '../Typography';

import styles from './MeterLabelIndicator.module.css';

export interface MeterLabelIndicatorProps {
  arrowAlign: 'bottom' | 'top';
  percent: number;
  value: string;
}

export function MeterLabelIndicator({
  arrowAlign,
  percent,
  value,
}: MeterLabelIndicatorProps) {
  return (
    <div
      className={cx(styles.meterLabelIndicator, styles[arrowAlign])}
      style={{ left: `${percent}%` }}
    >
      <Typography className={styles.valueLabel} variant="extraSmall" isBold>
        {value}
      </Typography>

      {arrowAlign === 'bottom' ? (
        <ArrowUpFilledIcon className={styles.arrowIcon} />
      ) : (
        <ArrowDownFilledIcon className={styles.arrowIcon} />
      )}
    </div>
  );
}
