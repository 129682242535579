import { cloneElement, useMemo } from 'react';

import { ArrowBackIcon, ArrowForwardIcon } from '@sb/ui/icons';

import Button from '../Button';
import Loader from '../Loader';

import styles from './WizardFooter.module.css';

interface WizardFooterProps {
  onBack?: () => void;
  onCancel?: () => void;
  onNext?: () => void;
  onSkip?: () => void;
  onBackLabel?: string;
  onNextIcon?: React.ReactElement | null;
  onNextLabel?: string;
  isCancelDisabled?: boolean;
  isNextDisabled?: boolean;
  isNextLoading?: boolean;
  isSkipDisabled?: boolean;
}

const WizardFooter = ({
  onBack,
  onCancel,
  onNext,
  onSkip,
  onBackLabel = 'Back',
  onNextIcon = <ArrowForwardIcon />,
  onNextLabel = 'Next',
  isCancelDisabled,
  isNextDisabled,
  isNextLoading,
  isSkipDisabled,
}: WizardFooterProps) => {
  const NextIconComponent = useMemo(() => {
    // Remove the "next" icon when it's `null`
    if (onNextIcon === null) return undefined;

    return (
      onNextIcon && cloneElement(onNextIcon, { className: styles.buttonIcon })
    );
  }, [onNextIcon]);

  return (
    <footer className={styles.wizardFooter}>
      <div className={styles.buttonGroup}>
        {onBack && (
          <Button
            className={styles.backButton}
            onClick={onBack}
            startIcon={<ArrowBackIcon className={styles.buttonIcon} />}
            variant="gray"
          >
            {onBackLabel}
          </Button>
        )}

        {onCancel && (
          <Button onClick={onCancel} disabled={isCancelDisabled} variant="gray">
            Cancel
          </Button>
        )}
      </div>

      <div className={styles.buttonGroup}>
        {onSkip && (
          <Button onClick={onSkip} variant="gray" disabled={isSkipDisabled}>
            Skip
          </Button>
        )}

        {onNext && !isNextLoading && (
          <Button
            className={styles.nextButton}
            disabled={isNextDisabled}
            endIcon={NextIconComponent}
            onClick={onNext}
          >
            {onNextLabel}
          </Button>
        )}

        {isNextLoading && <Loader size="small" />}
      </div>
    </footer>
  );
};

export default WizardFooter;
