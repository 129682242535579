/**
 * Motion direction validations:
 * A representation of which directions the robot arm can validly move in,
 * grouped by reference frame.
 */
import * as zod from 'zod';

// EachBaseFrame is the value that goes on each base frame.
// Just to reduce duplication.
//
// zod records unfortunately don't let you specify a key type
// otherwise I would use `zod.record(CartesianDirection, zod.boolean())`,
// and it would be that easy.
const EachBaseFrame = zod.object({
  '+x': zod.boolean(),
  '-x': zod.boolean(),
  '+y': zod.boolean(),
  '-y': zod.boolean(),
  '+z': zod.boolean(),
  '-z': zod.boolean(),
  '+roll': zod.boolean(),
  '-roll': zod.boolean(),
  '+pitch': zod.boolean(),
  '-pitch': zod.boolean(),
  '+yaw': zod.boolean(),
  '-yaw': zod.boolean(),
});

export const MotionDirectionValidations = zod.object({
  tooltip: EachBaseFrame,
  robotArm: EachBaseFrame,
});

export type MotionDirectionValidations = zod.infer<
  typeof MotionDirectionValidations
>;

// Default state of tool motion validity.
export const ALL_TOOL_MOTIONS_INVALID: MotionDirectionValidations = {
  tooltip: {
    '+x': false,
    '-x': false,
    '+y': false,
    '-y': false,
    '+z': false,
    '-z': false,
    '+roll': false,
    '-roll': false,
    '+pitch': false,
    '-pitch': false,
    '+yaw': false,
    '-yaw': false,
  },
  robotArm: {
    '+x': false,
    '-x': false,
    '+y': false,
    '-y': false,
    '+z': false,
    '-z': false,
    '+roll': false,
    '-roll': false,
    '+pitch': false,
    '-pitch': false,
    '+yaw': false,
    '-yaw': false,
  },
};
