import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { Notification, User } from '@sb/types';
import type { ToastState } from '@sb/ui/components';
import type { VizbotRoutineRunnerHandle } from '@sbrc/services';

interface AuthState {
  notifications: Notification.ConvertedResponse[];
  settings?: User.ConvertedResponse | null;
  toast: {
    state: ToastState | null;
    set: Dispatch<SetStateAction<ToastState | null>>;
  };
  visualizerChildren: {
    state: Record<string, React.ReactNode>;
    set: Dispatch<SetStateAction<Record<string, React.ReactNode>>>;
  };
  userID?: string | null;
  vizbotRoutineRunnerHandle?: VizbotRoutineRunnerHandle;
}

export default createContext<AuthState>({
  notifications: [],
  settings: undefined,
  toast: { state: null, set: () => {} },
  visualizerChildren: { state: {}, set: () => {} },
  userID: undefined,
});
