import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function VacuumIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 16 16">
      <path d="M4.50186 5H2.5C1.39543 5 0.5 5.89543 0.5 7V11H15.5V7C15.5 5.89543 14.6046 5 13.5 5H11.4981C11.4868 5.34862 11.4246 5.68436 11.3185 6H13.5C14.0523 6 14.5 6.44772 14.5 7V10H1.5V7C1.5 6.44772 1.94772 6 2.5 6H4.68147C4.57543 5.68436 4.51315 5.34862 4.50186 5Z" />
      <path d="M0.5 15V12H1.5V15H0.5Z" />
      <path d="M2.5 12V15H3.5V12H2.5Z" />
      <path d="M4.5 12V15H5.5V12H4.5Z" />
      <path d="M6.5 12V15H7.5V12H6.5Z" />
      <path d="M8.5 12V15H9.5V12H8.5Z" />
      <path d="M10.5 12V15H11.5V12H10.5Z" />
      <path d="M12.5 12V15H13.5V12H12.5Z" />
      <path d="M14.5 12V15H15.5V12H14.5Z" />
      <path d="M10.755 2.7257L11.6566 0.622069L10.7374 0.228149L9.97912 1.99753C10.2733 2.19958 10.5351 2.44544 10.755 2.7257Z" />
      <path d="M5.24505 2.72566L4.34351 0.622068L5.26265 0.228149L6.02094 1.9975C5.72674 2.19956 5.46498 2.44541 5.24505 2.72566Z" />
      <path
        fillRule="evenodd"
        d="M9.5 4.88464C9.5 4.05622 8.82843 3.38464 8 3.38464C7.17157 3.38464 6.5 4.05622 6.5 4.88464C6.5 5.71307 7.17157 6.38464 8 6.38464C8.82843 6.38464 9.5 5.71307 9.5 4.88464ZM8 2.38464C9.38071 2.38464 10.5 3.50393 10.5 4.88464C10.5 6.26536 9.38071 7.38464 8 7.38464C6.61929 7.38464 5.5 6.26536 5.5 4.88464C5.5 3.50393 6.61929 2.38464 8 2.38464Z"
      />
    </Icon>
  );
}
