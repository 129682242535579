import type { RoutineVariable, Step } from '@sb/types';

import { getVariablesFromRoutineSteps } from './getVariablesFromRoutineSteps';

/**
 * This function receives a list of steps from a routine as the input and it
 * returns a list of variables that can be used to configure a robot.
 *
 * Those variables are represented by the current state of another step. For
 * example, we could create an "if" conditional using the state of a
 * "Move Arm to Position" step doing something like this (pseudo code):
 *
 * if (MoveArmToPosition.currentActivity === 'moving') then do something
 *
 * Steps can have an output variable that can become a reusable variable here. However,
 * not all steps support that. You can see /types/step.ts:RoutineVariable.Kind and
 * VARIABLE_INFO_LIST_BY_STEP_KIND for a list of supported stepKinds.
 */
export const getFlattenedVariablesFromRoutineSteps = (
  steps: Step.ConvertedSummary[],
): RoutineVariable.StepVariableInformation[] => {
  const nested = getVariablesFromRoutineSteps(steps);

  return nested
    .map(({ variables, ...step }) =>
      variables.map((variable) => ({
        ...step,
        ...variable,
      })),
    )
    .flat();
};
