/**
 * Common options for motion plans
 */

import * as zod from 'zod';

import {
  ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  ABSOLUTE_MAX_JOINT_SPEEDS,
  ABSOLUTE_MAX_TOOLTIP_SPEED,
  ArmJointAccelerations,
  ArmJointVelocities,
} from '@sb/motion-planning';

export const SpeedProfile = zod.object({
  maxJointSpeeds: ArmJointVelocities,
  // Maximum tooltip velocity in meters per second
  maxTooltipSpeed: zod.number(),
  maxJointAccelerations: ArmJointAccelerations,
});

export type SpeedProfile = zod.infer<typeof SpeedProfile>;

export const ABSOLUTE_MAX_SPEED_PROFILE = {
  maxJointSpeeds: ABSOLUTE_MAX_JOINT_SPEEDS,
  maxJointAccelerations: ABSOLUTE_MAX_JOINT_ACCELERATIONS,
  maxTooltipSpeed: ABSOLUTE_MAX_TOOLTIP_SPEED,
};
