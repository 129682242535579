import type { Robot } from '@sb/types';

export function generateIOPort(
  prefix: Robot.IOPortKind,
  index: number,
): Robot.IOPort {
  const port: number = index + 1;

  return {
    highSignalName: 'High',
    kind: prefix,
    label: prefix === 'Input' ? 'IN' : 'OUT',
    lowSignalName: 'Low',
    name: `${prefix} ${port}`,
    port,
  };
}
