import type { WebRTCRoutineRunnerHandle } from '@sbrc/services';

import { useLiveRoutineRunnerHandle } from './useLiveRoutineRunnerHandle';

interface Args {
  robotID: string | null;
}

/**
 * Connect to a real running robot using WebRTC
 *
 * @returns A WebRTCRoutineRunnerHandle
 */
export function useWebRTCRoutineRunnerHandle({
  robotID,
}: Args): WebRTCRoutineRunnerHandle {
  return useLiveRoutineRunnerHandle({
    robotID,
    forceWebRTC: true,
  }) as WebRTCRoutineRunnerHandle;
}
