import type { DBBackupData } from '@sb/feathers-types';

import { getService } from '../utils';

export async function restoreFromBackup(
  path: string,
  robotID: string,
  key: string,
): Promise<DBBackupData> {
  const restoreService = getService('dataRestore')();
  restoreService.timeout = 20000;

  const response = await restoreService.create({ path, robotID, key }, {});

  return response;
}
