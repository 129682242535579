import { useMemo } from 'react';

import type { SafeguardState } from '@sb/routine-runner';
import type { BadgeVariant } from '@sb/ui/components';

import useIsRobotPaused from './useIsRobotPaused';
import useRobotConnectionStatus from './useRobotConnectionStatus';
import { useRobotSafeguardState } from './useRobotSafeguardState';
import useRobotStateKind from './useRobotStateKind';

export interface RobotBadgeStatus {
  kind:
    | 'antigravity'
    | 'disconnected'
    | 'failed'
    | 'idle'
    | 'loading'
    | 'moving'
    | 'running'
    | 'paused'
    | 'estop';
  label: string;
  color: BadgeVariant;
  ledColor: BadgeVariant;
  isConnected: boolean;
  safeguardState: SafeguardState | null;
}

export function useRobotBadgeStatus(robotID: string): RobotBadgeStatus {
  const routineRunnerArgs = { robotID };

  const routineRunnerStateKind = useRobotStateKind(routineRunnerArgs);

  const safeguardState = useRobotSafeguardState(routineRunnerArgs);

  const isPaused = useIsRobotPaused(routineRunnerArgs);

  const { kind: connectionStatus } =
    useRobotConnectionStatus(routineRunnerArgs);

  const badgeStatus: RobotBadgeStatus = useMemo(() => {
    const isConnected = connectionStatus === 'connected';

    if (connectionStatus === 'disconnected') {
      return {
        kind: 'disconnected',
        label: 'Disconnected',
        color: 'redLight',
        ledColor: 'redLight',
        isConnected,
        safeguardState,
      };
    }

    if (
      connectionStatus === 'constructing' ||
      connectionStatus === 'connecting'
    ) {
      return {
        kind: 'loading',
        label: 'Loading',
        color: 'gray',
        ledColor: 'gray',
        isConnected,
        safeguardState,
      };
    }

    if (safeguardState === 'eStop') {
      return {
        kind: 'estop',
        label: 'E-Stop',
        color: 'red',
        ledColor: 'red',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Failure') {
      return {
        kind: 'failed',
        label: 'Failed',
        color: 'redLight',
        ledColor: 'redLight',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'RunningAdHocCommand') {
      return {
        kind: 'moving',
        label: 'Moving',
        color: 'gray',
        ledColor: 'greenLight',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Recovering') {
      return {
        kind: 'moving',
        label: 'Recovering',
        color: 'gray',
        ledColor: 'greenLight',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'Antigravity') {
      return {
        kind: 'antigravity',
        label: 'Antigravity',
        color: 'gray',
        ledColor: 'greenLight',
        isConnected,
        safeguardState,
      };
    }

    if (routineRunnerStateKind === 'RoutineRunning' && !isPaused) {
      return {
        kind: 'running',
        label: 'Running',
        color: 'gray',
        ledColor: 'greenLight',
        isConnected,
        safeguardState,
      };
    }

    if (isPaused) {
      return {
        kind: 'paused',
        label: 'Paused',
        color: 'gray',
        ledColor: 'greenLight',
        isConnected,
        safeguardState,
      };
    }

    return {
      kind: 'idle',
      label: 'Idle',
      color: 'gray',
      ledColor: 'greenLight',
      isConnected,
      safeguardState,
    };
  }, [connectionStatus, routineRunnerStateKind, isPaused, safeguardState]);

  return badgeStatus;
}
