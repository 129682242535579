import type { Routine } from '@sb/types';

import { getRoutinesService } from './utils';

export async function updateRoutine(
  changes: Partial<Routine.EditableFields>,
  routineID: string,
): Promise<void> {
  await getRoutinesService().patch(routineID, changes);
}
