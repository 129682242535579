import * as zod from 'zod';

import { DeviceCommand } from '@sb/integrations/device';

import { PayloadState } from './PayloadState';

export const ActuateDeviceArgs = zod.object({
  command: DeviceCommand,
  payload: PayloadState.optional(),
  // minimum 2 ms for slight delay on guided mode
  retryTimeoutMS: zod.number().min(2).max(75_000).optional(),
});

export type ActuateDeviceArgs = zod.infer<typeof ActuateDeviceArgs>;
