import * as zod from 'zod';

import { DBBackupData } from './dbBackup';

export const USBDriveData = zod.object({
  mountPath: zod.string(),
  label: zod.string(),
  backups: zod.array(DBBackupData),
});

export type USBDriveData = zod.infer<typeof USBDriveData>;
