import { clamp } from 'lodash';

export function calculatePercent(
  value: number,
  min: number,
  max: number,
): number {
  const percent = ((value - min) / (max - min)) * 100;

  return clamp(percent, 0, 100);
}
