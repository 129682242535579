import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function TeamIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 15" {...props}>
      <path d="M12.5 11.5a.5.5 0 1 0 0 1h3a.5.5 0 0 0 .5-.5v-1a3.5 3.5 0 0 0-3.5-3.5c-.688-.001-1.362.202-1.936.584a.5.5 0 0 0 .554.833c.41-.272.9-.417 1.38-.416a2.5 2.5 0 0 1 2.501 2.5v.5h-2.5zm-.001-5a2.5 2.5 0 1 1 0-5 2.5 2.5 0 1 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 1 0 0 3zm-7 2a3.5 3.5 0 1 1 0-7 3.5 3.5 0 1 1 0 7zm0-1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 1 0 0 5zm0 2A5.5 5.5 0 0 1 11 14a.5.5 0 0 1-.5.5H.5A.5.5 0 0 1 0 14a5.5 5.5 0 0 1 5.5-5.5zm3.182 2.318a4.5 4.5 0 0 0-6.364 0 4.5 4.5 0 0 0-1.29 2.682h8.944a4.5 4.5 0 0 0-1.29-2.682z" />
    </Icon>
  );
}
