import { combineLatest } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import type { Robot, Unsubscribe } from '@sb/types';

import {
  convertEquipmentResponse,
  getEquipmentService,
} from '../equipment/utils';
import { cleanQuery, mapPaginated, retryOnTimeout } from '../utils';

import { convertRobotResponse, getRobotsService } from './utils';

export function onGetRobot(
  robotID: string | undefined,
  onSnapshot: (robots: Robot.ConvertedResponse | null) => void,
): Unsubscribe {
  const robot$ = getRobotsService()
    .watch()
    .find({
      query: cleanQuery({
        robotID,
        isDefault: robotID ? undefined : true,
        $limit: 1,
      }),
    })
    // ui was locking up on receiving robot data when starting a guided mode movement,
    // added a debounce to mitigate
    .pipe(debounceTime(500), retryOnTimeout());

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(retryOnTimeout());

  const subscription = combineLatest([robot$, equipment$]).subscribe(
    ([robotResponse, equipmentResponse]) => {
      const equipment = mapPaginated(
        equipmentResponse,
        convertEquipmentResponse,
      );

      const robot =
        mapPaginated(robotResponse, convertRobotResponse(equipment))[0] ?? null;

      onSnapshot(robot);
    },
  );

  return () => subscription.unsubscribe();
}
