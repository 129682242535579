import { combineLatest } from 'rxjs';

import type { Robot, Unsubscribe } from '@sb/types';

import {
  convertEquipmentResponse,
  getEquipmentService,
} from '../equipment/utils';
import { cleanQuery, mapPaginated, retryOnTimeout } from '../utils';

import { convertRobotResponse, getRobotsService } from './utils';

interface OnGetRobotsArguments {
  routineID?: string | null;
}

export function onGetRobots(
  { routineID }: OnGetRobotsArguments,
  onSnapshot: (robots: Robot.ConvertedResponse[]) => void,
): Unsubscribe {
  const robot$ = getRobotsService()
    .watch()
    .find({
      query: cleanQuery({
        latestRoutineID: routineID ?? undefined,
        $sort: { name: 1 },
      }),
    })
    .pipe(retryOnTimeout());

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(retryOnTimeout());

  const subscription = combineLatest([robot$, equipment$]).subscribe(
    ([robotResponse, equipmentResponse]) => {
      const equipment = mapPaginated(
        equipmentResponse,
        convertEquipmentResponse,
      );

      const robots = mapPaginated(
        robotResponse,
        convertRobotResponse(equipment),
      );

      onSnapshot(robots);
    },
  );

  return () => subscription.unsubscribe();
}
