import cx from 'classnames';

import Typography from '../Typography';

import { useWizardContext } from './shared';

import styles from './WizardMenuItem.module.css';

interface WizardMenuItemProps {
  isActive?: boolean;
  isComplete?: boolean;
  title: React.ReactNode;
  isSubStep?: boolean;
  description?: React.ReactNode;
  onClick?: () => void;
}

const WizardMenuItem = ({
  isActive,
  isComplete,
  title,
  isSubStep,
  description,
  onClick,
}: WizardMenuItemProps) => {
  const { isCollapsed } = useWizardContext();

  const handleClick = isCollapsed ? undefined : onClick;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx(
        styles.wizardMenuItem,
        isActive && styles.active,
        isComplete && styles.complete,
        isSubStep && styles.subStep,
        handleClick && styles.button,
      )}
      onClick={handleClick}
      role={handleClick ? 'button' : undefined}
    >
      <div className={styles.circle} />

      {!isCollapsed && (
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>{title}</Typography>
          {description && (
            <Typography className={styles.description}>
              {description}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default WizardMenuItem;
