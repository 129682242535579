export enum TimeScale {
  Day,
  Hour,
  Minute,
  Second,
  Millisecond,
}

export const toMillis = (value: number, timescale: TimeScale): number => {
  switch (timescale) {
    case TimeScale.Day:
      return value * 1000 * 60 * 60 * 24;
    case TimeScale.Hour:
      return value * 1000 * 60 * 60;
    case TimeScale.Minute:
      return value * 1000 * 60;
    case TimeScale.Second:
      return value * 1000;
    case TimeScale.Millisecond:
      return value;
    default: {
      const exhaustive: never = timescale; // ensure type exhaustive
      throw new Error(`Unexpected key for secrets ${exhaustive}`);
    }
  }
};

export const add = (date: Date, value: number, timescale: TimeScale) => {
  const sinceEpoch = date.getTime();

  return new Date(sinceEpoch + toMillis(value, timescale));
};

export const subtract = (date: Date, value: number, timescale: TimeScale) => {
  const sinceEpoch = date.getTime();

  return new Date(sinceEpoch - toMillis(value, timescale));
};

export const diff = (a: Date, b: Date): number => {
  return a.getTime() - b.getTime();
};

export const isOlderThan = (
  date: Date,
  value: number,
  timescale: TimeScale,
) => {
  return diff(subtract(new Date(), value, timescale), date) > 0;
};
