import { AbstractAnimation } from './AbstractAnimation';

export class Animation<T> extends AbstractAnimation<T> {
  private interval: ReturnType<typeof setInterval> | undefined;

  private onStopHandlers = new Map<number, () => void>();

  private uid: number = 0;

  public get running(): boolean {
    return this.interval !== undefined;
  }

  public start() {
    if (!this.interval) {
      this.startTs = Date.now();
      this.lastFrameTs = Date.now();
      this.interval = setInterval(this.animate.bind(this), this.msPerFrame);
      this.animate();
    }
  }

  public run(): Promise<void> {
    this.uid += 1;
    const key = this.uid;

    return new Promise((resolve) => {
      this.start();

      this.onStopHandlers.set(key, () => {
        this.onStopHandlers.delete(key);
        resolve();
      });
    });
  }

  public stop() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;

      for (const handler of this.onStopHandlers.values()) {
        handler();
      }
    }
  }

  private animate() {
    const ts = Date.now();
    const timeSinceLastFrame = ts - this.lastFrameTs;
    const timeSinceStart = ts - this.startTs;
    this.lastFrameTs = ts;

    if (this.callback === undefined) {
      throw new Error('Attempting to animate without callback set');
    }

    this.lastFrame = this.callback({
      timeSinceLastFrame,
      timeSinceStart,
      lastFrame: this.lastFrame,
    });
  }
}
