import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ExpandIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <path d="M2.207 13.5L13.5 2.207V8.5h1v-8h-8v1h6.293L1.5 12.793V6.5h-1v8h8v-1H2.207z" />
    </Icon>
  );
}
