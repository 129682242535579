import type { SpeedProfile } from '@sb/routine-runner';
import { calculateSpeedProfileFromSafetyLimits } from '@sb/routine-runner';
import type { SafetySettings } from '@sb/types';

import { combineSpeedProfiles } from './combineSpeedProfiles';

interface Args {
  safetySettings: SafetySettings.ConvertedResponse;
  additionalSpeedProfile?: SpeedProfile;
  speedRestrictionPercentage?: number;
}

export function calculateSpeedProfile({
  safetySettings,
  additionalSpeedProfile,
  speedRestrictionPercentage,
}: Args): SpeedProfile {
  const speedProfiles: Array<SpeedProfile> = [
    calculateSpeedProfileFromSafetyLimits(safetySettings),
  ];

  if (additionalSpeedProfile) {
    speedProfiles.push(additionalSpeedProfile);
  }

  if (speedRestrictionPercentage && speedRestrictionPercentage < 1) {
    speedProfiles.push(
      calculateSpeedProfileFromSafetyLimits(safetySettings, {
        speedMultiplier: speedRestrictionPercentage,
      }),
    );
  }

  const speedProfile = combineSpeedProfiles(speedProfiles);

  return speedProfile;
}
