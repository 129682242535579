import type { ModbusFunctionCode } from '../constants';
import type { ModbusRegisterRequest, ModbusResponse } from '../types';

type ModbusRequestHandler = (request: ModbusRegisterRequest) => ModbusResponse;

type ModbusRequestHandlerMap = {
  [functionCode: number]: { [F in ModbusFunctionCode]?: ModbusRequestHandler };
};

/**
 * Helper used in simulator classes to build modbus request handler
 */
export function handleModbusRegisterRequest(
  getIsConnected: () => boolean,
  modbusHandlers: ModbusRequestHandlerMap,
): ModbusRequestHandler {
  return (request) => {
    if (!getIsConnected()) {
      throw new Error('Gripper is not connected.');
    }

    const { functionCode, registerAddress } = request;

    const handler = modbusHandlers[registerAddress]?.[functionCode];

    if (!handler) {
      throw new Error(
        `Unsupported modbus message. Function code:${functionCode} Address:${registerAddress}`,
      );
    }

    return handler(request);
  };
}
