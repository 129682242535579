/* eslint-disable class-methods-use-this */

import { v4 as uuid } from 'uuid';

import type {
  ArmJointTorques,
  ArmJointLimits,
  ArmJointPositions,
  ArmJointVelocities,
  JointNumber,
} from '@sb/motion-planning';
import { JOINT_NUMBERS } from '@sb/motion-planning';
import type {
  ControlSystemEvent,
  InferenceEngineInterface,
  IOLevel,
  LEDColorPattern,
  PlayMotionPlanArgs,
  RGBAImage,
  RoutineRunnerState,
  SafeguardState,
} from '@sb/routine-runner';
import {
  ExternalToRobotPort,
  EXTERNAL_TO_ROBOT_PORT_NAMES,
  RelayPort,
  RELAY_PORT_NAMES,
  RobotInterface,
  RobotToExternalPort,
  ROBOT_TO_EXTERNAL_PORT_NAMES,
  SAFETY_PORT_NAMES,
} from '@sb/routine-runner';
import type { MotionResult } from '@sb/routine-runner/types/MotionResult';
import type { Eight, Six, Sixteen } from '@sb/utilities';
import { wait, sixteen, six } from '@sb/utilities';

import Camera from './Camera';
import VisualizerClassification from './Classification';
import { EquipmentSimulator } from './EquipmentSimulator';
import { RO1Simulator } from './RO1Simulator';

const CAMERA_RESOLUTION = { width: 2, height: 2 };

type IoState = RoutineRunnerState['kinematicState']['ioState'];

const IO_STATE_KEYS = [
  ...EXTERNAL_TO_ROBOT_PORT_NAMES,
  ...ROBOT_TO_EXTERNAL_PORT_NAMES,
  ...RELAY_PORT_NAMES,
  ...SAFETY_PORT_NAMES,
];

// keeps track of the state of an encoder that's emulating a failure where it
// sends back the same value over and over, not detecting a failure
type EncoderFailureInfo = {
  angle: number;
  timeSinceUpdate: Date;
};

/**
 * The SingleRobotSimulator is a game that can be mounted into our remote control through
 * a canvas element that simulates a virtual version of the robot and scene.
 *
 * It has an API similar to that of a robot so it can be swapped in to visualize
 * what a robot will do before it does it.
 *
 * It also has an API for managing objects loaded into the simulated scene.
 *
 * It is marked as implementing the inference engine interface and the camera interface
 * for now, but these methods aren't implemented yet. These implementations are necessary
 * to have a RoutineRunner.
 */
export class SingleRobotSimulator
  extends RobotInterface
  implements InferenceEngineInterface
{
  public RGBAImage = {
    getFromID: (): Promise<RGBAImage> => {
      return Promise.resolve(this.generateFakeImage());
    },
  };

  public camera = new Camera();

  public debugUUID = uuid();

  public failedEncoders = six<EncoderFailureInfo | null>(null);

  private ro1Simulator = new RO1Simulator();

  public equipment = new EquipmentSimulator();

  /**
   * Jerks the joints to this position (not a normal robot operation)
   * @category Visualization
   */
  public setJointPositions(positions: ArmJointPositions) {
    positions.forEach((position, jointIndex) => {
      const encoderFailureInfo = this.failedEncoders[jointIndex];

      if (!encoderFailureInfo) {
        this.ro1Simulator.jointPositions[jointIndex] = position;

        return;
      }

      const now = new Date();

      const timeSinceFailed =
        now.getTime() - encoderFailureInfo.timeSinceUpdate.getTime();

      encoderFailureInfo.timeSinceUpdate = now;

      // make the real joint positions drift in the same direction pathologically due to bad
      // encoder reading (emulate a PID controller that keeps getting the same error).
      if (position >= encoderFailureInfo.angle) {
        this.ro1Simulator.jointPositions[jointIndex] += timeSinceFailed / 1000;
      } else {
        this.ro1Simulator.jointPositions[jointIndex] -= timeSinceFailed / 1000;
      }
    });
  }

  /**
   * (not a normal robot operation)
   * @category Visualization
   */
  public setJointVelocities(velocities: ArmJointVelocities) {
    velocities.forEach((velocity, jointIndex) => {
      if (!this.failedEncoders[jointIndex]) {
        this.ro1Simulator.jointVelocities[jointIndex] = velocity;
      }
    });
  }

  public toggleJointFailure(jointNumber: JointNumber) {
    if (this.failedEncoders[jointNumber]) {
      this.failedEncoders[jointNumber] = null;
    } else {
      this.failedEncoders[jointNumber] = {
        angle: this.ro1Simulator.jointPositions[jointNumber],
        timeSinceUpdate: new Date(),
      };
    }
  }

  /**
   * @category Visualization
   */
  public stopMotion() {
    this.ro1Simulator.stopMotion();
  }

  private ioState: Partial<IoState> = {};

  /**
   * @category Robot
   */
  // eslint-disable-next-line @typescript-eslint/require-await
  public async setOutputIO(
    changes: { label: RobotToExternalPort | RelayPort; level: IOLevel }[],
  ): Promise<void> {
    changes.forEach(({ label, level }) => {
      if (
        RobotToExternalPort.safeParse(label).success ||
        RelayPort.safeParse(label).success
      ) {
        this.ioState[label] = level;
      } else {
        throw new Error(`Tried to set invalid output IO ${label}`);
      }
    });
  }

  /**
   * @category Robot
   */
  public setInputIO(
    label: ExternalToRobotPort | RelayPort,
    level: IOLevel,
  ): void {
    // TODO use new analog
    if (
      ExternalToRobotPort.safeParse(label).success ||
      RelayPort.safeParse(label).success
    ) {
      this.ioState[label] = level;
    } else {
      throw new Error(`Tried to set invalid input IO ${label}`);
    }
  }

  /**
   * @category Robot
   */
  public getIO(): IoState {
    // TODO use new analog
    const ioState = {} as IoState;

    IO_STATE_KEYS.forEach((key) => {
      ioState[key] = this.ioState[key] ?? 'low';
    });

    return ioState;
  }

  public getDigitalInputs(): Sixteen<boolean> {
    const ioState = this.getIO();
    const inputs = sixteen<boolean>(false);

    for (let input = 0; input < inputs.length; input += 1) {
      const port = ExternalToRobotPort.parse(`Input ${input}`);

      inputs[input] = ioState[port] === 'high';
    }

    return inputs;
  }

  public getDigitalOutputs(): Sixteen<boolean> {
    const ioState = this.getIO();
    const outputs = sixteen<boolean>(false);

    for (let output = 0; output < outputs.length; output += 1) {
      const port = RobotToExternalPort.parse(`Output ${output}`);

      outputs[output] = ioState[port] === 'high';
    }

    return outputs;
  }

  /**
   * @category Robot
   */
  public getAntigravitySpeeds(): ArmJointVelocities {
    return this.ro1Simulator.antigravitySpeeds;
  }

  /**
   * @category Robot
   */
  public getJointAngles(): ArmJointPositions {
    return this.ro1Simulator.jointPositions.map((position, joint) => {
      const failureInfo = this.failedEncoders[joint];

      return failureInfo?.angle ?? position;
    }) as ArmJointPositions;
  }

  /**
   * @category Robot
   */
  public getJointVelocities(): ArmJointVelocities {
    return this.ro1Simulator.jointVelocities;
  }

  /**
   * @category Robot
   */
  public getJointDisturbances(): ArmJointTorques {
    return [0, 0, 0, 0, 0, 0];
  }

  /**
   * @category Robot
   */
  public isRobotBraked(): boolean {
    return this.ro1Simulator.isBraked;
  }

  public get brakedJoints(): Six<boolean> {
    return this.ro1Simulator.brakedJoints;
  }

  /**
   * @category Robot
   */
  public playMotionPlan({
    motionPlan,
  }: PlayMotionPlanArgs): Promise<MotionResult> {
    return this.ro1Simulator.playMotionPlan(motionPlan);
  }

  /**
   * @category Robot
   */
  public async stopArm(): Promise<void> {
    this.stopMotion();

    return Promise.resolve();
  }

  /**
   * @category Robot
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async anticipatePayload(): Promise<void> {}

  /**
   * @category Robot
   */
  public async brake(): Promise<void> {
    await Promise.all(
      JOINT_NUMBERS.map((jointNumber) =>
        this.ro1Simulator.setIsJointBraked(jointNumber, true),
      ),
    );
  }

  public async emergencyStop(): Promise<void> {
    this.stopMotion();

    await wait(500);

    await this.brake();
  }

  /**
   * @category Robot
   */
  public getControlBoxButtonStates(): Eight<boolean> {
    return [false, false, false, false, false, false, false, false];
  }

  /**
   * @category Robot
   */
  public getWristButtonStates(): Eight<boolean> {
    return [false, false, false, false, false, false, false, false];
  }

  /**
   * @category Robot
   */
  public authorizeAntigravityMode(): Promise<void> {
    return this.ro1Simulator.authorizeAntigravityMode();
  }

  /**
   * @category Robot
   */
  public deauthorizeAntigravityMode(): Promise<void> {
    return this.ro1Simulator.deauthorizeAntigravityMode();
  }

  /**
   * @category Robot
   */
  public async setInFailureState(_isInFailure: boolean): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @category Robot
   */
  public async unbrake(): Promise<void> {
    await Promise.all(
      JOINT_NUMBERS.map((jointNumber) =>
        this.ro1Simulator.setIsJointBraked(jointNumber, false),
      ),
    );
  }

  public async setIsJointBraked(jointNumber: JointNumber, isBraked: boolean) {
    await this.ro1Simulator.setIsJointBraked(jointNumber, isBraked);
  }

  /**
   * @category Robot
   */
  public async setAntigravitySpeeds(speeds: ArmJointVelocities): Promise<void> {
    this.ro1Simulator.antigravitySpeeds = speeds;

    return Promise.resolve();
  }

  /**
   * @category Robot
   */
  public async setJointSafetyLimits(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @category Robot
   */
  public async setIMUCollisionThresholds(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * @category Robot
   */
  public async setSafeguardRules(): Promise<void> {
    return Promise.resolve();
  }

  public async setLEDPattern(_colorPattern: LEDColorPattern): Promise<boolean> {
    return Promise.resolve(true);
  }

  public getSafeguardState(): SafeguardState {
    return 'fullSpeed';
  }

  public supportsSafeguardRules() {
    return true;
  }

  /**
   * @category Robot
   */
  public onControlSystemEvent(_: (event: ControlSystemEvent) => void) {
    return () => {};
  }

  public getJointLimits(): ArmJointLimits {
    return this.ro1Simulator.jointLimits;
  }

  public Classification = {
    construct: () => {
      return new VisualizerClassification(() =>
        Promise.reject(new Error('Not Implemented')),
      );
    },
  };

  /**
   * Allows the Classification to ask the user for input.
   */
  public generateFakeImage(): RGBAImage {
    const { width, height } = CAMERA_RESOLUTION;

    return { rgba: Buffer.alloc(width * height * 4), width, height };
  }
}
