import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AlertIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 14" {...props}>
      <path
        fillRule="evenodd"
        d="M7 1.875C5.42079 1.875 4.125 3.18147 4.125 4.8125V6.1875V6.625V7.48214C4.125 8.10823 3.9012 8.67692 3.52997 9.125H10.47C10.0988 8.67692 9.875 8.10823 9.875 7.48214V6.625V6.1875V4.8125C9.875 3.18147 8.57921 1.875 7 1.875ZM12.625 10.125C12.9011 10.125 13.125 9.90114 13.125 9.625C13.125 9.34886 12.9011 9.125 12.625 9.125C11.6352 9.125 10.875 8.36676 10.875 7.48214V6.625V6.1875V4.8125C10.875 2.64657 9.14871 0.875 7 0.875C4.85129 0.875 3.125 2.64657 3.125 4.8125V6.1875V6.625V7.48214C3.125 8.36676 2.36477 9.125 1.375 9.125C1.09886 9.125 0.875 9.34886 0.875 9.625C0.875 9.90114 1.09886 10.125 1.375 10.125H12.625ZM5.5 10.625C5.77614 10.625 6 10.8489 6 11.125C6 11.6773 6.44772 12.125 7 12.125C7.55228 12.125 8 11.6773 8 11.125C8 10.8489 8.22386 10.625 8.5 10.625C8.77614 10.625 9 10.8489 9 11.125C9 12.2296 8.10457 13.125 7 13.125C5.89543 13.125 5 12.2296 5 11.125C5 10.8489 5.22386 10.625 5.5 10.625Z"
      />
    </Icon>
  );
}
