import type { DeviceCommand } from '../device';
import { calculateOR2FG7StateFromCommand } from '../OnRobot2FG7';
import { calculateOR3FG15StateFromCommand } from '../OnRobot3FG15';
import { calculateORDualQuickChangerStateFromCommand } from '../OnRobotDualQuickChanger/calculateORDualQuickChangerStateFromCommand';
import { calculateORScrewdriverStateFromCommand } from '../OnRobotScrewdriver';
import { calculateORVGP20StateFromCommand } from '../OnRobotVGP20';

import type { GripperState } from './GripperState';

export function calculateEndEffectorStateFromCommand(
  command: DeviceCommand,
): Partial<GripperState> {
  switch (command.kind) {
    case 'OnRobot3FG15Command':
      return calculateOR3FG15StateFromCommand(command);

    case 'OnRobot2FG7Command':
      return calculateOR2FG7StateFromCommand(command);

    case 'OnRobotDualQuickChangerCommand':
      return calculateORDualQuickChangerStateFromCommand(command);

    case 'OnRobotScrewdriverCommand':
      return calculateORScrewdriverStateFromCommand(command);

    case 'OnRobotVGP20Command':
      return calculateORVGP20StateFromCommand(command);

    default:
      return {};
  }
}
