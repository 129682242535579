import { asPaginated } from '../utils';

import { getUsersService } from './utils';

export async function isEmailRegistered(email: string): Promise<boolean> {
  const response = await getUsersService().find({
    query: { email, $limit: 0 },
    paginate: { max: 1 },
  });

  return asPaginated(response).total > 0;
}
