import type * as zod from 'zod';

import { wait } from '@sb/utilities';

import type { StepPlayArguments } from '../Step';
import Step from '../Step';

import Arguments from './Arguments';
import Variables from './Variables';

type Arguments = zod.infer<typeof Arguments>;

type Variables = zod.infer<typeof Variables>;

const CONFIRM_INTERVAL_CHECK_MS = 10;

export default class WaitForConfirmationStep extends Step<
  Arguments,
  Variables
> {
  public static areSubstepsRequired = false;

  public static Arguments = Arguments;

  public static Variables = Variables;

  protected initializeVariableState(): void {
    this.variables = {
      confirmed: false,
    };
  }

  private checkInterval?: ReturnType<typeof setInterval>;

  public confirm() {
    this.setVariable('confirmed', true);
  }

  public async _play({ pauseRoutine }: StepPlayArguments): Promise<void> {
    await pauseRoutine({
      kind: 'waitForConfirmation',
      reason: this.args.message,
    });

    this.setVariable('confirmed', false);

    return new Promise((resolve) => {
      // check if confirmed every CONFIRM_INTERVAL_CHECK_MS ms
      // temporarily ignore confirmations if paused
      // (will immediately succeed once resumed)
      this.checkInterval = setInterval(async () => {
        if (this.variables.confirmed) {
          // stop checking for confirmed
          if (this.checkInterval) {
            clearInterval(this.checkInterval);
          }

          this.routineContext.logger.info(
            'Waiting specified delay (as configured in routine)',
            {
              delayMS: this.args.delayMS,
            },
          );

          await wait(this.args.delayMS);
          resolve();
        }
      }, CONFIRM_INTERVAL_CHECK_MS);
    });
  }

  public _resume(): void {
    if (!this.variables.confirmed) {
      throw new Error(
        'WaitForConfirmationStep needs to be confirmed before it can be resumed.',
      );
    }
  }

  public _stop() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  public hasSelfTimeout(): boolean {
    return false;
  }
}
