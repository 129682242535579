import type { SpeedProfile } from '@sb/routine-runner';
import type { MotionSpeed } from '@sb/types';

import { calculateSpeedProfileFromPercent } from './calculateSpeedProfileFromPercent';

interface Arguments {
  motionSpeed?: MotionSpeed | null;
  baseSpeedProfile: SpeedProfile;
}

export const calculateSpeedProfileFromStepMotionSpeed = ({
  motionSpeed,
  baseSpeedProfile,
}: Arguments): SpeedProfile => {
  if (motionSpeed?.customLimits) {
    return motionSpeed.customLimits;
  }

  if (motionSpeed?.motionSpeedPercent) {
    return calculateSpeedProfileFromPercent(
      motionSpeed.motionSpeedPercent,
      baseSpeedProfile,
    );
  }

  return baseSpeedProfile;
};
