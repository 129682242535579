import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function MoveIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M10.89 13.188a.5.5 0 0 1-.013.64l-.065.062-2.5 2a.5.5 0 0 1-.542.054l-.083-.054-2.5-2a.5.5 0 0 1 .549-.831l.075.05L8 14.859l2.188-1.749a.5.5 0 0 1 .703.078zM8 5a3 3 0 1 1 0 6 3 3 0 1 1 0-6zm5.828.123l.062.065 2 2.5a.5.5 0 0 1 .054.542l-.054.083-2 2.5a.5.5 0 0 1-.831-.549l.05-.075L14.859 8 13.11 5.812a.5.5 0 0 1 .013-.64l.065-.062a.5.5 0 0 1 .64.013zM2.812 5.11a.5.5 0 0 1 .128.628l-.05.075L1.14 8l1.75 2.188a.5.5 0 0 1-.013.64l-.065.062a.5.5 0 0 1-.64-.013l-.062-.065-2-2.5a.5.5 0 0 1-.054-.542l.054-.083 2-2.5a.5.5 0 0 1 .703-.078zM8 6a2 2 0 1 0 0 4 2 2 0 1 0 0-4zM8.23.056l.083.054 2.5 2a.5.5 0 0 1-.549.831l-.075-.05L8 1.14 5.812 2.89a.5.5 0 0 1-.64-.013l-.062-.065a.5.5 0 0 1 .013-.64l.065-.062 2.5-2A.5.5 0 0 1 8.23.056z" />
    </Icon>
  );
}
