import { getApp } from '../utils';

import { currentUserID } from './utils';

export async function loginWithPIN(pin: string): Promise<void> {
  const result = await getApp().authenticate({
    strategy: 'pin',
    username: 'default',
    pin,
  });

  currentUserID().set(result.user.id);
}
