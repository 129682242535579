import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Return a debounced function which delays invoking fn until after waitMS have elapsed
 * since the last time the debounced function was invoked
 * If the component is unmounted before waitMS has elapsed then fn will not be called
 */
export function useDebouncedCallback<Args extends any[]>(
  fn: (...args: Args) => void,
  waitMS: number,
): (...args: Args) => void {
  const fnRef = useRef(fn);

  useEffect(() => {
    fnRef.current = fn;
  });

  const [argsArray, setArgsArray] = useState<Args | null>(null);

  useEffect(() => {
    if (argsArray === null) {
      return undefined;
    }

    // use the function current before the timeout starts
    const fnCurrent = fnRef.current;

    const timeoutID = setTimeout(() => {
      fnCurrent(...argsArray);
      setArgsArray(null);
    }, waitMS);

    return () => clearTimeout(timeoutID);
  }, [argsArray, waitMS]);

  return useCallback((...args: Args) => setArgsArray(args), []);
}
