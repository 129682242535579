import { useContext, useCallback } from 'react';

import type { ToastState } from '@sb/ui/components';
import { GlobalContext } from '@sbrc/contexts';

const useToast = () => {
  const { toast } = useContext(GlobalContext);
  const { state, set } = toast;

  const setToast = useCallback(
    (newToast: ToastState | null) => {
      if (newToast?.kind === 'error') {
        // eslint-disable-next-line no-console
        console.error({
          kind: 'toastError',
          newToast,
        });
      }

      set(newToast);
    },
    [set],
  );

  return { toastState: state, setToast };
};

export default useToast;
