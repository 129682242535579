import { ActuateGripperStep } from './gripper-general/steps/ActuateGripper/Step';
import { HaasControlRegionStep } from './HaasMill/steps/HaasControlRegion/Step';
import { HaasRunProgramStep } from './HaasMill/steps/HaasRunProgram/Step';
import { ActuateScrewdriverStep } from './OnRobotScrewdriver/steps/ActuateScrewdriver/Step';
import { ActuateVacuumStep } from './OnRobotVGP20/steps/ActuateVacuum/Step';

export const registerIntegrationSteps = (stepRegistry: any) => {
  stepRegistry.registerStepKind('ActuateGripper', ActuateGripperStep);
  stepRegistry.registerStepKind('ActuateScrewdriver', ActuateScrewdriverStep);
  stepRegistry.registerStepKind('ActuateVacuum', ActuateVacuumStep);
  stepRegistry.registerStepKind('HaasControlRegion', HaasControlRegionStep);
  stepRegistry.registerStepKind('HaasRunProgram', HaasRunProgramStep);
};

export const getIntegrationTypes = () =>
  [
    {
      stepKind: 'ActuateGripper',
      Arguments: ActuateGripperStep.Arguments,
      Variables: ActuateGripperStep.Variables,
    },
    {
      stepKind: 'ActuateScrewdriver',
      Arguments: ActuateScrewdriverStep.Arguments,
      Variables: ActuateScrewdriverStep.Variables,
    },
    {
      stepKind: 'ActuateVacuum',
      Arguments: ActuateVacuumStep.Arguments,
      Variables: ActuateVacuumStep.Variables,
    },
    {
      stepKind: 'HaasControlRegion',
      Arguments: HaasControlRegionStep.Arguments,
      Variables: HaasControlRegionStep.Variables,
    },
    {
      stepKind: 'HaasRunProgram',
      Arguments: HaasRunProgramStep.Arguments,
      Variables: HaasRunProgramStep.Variables,
    },
  ] as const;
