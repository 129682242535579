import type { RoutineStepConfiguration } from '@sb/routine-runner';

import { getAllEnabledEquipment } from '../feathers-client/equipment/getAllEquipment';

import type { RoutineRunnerHandle } from './RoutineRunnerHandle';

export async function synchronizeGlobalEquipmentWithRoutineRunner(
  liveRobotRoutineRunnerHandle: RoutineRunnerHandle,
) {
  if (liveRobotRoutineRunnerHandle.getState()?.kind === 'RoutineRunning') {
    return;
  }

  // Dummy step: routine requires at least one step to be valid
  const dummyStep: RoutineStepConfiguration = {
    id: '0',
    stepKind: 'Wait',
    args: { milliseconds: 0 },
  };

  const equipment = await getAllEnabledEquipment();

  await liveRobotRoutineRunnerHandle.loadRoutine({
    id: '0',
    name: 'Dummy routine for setting equipment',
    steps: [dummyStep],
    equipment: equipment.map((e) => e.config),
  });
}
