import { combineLatest } from 'rxjs';

import type { Routine, Unsubscribe } from '@sb/types';

import {
  convertEquipmentResponse,
  getEquipmentService,
} from '../equipment/utils';
import { mapPaginated, retryOnTimeout } from '../utils';

import { convertRoutineResponse, getRoutinesService } from './utils';

export function onGetRoutine(
  routineID: string,
  onSnapshot: (routine: Routine.ConvertedResponse | null) => void,
): Unsubscribe {
  const routine$ = getRoutinesService()
    .watch()
    .find({ query: { id: routineID, $limit: 1 } })
    .pipe(retryOnTimeout());

  const equipment$ = getEquipmentService()
    .watch()
    .find({ query: { isEnabled: true, $sort: { updatedAt: -1 } } })
    .pipe(retryOnTimeout());

  const subscription = combineLatest([routine$, equipment$]).subscribe(
    ([routineResponse, equipmentResponse]) => {
      const equipment = mapPaginated(
        equipmentResponse,
        convertEquipmentResponse,
      );

      const routine = mapPaginated(routineResponse, convertRoutineResponse)[0];

      if (!routine) {
        onSnapshot(null);
      } else {
        onSnapshot({
          ...routine,
          equipment: equipment.map((e) => e.config),
        });
      }
    },
  );

  return () => subscription.unsubscribe();
}
