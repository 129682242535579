import { useCallback } from 'react';

import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';
import { useRoutineRunnerHandle } from './useRoutineRunnerHandle';
import useToast from './useToast';

interface StopRobotCallback {
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

/**
 * Stop Vizbot or live robot and display a user feedback:
 * i.e. a toast with progress, success, and error messages.
 */
const useStopRobot = (args: UseRoutineRunnerHandleArguments) => {
  const routineRunnerHandle = useRoutineRunnerHandle(args);

  const { setToast, toastState } = useToast();

  const stopRobot = useCallback(
    async (callback?: StopRobotCallback) => {
      try {
        setToast({ kind: 'progress', message: 'Stopping robot...' });

        await routineRunnerHandle.stop('Stopped by Remote Control user');

        setToast({ kind: 'success', message: 'Robot successfully stopped!' });

        if (callback?.onSuccess) callback.onSuccess();
      } catch (error) {
        setToast({ kind: 'error', message: error.message });

        if (callback?.onError) callback.onError();
      } finally {
        if (callback?.onFinally) callback.onFinally();
      }
    },
    [routineRunnerHandle, setToast],
  );

  return { stopRobot, isStopping: toastState?.kind === 'progress' };
};

export default useStopRobot;
