import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function PictureIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" {...props}>
      <path d="M19 .5A2.5 2.5 0 0 1 21.5 3h0v16a2.5 2.5 0 0 1-2.5 2.5h0H3A2.5 2.5 0 0 1 .5 19h0V3A2.5 2.5 0 0 1 3 .5h0zm0 1H3A1.5 1.5 0 0 0 1.5 3h0v16A1.5 1.5 0 0 0 3 20.5h0 16a1.5 1.5 0 0 0 1.5-1.5h0V3A1.5 1.5 0 0 0 19 1.5h0zm-4.953 6.502a.5.5 0 0 1 .4.274h0l3.5 7a.5.5 0 0 1-.022.487.5.5 0 0 1-.425.237h0-13a.5.5 0 0 1-.437-.258.5.5 0 0 1 .013-.507h0l2.5-4a.5.5 0 0 1 .39-.234.5.5 0 0 1 .418.179h0l2.097 2.516 4.121-5.494c.052-.068.12-.121.197-.156s.163-.05.248-.044zM8.5 5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 1 1 0-3z" />
    </Icon>
  );
}
