import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function LogoutIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" {...props}>
      <path d="M12.02 2.893l.62-.785C14.432 3.52 15.5 5.673 15.5 8a7.5 7.5 0 1 1-15 0c0-2.327 1.068-4.48 2.86-5.893l.62.785C2.425 4.12 1.5 5.983 1.5 8a6.5 6.5 0 1 0 13 0c0-2.017-.925-3.88-2.48-5.107zM7.5 0h1v7h-1V0z" />
    </Icon>
  );
}
