import { useEffect, useRef } from 'react';

/**
 * This hook checks if a component will unmount. This is useful
 * when we need to cancel a promise or unsubscribe from an event
 * listener before the component unmounts to avoid a memory leak.
 */
const useComponentWillUnmount = () => {
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { isMounted: isMounted.current };
};

export default useComponentWillUnmount;
