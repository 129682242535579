/**
 * One target/desired waypoint for the arm in a motion plan.
 *
 * Motion plans are generated from a list of these.
 */
import * as zod from 'zod';

import { CartesianPose } from '@sb/geometry';

import { ArmJointPositions } from './ArmJointPositions';
import { GripperOpenness } from './GripperOpenness';

export const MotionKind = zod.enum(['joint', 'line']);
export type MotionKind = zod.infer<typeof MotionKind>;

export const PoseArmTarget = zod.object({
  pose: CartesianPose,
});

export type PoseArmTarget = zod.infer<typeof PoseArmTarget>;

export const JointArmTarget = zod.object({
  jointAngles: ArmJointPositions,
});

export type JointArmTarget = zod.infer<typeof JointArmTarget>;

export const GraspCandidate = zod.object({
  gripperOpenness: GripperOpenness,
  preGraspPose: CartesianPose,
  graspPose: CartesianPose,
  postGraspPose: CartesianPose,
});
// This is used for the grasp API.
// It is currently not recommended that users be allowed
// to use this in step configurations directly, even where
// ArmTarget is specified in the schema.
export const GraspArmTarget = zod.object({
  gripperEvent: GraspCandidate,
});

export type GraspArmTarget = zod.infer<typeof GraspArmTarget>;

const EndTarget = zod.union([PoseArmTarget, JointArmTarget, GraspArmTarget]);

export const ArmTarget = zod
  .object({
    motionKind: MotionKind,
    blendRadius: zod.number().optional(),
  })
  .and(EndTarget);

export type ArmTarget = zod.infer<typeof ArmTarget>;
