import cx from 'classnames';

import type { ColorVariant } from '../variants';

import Typography from './Typography';

import styles from './AlertDescription.module.css';

interface AlertProps {
  children: React.ReactNode;
  className?: string;
  color?: ColorVariant;
  variant?: 'small' | 'medium';
}

const AlertDescription = ({
  children,
  className,
  color,
  variant = 'small',
}: AlertProps) => {
  return (
    <Typography
      className={cx(styles.alertDescription, styles[variant], className)}
      color={color}
    >
      {children}
    </Typography>
  );
};

export default AlertDescription;
