import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function AddBeforeIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 14 16" {...props}>
      <path
        fillRule="evenodd"
        d="M8 0.5C8 0.223858 8.22386 0 8.5 0H13.5C13.7761 0 14 0.223858 14 0.5V15.5C14 15.7761 13.7761 16 13.5 16H8.5C8.22386 16 8 15.7761 8 15.5V0.5ZM9 1V15H13V1H9ZM2.5 7.5V5H3.5V7.5H6V8.5H3.5V11H2.5V8.5H0V7.5H2.5Z"
      />
    </Icon>
  );
}
