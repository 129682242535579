import type { MotionDirectionValidations } from '@sb/motion-planning';
import type { RoutineRunnerState } from '@sb/routine-runner';

import useRobotState from './useRobotState';
import type { UseRoutineRunnerHandleArguments } from './useRoutineRunnerHandle';

const getValidToolDirections = (
  routineRunnerState: RoutineRunnerState | null,
): MotionDirectionValidations | null => {
  if (routineRunnerState?.kind !== 'Idle') {
    return null;
  }

  return routineRunnerState.validToolDirections;
};

export default function useRobotValidToolDirections(
  args: UseRoutineRunnerHandleArguments,
): MotionDirectionValidations | null {
  return useRobotState(args, getValidToolDirections);
}
