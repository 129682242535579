import cx from 'classnames';

import { CloseIcon } from '@sb/ui/icons';

import styles from './MultiselectItem.module.css';

interface MultiselectItemProps {
  children: React.ReactNode;
  className?: string;
  labelClassName?: string;
  onRemove: () => void;
}

const MultiselectItem = ({
  children,
  className,
  labelClassName,
  onRemove,
}: MultiselectItemProps) => {
  return (
    <div
      className={cx(styles.multiselectItem, className)}
      role="button"
      onClick={(e) => {
        e.stopPropagation();
        onRemove();
      }}
    >
      <div className={cx(styles.label, labelClassName)}>{children}</div>

      <CloseIcon className={styles.closeIcon} />
    </div>
  );
};

export default MultiselectItem;
