import { isValidElement } from 'react';

interface TabItemProps {
  id: string;
  label: string;
  panel: React.ReactNode;
  isDisabled?: boolean;
}

/** `panel` is rendered by `<Tabs />` component */
export function TabItem(_props: TabItemProps) {
  return null;
}

export function isTabItemElement(
  object: any,
): object is React.ReactElement<TabItemProps> {
  return (
    isValidElement(object) &&
    // compare by name so that this still works after HMR refresh
    typeof object.type === 'function' &&
    object.type.name === TabItem.name
  );
}
