import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function ResetAllIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 14" {...props}>
      <path d="M4 1.5C4 1.22386 4.22386 1 4.5 1H11C13.7611 1 16 3.23886 16 6C16 8.76114 13.7611 11 11 11H4.5C4.22386 11 4 10.7761 4 10.5C4 10.2239 4.22386 10 4.5 10H11C13.2089 10 15 8.20886 15 6C15 3.79114 13.2089 2 11 2H4.5C4.22386 2 4 1.77614 4 1.5Z" />
      <path d="M4.85355 6.14645C5.04882 6.34171 5.04882 6.65829 4.85355 6.85355L1.20711 10.5L4.85355 14.1464C5.04882 14.3417 5.04882 14.6583 4.85355 14.8536C4.65829 15.0488 4.34171 15.0488 4.14645 14.8536L0.146447 10.8536C-0.0488155 10.6583 -0.0488155 10.3417 0.146447 10.1464L4.14645 6.14645C4.34171 5.95118 4.65829 5.95118 4.85355 6.14645Z" />
      <path d="M8.85355 6.14645C9.04882 6.34171 9.04882 6.65829 8.85355 6.85355L5.20711 10.5L8.85355 14.1464C9.04882 14.3417 9.04882 14.6583 8.85355 14.8536C8.65829 15.0488 8.34171 15.0488 8.14645 14.8536L4.14645 10.8536C3.95118 10.6583 3.95118 10.3417 4.14645 10.1464L8.14645 6.14645C8.34171 5.95118 8.65829 5.95118 8.85355 6.14645Z" />
    </Icon>
  );
}
