import cx from 'classnames';
import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import { useClickOutside } from '@sb/ui/hooks';

import styles from './Popover.module.css';

type PopoverVariant = 'default' | 'alert' | 'warning' | 'info';
export type PopoverPlacement = 'top' | 'left' | 'right' | 'bottom';

interface PopoverProps {
  /** When this element is clicked or hovered, the popover will be displayed. */
  children: ReactNode;
  /** Custom className for the 'children' element. */
  className?: string;
  /** Content to be displayed in the popover. */
  content: ReactNode;
  /** Custom className passed to the content container. */
  contentClassName?: string;
  /**
   * Manually open/close the popover. This can be omitted
   * if you want the popover component to be uncontrolled
   * and it will automatically handle the open/close logic.
   */
  isOpen?: boolean;
  /** When this prop is set to `true`, the popover won't close on scroll. */
  isScrollable?: boolean;
  placement?: PopoverPlacement;
  /** Background color for the popover. */
  variant?: PopoverVariant;
  /** Distance from reference element to popover */
  offset?: number;
}

type PopoverMouseState = 'none' | 'hovered' | 'tapped';

export default function Popover({
  children,
  className,
  content,
  contentClassName,
  isOpen,
  isScrollable,
  placement = 'top',
  variant = 'default',
  offset = 5,
}: PopoverProps) {
  const [popoverMouseState, setPopoverMouseState] =
    useState<PopoverMouseState>('none');

  const isPopoverOpen = isOpen ?? popoverMouseState !== 'none';

  /** Clickable element used to open the popover. */
  const referenceElement = useRef<HTMLDivElement>(null);

  /** Element responsible for displaying the popover. */
  const popperElement = useRef<HTMLDivElement>(null);

  /** Element responsible for displaying an arrow. */
  const arrowElement = useRef<HTMLDivElement | null>(null);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement.current,
    popperElement.current,
    {
      placement,
      strategy: 'fixed', // avoid triggering overflow on body
      modifiers: [
        { name: 'arrow', options: { element: arrowElement.current } },
        { name: 'offset', options: { offset: [0, offset] } },
        { name: 'preventOverflow', options: { padding: 5 } },
        { name: 'eventListeners', enabled: isPopoverOpen },
      ],
    },
  );

  const handleClickOutside = useCallback(() => {
    setPopoverMouseState('none');
  }, []);

  // Close the popover when we click outside the reference element.
  useClickOutside([referenceElement], handleClickOutside);

  // Close the popover on scroll when it's not scrollable and uncontrolled (without isOpen).
  useEffect(() => {
    if (popoverMouseState === 'none' || isScrollable) {
      return undefined;
    }

    const closePopover = () => setPopoverMouseState('none');

    window.addEventListener('wheel', closePopover);

    return () => window.removeEventListener('wheel', closePopover);
  }, [popoverMouseState, isScrollable]);

  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setPopoverMouseState((state) => (state === 'tapped' ? 'none' : 'tapped'));
  };

  /**
   * React-popper doesn't position the popover very well
   * when using CSS's :hover. So, we need to use event listeners
   * for mouse over and out to open/close the popover.
   */
  const handleMouseEnter = () => {
    setPopoverMouseState((state) => (state === 'none' ? 'hovered' : state));
  };

  const handleMouseLeave = () => {
    setPopoverMouseState('none');
  };

  return (
    <>
      <div
        ref={referenceElement}
        className={cx(styles.referenceElement, className)}
        onPointerDown={handleMouseDown}
        onFocus={handleMouseEnter}
        onPointerEnter={handleMouseEnter}
        onBlur={handleMouseLeave}
        onPointerLeave={handleMouseLeave}
        role="button"
      >
        {children}
      </div>

      {createPortal(
        <div
          ref={popperElement}
          className={cx(
            styles.content,
            isPopoverOpen && styles.isOpen,
            styles[variant],
            contentClassName,
          )}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          <div
            ref={arrowElement}
            className={styles.arrow}
            style={popperStyles.arrow}
          />
          {content}
        </div>,
        document.body,
      )}
    </>
  );
}
