import type { IconProps } from './Icon';
import { Icon } from './Icon';

export function DoubleArrowBackIcon(props: IconProps) {
  return (
    <Icon {...props} viewBox="0 0 15 16">
      <path d="M1.682 8l6.184 6.66-.733.68L.318 8 7.134.66l.733.68L1.682 8zm13.184-6.66L8.682 8l6.184 6.66-.733.68L7.318 8 14.134.66l.733.68z" />
    </Icon>
  );
}
