import { camelCase } from 'lodash';

import type { Robot, RoutineVariable } from '@sb/types';

import { IO_STATE_VARIABLES_DEFAULT } from './constants';

export function getVariablesFromIOInputs(
  ioInputs: Robot.IOPort[] | undefined,
): RoutineVariable.IOStateVariableInformation[] {
  if (!ioInputs) {
    return [];
  }

  return ioInputs.map((ioInput) => {
    const variable: RoutineVariable.IOStateVariableInformation = {
      displayName: ioInput.name,
      kind: 'ioState',
      port: ioInput.port,
      variableKind: 'enum',
      variableName: `kinematicState.ioState['Input ${ioInput.port}']` as const,
      deviceName: ioInput.deviceName,
      values: IO_STATE_VARIABLES_DEFAULT,
      userIdentifier: [
        'IO',
        camelCase(ioInput.deviceName),
        camelCase(ioInput.name),
      ]
        .filter((x) => x)
        .join('_'),
    };

    return variable;
  });
}
